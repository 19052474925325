import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import CustomTypography from "../CustomComponents/CustomTypography";
import { ReactComponent as HomeIcon } from "../../static/Icons/home.svg";
import { ReactComponent as ProposalIcon } from "../../static/Icons/proposal.svg";
import { ReactComponent as BuildingIcon } from "../../static/Icons/building.svg";
import { ReactComponent as AddIcon } from "../../static/Icons/add.svg";
import { ReactComponent as SearchIcon } from "../../static/Icons/search.svg";
import { ReactComponent as UnionSVG } from "../../static/Icons/union.svg";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setSelectedJobId } from "../../store";

const MainTabSection = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 0.75rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.75rem",
  flex: "1 0 0",
  alignSelf: "stretch",
}));

const HomeTab = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.5625rem",
  alignItems: "center",
  alignContent: "center",
  gap: "1.5rem",
  alignSelf: "stretch",
  borderRadius: "0.5625rem",
}));

const HomeFrame = styled(Box)(() => ({
  display: "flex",
  width: "11.75rem",
  alignItems: "center",
  gap: "0.4375rem",
  cursor: "pointer",
}));

const HomeIconBox = styled(Box)(() => ({
  display: "flex",
  width: "1.125rem",
  height: "1.125rem",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
}));

const HomeIconStyled = styled(HomeIcon)(() => ({
  width: "1.16913rem",
  height: "1.16913rem",
  flexShrink: 0,
}));

const HomeText = styled(CustomTypography)(() => ({
  width: "2.375rem",
  height: "0.82081rem",
  flexShrink: 0,
}));

const AccountTab = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.5625rem",
  alignItems: "center",
  alignContent: "center",
  gap: "1.5rem",
  alignSelf: "stretch",
  borderRadius: "0.5625rem",
}));

const AccountFrame = styled(Box)(() => ({
  display: "flex",
  width: "11.75rem",
  alignItems: "center",
  gap: "0.4375rem",
}));

const AccountText = styled(CustomTypography)(() => ({
  width: "6rem",
  height: "0.82081rem",
  flexShrink: 0,
}));

const JobsTab = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.5625rem 0rem 0.5625rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5625rem",
  alignSelf: "stretch",
}));

const JobsFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
}));

const JobsLogoTextFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.4375rem",
}));

const BuildingIconLogo = styled(BuildingIcon)(() => ({
  width: "1.125rem",
  height: "1.125rem",
}));

const JobsText = styled(CustomTypography)(() => ({
  width: "3.72781rem",
  height: "0.88856rem",
  colorOption: "PRIMARY_COLOR",
}));

const CreateAndSearchFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "3.10144rem",
}));

const SearchIconBox = styled(Box)(() => ({
  display: "flex",
  width: "1.35094rem",
  height: "1.35094rem",
  padding: "0.47956rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.36063rem",
  flexShrink: 0,
  borderRadius: "4.50769rem",
  background: "var(--Nuetrals-Background---Tertiary, #FFF)",
}));

const SearchIconStyled = styled(SearchIcon)(() => ({
  width: "0.77944rem",
  height: "0.77944rem",
  flexShrink: 0,
}));

const AddIconBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  gap: "0.30013rem",
  flexWrap: "wrap",
  position: "relative",
  "&:hover .createJobTooltip": {
    display: "block",
  },
}));

const AddIconStyled = styled(AddIcon)(() => ({
  width: "1.35094rem",
  height: "1.35094rem",
  fill: "var(--Buttons-Button----Tonal-Enabled---Hover---Pressed---BG, #FFF)",
}));

const Line = styled(Box)(() => ({
  width: "12.75rem",
  height: "0.04063rem",
  background: "var(--Nuetrals-Border---1, #ABA898)",
}));

const JobScroll = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 0.5625rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.25rem",
  alignSelf: "stretch",
}));

const JobScrollFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.8125rem",
  alignSelf: "stretch",
}));

const RecentTextFrame = styled(CustomTypography)(() => ({
  display: "flex",
  alignItems: "flex-start",
  gap: "0.8125rem",
  alignSelf: "stretch",
}));

/** Single job item container with the requested hover style. */
const JobScrollContentFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.4375rem 0.625rem",
  justifyContent: "space-between",
  gap: "2rem",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "0.5rem",
  cursor: "pointer",
  "&:hover": {
    borderRadius: "0.6875rem",
    background: "var(--Nuetrals-Background---Tab, #DCDACB)",
  },
}));

const CreateJobTooltip = styled(Box)(() => ({
  display: "none",
  position: "absolute",
  top: "-2.5rem",
  left: "-1rem",
  zIndex: 9999,
  cursor: "pointer",
}));

const TooltipBubble = styled(Box)(() => ({
  position: "relative",
  width: "6.44506rem",
  height: "1.93406rem",
  zIndex: 9999,
}));

const UnionIconStyled = styled(UnionSVG)(() => ({
  position: "absolute",
  top: "0.5rem",
  left: "-3.1rem",
  width: "9.44506rem",
  height: "2.93406rem",
  fill: "var(--Nuetrals-Background---Tertiary, #FFF)",
  strokeWidth: "1px",
  stroke: "var(--Nuetrals-Border---Tab, #DCDACB)",
  filter: "drop-shadow(0px 4px 11.9px rgba(0, 0, 0, 0.10))",
}));

const CreateJobText = styled(CustomTypography)(() => ({
  position: "absolute",
  top: "1.7rem",
  left: "1.7rem",
  transform: "translate(-50%, -50%)",
  color: "var(--Nuetrals-Text, #000)",
}));

const MainTab = ({ recentJobs = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Global store states
  const selectedJobId = useSelector((state) => state.jobsData.selectedJobId);
  const unsavedChanges = useSelector((state) => state.takeoffData.unsavedChanges);

  // For the "we're working on Home" message
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // For the custom "Unsaved Changes" modal
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);

  // Handler for job clicks
  const handleJobClick = (job) => {
    // If there are unsaved changes, do NOT navigate or set job
    if (unsavedChanges) {
      setUnsavedModalOpen(true);
      return;
    }
    // Otherwise, proceed
    dispatch(setSelectedJobId(job.id));
    navigate(`/validate/${job.id}`);

    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  const handleHomeClick = () => {
    if (unsavedChanges) {
      setUnsavedModalOpen(true);
      return;
    }    
    dispatch(setSelectedJobId(null));
    navigate(`/home`);
  };

  const handleProposalClick = () => {
    if (unsavedChanges) {
      setUnsavedModalOpen(true);
      return;
    }
    dispatch(setSelectedJobId(null));
    navigate(`/proposal`);
  }

  return (
    <MainTabSection>
      {/* Home */}
      <HomeTab onClick={handleHomeClick}>
        <HomeFrame>
          <HomeIconBox>
            <HomeIconStyled />
          </HomeIconBox>
          <HomeText variant="body3Medium14">Home</HomeText>
        </HomeFrame>
      </HomeTab>

      <HomeTab onClick={handleProposalClick}>
        <HomeFrame>
          <HomeIconBox>
            <ProposalIcon />
          </HomeIconBox>
          <HomeText variant="body3Medium14">Proposals</HomeText>
        </HomeFrame>
      </HomeTab>

      {/* Jobs */}
      <JobsTab>
        <JobsFrame>
          <JobsLogoTextFrame>
            <BuildingIconLogo />
            <JobsText variant="body3Medium14">Jobs</JobsText>
          </JobsLogoTextFrame>

          <CreateAndSearchFrame>
            {/* <SearchIconBox>
              <SearchIconStyled />
            </SearchIconBox> */}

            {/* The Add icon + Tooltip */}
            <AddIconBox
              onClick={() => {
                if (unsavedChanges) {
                  setUnsavedModalOpen(true);
                  return;
                }
                dispatch(setSelectedJobId(null));
                navigate(`/chat`);
              }}
            >
              <AddIconStyled />
              <CreateJobTooltip className="createJobTooltip">
                <TooltipBubble>
                  <UnionIconStyled />
                  <CreateJobText variant="regular12">Create Job</CreateJobText>
                </TooltipBubble>
              </CreateJobTooltip>
            </AddIconBox>
          </CreateAndSearchFrame>
        </JobsFrame>
        <Line />
      </JobsTab>

      {/* Recent jobs list */}
      <JobScroll>
        <JobScrollFrame>
          <RecentTextFrame variant="body3Medium14">Recent</RecentTextFrame>
          <Box>
            {recentJobs.length === 0 ? (
              <JobScrollContentFrame>
                <CustomTypography variant="regular12">
                  No recent jobs
                </CustomTypography>
              </JobScrollContentFrame>
            ) : (
              recentJobs.map((job) => (
                <JobScrollContentFrame
                  key={job.id}
                  onClick={() => handleJobClick(job)}
                  // Highlight if the job is currently selected
                  sx={{
                    ...(selectedJobId === job.id && {
                      borderRadius: "0.6875rem",
                      background: "#DCDACB",
                    }),
                  }}
                >
                  <Typography variant="body3Regular14">
                    {job.job_name || "Untitled Job"}
                  </Typography>
                </JobScrollContentFrame>
              ))
            )}
          </Box>
        </JobScrollFrame>
      </JobScroll>

      {/* MUI Snackbar for messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          severity="info"
          onClose={() => setOpenSnackbar(false)}
          sx={{ fontSize: "1rem" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* "Unsaved Changes" single-button dialog */}
      <Dialog
        open={unsavedModalOpen}
        onClose={() => setUnsavedModalOpen(false)}
        aria-labelledby="unsaved-changes-dialog"
      >
        <DialogTitle id="unsaved-changes-dialog">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You might have unsaved changes. Please save them before navigating away.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnsavedModalOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </MainTabSection>
  );
};

export default MainTab;
