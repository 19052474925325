// src/components/FileUpload/FileUpload.js

import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import CustomTypography from "../CustomComponents/CustomTypography";
import { Box } from "@mui/material";
import { ReactComponent as DownloadSVG } from "../../static/Icons/download.svg";

const FileUploadFrame = styled(Box)(() => ({
  display: 'flex',
  width: '44.33038rem',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2.5rem'
}));

const UploadText = styled(CustomTypography)(() => ({
  alignSelf: 'stretch',
  color: 'var(--Typography-Primary, #1D1D1B)',
  textAlign: 'center'
}));

const UploadField = styled(Box)(() => ({
  display: 'flex',
  padding: '2.25rem 2.75rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.58044rem',
  alignSelf: 'stretch',
  borderRadius: '2.04563rem',
  border: '1px dashed var(--Nuetrals-Border---1, #ABA898)',
  background: 'var(--Nuetrals-Background---Secondary, #E7E7DF)',
  boxShadow: '0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)',
  cursor: 'pointer',
  position: 'relative',

  // No visual change, just ensure we can drop on it:
  // We are NOT changing design (colors, fonts, etc.).
  // We only add the event handlers for drag & drop below.
}));

const UploadFieldFrame = styled(Box)(() => ({
  display: 'flex',
  width: '38.83038rem',
  padding: '1.05594rem 1.9375rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0.65994rem'
}));

const DescriptionFrame = styled(Box)(() => ({
  display: 'flex',
  width: '18.41294rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flex:4,
  gap: '0.5625rem',
  flexShrink: 0
}));

const UploadFileText = styled(CustomTypography)(() => ({
  alignSelf: 'stretch',
  color: 'var(--Typography-Primary, #1D1D1B)',
  fontFamily: 'Roboto',
  fontSize: '1.0625rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '120%'
}));

const AllowedFileTypes = styled(CustomTypography)(() => ({
  alignSelf: 'stretch',
  color: 'var(--Typography-Secondary, #666660)',
  fontFamily: 'Roboto',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '120%'
}));

const DownloadIcon = styled(DownloadSVG)(() => ({
  width: '1.5rem',
  height: '1.5rem',
  flexShrink: 0,
  flex:1,
}));

/**
 * onFileChosen(file: File)
 */
const FileUpload = ({ onFileChosen }) => {
  const fileInputRef = useRef(null);

  // For drag hover styling (optional). If you truly want no visual change, ignore.
  // Here we don’t apply any visible styling changes, so the UI remains identical.
  const [dragOver, setDragOver] = useState(false);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) return;
    const file = event.target.files[0];
    if (onFileChosen) onFileChosen(file);
  };

  // DRAG & DROP handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (onFileChosen) onFileChosen(file);
    }
  };

  return (
    <FileUploadFrame>
      <UploadText variant="h2">
        {/* Upload Your Project Plans &amp; Images */}
        Upload Your Project Plans
      </UploadText>

      <UploadField
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        // We do NOT change the styling based on dragOver
        // to avoid any UI changes. So the design stays identical.
      >
        {/* hidden file input */}
        <input
          type="file"
          accept=".pdf"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <UploadFieldFrame>
          <DescriptionFrame>
            <UploadFileText variant="body1">
              Starting a job is as easy as uploading the digital plans…we will start working on the full takeoff right away!
              {/* Select a file or drag and drop here */}
            </UploadFileText>
            <AllowedFileTypes variant="body3">
              PDF file size no more than 200MB
            </AllowedFileTypes>
          </DescriptionFrame>
          <DownloadIcon />
        </UploadFieldFrame>
      </UploadField>
    </FileUploadFrame>
  );
};

export default FileUpload;
