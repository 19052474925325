export const getHeaderData = (snapshot_data = {}) => {
  const projectTeam = snapshot_data?.data?.projectTeam || {};
  const projectIdentification =
    snapshot_data?.data?.projectIdentification || {};
  const projectTimeLine = snapshot_data?.data?.projectTimelineHighlights || {};

  const currentDate = new Date().toLocaleDateString();

  return {
    companyInfo: {
      name: projectTeam.Architect || "[Name of Company]",
      address: "[Address of Company]",
      phone: "[Phone of Company]",
      license: "[Value]",
      logo: null,
    },
    proposal: {
      date: new Date().toISOString().split("T")[0],
      validUntil: "[Valid Until Date]",
    },
    preparedFor: {
      name: "[Client Name]",
      address: "[Client Address]",
      contact: "[Client Phone]",
    },
    project: {
      name: projectIdentification["Project Name"] || "Project Name",
      address: projectIdentification["Project Location"] || "Project Address",
      projectType: projectIdentification["Project Type"] || "[Project Type]",
      duration: projectTimeLine["Estimated Duration"] || "[Project Duration]",
      completion: "[Project Completion Date]",
    },
    executiveSummary:
      "This construction project involves the development of a [type of project, ...]",
  };
};

export const getLicenseInsuranceData = (snapshot_data = {}) => {
  // There is no direct mapping for license/insurance details in the snapshot_data.
  // You can update these defaults if the data becomes available.
  return {
    licenseNumber: "[License Number]",
    validUntil: "[License Validity Date]",
    classifications: "[Type A, Type B, Type C]",
    liability: "[Liability Coverage]",
    workersComp: "[Workers Compensation Coverage]",
    bondCapacity: "[Bond Capacity]",
  };
};

export const getCompanyData = (snapshot_data = {}) => {
  // No mapping from snapshot_data for company info.
  return {
    foundedYear: "[Year of Foundation]",
    foundingStory: "[Company Founding Story]",
    missionStatement: "[Company Mission Statement]",
    coreValues: ["Value 1", "Value 2", "Value 3"],
    milestones: ["Achievement 1", "Achievement 2", "Achievement 3"],
    serviceAreas: ["Area 1", "Area 2", "Area 3", "Area 4"],
  };
};

export const getProjectOverviewData = (snapshot_data = {}) => {
  const projectIdentification =
    snapshot_data?.data?.projectIdentification || {};
  const buildingSizeAndLayout =
    snapshot_data?.data?.buildingSizeAndLayout || {};
  const potentialChallenges =
    snapshot_data?.data?.potentialChallengesOrConsiderations || {};

  return {
    // Use the Architectural Style to build a vision statement
    visionStatement: `A ${
      projectIdentification["Architectural Style"] || "unique"
    } project that redefines modern living.`,
    keyDeliverables: [
      `${
        buildingSizeAndLayout["Total Square Footage"] || 0
      } sq. ft. Total Area`,
      `${buildingSizeAndLayout["Bedrooms"] || 0} Bedrooms`,
      `${buildingSizeAndLayout["Bathrooms"] || 0} Bathrooms`,
      buildingSizeAndLayout["Garage"]
        ? `Garage: ${buildingSizeAndLayout["Garage"]}`
        : "",
    ].filter(Boolean),
    // Use the architectural style as a design style
    designStyle: [projectIdentification["Architectural Style"] || "Design"],
    // Use the site constraints (if any) as special requirements
    specialRequirements: [
      potentialChallenges["Site Constraints"],
      potentialChallenges["Technical Complexity"],
      potentialChallenges["Coordination Needs"],
    ] || ["Requirement 1", "Requirement 2"],
  };
};

export const getMaterialSpecificaitonsData = (snapshot_data = {}) => {
  const materialData = snapshot_data?.data?.materialAndFinishOverview || {};
  const structuralData = snapshot_data?.data?.structuralSystemsOverview || {};

  return {
    structuralMaterials: [
      structuralData["Foundation Type"] || "[Material]",
      structuralData["Framing Material"] || "[Material]",
      structuralData["Roof Type"] || "[Material]",
    ],
    interiorFinishes: materialData["Interior Finishes"] || [
      "[Finish]",
      "[Finish]",
      "[Finish]",
    ],
    exteriorMaterials: materialData["Exterior Materials"] || ["[Material]"],
  };
};

export const getFoundationDetailsData = (snapshot_data = {}) => {
  const structuralData = snapshot_data?.data?.structuralSystemsOverview || {};

  return [
    {
      label: "Foundation Type",
      value: structuralData["Foundation Type"] || "[Value]",
    },
    {
      label: "Framing Material",
      value: structuralData["Framing Material"] || "[Value]", // Not provided in snapshot_data
    },
    {
      label: "Roof Type",
      value: structuralData["Roof Type"] || "[Value]", // Not provided in snapshot_data
    },
  ];
};

export const getAllowancesData = (snapshot_data = {}) => {
  // No mapping available in snapshot_data for allowances.
  return [
    {
      label: "Fixtures Allowance",
      value: "[Value]",
    },
    {
      label: "Finishes Allowance",
      value: "[Value]",
    },
    {
      label: "Landscaping Allowance",
      value: "[Value]",
    },
    {
      label: "Contingency Allowance",
      value: "[Value]",
    },
  ];
};

export const getLegalComplianceData = (snapshot_data = {}) => {
  const regulatoryInfo =
    snapshot_data?.data?.regulatoryAndComplianceInformation || {};

  return {
    "Contract Terms & Conditions": [
      {
        label: "Building Codes",
        value: regulatoryInfo["Building Codes"] || "[Description]",
      },
    ],
    "Zoning Requirements": [
      {
        label: "Zoning Requirements",
        value: regulatoryInfo["Zoning Requirements"] || "[Description]",
      },
    ],
    "Permits & Compliance": [
      {
        label: "Permits & Approvals",
        value: regulatoryInfo["Permits & Approvals"] || "[Description]",
      },
    ],
    Insurance: [
      {
        label: "Insurance",
        value: "[Value]", // Update if snapshot_data provides insurance details
      },
    ],
    "Dispute Resolution": [
      {
        label: "Dispute Resolution",
        value: "[Value]", // Update as necessary
      },
    ],
  };
};

export const getSystemsData = (snapshot_data = {}) => {
  const systemsData =
    snapshot_data?.data?.mechanicalElectricalAndPlumbingSystems || {};

  return [
    {
      label: "HVAC System",
      value: systemsData["HVAC System"] || "[Value]",
    },
    {
      label: "Plumbing System",
      value: systemsData["Plumbing Features"] || "[Value]",
    },
    {
      label: "Electrical System",
      value: systemsData["Electrical Systems"] || "[Value]",
    },
    {
      label: "Fire Protection System",
      value: systemsData["Fire Protection"] || "[Value]",
    },
  ];
};
