// src/components/Authentication/Login.jsx
import React, { useState, useContext } from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Link,
  styled,
  Box,
  Input
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../CardComponent';
import AlertMessage from '../AlertMessage';
import Navbar from '../Navbar/Navbar';

import { ReactComponent as GoogleIcon } from "../../static/Icons/googleIcon.svg";
import { color, height, width } from '@mui/system';

const LoginFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1.5rem',
}));

const WelcomeFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}));

const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  width: '100%',
}));

const UsernamePasswordFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));

const InputFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const GoogleFrame = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '629.40213rem',
  border: '1px solid #DCDACB',
  background: '#FFF',
  padding: '0.5rem 1.5rem',
}));

const UsernamePasswordInnerFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoginButtonFrame = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
}));

// Updated TextInputFrame with proper autofill override and simplified styling
const TextInputFrame = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '3.02144rem',
    background: theme.palette.neutrals.background.input2,
    borderRadius: '0.94419rem',
    '& fieldset': {
      border: 'none',
    },
    // '&:hover fieldset': {
    //   border: 'none',
    //   background: theme.palette.neutrals.background.input2,
    // },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '0.69244rem 1.00713rem',
      // Override autofill styles so that the background and text color remain consistent.
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.neutrals.background.input2} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
}));

function Login({ onSwitchToSignup }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { usernamePassLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await usernamePassLogin(username, password);
      if (user) {
        navigate('/chat');
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Login failed');
    }
  };

  return (
    <div>
      {/* <CardComponent title="Login"> */}

      <LoginFrame>
        <WelcomeFrame>
          <Typography variant="welcometext" align="center" sx={{ color: 'typographyPrimary.main' }}>
            Welcome Back!
          </Typography>
          <Typography variant="logintext" align="center" sx={{ color: 'typographySecondary.main' }} my={1}>
            Please login using your Assembli credentials
          </Typography>
        </WelcomeFrame>

        <FormFrame>
          <form onSubmit={handleLogin}>
            <InputFrame>
              <Typography variant="body2medium16">Username</Typography>
              <TextInputFrame
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="username"
              />

              <Typography variant="body2medium16">Password</Typography>
              <TextInputFrame
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputFrame>


            <LoginButtonFrame type="submit" variant="ghost" color="primary">
                Login
              </LoginButtonFrame>

          </form>
        </FormFrame>
      </LoginFrame>

      {error && (
        <AlertMessage message={error} type="error" onClose={() => setError('')} />
      )}

      <Box mt={2}>
        <Typography variant="body2" align="center" my={1}>
          <Link
            href="/forgot-username"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            Forgot Username?
          </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            href="/forgot-password"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            Forgot Password?
          </Link>
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" align="center">
          Need help? Contact support at{' '}
          <Link
            href="mailto:support@assembli.ai"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            support@assembli.ai
          </Link>
        </Typography>
      </Box>

      {onSwitchToSignup && (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          className="mt-4"
        >
          Don’t have an account?{' '}
          <Link
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
            onClick={onSwitchToSignup}
            style={{ cursor: 'pointer' }}
          >
            Sign up here
          </Link>
        </Typography>
      )}

      {/*         
        <form onSubmit={handleLogin} className="space-y-4">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
            Login
          </Button>
        </form>

        <AlertMessage message={error} type="error" onClose={() => setError('')} />

        <div className="mt-4 flex items-center justify">
          <Typography variant="body2" align="center" my={1}>
            <Link
              href="/forgot-username"
              underline="hover"
              className="text-blue-600 hover:text-blue-800"
            >
              Forgot Username?
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              href="/forgot-password"
              underline="hover"
              className="text-blue-600 hover:text-blue-800"
            >
              Forgot Password?
            </Link>
          </Typography>
        </div>

        <div className="mt-6 flex items-center justify-center space-x-2 text-gray-600">
          <Typography variant="body2">
            Need help? Contact support at{' '}
            <Link
              href="mailto:support@assembli.ai"
              underline="hover"
              className="text-blue-600 hover:text-blue-800"
            >
              support@assembli.ai
            </Link>
          </Typography>
        </div> */}

      {/* CHANGED: Instead of <Link href="/signup">, we call onSwitchToSignup() */}
      {/* <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          className="mt-4"
        >
          Don’t have an account?{' '}
          <Link
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
            onClick={onSwitchToSignup}
            style={{ cursor: 'pointer' }}
          >
            Sign up here
          </Link>
        </Typography> */}
      {/* </CardComponent> */}
    </div>
  );
}

export default Login;
