
///Feb 5 with Unit labouur and Material
// import React from "react";
// import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
// import { VerticalBar, CostIcon } from "./Icons";
// import { styles as mainstyles } from "./StyleSheets";
// import {
//   normalizeTakeOffData,
//   generateSettings,
//   createExportData,
// } from "../utils/takeOffExport";

// const styles = StyleSheet.create({
//   page: {
//     fontSize: 10,
//     fontFamily: "Helvetica",
//   },
//   tableHeader: {
//     flexDirection: "row",
//     color: "#2961A7",
//     borderBottom: "1 solid #000",
//     marginBottom: 2,
//     fontWeight: "bold",
//   },
//   tableRow: {
//     flexDirection: "row",
//     borderBottom: "1 solid #ccc",
//     paddingVertical: 2,
//   },
//   tableCell: {
//     fontSize: 10,
//     flex: 1,
//     padding: 2,
//   },
//   totalRow: {
//     flexDirection: "row",
//     borderTop: "1.5 solid #000",
//     marginTop: 5,
//     fontWeight: "bold",
//   },
//   grandTotal: {
//     padding: 5,
//     backgroundColor: "#e6ffe6",
//     textAlign: "right",
//     fontWeight: "bold",
//     fontSize: 12,
//     marginBottom: 10,
//     borderRadius: 10,
//   },
// });

// const CostBreakDownPDF = ({ data }) => {
//   // 1) Safety check
//   if (!data || !data.takeOff) {
//     return (
//       <Document>
//         <Page style={styles.page}>
//           <Text>No takeoff data available</Text>
//         </Page>
//       </Document>
//     );
//   }

//   // 2) If user gave us settings, we use them. Otherwise, generate defaults
//   let finalExport;
//   if (data.settings && Object.keys(data.settings).length > 0) {
//     // They already have settings
//     const normalized = normalizeTakeOffData(data.takeOff);
//     finalExport = createExportData(normalized, data.settings);
//   } else {
//     // No valid settings => generate default
//     const normalized = normalizeTakeOffData(data.takeOff);
//     const defaultSettings = generateSettings(normalized);
//     finalExport = createExportData(normalized, defaultSettings);
//     console.log("finalExport in pdf", finalExport);
//   }

//   const divisionsToShow = finalExport.divisions.filter(
//     (division) =>
//       division.divisionKey !== "General_Info" &&
//       division.divisionKey !== "undefined"
//   );

//   return (
//     <Document>
//       <View style={mainstyles.page}>
//         {/* Header */}
//         <View style={mainstyles.header}>
//           <VerticalBar style={mainstyles.icon} />
//           <Text style={mainstyles.headerTitle}>Cost Breakdown Analysis</Text>
//         </View>

//         {divisionsToShow.map((division) => {
//           const { divisionKey, divisionName, total, columns, subdivisions } =
//             division || {};

//           console.log("columns", columns, "subdivisions", subdivisions);

//           // If no columns are turned on, optionally skip the table entirely
//           const hasAtLeastOneColumn = Object.values(columns || {}).some(
//             Boolean
//           );

//           return (
//             <View
//               key={divisionKey}
//               style={{
//                 marginBottom: 7,
//                 backgroundColor: "#F6F5F4",
//                 borderRadius: 10,
//                 padding: 10,
//               }}
//             >
//               {/* Division Header */}
//               <View style={mainstyles.sectionHeaderBox} wrap={false}>
//                 <View style={mainstyles.sectionHeader}>
//                   <CostIcon style={mainstyles.icon} />
//                   <Text style={mainstyles.title}>
//                     {divisionKey} - {divisionName}
//                   </Text>
//                 </View>
//               </View>

//               {/* If no columns are turned on, show nothing or a placeholder */}
//               {hasAtLeastOneColumn ? (
//                 <>
//                   {/* Table Header */}
//                   <View style={styles.tableHeader}>
//                     {columns.subdivisions && (
//                       <Text style={styles.tableCell}>Subdivision</Text>
//                     )}
//                     {columns.quantities && (
//                       <Text style={styles.tableCell}>Quantity</Text>
//                     )}
//                     {columns.units && (
//                       <Text style={styles.tableCell}>Units</Text>
//                     )}
//                     {columns.unitLaborCosts && (
//                       <Text style={styles.tableCell}>Unit Labor Cost</Text>
//                     )}
//                     {columns.unitMaterialCosts && (
//                       <Text style={styles.tableCell}>Unit Material Cost</Text>
//                     )}
//                     {columns.totalCosts && (
//                       <Text style={styles.tableCell}>Total Cost</Text>
//                     )}
//                     {columns.wasteFactor && (
//                       <Text style={styles.tableCell}>Waste Factor</Text>
//                     )}
//                   </View>

//                   {/* Table Body */}
//                   {subdivisions.map((sub, idx) => (
//                     <View style={styles.tableRow} key={idx}>
//                       {columns.subdivisions && (
//                         <Text style={styles.tableCell}>{sub.name}</Text>
//                       )}
//                       {columns.quantities && (
//                         <Text style={styles.tableCell}>{sub.quantity}</Text>
//                       )}
//                       {columns.units && (
//                         <Text style={styles.tableCell}>{sub.units}</Text>
//                       )}
//                       {columns.unitLaborCosts && (
//                         <Text style={styles.tableCell}>
//                           {sub.unitLaborCost !== null
//                             ? `$${(sub.unitLaborCost || 0).toLocaleString()}`
//                             : ""}
//                         </Text>
//                       )}
//                       {columns.unitMaterialCosts && (
//                         <Text style={styles.tableCell}>
//                           {sub.unitMaterialCost !== null
//                             ? `$${(sub.unitMaterialCost || 0).toLocaleString()}`
//                             : ""}
//                         </Text>
//                       )}
//                       {columns.totalCosts && (
//                         <Text style={styles.tableCell}>
//                           {sub.totalCost !== null
//                             ? `$${(sub.totalCost || 0).toLocaleString()}`
//                             : ""}
//                         </Text>
//                       )}
//                       {columns.wasteFactor && (
//                         <Text style={styles.tableCell}>
//                           {sub.wasteFactor ?? ""}
//                         </Text>
//                       )}
//                     </View>
//                   ))}
//                 </>
//               ) : (
//                 <Text style={{ fontStyle: "italic", marginTop: 5 }}>
//                   (No columns selected)
//                 </Text>
//               )}

//               {/* Division Total */}
//               <View style={styles.totalRow}>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     { textAlign: "right", fontWeight: "bold" },
//                   ]}
//                 >
//                   Division Total: ${total.toLocaleString()}
//                 </Text>
//               </View>
//             </View>
//           );
//         })}

//         {/* Grand Total */}
//         <Text style={styles.grandTotal}>
//           Grand Total: ${finalExport.grandTotal.toLocaleString()}
//         </Text>
//       </View>
//     </Document>
//   );
// };

// export default CostBreakDownPDF;

// /*************************************************
//  * CostBreakDownPDF.js
//  *************************************************/
// import React from "react";
// import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
// import { normalizeTakeOffData, createExportData } from "../utils/takeOffExport";

// const styles = StyleSheet.create({
//   page: {
//     fontSize: 10,
//     fontFamily: "Helvetica",
//     padding: 16,
//   },
//   header: {
//     fontSize: 16,
//     marginBottom: 12,
//     fontWeight: "bold",
//   },
//   divisionBox: {
//     marginBottom: 10,
//     padding: 8,
//     border: "1 solid #ccc",
//   },
//   divisionTitle: {
//     fontSize: 12,
//     marginBottom: 6,
//     fontWeight: "bold",
//   },
//   subdivisionTitleLine: {
//     fontSize: 10,
//     marginTop: 4,
//     marginBottom: 2,
//     paddingVertical: 2,
//     fontWeight: "bold",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     backgroundColor: "#f0f0f0",
//   },
//   itemsTableHeader: {
//     flexDirection: "row",
//     borderBottom: "1 solid #000",
//     marginBottom: 2,
//     fontWeight: "bold",
//   },
//   itemsRow: {
//     flexDirection: "row",
//     borderBottom: "1 solid #ccc",
//     paddingVertical: 2,
//   },
//   cell: {
//     fontSize: 10,
//     padding: 2,
//     flexGrow: 1,
//   },
//   grandTotal: {
//     marginTop: 16,
//     padding: 6,
//     fontSize: 12,
//     fontWeight: "bold",
//     textAlign: "right",
//     backgroundColor: "#e6ffe6",
//     borderRadius: 4,
//   },
// });

// // We define a column label map
// const columnLabels = {
//   unit: "Unit",
//   quantity: "Quantity",
//   materialPrice: "Material Price",
//   laborPrice: "Labor Price",
//   extendedPrice: "Extended Price",
//   markupPercent: "Markup %",
//   markupAmount: "Markup $",
//   taxPercent: "Tax %",
//   taxAmount: "Tax $",
//   total: "Total",
// };

// const CostBreakDownPDF = ({ data }) => {
//   // 1) Safety check
//   if (!data || !data.takeOff) {
//     return (
//       <Document>
//         <Page style={styles.page}>
//           <Text>No takeoff data available</Text>
//         </Page>
//       </Document>
//     );
//   }

//   // We'll check the preset to know if we skip subdivisions in "overview"
//   const preset = data.settings?.activePreset || "standard";

//   // 2) Build final data, which includes toggles
//   const normalized = normalizeTakeOffData(data.takeOff);
//   const finalExport = createExportData(normalized, data.settings);

//   const { divisions, grandTotal } = finalExport;
//   if (!divisions || divisions.length === 0) {
//     return (
//       <Document>
//         <Page style={styles.page}>
//           <Text>No divisions found</Text>
//         </Page>
//       </Document>
//     );
//   }

//   // We’ll gather which columns appear at least once across all items
//   // BUT only if we plan to show items (i.e. not in "overview").
//   // If preset is "overview", we skip columns entirely.
//   let usedColumns = new Set();
//   if (preset !== "overview") {
//     divisions.forEach((div) => {
//       div.subdivisions.forEach((sub) => {
//         sub.items.forEach((item) => {
//           Object.keys(item).forEach((key) => {
//             if (key !== "name" && item[key] != null) {
//               usedColumns.add(key);
//             }
//           });
//         });
//       });
//     });
//   }
//   // Convert to array in the order we want:
//   const columnOrder = [
//     "unit",
//     "quantity",
//     "materialPrice",
//     "laborPrice",
//     "extendedPrice",
//     "markupPercent",
//     "markupAmount",
//     "taxPercent",
//     "taxAmount",
//     "total",
//   ].filter((col) => usedColumns.has(col));

//   return (
//     <Document>
//       <View style={styles.page}>
//         <Text style={styles.header}>Cost Breakdown Analysis</Text>

//         {divisions.map((division) => {
//           const { divisionKey, divisionName, divisionTotal, subdivisions } =
//             division;

//           // If "overview", we skip subdivisions entirely
//           // So we just show a box for the division
//           if (preset === "overview") {
//             return (
//               <View key={divisionKey} style={styles.divisionBox}>
//                 <Text style={styles.divisionTitle}>
//                   {divisionKey} - {divisionName} (Total: $
//                   {divisionTotal.toLocaleString()})
//                 </Text>
//               </View>
//             );
//           }

//           // Otherwise (standard, costs, custom), we show subdivisions
//           return (
//             <View key={divisionKey} style={styles.divisionBox}>
//               <Text style={styles.divisionTitle}>
//                 {divisionKey} - {divisionName} (Total: $
//                 {divisionTotal.toLocaleString()})
//               </Text>

//               {/* If "costs" preset => columns are all off, effectively no items.
//                   We'll still show subdivision header with "Subdivision Total".
//                   If standard or custom => we might have columns.
//               */}
//               {subdivisions.map((sub, idx) => {
//                 const {
//                   subdivisionKey,
//                   subdivisionName,
//                   subdivisionTotal,
//                   items,
//                 } = sub;

//                 // Subdivision header line
//                 return (
//                   <View key={idx}>
//                     <View style={styles.subdivisionTitleLine}>
//                       <Text style={[styles.cell, { fontWeight: "bold" }]}>
//                         {subdivisionKey} - {subdivisionName}
//                       </Text>
//                       <Text style={[styles.cell, { textAlign: "right" }]}>
//                         Subdivision Total: ${subdivisionTotal.toLocaleString()}
//                       </Text>
//                     </View>

//                     {/* If we have some columns in usedColumns, we show a table header and rows.
//                         If we're in "costs" preset => usedColumns is likely empty, so no items.
//                     */}
//                     {columnOrder.length > 0 && items.length > 0 && (
//                       <View>
//                         {/* Items Table Header */}
//                         <View style={styles.itemsTableHeader}>
//                           {columnOrder.map((colKey) => (
//                             <Text key={colKey} style={styles.cell}>
//                               {columnLabels[colKey]}
//                             </Text>
//                           ))}
//                         </View>

//                         {/* Items Rows */}
//                         {items.map((item, iidx) => (
//                           <View style={styles.itemsRow} key={iidx}>
//                             {columnOrder.map((colKey) => {
//                               let displayVal = "";
//                               const val = item[colKey];
//                               switch (colKey) {
//                                 case "materialPrice":
//                                 case "laborPrice":
//                                 case "extendedPrice":
//                                 case "markupAmount":
//                                 case "taxAmount":
//                                 case "total":
//                                   displayVal =
//                                     val != null
//                                       ? `$${val.toLocaleString()}`
//                                       : "$0";
//                                   break;
//                                 case "markupPercent":
//                                 case "taxPercent":
//                                   displayVal = val != null ? `${val}%` : "0%";
//                                   break;
//                                 case "quantity":
//                                   displayVal = val != null ? val : "";
//                                   break;
//                                 case "unit":
//                                   displayVal = val || "";
//                                   break;
//                                 default:
//                                   displayVal = val || "";
//                                   break;
//                               }
//                               return (
//                                 <Text key={colKey} style={styles.cell}>
//                                   {displayVal}
//                                 </Text>
//                               );
//                             })}
//                           </View>
//                         ))}
//                       </View>
//                     )}
//                   </View>
//                 );
//               })}
//             </View>
//           );
//         })}

//         {/* Grand Total */}
//         <Text style={styles.grandTotal}>
//           Grand Total: ${grandTotal.toLocaleString()}
//         </Text>
//       </View>
//     </Document>
//   );
// };

import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { normalizeTakeOffData, createExportData } from "../utils/takeOffExport";
import { VerticalBar, CostIcon } from "./Icons";
import { styles as mainstyles } from "./StyleSheets";

// ---------------------------------------------------------------------
// Styles remain the same
// ---------------------------------------------------------------------
const pdfStyles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontFamily: "Helvetica",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 10,
    border: "1 solid #D1D5DB",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    borderBottom: "1 solid #E5E7EB",
  },
  divisionSection: {
    marginBottom: 10,
    backgroundColor: "#F6F5F4",
    borderRadius: 10,
    padding: 10,
  },
  divisionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 6,
    color: "#111827",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottom: "1 solid #000",
    marginBottom: 2,
    fontWeight: "bold",
    color: "#2961A7",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1 solid #ccc",
    paddingVertical: 2,
  },
  tableCell: {
    fontSize: 10,
    flex: 1,
    padding: 2,
  },
  subdivisionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#E7E7DF",
    padding: 4,
    fontWeight: "bold",
    marginTop: 6,
    marginBottom: 2,
  },
  subdivisionTotalRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    borderTop: "1 solid #ccc",
    paddingTop: 2,
    marginTop: 2,
    marginBottom: 4,
  },
  divisionTotalRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    borderTop: "1.5 solid #000",
    marginTop: 5,
    paddingTop: 3,
    fontWeight: "bold",
  },
  grandTotal: {
    padding: 5,
    backgroundColor: "#e6ffe6",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: 12,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
});

// ---------------------------------------------------------------------
// Column labels remain the same
// ---------------------------------------------------------------------
const columnLabels = {
  name: "Item Name",
  unit: "Unit",
  quantity: "Quantity",
  wasteFactor: "Waste Factor (%)",
  materialPrice: "Material Price",
  laborPrice: "Labor Price",
  extendedPrice: "Extended Price",
  markupPercent: "Markup %",
  markupAmount: "Markup $",
  taxPercent: "Tax %",
  taxAmount: "Tax $",
  total: "Total",
};

// NEW: Our master column order, so we always keep the same ordering
const masterColumnOrder = [
  "name",
  "unit",
  "quantity",
  "wasteFactor",
  "materialPrice",
  "laborPrice",
  "extendedPrice",
  "markupPercent",
  "markupAmount",
  "taxPercent",
  "taxAmount",
  "total",
];

const CostBreakDownPDF = ({ data }) => {
  if (!data || !data.takeOff) {
    return (
      <Document>
        <Page style={pdfStyles.page}>
          <Text>No takeoff data available</Text>
        </Page>
      </Document>
    );
  }

  const preset = data.settings?.activePreset || "standard";
  const normalized = normalizeTakeOffData(data.takeOff);
  const finalExport = createExportData(normalized, data.settings);
  const { divisions, grandTotal } = finalExport;

  if (!divisions || divisions.length === 0) {
    return (
      <Document>
        <Page style={pdfStyles.page}>
          <Text>No divisions found</Text>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <View style={pdfStyles.page}>
        <View style={mainstyles.header}>
          <VerticalBar />
          <Text style={mainstyles.headerTitle}>Company Overview & History</Text>
        </View>

        {divisions.map((division) => {
          const {
            divisionKey,
            divisionName,
            divisionTotal,
            columns: divisionColumns, // NEW: this is the toggles object
            subdivisions,
          } = division;

          // OVERVIEW: only show the division + total, skip subdivisions/items
          if (preset === "overview") {
            return (
              <View key={divisionKey} style={pdfStyles.divisionSection}>
                <Text style={pdfStyles.divisionTitle}>
                  {divisionKey} - {divisionName}
                </Text>
                <View style={pdfStyles.divisionTotalRow}>
                  <Text>Division Total: ${divisionTotal.toLocaleString()}</Text>
                </View>
              </View>
            );
          }

          // Otherwise, show subdivisions + items
          // NEW: Determine for THIS division which columns are turned on
          // We filter the masterColumnOrder by divisionColumns[colKey] === true
          const divisionColumnOrder = masterColumnOrder.filter(
            (colKey) => divisionColumns[colKey]
          );

          return (
            <View key={divisionKey} style={pdfStyles.divisionSection}>
              <View style={mainstyles.sectionHeaderBox}>
                <View style={mainstyles.sectionHeader}>
                  <CostIcon style={mainstyles.icon} />

                  <Text style={mainstyles.title}>
                    {divisionKey} - {divisionName}
                  </Text>
                </View>
              </View>
              {/* <Text style={pdfStyles.divisionTitle}>
                {divisionKey} - {divisionName}
              </Text> */}

              {/* If we have at least one column toggled on, render table header */}
              {divisionColumnOrder.length > 0 && (
                <View style={pdfStyles.tableHeader}>
                  {divisionColumnOrder.map((colKey) => (
                    <Text
                      key={colKey}
                      style={[
                        pdfStyles.tableCell,
                        colKey === "name" && { flex: 2 },
                        colKey === "extendedPrice" && { flex: 2 },
                      ]}
                    >
                      {columnLabels[colKey]}
                    </Text>
                  ))}
                </View>
              )}

              {/* Subdivisions */}
              {subdivisions.map((sub, sIndex) => {
                const {
                  subdivisionKey,
                  subdivisionName,
                  subdivisionTotal,
                  items,
                } = sub;

                return (
                  <View key={subdivisionKey}>
                    <View style={pdfStyles.subdivisionHeader}>
                      <Text style={[pdfStyles.tableCell, { flex: 1 }]}>
                        {subdivisionName}
                      </Text>
                    </View>

                    {/* Items */}
                    {items.map((item, iIndex) => {
                      // If no columns toggled on, skip entirely
                      if (divisionColumnOrder.length === 0) return null;

                      return (
                        <View key={iIndex} style={pdfStyles.tableRow}>
                          {divisionColumnOrder.map((colKey) => {
                            let displayVal = "";
                            const val = item[colKey];

                            switch (colKey) {
                              case "name":
                                displayVal = val || "";
                                break;
                              case "materialPrice":
                              case "laborPrice":
                              case "extendedPrice":
                              case "markupAmount":
                              case "taxAmount":
                              case "total":
                                displayVal =
                                  val != null
                                    ? `$${val.toLocaleString()}`
                                    : "$0";
                                break;
                              case "markupPercent":
                              case "taxPercent":
                                displayVal = val != null ? `${val}%` : "0%";
                                break;
                              case "quantity":
                                displayVal = val != null ? val : "";
                                break;
                              case "unit":
                                displayVal = val || "";
                                break;
                              case "wasteFactor":
                                displayVal = val != null ? `${val}%` : "0%";
                                break;
                              default:
                                displayVal = val || "";
                                break;
                            }

                            return colKey === "name" ||
                              colKey === "extendedPrice" ? (
                              <Text
                                key={colKey}
                                style={[pdfStyles.tableCell, { flex: 2 }]}
                              >
                                {displayVal}
                              </Text>
                            ) : (
                              <Text key={colKey} style={pdfStyles.tableCell}>
                                {displayVal}
                              </Text>
                            );
                          })}
                        </View>
                      );
                    })}

                    {/* Subdivision total */}
                    <View style={pdfStyles.subdivisionTotalRow}>
                      <Text>
                        Subdivision Total: ${subdivisionTotal.toLocaleString()}
                      </Text>
                    </View>
                  </View>
                );
              })}

              {/* Division total */}
              <View style={pdfStyles.divisionTotalRow}>
                <Text>Division Total: ${divisionTotal.toLocaleString()}</Text>
              </View>
            </View>
          );
        })}

        {/* Grand Total */}
        <Text style={pdfStyles.grandTotal}>
          Grand Total: ${grandTotal.toLocaleString()}
        </Text>
      </View>
    </Document>
  );
};

export default CostBreakDownPDF;
