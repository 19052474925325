// src/components/EstimationStudio/ImageDrawer.jsx
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../static/Icons/close.svg";

const Rectangle = styled(Box)(() => ({
  width: "36.41538rem",
  height: "61.76106rem",
  flexShrink: 0,
  background: "var(--Nuetrals-Background---Tertiary, #FFF)",
}));

const ImageDrawerFrame = styled(Box)(() => ({
  display: "flex",
  width: "34.375rem",
  flexDirection: "column",
  justifyContent: "right",
  alignItems: "center",
  gap: "1.875rem",
  backgroundColor: "#FFF",
  height: "100vh",
  overflowY: "auto",
}));

const HeadingImageDrawerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.1875rem 1.375rem 0rem 1.375rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.9375rem",
  alignSelf: "stretch",
}));

const HeadingImageDrawerInnerFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
}));

const CloseButtonFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.34369rem",
  alignItems: "center",
  alignContent: "center",
  gap: "0.41244rem",
  flexWrap: "wrap",
  borderRadius: "1.9935rem",
  background: "#F3F3F1",
  cursor: "pointer",
  marginRight: "0.625rem",
}));

const CloseButtonIcon = styled(CloseIcon)(() => ({
  width: "1.5rem",
  height: "1.5rem",
}));

const DetailsFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.5625rem",
  alignSelf: "stretch",
}));

const ImagesFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2.1875rem",
  alignSelf: "stretch",
}));

const ImageFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 1.375rem",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

/** 
 * The overlay for showing the enlarged image with a 
 * “genie” scale/fade‐in animation.
 */
const OverlayBackdrop = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999,
  // Keyframe animation for a slight "genie" scale/fade in
  animation: "genieOpen 0.4s ease forwards",
  "@keyframes genieOpen": {
    "0%": {
      transform: "scale(0.7)",
      opacity: 0,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
});

/**
 * A container with a border where the image is shown/panned.
 */
const ZoomPanContainer = styled(Box)({
  position: "relative",
  width: "80vw",
  height: "80vh",
  overflow: "hidden",
  border: "2px solid #ddd",
  borderRadius: "0.75rem",
  backgroundColor: "#fff",
  // We also fade in the container so it looks smooth
  opacity: 0,
  animation: "fadeInContainer 0.4s forwards",
  "@keyframes fadeInContainer": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
});

/**
 * The actual image that is draggable and zoomable.
 */
const DraggableZoomImage = styled("img")({
  cursor: "grab",
  // Smooth transitions if the user quickly changes zoom:
  transition: "transform 0.1s ease-out",
  width: "100%",
  height: "100%",
});

/**
 * Props:
 *  - onClose: function()  (Close the drawer)
 *  - subdivisionName?: string (if you want to show which sub was clicked)
 *  - images?: string[] (array of image URLs)
 */
const ImageDrawer = ({ onClose, subdivisionName, images = [] }) => {
  // Track which image (if any) is enlarged
  const [enlargedIndex, setEnlargedIndex] = useState(null);

  // Zoom & pan states for the enlarged image
  const [zoom, setZoom] = useState(1);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const imageRef = useRef(null);

  // Handler: open the enlarged version of the clicked image
  const handleEnlarge = (idx) => {
    setEnlargedIndex(idx);
    setZoom(1);
    setPosX(0);
    setPosY(0);
  };

  // Close the enlarged image
  const handleCloseEnlarged = () => {
    setEnlargedIndex(null);
    // reset transforms
    setZoom(1);
    setPosX(0);
    setPosY(0);
    setIsDragging(false);
  };

  // Zoom methods
  const handleZoomIn = () => setZoom((prev) => prev + 0.25);
  const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.25, 0.25));

  // Mouse/panning handlers
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.clientX - posX);
    setStartY(e.clientY - posY);
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    // Calculate new offset
    const x = e.clientX - startX;
    const y = e.clientY - startY;
    setPosX(x);
    setPosY(y);
  };

  // Trackpad or mouse wheel => zoom
  const handleWheel = (e) => {
    e.preventDefault();
    if (e.deltaY < 0) {
      // Scrolling "up" => zoom in
      handleZoomIn();
    } else if (e.deltaY > 0) {
      // Scrolling "down" => zoom out
      handleZoomOut();
    }
  };

  // Listen for pointer movements outside container
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  // Keyboard shortcuts => zoom in/out
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (enlargedIndex === null) return;
      if (e.key === "+" || e.key === "=") {
        handleZoomIn();
      } else if (e.key === "-") {
        handleZoomOut();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [enlargedIndex]);

  return (
    <ImageDrawerFrame>
      <HeadingImageDrawerFrame>
        <HeadingImageDrawerInnerFrame>
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
            {/* Optionally show name or any icon here */}
          </Box>
          <CloseButtonFrame onClick={onClose}>
            <CloseButtonIcon />
          </CloseButtonFrame>
        </HeadingImageDrawerInnerFrame>

        <DetailsFrame>
          {/* <Typography variant="h3" sx={{ color: "#000" }}>
            Data Pull
          </Typography> */}
          <Typography variant="body3" sx={{ color: "#000", fontWeight: 600 }}>
          Validate the Assembli Model annotations!
          </Typography>
        </DetailsFrame>

        <Box
          sx={{
            width: "31.625rem",
            height: "0.0625rem",
            background: "var(--Nuetrals-Border---2, #DCDACB)",
          }}
        ></Box>
      </HeadingImageDrawerFrame>

      {/* Thumbnails (or smaller images) */}
      <ImagesFrame>
        {images.map((src, idx) => (
          <ImageFrame key={idx}>
            <img
              src={src}
              alt={`subdivision-image-${idx}`}
              style={{ maxWidth: "100%", maxHeight: "25vh", flex: 1, cursor: "pointer" }}
              onClick={() => handleEnlarge(idx)}
            />
          </ImageFrame>
        ))}
      </ImagesFrame>

      {/* Enlarged "genie" overlay */}
      {enlargedIndex !== null && (
        <OverlayBackdrop onClick={handleCloseEnlarged}>
          <ZoomPanContainer
            onClick={(e) => {
              // Stop click inside container from closing
              e.stopPropagation();
            }}
            // Mouse/touchpad wheel event for zoom
            onWheel={handleWheel}
          >
            {/* Pannable/zoomable image */}
            <DraggableZoomImage
              ref={imageRef}
              src={images[enlargedIndex]}
              alt="enlarged"
              onMouseDown={handleMouseDown}
              style={{
                transform: `translate(${posX}px, ${posY}px) scale(${zoom})`,
              }}
            />

            {/* Zoom controls in top-right corner */}
            <Box
              sx={{
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Button variant="contained" onClick={handleZoomIn}>
                +
              </Button>
              <Button variant="contained" onClick={handleZoomOut}>
                -
              </Button>
            </Box>
          </ZoomPanContainer>
        </OverlayBackdrop>
      )}
    </ImageDrawerFrame>
  );
};

export default ImageDrawer;
