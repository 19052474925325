import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Navbar({ proposalId }) {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#1E88E5",
        boxShadow: "0px 4px 20px rgba(0,0,0,0.2)",
      }}
    >
      <Toolbar>
        {/* Display title for unauthenticated users */}
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
          {isAuthenticated ? "" : "Assembli AI (MVP)"}
        </Typography>

        {/* Links or Buttons */}
        {isAuthenticated ? (
          <>
            <Button
              component={Link}
              to="/create-job"
              color="inherit"
              sx={{ mx: 1 }}
            >
              Create Job
            </Button>
            <Button
              component={Link}
              to="/snapshot"
              color="inherit"
              sx={{ mx: 1 }}
            >
              Snapshot Job
            </Button>
            <Button component={Link} to="/chat" color="inherit" sx={{ mx: 1 }}>
              Dashboard
            </Button>

            <Button
              component={Link}
              to="/choose-cost"
              color="inherit"
              sx={{ mx: 1 }}
            >
              Cost Catalog
            </Button>
            <Button
              color="inherit"
              sx={{ mx: 1 }}
              component={Link}
              to="/proposal"
            >
              Proposal Dashboard
            </Button>

            {/* User Account Menu */}
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMenuClick}
              sx={{ ml: 2 }}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                component={Link}
                to="/profile"
                onClick={handleMenuClose}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout(proposalId);
                  handleMenuClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button component={Link} to="/login" color="inherit" sx={{ mx: 1 }}>
              Login
            </Button>
            <Button
              component={Link}
              to="/signup"
              variant="outlined"
              color="inherit"
              sx={{ mx: 1, borderColor: "#fff" }}
            >
              Signup
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
