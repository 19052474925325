import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Import the mapping interface
import LineItemMappingInterface from "./LineItemMappingInterface";

// Services
import { 
    fetchLineItemCategories,
  getAllLineItems, 
  getDivisionStructure, 
  saveMappedData 
} from "../../services/pricingService";
import Sidebar from "../Sidebar/Sidebar";

const LineItemMappingPage = () => {
  const navigate = useNavigate();
  const [lineItems, setLineItems] = useState([]);
  const [divisionsData, setDivisionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch all line items (from all files) combined into a single list
        const lineItemsResponse = await getAllLineItems();
        
        if (lineItemsResponse.error) {
          throw new Error(lineItemsResponse.error);
        }
        
        setLineItems(lineItemsResponse || []);
        
        // Fetch division structure
        const divisionsResponse = await fetchLineItemCategories();
        
        if (divisionsResponse.error) {
          throw new Error(divisionsResponse.error);
        }
        
        setDivisionsData(divisionsResponse || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "An error occurred while fetching data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);

  // Handle saving mappings
  const handleSaveMapping = async (updatedLineItems) => {
    try {
      // Send the updated mappings to the server
      const response = await saveMappedData(updatedLineItems);
      
      if (response.error) {
        throw new Error(response.error);
      }
      
      // Update the local state with the latest mappings
      setLineItems(updatedLineItems);
      
      return response;
    } catch (error) {
      console.error("Error saving mappings:", error);
      throw error;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "calc(100vh - 64px)",
            marginLeft: "50rem" // Adjust based on your sidebar width
          }}
        >
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading mapping data...</Typography>
        </Box>
      );
    }

    if (error) {
      return (
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "calc(100vh - 64px)",
            marginLeft: "240px", // Adjust based on your sidebar width
            flexDirection: "column" 
          }}
        >
          <Typography variant="h6" color="error" gutterBottom>
            Error loading data
          </Typography>
          <Typography>{error}</Typography>
        </Box>
      );
    }

    // Render the mapping interface with the fetched data
    return (
      <LineItemMappingInterface
        lineItems={lineItems}
        divisionsData={divisionsData}
        onSaveMapping={handleSaveMapping}
      />
    );
  };

  // Always render the Sidebar, and conditionally render the content
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      {renderContent()}
    </Box>
  );
};

export default LineItemMappingPage;