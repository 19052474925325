import React from "react";
import { Document, Text, View } from "@react-pdf/renderer";

import { styles } from "./StyleSheets";

import {
  MilestoneIcon,
  MissionIcon,
  VerticalBar,
  ServiceIcon,
  CompanyStoryIcon,
} from "./Icons";

const CompanyOverviewPDF = ({
  foundedYear,
  foundingStory,
  missionStatement,
  coreValues,
  milestones,
  serviceAreas,
}) => {
  return (
    <Document>
      <View style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Company Overview & History</Text>
        </View>

        {/* Company Story Section */}
        <View style={styles.section}>
          <View style={styles.sectionHeaderBox}>
            <View style={styles.sectionHeader}>
              <CompanyStoryIcon />
              <Text style={styles.title}>Company Story</Text>
            </View>
          </View>
          <View style={styles.contentBox}>
            <Text style={styles.label}>Founded: {foundedYear}</Text>
            <Text style={styles.text}>{foundingStory}</Text>
          </View>
        </View>

        {/* Mission & Values Section */}
        <View style={styles.section}>
          <View style={styles.sectionHeaderBox}>
            <View style={styles.sectionHeader}>
              <MissionIcon />
              <Text style={styles.title}>Mission & Values</Text>
            </View>
          </View>
          <View style={styles.contentBox}>
            <Text style={styles.label}>Mission Statement:</Text>
            <Text style={styles.text}>{missionStatement}</Text>

            <Text style={[styles.label, { marginTop: 3 }]}>Core Values:</Text>
            <View style={styles.valuesContainer}>
              {coreValues.map((value, index) => (
                <View key={index} style={styles.value}>
                  <Text>{value}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Grid Layout for Milestones and Service Areas */}
        <View style={styles.grid}>
          {/* Growth Milestones */}
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <MilestoneIcon />
                  <Text style={styles.title}>Growth Milestones</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {milestones.map((milestone, index) => (
                  <Text key={index} style={styles.text}>
                    {milestone}
                  </Text>
                ))}
              </View>
            </View>
          </View>

          {/* Service Areas */}
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <ServiceIcon />
                  <Text style={styles.title}>Service Areas</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {serviceAreas.map((area, index) => (
                  <View
                    key={index}
                    style={{
                      ...styles.text,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View style={styles.dot} />
                    <Text>{area}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Document>
  );
};

export default CompanyOverviewPDF;
