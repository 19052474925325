import React, { useEffect } from "react";
import { styled, keyframes } from "@mui/material/styles";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import CustomTypography from "../CustomComponents/CustomTypography";
import { posthog } from "posthog-js";

import { ReactComponent as AiIcon } from "../../static/Icons/Icon.svg";
import { ReactComponent as ChatAiIcon } from "../../static/Icons/chatAiIcon.svg";
import { ReactComponent as SendIcon } from "../../static/Icons/send.svg";
import { ReactComponent as BlueCircleIcon } from "../../static/Icons/blueCircle.svg";
import { ReactComponent as GrayCircleIcon } from "../../static/Icons/grayCircle.svg";
import { ReactComponent as CloseIcon } from "../../static/Icons/close.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatMessagesForJob,
  appendChatMessagesForJob,
  setChatLoading,
  setChatError,
  setChatInputValue,
} from "../../store";

// Services
import { sendChatMessage } from "../../services/jobService";

// -------------------------------------------
// ANIMATION FOR LOADING DOTS
// -------------------------------------------
const dotBounce = keyframes`
  0% { transform: translateY(0); opacity: 0.2; }
  20% { transform: translateY(-5px); opacity: 1; }
  40% { transform: translateY(0); opacity: 0.2; }
  100% { transform: translateY(0); opacity: 0.2; }
`;

const Dot = styled("span")(({ delay }) => ({
  display: "inline-block",
  width: "4px",
  height: "4px",
  borderRadius: "50%",
  backgroundColor: "#1D1D1B",
  marginLeft: "2px",
  animation: `${dotBounce} 1.4s infinite ease-in-out both`,
  animationDelay: delay,
}));

const LoadingDots = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Dot delay="0s" />
      <Dot delay="0.2s" />
      <Dot delay="0.4s" />
    </Box>
  );
};

// -------------------------------------------
// STYLED COMPONENTS
// -------------------------------------------
const AiAssistantFrame = styled(Box)(({ isDrawer }) => ({
  width: isDrawer ? "34.375rem" : "16.125rem",
  height: isDrawer ? "100vh" : "35vh",
  flexShrink: 0,
  borderRadius: "1.25rem",
  background: "#FFF",
  boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  overflowY: isDrawer ? "auto" : "hidden",
}));

const AiAssistantHeadingFrame = styled(Box)(() => ({
  display: "flex",
  width: "14.17681rem",
  height: "1.8125rem",
  paddingLeft: "0.4375rem",
  alignItems: "center",
  gap: "0.4375rem",
  flexShrink: 0,
  marginTop: "1rem",
}));

const ChatIcon = styled(ChatAiIcon)(() => ({
  width: "1.43913rem",
  height: "1.03644rem",
  flexShrink: 0,
}));

const AiAssistantHeadingText = styled(CustomTypography)(() => ({
  color: "#1D1D1B",
  fontFamily: "Roboto",
  fontSize: "1.0625rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

const RectangularFrame = styled(Box)(({ isDrawer }) => ({
  flex: 1,
  borderRadius: "0.9375rem",
  background: "#F3F3F1",
  margin: "0.75rem",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const InputsFrame = styled(Box)(({ isDrawer }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginTop: "0.5rem",
  marginLeft: "0.75rem",
  marginBottom: "0.75rem",
  width: isDrawer ? "31.875rem" : "14.6875rem",
}));

const InputFieldFrame = styled(Box)(({ isDrawer }) => ({
  display: "flex",
  height: "2.14975rem",
  padding: "0.5rem 0.71656rem",
  alignItems: "center",
  gap: "1rem",
  alignSelf: "stretch",
  borderRadius: "0.67181rem",
  background: "#E7E7DF",
}));

const InputFieldSendIconFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.3125rem",
  alignItems: "center",
  gap: "0.625rem",
  borderRadius: "6.25rem",
  cursor: "pointer",
}));

const InputFieldSendIcon = styled(SendIcon)(() => ({
  width: "1rem",
  height: "1rem",
}));

const PromptSuggestionFrame = styled(Box)(() => ({
  display: "inline-flex",
  height: "1.82413rem",
  padding: "0.52119rem 1.45925rem 0.52119rem 1.04231rem",
  alignItems: "center",
  gap: "0.52119rem",
  flexShrink: 0,
  borderRadius: "5.21169rem",
  border: "0.834px solid #ABA898",
  width: "fit-content",
  cursor: "pointer",
  marginBottom: "0.5rem",
}));

const PromptSuggestionIcon = styled(AiIcon)(() => ({
  width: "0.97681rem",
  height: "0.93281rem",
}));

const PromptSuggestionText = styled(CustomTypography)(() => ({
  color: "#666660",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "136%",
}));

const ChatContainer = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1rem",
  overflowY: "auto",
}));

const UserMessageRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  marginBottom: "0.75rem",
}));

const AssistantMessageRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  marginBottom: "0.75rem",
}));

const UserMessageBubble = styled(Box)(() => ({
  maxWidth: "60%",
  display: "inline-flex",
  flexWrap: "wrap",
  padding: "0.5rem 1rem",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1.25rem",
  background: "#2961A7",
  color: "#FFF",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
}));

const AssistantMessageBubble = styled(Box)(() => ({
  maxWidth: "60%",
  display: "inline-flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "1.25rem",
  background: "#DCDACB",
  color: "#1D1D1B",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  padding: "0.5rem 1rem",
}));

const BlueCircleIconFrame = styled(BlueCircleIcon)(() => ({
  marginLeft: "0.3rem",
  marginBottom: "0.1rem",
}));

const GrayCircleIconFrame = styled(GrayCircleIcon)(() => ({
  marginRight: "0.3rem",
  marginBottom: "0.1rem",
}));

const CloseButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  padding: "1rem",
}));

const CloseIconButton = styled(IconButton)(() => ({
  background: "#F3F3F1",
  borderRadius: "30px",
  width: "2rem",
  height: "2rem",
  "&:hover": {
    background: "#ddd",
  },
}));

// -------------------------------------------
// MAIN COMPONENT
// -------------------------------------------
const AiAssistant = ({ jobId, isDrawer = false, onClose }) => {
  const dispatch = useDispatch();

  // Redux chat state
  const allMessages = useSelector((state) => state.chatMessagesData.messages);
  const inputValue = useSelector(
    (state) => state.chatMessagesData.inputValues[jobId] || "",
  );
  const messagesForJob = allMessages[jobId] || [];
  const isLoading = useSelector(
    (state) =>
      (state.chatMessagesData.loading &&
        state.chatMessagesData.loading[jobId]) ||
      false,
  );

  // On mount (or jobId change), fetch chat messages if not present
  useEffect(() => {
    if (jobId) {
      dispatch(fetchChatMessagesForJob(jobId));
    }
  }, [jobId, dispatch]);

  // Handle input changes
  const handleInputChange = (e) => {
    dispatch(setChatInputValue({ jobId, value: e.target.value }));
  };

  // Handle sending a message
  const handleSend = async () => {
    const trimmed = inputValue.trim();
    if (!trimmed) return;

    // Clear out input
    dispatch(setChatInputValue({ jobId, value: "" }));

    if (!jobId) {
      // If there's no jobId, use a mock response
      dispatch(
        appendChatMessagesForJob({
          jobId: "no-job",
          newMessages: [
            { role: "user", message: trimmed },
            {
              role: "assistant",
              message: "Mock response since no jobId provided.",
            },
          ],
        }),
      );
      return;
    }

    // Immediately append the user message
    dispatch(
      appendChatMessagesForJob({
        jobId,
        newMessages: [{ role: "user", message: trimmed }],
      }),
    );

    // Set loading state to show the animated loading indicator
    dispatch(setChatLoading({ jobId, isLoading: true }));

    try {
      const result = await sendChatMessage(jobId, trimmed);

      posthog.capture("assistant:chat_sent", {
        job_id: jobId,
        chat_content: trimmed,
        is_internal_user: false,
      });
      // Turn off loading state so loading indicator disappears
      dispatch(setChatLoading({ jobId, isLoading: false }));

      if (Array.isArray(result) && result.length) {
        // Extract the assistant response (ignore any duplicate user message)
        const assistantResponse = result.find(
          (msg) => msg.role === "assistant",
        );
        if (assistantResponse) {
          dispatch(
            appendChatMessagesForJob({
              jobId,
              newMessages: [assistantResponse],
            }),
          );
          posthog.capture("assistant:chat_responded", {
            job_id: jobId,
            chat_response: assistantResponse,
            is_internal_user: false,
          });
        } else {
          dispatch(
            appendChatMessagesForJob({
              jobId,
              newMessages: [
                { role: "assistant", message: "No content returned from AI." },
              ],
            }),
          );
          posthog.capture("assistant:no_response", {
            job_id: jobId,
            is_internal_user: false,
          });
        }
      } else {
        dispatch(
          appendChatMessagesForJob({
            jobId,
            newMessages: [
              { role: "assistant", message: "No content returned from AI." },
            ],
          }),
        );
      }
    } catch (err) {
      console.error("Error sending chat:", err);
      dispatch(setChatLoading({ jobId, isLoading: false }));
      dispatch(
        appendChatMessagesForJob({
          jobId,
          newMessages: [
            { role: "assistant", message: "Error: could not send message." },
          ],
        }),
      );
      dispatch(setChatError({ jobId, error: err.message }));
      posthog.capture("assistant:chat_failed", {
        job_id: jobId,
        error: err.message,
        is_internal_user: false,
      });
    }
  };

  // Handle Enter press in the input
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  // Clicking on a suggestion sets that text in the input
  const handleSuggestionClick = (text) => {
    dispatch(setChatInputValue({ jobId, value: text }));
  };

  return (
    <AiAssistantFrame isDrawer={isDrawer}>
      {/* If in drawer mode, show a top-right close button */}
      {isDrawer && onClose && (
        <CloseButtonContainer>
          <CloseIconButton onClick={onClose}>
            <CloseIcon width={16} height={16} />
          </CloseIconButton>
        </CloseButtonContainer>
      )}

      {/* Title Row */}
      <AiAssistantHeadingFrame>
        <ChatIcon />
        <AiAssistantHeadingText variant="body1SemiBold17">
          Assistant AI
        </AiAssistantHeadingText>
      </AiAssistantHeadingFrame>

      {/* Chat area */}
      <RectangularFrame>
        <ChatContainer>
          <Stack direction="column">
            {/* Suggestions shown ONLY if no messages exist yet */}
            {messagesForJob.length === 0 && (
              <Box sx={{ marginBottom: "1rem" }}>
                <PromptSuggestionFrame
                  onClick={() => handleSuggestionClick("Total divisions")}
                >
                  <PromptSuggestionIcon />
                  <PromptSuggestionText variant="body1">
                    Total divisions
                  </PromptSuggestionText>
                </PromptSuggestionFrame>

                <PromptSuggestionFrame
                  onClick={() => handleSuggestionClick("Doors in 1st floor")}
                >
                  <PromptSuggestionIcon />
                  <PromptSuggestionText variant="body1">
                    Doors in 1st floor
                  </PromptSuggestionText>
                </PromptSuggestionFrame>

                <PromptSuggestionFrame
                  onClick={() => handleSuggestionClick("Total cost")}
                >
                  <PromptSuggestionIcon />
                  <PromptSuggestionText variant="body1">
                    Total cost
                  </PromptSuggestionText>
                </PromptSuggestionFrame>
              </Box>
            )}

            {/* Messages in ascending (old->new) order */}
            {messagesForJob.map((msg, idx) => {
              if (msg.role === "user") {
                return (
                  <UserMessageRow key={idx}>
                    <UserMessageBubble>
                      <Typography variant="regular10">{msg.message}</Typography>
                    </UserMessageBubble>
                    <BlueCircleIconFrame />
                  </UserMessageRow>
                );
              } else {
                return (
                  <AssistantMessageRow key={idx}>
                    <GrayCircleIconFrame />
                    <AssistantMessageBubble>
                      <Typography
                        variant="regular10"
                        sx={{
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {msg.message}
                      </Typography>
                    </AssistantMessageBubble>
                  </AssistantMessageRow>
                );
              }
            })}

            {/* Animated loading indicator if AI is processing */}
            {isLoading && (
              <AssistantMessageRow>
                <GrayCircleIconFrame />
                <AssistantMessageBubble>
                  <LoadingDots />
                </AssistantMessageBubble>
              </AssistantMessageRow>
            )}
          </Stack>
        </ChatContainer>
      </RectangularFrame>

      {/* Input area */}
      <InputsFrame isDrawer={isDrawer}>
        <InputFieldFrame isDrawer={isDrawer}>
          <Box
            component="input"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
            style={{
              flex: 1,
              border: "none",
              background: "transparent",
              fontFamily: "Roboto",
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#1D1D1B",
              outline: "none",
              cursor: "text",
            }}
            placeholder="Ask Assistant a question...."
          />
          <InputFieldSendIconFrame onClick={handleSend}>
            <InputFieldSendIcon />
          </InputFieldSendIconFrame>
        </InputFieldFrame>
      </InputsFrame>
    </AiAssistantFrame>
  );
};

export default AiAssistant;
