import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useSelector } from "react-redux";
import Cropper from "react-easy-crop";

import {
  getSignedUrl,
  getUploadPolicyForProposal,
  uploadToGCS,
} from "../../../services/uploadService"; // Adjust path as needed
import getCroppedImg from "./getCroppedImg"; // Helper function for cropping

const HeaderEditor = ({ data, onSave }) => {
  const proposalId = useSelector((state) => state.takeoffData.proposalId);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);
  const [logoUrl, setLogoUrl] = useState(null);

  // === Cropper states ===
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Add rotation & zoom
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  // ---------------------------------------
  //            Rotation / Zoom
  // ---------------------------------------
  const handleRotateLeft = () => {
    setRotation((prev) => (prev - 90) % 360);
  };
  const handleRotateRight = () => {
    setRotation((prev) => (prev + 90) % 360);
  };
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };
  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 1));
  };

  /**
   * Called by react-easy-crop on crop complete.
   */
  const onCropComplete = useCallback((_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  /**
   * Called when the user confirms the crop.
   * It generates the cropped image, uploads it, and updates the form data.
   */
  const handleCropConfirm = async () => {
    try {
      if (!selectedFile || !croppedAreaPixels) return;

      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(selectedFile),
        croppedAreaPixels,
        rotation
      );
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      // Get the upload policy from your backend

      const policy = await getUploadPolicyForProposal(
        croppedFile.name,
        croppedFile.type,
        proposalId
      );

      // Upload the file to GCS
      const publicUrl = await uploadToGCS(policy, croppedFile);
      const objectName = policy.object_name;

      // Update form data with the new logo
      setFormData((prev) => ({
        ...prev,
        companyInfo: {
          ...prev.companyInfo,
          logo: objectName,
        },
      }));

      // Reset cropping states
      setIsCropModalOpen(false);
      setSelectedFile(null);
      setRotation(0);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    } catch (err) {
      console.error("Logo Upload Error:", err);
      alert("Error uploading logo: " + err.message);
    }
  };

  // Fetch the signed URL whenever the logo field changes
  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (formData.companyInfo.logo) {
        try {
          const url = await getSignedUrl(formData.companyInfo.logo);
          setLogoUrl(url.url);
        } catch (err) {
          console.error("Error fetching signed URL:", err);
          setLogoUrl(null);
        }
      } else {
        setLogoUrl(
          "https://www.shutterstock.com/image-vector/image-icon-trendy-flat-style-600nw-643080895.jpg"
        );
      }
    };

    fetchLogoUrl();
  }, [formData.companyInfo.logo]);

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(formData);
  };

  const handleInputChange = (section, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  /**
   * Triggered when a user selects a file.
   * Sets the file for cropping and opens the crop modal.
   */
  const handleSelectLogo = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setIsCropModalOpen(true);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {/* -- Sticky header area for the Edit/Save button -- */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      {/* -- Scrollable content section -- */}
      <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
        {/* === COMPANY INFO & PROPOSAL INFO === */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Company & Proposal Information
          </Typography>
          {/* ----------- LOGO UPLOAD SECTION ----------- */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Company Logo</Typography>
            {isEditing ? (
              <>
                {formData.companyInfo.logo && (
                  <img
                    src={logoUrl}
                    alt="Company Logo Preview"
                    style={{ maxHeight: 60, display: "block", marginBottom: 8 }}
                  />
                )}
                <Button variant="contained" component="label" sx={{ mr: 2 }}>
                  Select Logo
                  <input
                    type="file"
                    hidden
                    onChange={handleSelectLogo}
                    accept="image/*"
                  />
                </Button>
              </>
            ) : formData.companyInfo.logo ? (
              <img src={logoUrl} alt="Company Logo" style={{ maxHeight: 60 }} />
            ) : (
              <Typography>No logo uploaded</Typography>
            )}
          </Box>

          {/* Company Name */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Company Name</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.companyInfo.name}
                onChange={(e) =>
                  handleInputChange("companyInfo", "name", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.companyInfo.name}</Typography>
            )}
          </Box>

          {/* Company Address */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Company Address</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                multiline
                rows={2}
                value={formData.companyInfo.address}
                onChange={(e) =>
                  handleInputChange("companyInfo", "address", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.companyInfo.address}</Typography>
            )}
          </Box>

          {/* Phone */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Phone</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.companyInfo.phone}
                onChange={(e) =>
                  handleInputChange("companyInfo", "phone", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.companyInfo.phone}</Typography>
            )}
          </Box>

          {/* License */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">License</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.companyInfo.license}
                onChange={(e) =>
                  handleInputChange("companyInfo", "license", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.companyInfo.license}</Typography>
            )}
          </Box>

          {/* Proposal Number */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Proposal Number</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.proposal.number}
                onChange={(e) =>
                  handleInputChange("proposal", "number", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.proposal.number}</Typography>
            )}
          </Box>

          {/* Date */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Date</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                type="date"
                value={formData.proposal.date}
                onChange={(e) =>
                  handleInputChange("proposal", "date", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.proposal.date}</Typography>
            )}
          </Box>

          {/* Valid Until */}
          <Box>
            <Typography variant="subtitle2">Valid Until</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                type="date"
                value={formData.proposal.validUntil}
                onChange={(e) =>
                  handleInputChange("proposal", "validUntil", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.proposal.validUntil}</Typography>
            )}
          </Box>
        </Box>

        {/* === PREPARED FOR === */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Prepared For
          </Typography>

          {/* Name */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Name</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.preparedFor.name}
                onChange={(e) =>
                  handleInputChange("preparedFor", "name", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.preparedFor.name}</Typography>
            )}
          </Box>

          {/* Address */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Address</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                multiline
                rows={2}
                value={formData.preparedFor.address}
                onChange={(e) =>
                  handleInputChange("preparedFor", "address", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.preparedFor.address}</Typography>
            )}
          </Box>

          {/* Contact */}
          <Box>
            <Typography variant="subtitle2">Contact</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.preparedFor.contact}
                onChange={(e) =>
                  handleInputChange("preparedFor", "contact", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.preparedFor.contact}</Typography>
            )}
          </Box>
        </Box>

        {/* === PROJECT === */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Project
          </Typography>

          {/* Project Name */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Project Name</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.project.name}
                onChange={(e) =>
                  handleInputChange("project", "name", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.project.name}</Typography>
            )}
          </Box>

          {/* Project Address */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Project Address</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.project.address}
                onChange={(e) =>
                  handleInputChange("project", "address", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.project.address}</Typography>
            )}
          </Box>

          {/* Project Duration */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Project Duration</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.project.duration}
                onChange={(e) =>
                  handleInputChange("project", "duration", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.project.duration}</Typography>
            )}
          </Box>

          {/* Estimated Completion */}
          <Box>
            <Typography variant="subtitle2">Estimated Completion</Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={formData.project.completion}
                onChange={(e) =>
                  handleInputChange("project", "completion", e.target.value)
                }
              />
            ) : (
              <Typography>{formData.project.completion}</Typography>
            )}
          </Box>
        </Box>

        {/* === EXECUTIVE SUMMARY === */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Executive Summary
          </Typography>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={4}
              value={formData.executiveSummary}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  executiveSummary: e.target.value,
                }))
              }
            />
          ) : (
            <Typography>{formData.executiveSummary}</Typography>
          )}
        </Box>
      </Box>

      {/* === CROP MODAL === */}
      <Dialog
        open={isCropModalOpen}
        onClose={() => {
          setIsCropModalOpen(false);
          setSelectedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          sx={{
            position: "relative",
            width: "100%",
            height: 400,
            background: "#333",
          }}
        >
          {selectedFile && (
            <>
              <Cropper
                image={URL.createObjectURL(selectedFile)}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                cropShape="rect" // or 'round'
                showGrid={true}
                aspect={1} // You can keep 1:1 or 4:4, whichever you prefer
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />

              {/* Zoom Buttons (top-right corner) */}
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <IconButton onClick={handleZoomIn} color="primary">
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut} color="primary">
                  <ZoomOutIcon />
                </IconButton>
              </Box>
            </>
          )}
        </DialogContent>

        {/* Rotation & Save/Cancel */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <IconButton onClick={handleRotateLeft} color="primary">
            <RotateLeftIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleRotateRight} color="primary">
            <RotateRightIcon fontSize="large" />
          </IconButton>
        </Box>

        <DialogActions>
          <Button
            onClick={() => {
              setIsCropModalOpen(false);
              setSelectedFile(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCropConfirm}>
            Save Crop
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HeaderEditor;
