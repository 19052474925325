import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import {
  TableStructureFrame,
  TableStructureContentFrame,
  TableStructureHeadingFrame,
  TableStructureHeadingIconFrame,
  TableStructureHeadingIcon,
  TableStructureHeadingRotateIcon,
  TableStructureHeadingText,
} from "../EstimationStudio/TableStructure";

import {
  fetchUserPricingData,
  fetchFileDataById,
  getPreviewUrl,
  updateFileStatus,
  insertDataIntoCostCatalog,
  mapAcceptedLinesToAssembliStructure,
} from "../../services/pricingService";

import LineItemsTable from "./LineItemsTable";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { getColumnNameToTitleCase } from "../../utils/generalUtils";

const CustomPricing = () => {
  const [files, setFiles] = useState([]);
  const [fileDataMap, setFileDataMap] = useState({}); // fileId -> array of line items
  const [openFiles, setOpenFiles] = useState({});
  const [loadingFiles, setLoadingFiles] = useState({});
  const [loadingPreviewMap, setLoadingPreviewMap] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [updateLoadingMap, setUpdateLoadingMap] = useState({});

  const navigate = useNavigate();

  // Store all valid line ids per file
  const [allValidLinesMap, setAllValidLinesMap] = useState({});
  // Already selected rows from user interaction
  const [selectedRowsMap, setSelectedRowsMap] = useState({});
  // New: Store the status of each line item per file
  // Format: { [fileId]: { [lineId]: "accepted" | "rejected" | "pending" } }
  const [rowsStatusMap, setRowsStatusMap] = useState({});
  const [divisionData, setDivisionData] = useState([]);

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const response = await fetchUserPricingData();
        if (response.error) {
          throw new Error(response.error);
        }
        setFiles(response.files);
        if (response.divisionsData) {
          setDivisionData(response.divisionsData);
        }
      } catch (err) {
        console.error("Error fetching file list:", err);
        setError(err.message);
        setSnackbarOpen(true);
      }
    };
    fetchFileList();
  }, []);

  const buildRowStatusMap = (response) => {
    const statusMap = {};
    if (
      response.all_valid_line_ids &&
      Array.isArray(response.all_valid_line_ids)
    ) {
      response.all_valid_line_ids.forEach((lineId) => {
        if (
          response.approved_line_ids &&
          response.approved_line_ids.includes(lineId)
        ) {
          statusMap[lineId] = "accepted";
        } else if (
          response.rejected_line_ids &&
          response.rejected_line_ids.includes(lineId)
        ) {
          statusMap[lineId] = "rejected";
        } else {
          statusMap[lineId] = "pending";
        }
      });
    }
    return statusMap;
  };

  const sortLineItems = (lineItems, statusMap) => {
    return lineItems.sort((a, b) => {
      const order = { pending: 1, accepted: 2, rejected: 3 };
      const getStatusOrder = (item) => {
        // Get status from the statusMap if available; otherwise, fallback to the row's value.
        const status = statusMap[item.line_id] || item.line_status || "pending";
        return order[status] || 4;
      };
      return getStatusOrder(a) - getStatusOrder(b);
    });
  };

  const toggleFile = useCallback(
    async (file) => {
      const fileId = file.file_id;
      setOpenFiles((prev) => ({
        ...prev,
        [fileId]: !prev[fileId],
      }));

      // If expanding and we don't have data yet, fetch it
      if (!openFiles[fileId] && !fileDataMap[fileId]) {
        setLoadingFiles((prev) => ({ ...prev, [fileId]: true }));
        try {
          const response = await fetchFileDataById(file);
          console.log(response);
          if (response.error) {
            throw new Error(response.error);
          }
          // Save the extracted line items
          setFileDataMap((prevMap) => ({
            ...prevMap,
            [fileId]: response.extracted_invoice_data,
          }));
          // Save all valid line ids
          setAllValidLinesMap((prev) => ({
            ...prev,
            [fileId]: response.all_valid_line_ids,
          }));
          // Save selected rows if provided (or empty array)
          setSelectedRowsMap((prevMap) => ({
            ...prevMap,
            [fileId]: response.selected_rows || [],
          }));
          // Build and store row status for each valid line id
          const statusMap = buildRowStatusMap(response);
          setRowsStatusMap((prev) => ({
            ...prev,
            [fileId]: statusMap,
          }));
          console.log("Row status map:", statusMap);
        } catch (err) {
          console.error("Error fetching file data:", err);
          setError(err.message);
          setSnackbarOpen(true);
        } finally {
          setLoadingFiles((prev) => ({ ...prev, [fileId]: false }));
        }
      }
    },
    [openFiles, fileDataMap]
  );

  const handlePreview = useCallback(
    async (file) => {
      const fileId = file.file_id;
      setLoadingPreviewMap((prev) => ({ ...prev, [fileId]: true }));

      // Ensure file data is loaded (same as before)
      if (!fileDataMap[fileId]) {
        setLoadingFiles((prev) => ({ ...prev, [fileId]: true }));
        try {
          const response = await fetchFileDataById(fileId);
          if (response.error) throw new Error(response.error);
          setFileDataMap((prevMap) => ({
            ...prevMap,
            [fileId]: response.extracted_invoice_data,
          }));
          // Save all valid line ids
          setAllValidLinesMap((prev) => ({
            ...prev,
            [fileId]: response.all_valid_line_ids,
          }));
          setSelectedRowsMap((prevMap) => ({
            ...prevMap,
            [fileId]: response.selected_rows || [],
          }));
          // Build and store row status for this file
          const statusMap = buildRowStatusMap(response);
          setRowsStatusMap((prev) => ({
            ...prev,
            [fileId]: statusMap,
          }));
        } catch (error) {
          console.error("Error fetching file data:", error);
          setError(error.message);
          setSnackbarOpen(true);
        } finally {
          setLoadingFiles((prev) => ({ ...prev, [fileId]: false }));
        }
      }

      // Check if the file already has a valid preview URL.
      let previewUrlToUse = file.signed_url;
      const now = new Date();
      if (
        !previewUrlToUse ||
        (file.expiration_time && now > new Date(file.expiration_time))
      ) {
        console.log("Preview URL is missing or expired—fetch a new one.");
        try {
          const response = await getPreviewUrl(fileId);
          if (response.error) throw new Error(response.error);
          previewUrlToUse = response.signed_url;
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file_id === fileId
                ? {
                    ...f,
                    signed_url: response.signed_url,
                    expiration_time: response.expiration_time,
                  }
                : f
            )
          );
        } catch (err) {
          console.error("Error fetching preview URL:", err);
          setError(err.message);
          setSnackbarOpen(true);
        }
      }

      setPreviewFile(file);
      setPreviewUrl(previewUrlToUse);
      setPreviewOpen(true);
      setLoadingPreviewMap((prev) => ({ ...prev, [fileId]: false }));
    },
    [fileDataMap]
  );

  const updateStatus = useCallback(
    async (file, status) => {
      const fileId = file.file_id;
      try {
        // Set the update loading flag for this file
        setUpdateLoadingMap((prev) => ({
          ...prev,
          [fileId]: true,
        }));

        // If selectedRowsMap is empty, default to all valid line ids from the backend
        const selectedRows =
          selectedRowsMap[fileId] && selectedRowsMap[fileId].length > 0
            ? selectedRowsMap[fileId]
            : allValidLinesMap[fileId] || [];
        // console.log("Rows selected for update:", selectedRows);
        // console.log("File details of the file to update:", file);
        const resp = await updateFileStatus(fileId, status, selectedRows);
        if (resp.error) {
          throw new Error(resp.error);
        }
        setFiles((prevFiles) =>
          prevFiles.map((f) => (f.file_id === fileId ? { ...f, status } : f))
        );

        // Update the rowsStatusMap to reflect the new status for the updated rows.
        setRowsStatusMap((prev) => ({
          ...prev,
          [fileId]: {
            ...prev[fileId],
            ...selectedRows.reduce((acc, lineId) => {
              acc[lineId] = status === "approved" ? "accepted" : "rejected";
              return acc;
            }, {}),
          },
        }));

        // Clear out the selected rows for this file.
        setSelectedRowsMap((prev) => ({
          ...prev,
          [fileId]: [],
        }));

        // Set a success message in the snackbar
        setSnackbarMessage(
          `File ${status === "approved" ? "approved" : "rejected"} successfully`
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error updating file status:", err);
        setSnackbarMessage(err.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        // Clear the update loading flag for this file
        setUpdateLoadingMap((prev) => ({
          ...prev,
          [fileId]: false,
        }));
      }
    },
    [selectedRowsMap, allValidLinesMap, rowsStatusMap]
  );

  const getStatusIcon = useCallback((status) => {
    switch (status) {
      case "approved":
        return <CheckCircleIcon color="success" />;
      case "rejected":
        return <CancelIcon color="error" />;
      default:
        return <HourglassEmptyIcon color="action" />;
    }
  }, []);

  // Callback to handle row selection changes in the table
  const handleRowSelectionChange = useCallback((fileId, selectedRowIds) => {
    setSelectedRowsMap((prev) => ({
      ...prev,
      [fileId]: selectedRowIds,
    }));
  }, []);

  // Callback to handle line item updates (edits, deletes, etc.)
  const handleLineItemsUpdate = useCallback((fileId, updatedRows) => {
    setFileDataMap((prevMap) => ({
      ...prevMap,
      [fileId]: updatedRows,
    }));
  }, []);

  const handleClosePreview = () => {
    if (previewFile) {
      const fileId = previewFile.file_id;
      // Retrieve the current rows for this file
      const unsortedRows = fileDataMap[fileId] || [];
      // Sort the rows using your sortLineItems function
      const sortedRows = sortLineItems(
        [...unsortedRows],
        rowsStatusMap[fileId] || {}
      );
      console.log("Sorted rows:", sortedRows);
      // Update the fileDataMap with the sorted rows
      setFileDataMap((prevMap) => ({
        ...prevMap,
        [fileId]: sortedRows,
      }));

      // Clear out the selected rows for this file.
      setSelectedRowsMap((prev) => ({
        ...prev,
        [fileId]: [],
      }));
    }
    // Then close the preview dialog
    setPreviewOpen(false);
    setPreviewUrl("");
    setPreviewFile(null);
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const isNumericColumn = (key, data) =>
    data.every((item) => item[key] === null || !isNaN(parseFloat(item[key])));


  const getColumnsFromData = (data, screenName) => {
    console.log("Data:", data);
    if (!data.length) return [];
    console.log("Data keys:", Object.keys(data[0]));
    // Generate columns from data keys
    let columns = Object.keys(data[0]).map((key) => ({
      id: key,
      field: key,
      // label: key.replace(/_/g, " "), //.toUpperCase(),
      label: getColumnNameToTitleCase(key),
      type: isNumericColumn(key, data) ? "number" : "string",
      valueFormatter: key.toLowerCase().includes("price")
        ? (value) => currencyFormatter.format(value)
        : undefined,
    }));

    if (screenName === "preview") {
      columns = columns.filter((column) => column.id !== "full_description");
    }

    // Define the desired order of column keys
    const desiredOrder = [
      "division_name",
      "subdivision_name",
      "material_name",
      "material_price",
      "labor_price",
      "unit_of_measure",
    ];

    // Sort columns based on their index in the desiredOrder array.
    // Columns not in desiredOrder will be placed at the end.
    const orderedColumns = columns.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.id);
      const indexB = desiredOrder.indexOf(b.id);

      if (indexA === -1 && indexB === -1) return 0; // both not found, leave as-is
      if (indexA === -1) return 1; // a goes after b
      if (indexB === -1) return -1; // a goes before b

      return indexA - indexB;
    });

    return orderedColumns;
    // return columns;
  };

  // const handleInsertData = async () => {
  //   try {
  //     console.log("Inserting data into cost catalog...");
  //     const result = await insertDataIntoCostCatalog();
  //     setSnackbarMessage("Data inserted into cost catalog successfully");
  //     setSnackbarSeverity("success");
  //     setSnackbarOpen(true);
  //     navigate(`/user-pricing`);
  //   } catch (error) {
  //     setSnackbarMessage("Error inserting data: " + error.message);
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // };
  const [isMappingLoading, setIsMappingLoading] = useState(false);

  const navigateToLineItemMappingPage = async () => {
    try {
      // Show loading state
      setIsMappingLoading(true);

      setSnackbarMessage("Mapping line items. Please wait...");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);

      // Wait for the mapping operation to complete
      const result = await mapAcceptedLinesToAssembliStructure();

      // Show success message
      setSnackbarMessage("Mapping completed successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Navigate to the mapping page after successful operation
      navigate("/mapping");
    } catch (error) {
      // Handle errors
      console.error("Error mapping line items:", error);
      setSnackbarMessage(
        "Error mapping line items: " + (error.message || "Unknown error")
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      // Hide loading state
      setIsMappingLoading(false);
    }
  };
  return (
    <Box
      sx={{
        p: 3,
        marginTop: "64px",
        overflowY: "auto",
        position: "relative",
        zIndex: 0,
      }}
    >
      <Sidebar />

      {/* Backdrop for global loading indicator */}
      {/* {updateLoadingMap[previewFile?.file_id] && (
      <Backdrop
        open
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 2,
          color: "#fff",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )} */}

      <Box
        sx={{
          marginLeft: "20.1rem",
          marginTop: "1.86rem",
          marginRight: "6rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.5rem",
          }}
        >
          <Typography variant="h2semibold31">Custom Cost Catalog</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToLineItemMappingPage}
            disabled={isMappingLoading}
          >
            {isMappingLoading ? (
              <>
                <CircularProgress size={24} sx={{ mr: 1 }} color="inherit" />
                Mapping Lines...
              </>
            ) : (
              "Map Accepted Lines To Assembli Structure"
            )}
          </Button>
        </Box>

        <TableStructureFrame>
          {files.map((file) => {
            const fileId = file.file_id;
            const isOpen = openFiles[fileId];
            const extractedData = fileDataMap[fileId] || [];
            return (
              <Box key={fileId} sx={{ width: "100%" }}>
                <TableStructureContentFrame
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleFile(file)}
                >
                  {/* Left side: Icon and truncated file name */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: { xs: "60%", md: "50%" },
                      overflow: "hidden",
                      flexShrink: 1,
                    }}
                  >
                    <TableStructureHeadingIconFrame sx={{ flexShrink: 0 }}>
                      {isOpen ? (
                        <TableStructureHeadingIcon />
                      ) : (
                        <TableStructureHeadingRotateIcon />
                      )}
                    </TableStructureHeadingIconFrame>
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.file_name}
                    </Typography>
                  </Box>

                  {/* Right side: Buttons and status icon */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      flexShrink: 0,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      variant="ghost"
                      onClick={() => handlePreview(file)}
                      disabled={loadingPreviewMap[fileId]}
                    >
                      {loadingPreviewMap[fileId] ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Typography
                          variant="body3medium14"
                          sx={{ color: "#2961A7" }}
                        >
                          Review
                        </Typography>
                      )}
                    </Button>
                    <Box sx={{ ml: 1 }}>{getStatusIcon(file.status)}</Box>
                  </Box>
                </TableStructureContentFrame>

                {isOpen && (
                  <Box sx={{ p: 2, backgroundColor: "#FAFAF9" }}>
                    {loadingFiles[fileId] ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "150px",
                        }}
                      >
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>Loading data...</Typography>
                      </Box>
                    ) : (
                      <>
                        <LineItemsTable
                          lineItems={extractedData}
                          columns={getColumnsFromData(extractedData)}
                          divisionsData={divisionData}
                          rowsPerPage={5}
                          selectedFileId={fileId}
                          selectedRows={selectedRowsMap[fileId] || []}
                          onRowSelectionChange={(newSelection) =>
                            handleRowSelectionChange(fileId, newSelection)
                          }
                          onLineItemsUpdate={(updatedRows) =>
                            handleLineItemsUpdate(fileId, updatedRows)
                          }
                          // Pass the row status mapping for this file to color-code rows
                          rowsStatus={rowsStatusMap[fileId] || {}}
                        />
                      </>
                    )}
                  </Box>
                )}
              </Box>
            );
          })}
        </TableStructureFrame>
      </Box>

      {/* PREVIEW DIALOG */}
      <Dialog open={previewOpen} onClose={handleClosePreview} fullScreen>
        <DialogTitle>
          Preview: {previewFile ? previewFile.file_name : ""}
        </DialogTitle>
        <DialogContent sx={{ display: "flex", gap: "1rem", height: "80vh" }}>
          <Box sx={{ flex: 1, border: "1px solid #ddd", overflow: "hidden" }}>
            {previewUrl ? (
              <iframe
                src={previewUrl}
                title="File Preview"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Typography>No preview available.</Typography>
            )}
          </Box>
          {previewFile && (
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {fileDataMap[previewFile.file_id] && (
                <LineItemsTable
                  lineItems={fileDataMap[previewFile.file_id]}
                  columns={getColumnsFromData(
                    fileDataMap[previewFile.file_id],
                    "preview"
                  )}
                  divisionsData={divisionData}
                  rowsPerPage={5}
                  selectedFileId={previewFile.file_id}
                  selectedRows={selectedRowsMap[previewFile.file_id] || []}
                  onRowSelectionChange={(newSelection) =>
                    handleRowSelectionChange(previewFile.file_id, newSelection)
                  }
                  onLineItemsUpdate={(updatedRows) =>
                    handleLineItemsUpdate(previewFile.file_id, updatedRows)
                  }
                  // Also pass row status mapping for the preview table
                  rowsStatus={rowsStatusMap[previewFile.file_id] || {}}
                />
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClosePreview}>
            <Typography sx={{ color: "#FFF" }}>Close</Typography>
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={async () => {
              await updateStatus(previewFile, "approved");
              handleClosePreview();
            }}
            disabled={updateLoadingMap[previewFile?.file_id]}
          >
            <Typography sx={{ color: "#FFF" }}>Accept</Typography>
          </Button>
          <Button
            variant="no-background"
            color="error"
            onClick={async () => {
              await updateStatus(previewFile, "rejected");
              handleClosePreview();
            }}
            disabled={updateLoadingMap[previewFile?.file_id]}
          >
            <Typography sx={{ color: "#F00" }}>Reject</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default CustomPricing;
