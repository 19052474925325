// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   List,
//   ListItem,
//   IconButton,
//   Grid,
//   Divider,
// } from "@mui/material";
// import {
//   Edit as EditIcon,
//   Save as SaveIcon,
//   Add as AddIcon,
//   Remove as RemoveIcon,
// } from "@mui/icons-material";

// /**
//  * Example `data` shape expected:
//  * {
//  *   "Contract Terms": [
//  *     { label: "Term 1", value: "Some description for Term 1" },
//  *     { label: "Term 2", value: "Some description for Term 2" },
//  *   ],
//  *   "Warranty": [
//  *     { label: "Warranty Period", value: "1 year" },
//  *     { label: "Warranty Coverage", value: "Parts and labor" },
//  *   ],
//  *   // ... etc
//  * }
//  */

// const LegalComplianceEditor = ({ onSave, data }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const customTextFieldStyles = {
//     borderRadius: "0.9375rem",
//     border: "1px solid var(--Nuetrals-Border---1, #ABA898)",
//     background: "var(--Nuetrals-Background---input, #E5E5DC)",
//     boxShadow: "0px 9px 15.2px 0px rgba(255, 214, 179, 0.20)",
//     // Adding some padding to make the text not touch the borders
//   };

//   // Instead of storing the object directly, convert it into an array of sections:
//   // [
//   //   { id, name: "Contract Terms", items: [{ label, value }, ...] },
//   //   { id, name: "Warranty", items: [{ label, value }, ...] },
//   //   ...
//   // ]
//   //
//   // This makes it easier to rename `name` without worrying about object keys.
//   const [sections, setSections] = useState([]);

//   useEffect(() => {
//     if (data) {
//       // Convert the incoming `data` object into an array
//       const newSections = Object.entries(data).map(
//         ([sectionName, items], idx) => ({
//           id: `section-${idx}`, // or use a unique ID generator
//           name: sectionName,
//           items: items ?? [],
//         })
//       );
//       setSections(newSections);
//     }
//   }, [data]);

//   /**
//    * When user toggles from Edit -> Save,
//    * convert `sections` array back to object format and call onSave.
//    */
//   const handleToggleEdit = () => {
//     if (isEditing) {
//       // Convert array back into an object
//       const updatedData = {};
//       sections.forEach((sec) => {
//         updatedData[sec.name] = sec.items;
//       });
//       onSave?.(updatedData); // pass updated object to parent
//     }
//     setIsEditing((prev) => !prev);
//   };

//   /**
//    * Handle changing the section name (rename).
//    */
//   const handleSectionNameChange = (sectionId, newName) => {
//     setSections((prev) =>
//       prev.map((sec) =>
//         sec.id === sectionId ? { ...sec, name: newName } : sec
//       )
//     );
//   };

//   /**
//    * Add a new section
//    */
//   const handleAddSection = () => {
//     setSections((prev) => {
//       // Extract just the names of existing sections
//       const existingNames = prev.map((sec) => sec.name);

//       // Start counting up from 1 for a new name
//       let baseName = "New Section";
//       let counter = 1;
//       let newName = baseName;

//       // If "New Section" already exists, move to "New Section (2)", etc.
//       while (existingNames.includes(newName)) {
//         counter++;
//         newName = `${baseName} (${counter})`;
//       }

//       const newSection = {
//         id: `section-${Date.now()}`,
//         name: newName,
//         items: [{ label: "New Label", value: "New Value" }],
//       };
//       return [...prev, newSection];
//     });
//   };

//   /**
//    * Remove a section by ID
//    */
//   const handleRemoveSection = (sectionId) => {
//     setSections((prev) => prev.filter((sec) => sec.id !== sectionId));
//   };

//   /**
//    * Add a new item to a particular section
//    */
//   const handleAddItem = (sectionId) => {
//     setSections((prev) =>
//       prev.map((sec) => {
//         if (sec.id === sectionId) {
//           return {
//             ...sec,
//             items: [...sec.items, { label: "New Label", value: "New Value" }],
//           };
//         }
//         return sec;
//       })
//     );
//   };

//   /**
//    * Remove an item from a particular section
//    */
//   const handleRemoveItem = (sectionId, itemIndex) => {
//     setSections((prev) =>
//       prev.map((sec) => {
//         if (sec.id === sectionId) {
//           const newItems = sec.items.filter((_, i) => i !== itemIndex);
//           return { ...sec, items: newItems };
//         }
//         return sec;
//       })
//     );
//   };

//   /**
//    * Edit a label/value pair in a particular section item
//    */
//   const handleItemChange = (sectionId, itemIndex, field, value) => {
//     setSections((prev) =>
//       prev.map((sec) => {
//         if (sec.id === sectionId) {
//           const updatedItems = sec.items.map((item, i) =>
//             i === itemIndex ? { ...item, [field]: value } : item
//           );
//           return { ...sec, items: updatedItems };
//         }
//         return sec;
//       })
//     );
//   };

//   return (
//     <Box
//       sx={{
//         // maxHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         width: "100%",
//       }}
//     >
//       {/* Sticky Header: Edit/Save + Add Section button */}
//       <Box
//         sx={{
//           position: "sticky",
//           top: 0,
//           zIndex: 200,
//           backgroundColor: "white",
//           p: 2,
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           borderBottom: "1px solid #ddd",
//         }}
//       >
//         {/* <Typography variant="h6">Legal & Compliance Editor</Typography> */}
//         <Box>
//           <Button
//             variant="contained"
//             startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
//             onClick={handleToggleEdit}
//             sx={{ mr: 2 }}
//           >
//             {isEditing ? "Save" : "Edit"}
//           </Button>
//           {isEditing && (
//             <Button
//               //   variant="outlined"
//               startIcon={<AddIcon />}
//               onClick={handleAddSection}
//             >
//               Add Section
//             </Button>
//           )}
//         </Box>
//       </Box>

//       {/* Main scrollable area */}
//       <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
//         {sections.map(({ id, name, items }) => (
//           <Box key={id} sx={{ mb: 4 }}>
//             {/* Section name + remove section icon */}
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 mb: 2,
//                 justifyContent: "space-between",
//               }}
//             >
//               {isEditing ? (
//                 <TextField
//                   variant="standard"
//                   label="Section Name"
//                   value={name}
//                   onChange={(e) => handleSectionNameChange(id, e.target.value)}
//                 />
//               ) : (
//                 <Typography variant="h6" color="primary">
//                   {name}
//                 </Typography>
//               )}
//               {isEditing && (
//                 <IconButton
//                   color="error"
//                   onClick={() => handleRemoveSection(id)}
//                 >
//                   <RemoveIcon />
//                 </IconButton>
//               )}
//             </Box>

//             {/* Items List */}
//             <List>
//               {items.map((item, index) => (
//                 <ListItem
//                   key={index}
//                   sx={{
//                     py: 1,
//                     flexDirection: "column",
//                     alignItems: "start",
//                   }}
//                   secondaryAction={
//                     isEditing && (
//                       <IconButton
//                         edge="end"
//                         size="small"
//                         onClick={() => handleRemoveItem(id, index)}
//                       >
//                         <RemoveIcon />
//                       </IconButton>
//                     )
//                   }
//                 >
//                   {isEditing ? (
//                     <Grid container spacing={2}>
//                       <Grid item xs={12} sm={5}>
//                         <TextField
//                           fullWidth
//                           size="small"
//                           label="Label"
//                           value={item.label}
//                           onChange={(e) =>
//                             handleItemChange(id, index, "label", e.target.value)
//                           }
//                           sx={customTextFieldStyles}
//                         />
//                       </Grid>
//                       <Grid item xs={12} sm={7}>
//                         <TextField
//                           fullWidth
//                           size="small"
//                           label="Value"
//                           value={item.value}
//                           onChange={(e) =>
//                             handleItemChange(id, index, "value", e.target.value)
//                           }
//                           sx={customTextFieldStyles}
//                         />
//                       </Grid>
//                     </Grid>
//                   ) : (
//                     <>
//                       <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                         {item.label}
//                       </Typography>
//                       <Typography variant="body2" sx={{ ml: 2 }}>
//                         {item.value}
//                       </Typography>
//                     </>
//                   )}
//                 </ListItem>
//               ))}
//             </List>

//             {/* Button to add new items to this section */}
//             {isEditing && (
//               <Button
//                 startIcon={<AddIcon />}
//                 onClick={() => handleAddItem(id)}
//                 sx={{ mt: 1 }}
//               >
//                 Add Item
//               </Button>
//             )}

//             <Divider sx={{ mt: 2 }} />
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default LegalComplianceEditor;
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const chunkArray = (arr, size) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

const LegalComplianceEditor = ({ onSave, data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [sections, setSections] = useState([]);

  // Example custom styling (optional)
  const customTextFieldStyles = {
    marginBottom: "1rem",
  };

  useEffect(() => {
    if (data) {
      // Convert the incoming `data` object into an array of section objects
      const newSections = Object.entries(data).map(
        ([sectionName, items], idx) => ({
          id: `section-${idx}`,
          name: sectionName,
          items: items ?? [],
        })
      );
      setSections(newSections);
    }
  }, [data]);

  const handleToggleEdit = () => {
    if (isEditing) {
      // Convert array back into an object for saving
      const updatedData = {};
      sections.forEach((sec) => {
        updatedData[sec.name] = sec.items;
      });
      onSave?.(updatedData);
    }
    setIsEditing((prev) => !prev);
  };

  const handleSectionNameChange = (sectionId, newName) => {
    setSections((prev) =>
      prev.map((sec) =>
        sec.id === sectionId ? { ...sec, name: newName } : sec
      )
    );
  };

  const handleAddSection = () => {
    setSections((prev) => {
      const existingNames = prev.map((sec) => sec.name);
      let baseName = "New Section";
      let counter = 1;
      let newName = baseName;
      // ensure uniqueness
      while (existingNames.includes(newName)) {
        counter++;
        newName = `${baseName} (${counter})`;
      }

      return [
        ...prev,
        {
          id: `section-${Date.now()}`,
          name: newName,
          items: [{ label: "New Label", value: "New Value" }],
        },
      ];
    });
  };

  const handleRemoveSection = (sectionId) => {
    setSections((prev) => prev.filter((sec) => sec.id !== sectionId));
  };

  const handleAddItem = (sectionId) => {
    setSections((prev) =>
      prev.map((sec) =>
        sec.id === sectionId
          ? {
              ...sec,
              items: [...sec.items, { label: "New Label", value: "New Value" }],
            }
          : sec
      )
    );
  };

  const handleRemoveItem = (sectionId, index) => {
    setSections((prev) =>
      prev.map((sec) => {
        if (sec.id === sectionId) {
          const newItems = sec.items.filter((_, i) => i !== index);
          return { ...sec, items: newItems };
        }
        return sec;
      })
    );
  };

  const handleItemChange = (sectionId, itemIndex, field, newValue) => {
    setSections((prev) =>
      prev.map((sec) => {
        if (sec.id === sectionId) {
          const updatedItems = sec.items.map((item, i) =>
            i === itemIndex ? { ...item, [field]: newValue } : item
          );
          return { ...sec, items: updatedItems };
        }
        return sec;
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Sticky header with Edit/Save + Add Section */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 200,
          backgroundColor: "white",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Box>
          <Button
            variant="contained"
            startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
            onClick={handleToggleEdit}
            sx={{ mr: 2 }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button startIcon={<AddIcon />} onClick={handleAddSection}>
              Add Section
            </Button>
          )}
        </Box>
      </Box>

      {/* Main content area */}
      <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
        {sections.map(({ id, name, items }) => {
          // Split items into chunks of 2 for two-column layout
          const chunked = chunkArray(items, 2);

          return (
            <Box key={id} mb={4}>
              {/* Section Title Row */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                {isEditing ? (
                  <TextField
                    variant="standard"
                    label="Section Name"
                    value={name}
                    onChange={(e) =>
                      handleSectionNameChange(id, e.target.value)
                    }
                  />
                ) : (
                  <Typography variant="h6" color="primary">
                    {name}
                  </Typography>
                )}
                {isEditing && (
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveSection(id)}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>

              {/* Two‐column rendering of items */}
              {chunked.map((rowItems, rowIndex) => (
                <Grid container spacing={2} key={rowIndex} sx={{ mb: 2 }}>
                  {rowItems.map((item, colIndex) => (
                    <Grid item xs={12} sm={6} key={colIndex}>
                      {isEditing ? (
                        <Box>
                          {/* 
                             Use item.label as the *TextField label*, 
                             and item.value as its "value". 
                             The user can rename label in real time. 
                          */}
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Field Label"
                            value={item.label}
                            onChange={(e) =>
                              handleItemChange(
                                id,
                                rowIndex * 2 + colIndex,
                                "label",
                                e.target.value
                              )
                            }
                            sx={customTextFieldStyles}
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Field Value"
                            value={item.value}
                            onChange={(e) =>
                              handleItemChange(
                                id,
                                rowIndex * 2 + colIndex,
                                "value",
                                e.target.value
                              )
                            }
                            sx={customTextFieldStyles}
                          />
                          {/* Remove item button */}
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() =>
                              handleRemoveItem(id, rowIndex * 2 + colIndex)
                            }
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box>
                          {/* In non‐editing mode, just show a label‐value styled TextField (read‐only). */}
                          <TextField
                            fullWidth
                            variant="standard"
                            label={item.label}
                            value={item.value}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))}

              {/* Button to add new items to this section */}
              {isEditing && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => handleAddItem(id)}
                >
                  Add Item
                </Button>
              )}

              <Divider sx={{ mt: 2 }} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default LegalComplianceEditor;
