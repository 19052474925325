import * as React from "react";
import {
  Button,
  Card,
  Tab,
  Tabs,
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  CardContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const isDevelopment = process.env.NODE_ENV === "development";
const mockSnapshotData = isDevelopment
  ? require("./__mocks__/mockSnapshotData").mockSnapshotData
  : null;

const TabPanel = ({ snapshotData, value, index, sections }) => {
  const theme = useTheme();

  const renderScheduleTable = (scheduleData) => {
    if (!scheduleData?.headers || !scheduleData?.rows) return null;

    return (
      <Box sx={{ overflowX: "auto", width: "100%" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "1rem",
          }}
        >
          <thead>
            <tr>
              {scheduleData.headers.map((header, idx) => (
                <th
                  key={idx}
                  style={{
                    padding: "0.75rem",
                    borderBottom: `1px solid ${theme.palette.neutrals.border2}`,
                    textAlign: "left",
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Typography variant="h5">{header}</Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {scheduleData.rows.map((row, rowIdx) => (
              <tr key={rowIdx}>
                {scheduleData.headers.map((header, colIdx) => {
                  const headerKey = header === "Type Mark" ? "Type" : header;
                  const cellValue = row[headerKey];

                  return (
                    <td
                      key={colIdx}
                      style={{
                        padding: "0.75rem",
                        borderBottom: `1px solid ${theme.palette.neutrals.border2}`,
                      }}
                    >
                      <Typography variant="body1">
                        {cellValue !== undefined && cellValue !== null
                          ? cellValue.toString()
                          : "N/A"}
                      </Typography>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  };

  const isScheduleData = (data) =>
    data && typeof data === "object" && "headers" in data && "rows" in data;

  return value === index ? (
    <Box sx={{ minHeight: "8rem", width: "46rem" }}>
      <Card
        sx={{
          padding: 3,
          borderRadius: "1.9375rem",
          boxShadow: "0 px 8px 18.7px 0px rgba(175, 1170, 126, 0.10",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            width: "47rem",
            maxHeight: "50vh",
            overflow: "auto",
            padding: "0rem 2.1875rem 1.8125rem 2.1875rem",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.1875rem",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              paddingTop: "1.1875rem",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "0.875rem",
              alignSelf: "stretch",
            }}
          >
            {/* Title */}
            {snapshotData?.tabs[index]?.title}
          </Typography>
          <Divider
            sx={{
              background: theme.palette.neutrals.border2,
              width: "41.625rem",
              height: "0.0625rem",
            }}
          />
          <Grid container spacing={2}>
            {sections.map((section, sectionIndex) => {
              const sectionData = snapshotData.data[section];

              if (isScheduleData(sectionData)) {
                return (
                  <Box key={sectionIndex} sx={{ width: "100%" }}>
                    <Typography
                      padding={"0.625rem 0rem"}
                      variant="h4"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {section.replace(/([A-Z])/g, " $1").trim()}
                    </Typography>
                    {renderScheduleTable(sectionData)}
                  </Box>
                );
              }

              // If not schedule data, render as before
              return (
                <Grid item xs={6} key={sectionIndex}>
                  <Stack
                    gap="1.0625rem"
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"flex-start"}
                    flex="1 1 0"
                  >
                    <Typography
                      padding={"0.625rem 0rem"}
                      gap="0.625rem"
                      alignSelf="stretch"
                      variant="h5"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {section.replace(/([A-Z])/g, " $1").trim()}
                    </Typography>

                    <Stack spacing={"1.25rem"}>
                      {Object.entries(sectionData || {}).map(
                        ([key, value], idx) => (
                          <Box
                            key={idx}
                            display={"flex"}
                            flexDirection="column"
                            alignItems="flex-start"
                            gap="0.47175rem"
                            alignSelf="stretch"
                          >
                            {/* Keys/Values */}
                            <Typography
                              gap="0.47175rem"
                              alignSelf="stretch"
                              variant="h6"
                            >
                              {key}
                            </Typography>
                            {Array.isArray(value) ? (
                              <Typography variant="body1" alignSelf={"stretch"}>
                                {value.length > 0 ? value.join(", ") : "N/A"}
                              </Typography>
                            ) : (
                              <Typography variant="body1">
                                {value || "N/A"}
                              </Typography>
                            )}
                          </Box>
                        ),
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  ) : null;
};

const SnapshotDrawer = ({
  snapshotData: propSnapshotData,
  loading,
  onAccept,
}) => {
  const [value, setValue] = React.useState(0);

  // Use mock data in development when no prop provided
  let snapshotData = propSnapshotData;
  if (!snapshotData && isDevelopment) {
    snapshotData = mockSnapshotData;
  }

  if (!snapshotData) {
    return <div>No snapshot data provided</div>;
  }

  if (typeof snapshotData === "string") {
    try {
      snapshotData = JSON.parse(propSnapshotData);
    } catch (error) {
      return <div>Error parsing snapshot data</div>;
    }
  }

  if (!Array.isArray(snapshotData?.tabs)) {
    return <div>Invalid tabs structure</div>;
  }

  if (!snapshotData?.data || typeof snapshotData.data !== "object") {
    return <div>Invalid data structure</div>;
  }

  const TOTAL_TABS = snapshotData.tabs.length;

  const handleChange = (event, newValue) => setValue(newValue);
  const handleNext = () => {
    if (value === TOTAL_TABS - 1) {
      onAccept();
    }
    value < TOTAL_TABS - 1 && setValue(value + 1);
  };
  const handlePrevious = () => value > 0 && setValue(value - 1);

  return (
    <>
      {loading ? (
        <Typography variant="body1">Loading snapshot data...</Typography>
      ) : (
        <>
          <Stack
            alignItems={"center"}
            p={0}
            sx={{
              width: "100%",
              height: "calc(100vh - 2000px)",
              justifyContent: "space-between",
              paddingBottom: "60px",
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h3" align="center" sx={{ mb: 2 }}>
                Snapshot Overview
              </Typography>

              <TabPanel
                value={value}
                index={value}
                sections={snapshotData.tabs[value]?.sections || []}
                snapshotData={snapshotData}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.4375rem",
                }}
              >
                <Button
                  variant="ghost"
                  onClick={handlePrevious}
                  disabled={value === 0}
                >
                  Previous
                </Button>
                <Button
                  sx={{ width: "8.5625rem", height: "2.5rem" }}
                  onClick={handleNext}
                >
                  {value === TOTAL_TABS - 1 ? "Accept" : "Next"}
                </Button>
              </div>
            </Stack>
          </Stack>

          <Box
            style={{
              position: "fixed",
              bottom: "1.5rem",
              left: "calc(50% + 7.8rem)",
              width: "100%",
              transform: "translateX(-50%)",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  height: "0.26938rem",
                  minHeight: "0.26938rem",
                },
              }}
            >
              {snapshotData.tabs.map((_, index) => (
                <Tab key={index} />
              ))}
            </Tabs>
          </Box>
        </>
      )}
    </>
  );
};

export default SnapshotDrawer;
