import apiAxios from "./axiosConfig";

export const fetchProposals = async (params) => {
  try {
    const response = await apiAxios.post("/proposal/list", params);
    return response.data;
  } catch (error) {
    console.error("Error fetching proposals:", error);
    throw error;
  }
};

export const fetchActivityLogs = async (params) => {
  try {
    const response = await apiAxios.post("/proposal/logs", params);
    return response.data;
  } catch (error) {
    console.error("Error fetching activity logs:", error);
    throw error;
  }
};

/**
 * Adds a new log entry for a proposal
 * @param {Object} params - Log data
 * @param {string} params.proposal_id - The proposal ID to add log for
 * @param {string} params.action - Type of action (e.g., 'create', 'update', 'delete')
 * @param {string} params.message - Optional message with details about the action
 * @returns {Promise<Object>} - Confirmation with the new log ID
 */
export const addProposalLog = async (params) => {
  try {
    const response = await apiAxios.post("/proposal/add_log", params);
    return response.data;
  } catch (err) {
    console.error("Error adding proposal log:", err);
    return { error: "Failed to log action" };
  }
};

export const duplicateProposal = async (proposalId) => {
  try {
    const response = await apiAxios.post("/proposal/duplicate", {
      proposal_id: proposalId,
    });
    return response.data;
  } catch (error) {
    console.error("Error duplicating proposal:", error);
    throw error;
  }
};

export const createProposal = async (jobId, title, description, rawTakeoff) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/create_proposal", {
      job_id: jobId,
      proposal_name: title,
      custom_message: description,
      rawTakeoff: rawTakeoff,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    console.error(
      "Error creating proposal:",
      err.response?.data || err.message
    );
    return null;
  }
};

export const updateProposal = async (params) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/update_proposal", {
      params,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    console.error(
      "Error updating proposal:",
      err.response?.data || err.message
    );
    return null;
  }
};

export const proposal_inuse_toggle = async (params) => {
  try {
    // Call the Flask backend using apiAxios
    const response = await apiAxios.post("/proposal/proposal_inuse_toggle", {
      params,
    });

    // Return the response data
    return response.data; // Axios automatically parses the JSON response
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // Ignore the 401 Unauthorized error
      console.warn("Ignored 401 Unauthorized error on unmount.");
    } else {
      console.error(
        "Error updating proposal:",
        err.response?.data || err.message
      );
    }
    return null;
  }
};

/**
 * Sends a proposal via email with a PDF attachment.
 * @param {string} shareEmail - The recipient's email address.
 * @param {Blob} pdfBlob - The generated PDF Blob.
 * @returns {object|null} The response data or null if an error occurs.
 */
export const sendProposalEmail = async (shareEmail, pdfBlob) => {
  try {
    // Create FormData
    const formData = new FormData();
    formData.append("pdf_file", pdfBlob, "proposal.pdf");
    formData.append("email", shareEmail);

    // POST to your Flask endpoint
    const response = await apiAxios.post(
      "/proposal/send_proposal_email",
      formData
    );

    // Return the response data (Axios automatically parses JSON)
    return response.data;
  } catch (err) {
    console.error(err.response?.data || err.message);
  }
  return null;
};

// export const sendSignatureRequest = async (
//   clientEmail,
//   clientName,
//   proposalId,
//   pdfBlob
// ) => {
//   try {
//     // Create FormData
//     const formData = new FormData();
//     formData.append("pdf_file", pdfBlob, "proposal.pdf");
//     formData.append("email", clientEmail);
//     formData.append("client_name", clientName);
//     formData.append("proposal_id", proposalId);

//     // POST to your Flask endpoint
//     const response = await apiAxios.post(
//       "/proposal/send_signature_request",
//       formData
//     );

//     // Return the response data (Axios automatically parses JSON)
//     return response.data;
//   } catch (err) {
//     console.error(err.response?.data || err.message);
//   }
//   return null;
// };
export const sendSignatureRequest = async (
  clientEmail,
  clientName,
  proposalId,
  pdfBlob,
  signatureCoordinates = null
) => {
  try {
    // Create FormData
    const formData = new FormData();
    formData.append("pdf_file", pdfBlob, "proposal.pdf");
    formData.append("email", clientEmail);
    formData.append("client_name", clientName);
    formData.append("proposal_id", proposalId);

    // Include signature coordinates if provided
    if (signatureCoordinates) {
      formData.append(
        "signature_coordinates",
        JSON.stringify(signatureCoordinates)
      );
    }

    // POST to your Flask endpoint
    const response = await apiAxios.post(
      "/proposal/send_signature_request",
      formData
    );

    // Return the response data (Axios automatically parses JSON)
    return response.data;
  } catch (err) {
    console.error(err.response?.data || err.message);
    throw err; // Re-throw to handle in the component
  }
};
export const getSignatureRequest = async (token) => {
  try {
    console.log(`Fetching signature request with token: ${token}`);
    const response = await apiAxios.get(
      `/proposal/get_signature_request/${token}`
    );
    console.log("Signature request response:", response.data);
    return response.data;
  } catch (err) {
    console.error("Error in getSignatureRequest:", err);
    if (err.response) {
      console.error("Response error data:", err.response.data);
      console.error("Response status:", err.response.status);
    }
    throw err;
  }
};

export const submitSignature = async ({
  token,
  clientName,
  clientEmail,
  proposalId,
  signatureData,
  userAgentInfo,
  documentViewedAt,
  signatureSubmittedAt,
  signedPdfFile,
}) => {
  try {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("client_name", clientName || "");
    formData.append("client_email", clientEmail || "");
    formData.append("proposal_id", proposalId || "");
    formData.append("signature_data", signatureData || "");
    formData.append("user_agent_info", userAgentInfo || "");
    formData.append("document_viewed_at", documentViewedAt || "");
    formData.append("signature_submitted_at", signatureSubmittedAt || "");

    if (signedPdfFile) {
      formData.append("signed_pdf_file", signedPdfFile);
    }

    const response = await apiAxios.post(
      "/proposal/submit_signature",
      formData
    );
    return response.data;
  } catch (err) {
    console.error(err.response?.data || err.message);
    return null;
  }
};

export const fetchESignature = async (proposalId) => {
  try {
    const response = await apiAxios.get(`/proposal/esignature/${proposalId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching e-signature:", error);
    throw error;
  }
};
