import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 10,
    paddingBottom: 3,
    border: "1 solid #D1D5DB",
  },
  section: {
    marginBottom: 7,
    backgroundColor: "#F6F5F4",
    display: "flex",
    flexDirection: "column",
    padding: 10,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 1,
    alignSelf: "stretch",
    borderRadius: 10,
  },
  header: {
    marginBottom: 5,
    paddingBottom: 2,
    borderBottom: "1 solid #E5E7EB",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#1D1D1B",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionHeaderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderBottom: "1 solid #DCDACB",
    marginBottom: 5,
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
  },
  icon: {
    marginRight: 5,
    width: 16,
    height: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#111827",
  },
  contentBox: {
    // backgroundColor: "#F9FAFB",
  },
  label: {
    color: "#4B4B48",
    fontSize: 10.5,
    fontWeight: "bold",
    marginBottom: 3,
  },
  text: {
    fontSize: 10,
    color: "#1D1D1B",
    marginBottom: 3,
  },
  valuesList: {
    marginTop: 5,
  },
  value: {
    fontSize: 10,
    fontWeight: 500,
    color: "#1D1D1B",
    marginBottom: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#E7E7DF",
    padding: "4 8",
    borderRadius: 4,
    marginRight: 8,
  },
  valuesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    gap: 7,
  },
  column: {
    flex: 1,
  },
  dot: {
    width: 3,
    height: 3,
    backgroundColor: "#374151",
    borderRadius: "50%",
    marginRight: 5,
  },
  verticalBarIcon: {
    width: 6,
    height: 21,
    marginRight: 10,
  },
  coloredItem: {
    flexDirection: "row",
    marginBottom: 5,
    padding: 5,
    borderRadius: 8,
    backgroundColor: "#E7E7DF",
  },
});
