// src/theme/lightTheme.js
import { createTheme } from "@mui/material/styles";
import { textAlign } from "@mui/system";

/**
 * Minimal theme, focusing on just the two colors you want for the button,
 * plus your typography color tokens (#1D1D1B as primary, #FFFFFF as alt).
 */
export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 375,
      sm: 600,
      xmd: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1820,
    },
  },

  palette: {
    mode: "light",
    primary: {
      main: "#2961A7", // Normal button color
    },
    // You could use 'secondary' for the hover, or override via components
    secondary: {
      main: "#00407B", // Hover color, if you want to reference it in code
    },
    typographySecondary: {
      main: "#4B4B4B", // Text color for the button
    },
    typographyPrimary: {
      main: "#1D1D1B", // Text color for the button
    },
    text: {
      // #1D1D1B => Typography “primary”
      primary: "#1D1D1B",
    },
    background: {
      default: "#FFF", // or any other default background
    },
    highlights: {
      zero: "#FFFD96", // Highlight color for zero values
    },

    neutrals: {
      border1: "#ABA898", // from Figma dev mode
      // from Figma dev mode
      backgroundSecondary: "#E7E7DF",
      backgroundPrimary: "#F3F3F1",
      background: {
        input2: "#F4F4F0",
      },
    },
  },

  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "61px",
      fontWeight: 700,
      color: "#1D1D1B",
    },
    h2: {
      // fontFamily: "Atyp Display TRIAL",
      fontFamily: "Montserrat",
      fontSize: "1.9375rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
    },
    h2semibold31: {
      width: "24.25013rem",
      color: "#1D1D1B",
      fontFamily: "Montserrat",
      fontSize: "1.9375rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "2.7125rem",
    },
    h3semibold35: {
      fontFamily: "Atyp Display TRIAL",
      fontSize: "1.5625rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
    },
    subtitle1: {
      fontSize: "17px",
      fontWeight: 500,
      color: "#1D1D1B",
    },
    body1: {
      fontSize: "17px",
      fontWeight: 400,
      color: "#1D1D1B",
    },
    body1SemiBold17: {
      fontFamily: "Roboto",
      fontSize: "1.0625rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
    },
    body1Medium17: {
      fontFamily: "Roboto",
      fontSize: "1.0625rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#1D1D1B",
    },
    body2SemiBold16: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "135%",
    },
    body2Medium16: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.4875rem",
    },
    body2medium16: {
      color: "var(--Typography-Primary, #1D1D1B)",
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.4875rem",
    },
    body3: {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.05rem",
      color: "#1D1D1B",
    },
    body3medium14: {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "1.19rem",
      color: "#4B4B48",
    },
    body3semibold14: {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.19rem" /* 1.19rem */,
    },
    body3Medium14: {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "136%" /* 1.19rem */,
    },
    body3Regular14: {
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "170%" /* 1.19rem */,
    },
    regular12: {
      color: "#1D1D1B",
      fontFamily: "Roboto",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    captionregular12: {
      color: "var(--Typography-Secondary, #4B4B48)",
      fontFamily: "Roboto",
      fontSize: "0.75rem",
    },
    regular12caption: {
      color: "#1D1D1B",
      fontFamily: "Roboto",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.2rem",
    },
    regular10: {
      fontFamily: "Roboto",
      fontSize: "0.625rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },

    logo: {
      color: "var(--Logo-Blue, #00407B)",
      fontFamily: "Montserrat",
      fontSize: "1.84213rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "-0.05525rem",
    },
    logintext: {
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: "1.07006rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
    },
    welcometext: {
      textAlign: "center",
      fontFamily: "Atyp Display TRIAL",
      fontSize: "1.9375rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "auto",
          height: "auto",
          border: "5rem",
          borderRadius: "5rem",
          textTransform: "none",
          backgroundColor: "#2961A7",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#00407B",
          },
          variants: [
            {
              props: { variant: "ghost" },
              style: {
                color: "#32312F",
                "&:hover": {
                  backgroundColor: "#A5A49E",
                  borderColor: "#A5A49E",
                },
                backgroundColor: "#FFFFFF",
                border: "1.5px solid",
                borderRadius: "6.25rem",
                outline: "#969484",
              },
            },
            {
              props: { variant: "no-background" },
              style: {
                color: "#32312F",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                },
                backgroundColor: "#FFFFFF",
                borderRadius: "6.25rem",
              },
            },
            {
              props: { variant: "indigo" },
              style: {
                color: "#1D1D1B",
                backgroundColor: "#E0E5EB",
                "&:hover": {
                  backgroundColor: "#C8D1DC",
                  borderColor: "#7B8A9A",
                },
                border: "1.5px solid",
                borderRadius: "6.25rem",
                outline: "#969484",
              },
            },
            {
              props: { variant: "outlined" },
              style: {
                backgroundColor: "#FFF",
                "&:hover": {
                  backgroundColor: "#C8D1DC",
                  borderColor: "#7B8A9A",
                },
                border: "1.5px solid",
                borderRadius: "6.25rem",
                outline: "#969484",
              },
            },
          ],
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        html: {
          [themeParam.breakpoints.up("xs")]: {
            fontSize: "10px",
          },
          [themeParam.breakpoints.up("xsm")]: {
            fontSize: "11px",
          },
          [themeParam.breakpoints.up("sm")]: {
            fontSize: "12px",
          },
          [themeParam.breakpoints.up("xmd")]: {
            fontSize: "13px",
          },
          [themeParam.breakpoints.up("md")]: {
            fontSize: "14px",
          },
          [themeParam.breakpoints.up("lg")]: {
            fontSize: "15px",
          },
          [themeParam.breakpoints.up("xl")]: {
            fontSize: "16px",
          },
          [themeParam.breakpoints.up("xxl")]: {
            fontSize: "18px",
          },
        },

        // Custom scrollbar styles
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px", // Added for horizontal scrollbar
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
          borderRadius: "4px",
          margin: "4px", // changed to apply to both axes
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#DCDACB",
          borderRadius: "4px",
          minHeight: "40px",
          minWidth: "40px", // for horizontal scrollbar
          "&:hover": {
            background: "#ABA898",
          },
        },
      }),
    },

    // ---- NEW OVERRIDES FOR MENUS & MENU ITEMS ----
    MuiMenu: {
      styleOverrides: {
        paper: {
          // Style the Paper component (the dropdown container)
          border: "0.05rem solid #ccc",
          borderRadius: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#FFFFFF",
        },
        list: {
          // If you want to style the list container inside the menu
          padding: "0.5rem 0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#1D1D1B",
          borderBottom: "1px solid #E0E5EB",
          "&:last-of-type": {
            borderBottom: "none",
          },
          "&.Mui-selected": {
            // Styles for the selected menu item
            backgroundColor: "#E7E7DF",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#C8D1DC",
          },
          "&:hover": {
            // Hover style for non-selected items
            backgroundColor: "#F3F3F1",
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          gap: "0.3125rem",
          backgroundColor: "#DCDACB",
          minWidth: "3.5738rem",
          margin: "0 4px",
          borderRadius: "1.07756rem",
          "&.Mui-selected": { backgroundColor: "#47BCFF" },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        flexContainer: { justifyContent: "center" },
      },
    },
  },
});

export default lightTheme;
