import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";
import {
  VisionIcon,
  KeyDeliverableIcon,
  DesignStyleIcon,
  SpecialRequirementsIcon,
  VerticalBar,
} from "./Icons";

const ProjectOverviewPDF = ({
  visionStatement,
  keyDeliverables,
  designStyle,
  specialRequirements,
}) => {
  return (
    <Document>
      <View style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Project Overview & Objectives</Text>
        </View>

        {/* Vision Statement */}
        <View style={styles.section}>
          <View style={styles.sectionHeaderBox}>
            <View style={styles.sectionHeader}>
              <VisionIcon />
              <Text style={styles.title}>Vision Statement</Text>
            </View>
          </View>
          <View style={styles.contentBox}>
            <Text style={styles.text}>{visionStatement}</Text>
          </View>
        </View>

        {/* Key Deliverables */}
        <View style={styles.section}>
          <View style={styles.sectionHeaderBox}>
            <View style={styles.sectionHeader}>
              <KeyDeliverableIcon />
              <Text style={styles.title}>Key Deliverables</Text>
            </View>
          </View>
          <View style={styles.contentBox}>
            <View style={styles.grid}>
              {keyDeliverables.map((item, index) => (
                <View key={index} style={styles.coloredItem}>
                  <Text style={styles.text}>{item}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Design Style & Special Requirements in two columns */}

        <View style={styles.grid}>
          <View style={styles.column}>
            <View style={styles.section}>
              {/* Design Style */}

              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <DesignStyleIcon />
                  <Text style={styles.title}>Design Style</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {designStyle.map((style, index) => (
                  <View key={index} style={styles.coloredItem}>
                    <Text key={index} style={styles.text}>
                      {style}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>

          {/* Special Requirements */}
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <SpecialRequirementsIcon />
                  <Text style={styles.title}>Special Requirements</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {specialRequirements.map((req, index) => (
                  <View key={index} style={styles.coloredItem}>
                    <Text key={index} style={styles.text}>
                      {req}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Document>
  );
};

export default ProjectOverviewPDF;
