import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";
import { BuildingIcon, VerticalBar } from "./Icons";

const MaterialSpecificationsPDF = ({
  structuralMaterials,
  exteriorMaterials,
  interiorFinishes,
}) => {
  return (
    <Document>
      <View style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Material Specifications</Text>
        </View>

        <View style={styles.grid}>
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <BuildingIcon />
                  <Text style={styles.title}>Structural Materials</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {structuralMaterials.map((material, index) => (
                  <View key={index} style={styles.coloredItem}>
                    <Text key={index} style={styles.text}>
                      {material}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <BuildingIcon />
                  <Text style={styles.title}>Exterior Materials</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {exteriorMaterials.map((material, index) => (
                  <View key={index} style={styles.coloredItem}>
                    <Text key={index} style={styles.text}>
                      {material}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <BuildingIcon />
                  <Text style={styles.title}>Interior Finishes</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                {interiorFinishes.map((finish, index) => (
                  <View key={index} style={styles.coloredItem}>
                    <Text key={index} style={styles.text}>
                      {finish}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Document>
  );
};

export default MaterialSpecificationsPDF;
