import React, { useState, useEffect, useMemo } from "react";
import { 
  Box, 
  Snackbar, 
  Alert, 
  TextField, 
  Typography, 
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "../Sidebar/Sidebar"; // Import your Sidebar component
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  TableStructureFrame,
  TableStructureContentFrame,
  TableStructureHeadingFrame,
  TableStructureHeadingIconFrame,
  TableStructureHeadingIcon,
  TableStructureHeadingRotateIcon,
  TableStructureHeadingText,
} from "../EstimationStudio/TableStructure";
import { fetchAssembliCostData } from "../../services/pricingService";

const StyledTableContainer = styled(Box)(() => ({
    display: "flex",
    padding: "0.6875rem 0.75rem",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1.625rem",
    background: "var(--Nuetrals-Background---Tertiary, #FFF)",
    boxShadow:"0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
    flexDirection: "column",
}));

const StyledTableHeader = styled(Box)(() => ({
  display: "flex",
  fontFamily: "Roboto",
  fontWeight: 600,
  borderBottom: `1.5px solid #DCDACB`,
  padding: "1rem 1rem 0.5rem 1rem",
  fontSize: "1rem",
  color: "#000",
  justifyContent: "space-between",
  width: "100%",
  gap: "1rem",
}));

const StyledTableRow = styled(Box)(() => ({
  display: "flex",
  fontFamily: "Roboto",
  borderBottom: `1px solid #E7E7E7`,
  padding: "0.8rem 1rem",
  fontSize: "0.875rem",
  color: "#1D1D1B",
  alignItems: "center",
  "&:last-child": {
    borderBottom: "none",
  },
  justifyContent: "space-between",
  width: "100%",
  gap: "1rem",
}));

const MainContent = styled(Box)(() => ({
    display: "flex",
    width: "56.44019rem",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "3rem",
    marginLeft: "26.1rem",
    padding: "2rem",
    marginTop: "2.86rem",
    marginRight: "12rem",
    flex: 1,
}));

const AssembliPricing = () => {
  const [data, setData] = useState([]);
  const [openDivisions, setOpenDivisions] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("all");
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAssembliCostData();
        if (response.error) {
          setError(response.error);
          setSnackbarOpen(true);
        } else {
          setData(response);
          
          // Extract all line items for easier filtering
          const allLineItems = response.flatMap((division) =>
            division.subdivisions.flatMap((subdivision) =>
              subdivision.lineItems.map((item) => ({
                ...item,
                division_name: division.division,
                subdivision_name: subdivision.name,
              }))
            )
          );
          
          setAllItems(allLineItems);
          setError(null);
        }
      } catch (err) {
        setError("Failed to fetch data.");
        setSnackbarOpen(true);
      }
    };

    fetchData();
  }, []);

  // When search query changes, auto-expand divisions with matching items
  useEffect(() => {
    if (searchQuery.trim() && data.length > 0) {
      const matchingDivisions = {};
      
      data.forEach((division) => {
        const divisionHasMatch = division.subdivisions.some((subdivision) =>
          subdivision.lineItems.some((item) => itemMatchesSearch(item, subdivision.name))
        );
        
        if (divisionHasMatch) {
          matchingDivisions[division.division] = true;
        }
      });
      
      setOpenDivisions(prev => {
        const newState = { ...prev };
        Object.keys(matchingDivisions).forEach(div => {
          newState[div] = true;
        });
        return newState;
      });
    }
  }, [searchQuery, searchField, data]);

  const searchableFields = useMemo(() => {
    return [
      // { value: "all", label: "All Fields" },
      { value: "description", label: "Item Name" },
      { value: "subdivision_name", label: "Subdivision" },
      { value: "unit", label: "Unit" },
      // { value: "material_cost", label: "Material Cost" },
      // { value: "labor_cost", label: "Labor Cost" },
    ];
  }, []);

  const toggleDivision = (divisionName) => {
    setOpenDivisions((prev) => ({
      ...prev,
      [divisionName]: !prev[divisionName],
    }));
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const itemMatchesSearch = (item, subdivisionName) => {
    if (!searchQuery.trim()) return true;
    
    const lowerQuery = searchQuery.toLowerCase();
    
    // Search in all fields
    if (searchField === "all") {
      return (
        (item.description && item.description.toLowerCase().includes(lowerQuery)) ||
        (subdivisionName && subdivisionName.toLowerCase().includes(lowerQuery)) ||
        (item.unit && item.unit.toLowerCase().includes(lowerQuery)) ||
        (item.material_cost !== undefined && item.material_cost.toString().includes(lowerQuery)) ||
        (item.labor_cost !== undefined && item.labor_cost.toString().includes(lowerQuery)) ||
        (item.total_cost !== undefined && item.total_cost.toString().includes(lowerQuery))
      );
    }
    
    // Search in specific field
    if (searchField === "subdivision_name") {
      return subdivisionName && subdivisionName.toLowerCase().includes(lowerQuery);
    }
    
    // For number fields
    if (["material_cost", "labor_cost", "total_cost"].includes(searchField)) {
      return item[searchField] !== undefined && item[searchField].toString().includes(lowerQuery);
    }
    
    // For text fields
    return item[searchField] && item[searchField].toLowerCase().includes(lowerQuery);
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      {/* Sidebar */}
      <Sidebar />
      
      <MainContent>
        <Box sx={{
            display: "flex", 
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch"
        }}>
            <Typography variant="h2semibold31">Assembli Cost Database</Typography>
        </Box>

        {/* Search Controls - similar to UserPricingDivisions */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            mb: 2,
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexGrow: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                endAdornment: searchQuery && (
                  <IconButton
                    onClick={handleClearSearch}
                    size="small"
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>

          <FormControl size="small" sx={{ minWidth: 180 }}>
            <InputLabel>Search Field</InputLabel>
            <Select
              label="Search Field"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            >
              {searchableFields.map((field) => (
                <MenuItem key={field.value} value={field.value}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/*Table Container*/}
        <TableStructureFrame>
          {data.map((division) => {
            // Filter line items based on search
            const filteredItems = division.subdivisions.flatMap((subdivision) =>
              subdivision.lineItems
                .filter(item => itemMatchesSearch(item, subdivision.name))
                .map(item => ({
                  ...item,
                  subdivision: subdivision.name,
                }))
            );
            
            // Skip empty divisions when filtering
            if (searchQuery.trim() && filteredItems.length === 0) {
              return null;
            }

            return (
              <Box key={division.division} sx={{ width: "100%" }}>
                {/* Division Heading */}
                <TableStructureContentFrame
                  onClick={() => toggleDivision(division.division)}
                  sx={{ cursor: "pointer", padding: "0.5rem 1rem" }}
                >
                  <TableStructureHeadingFrame>
                    <TableStructureHeadingIconFrame>
                      {openDivisions[division.division] ? (
                        <TableStructureHeadingIcon />
                      ) : (
                        <TableStructureHeadingRotateIcon />
                      )}
                    </TableStructureHeadingIconFrame>
                    <TableStructureHeadingText>
                      {division.division}
                      {/* {division.division} {filteredItems.length > 0 && `(${filteredItems.length} items)`} */}
                    </TableStructureHeadingText>
                  </TableStructureHeadingFrame>
                </TableStructureContentFrame>

                {/* Line Items Table */}
                {openDivisions[division.division] && (
                  <StyledTableContainer>
                    <StyledTableHeader>
                      <Box sx={{ flex: 2 }}>Subdivision</Box>
                      <Box sx={{ flex: 2 }}>Item Name</Box>
                      <Box sx={{ flex: 1 }}>Unit</Box>
                      <Box sx={{ flex: 1 }}>Material Cost</Box>
                      <Box sx={{ flex: 1 }}>Labor Cost</Box>
                      <Box sx={{ flex: 1 }}>Total Cost</Box>
                    </StyledTableHeader>
                    {filteredItems.map((item, index) => (
                      <StyledTableRow key={index}>
                        <Box sx={{ flex: 2 }}>{item.subdivision}</Box>
                        <Box sx={{ flex: 2 }}>{item.description}</Box>
                        <Box sx={{ flex: 1 }}>{item.unit}</Box>
                        <Box sx={{ flex: 1 }}>{currencyFormatter.format(item.material_cost)}</Box>
                        <Box sx={{ flex: 1 }}>{currencyFormatter.format(item.labor_cost)}</Box>
                        <Box sx={{ flex: 1 }}>{currencyFormatter.format(item.total_cost)}</Box>
                      </StyledTableRow>
                    ))}
                  </StyledTableContainer>
                )}
              </Box>
            );
          })}
        </TableStructureFrame>

        {/* Message when no results found */}
        {searchQuery.trim() && data.every(division => 
          division.subdivisions.every(subdivision => 
            subdivision.lineItems.every(item => !itemMatchesSearch(item, subdivision.name))
          )
        ) && (
          <Box sx={{ p: 2, textAlign: "center", width: "100%" }}>
            <Typography variant="body1">
              No items found matching the search criteria.
            </Typography>
          </Box>
        )}

        {/* Snackbar for errors */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      </MainContent>
    </Box>
  );
};

export default AssembliPricing;