import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";
import { VerticalBar, DocumentIcon } from "./Icons";

const LegalCompliancePDF = ({
  // Default data just for illustration
  LegalCompliance,
}) => {
  return (
    <Document>
      <View style={styles.page}>
        {/* Page Header */}
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Legal & Compliance Overview</Text>
        </View>

        {/* Introductory note beneath the header */}
        {/* <Text style={{ fontSize: 10, marginBottom: 10 }}>
          This proposal includes comprehensive legal protection and compliance
          measures for your project.
        </Text> */}

        {/* Render each section (e.g., Contract Terms, Warranty, etc.) */}
        {Object.entries(LegalCompliance).map(([sectionName, items], index) => (
          <View key={index} style={styles.section}>
            {/* Section Heading */}
            <View style={styles.sectionHeaderBox}>
              <View style={styles.sectionHeader}>
                <DocumentIcon />
                <Text style={styles.title}>{sectionName}</Text>
              </View>
            </View>

            {/* List of label–description pairs for this section */}
            {items.map((item, i) => (
              <View
                key={i}
                style={{
                  borderBottom: "1 solid #D1D5DB",
                  paddingBottom: 5,
                  marginBottom: 5,
                  width: "100%",
                }}
              >
                <Text style={styles.label}>{item.label}</Text>
                <Text style={styles.text}>{item.value}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </Document>
  );
};

export default LegalCompliancePDF;
