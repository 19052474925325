// src/theme/darkTheme.js
import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90CAF9", // Example
    },
    secondary: {
      main: "#F48FB1", // Example
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
    text: {
      primary: "#fff",
      secondary: "#ccc",
    },
  },
  // Feel free to copy over typography/components from lightTheme
  // and adjust to your desired "dark" aesthetic
});

export default darkTheme;
