import React, { useState, useEffect } from "react";
import { Box, Slide, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import posthog from "posthog-js";
import Sidebar from "../Sidebar/Sidebar";
import TopProgress from "../TopProgress/TopProgress";
import FileUpload from "../FileUpload/FileUpload";
import FilePreview from "../FileUpload/FilePreview";
import PromptInput from "../FileUpload/PromptInput";
import LoadingPage from "../FileUpload/LoadingPage";
import Snapshot from "../Snapshot/Snapshot";
import SnapshotDrawer from "../Snapshot/SnapshotDrawer";
import {
  createJobNoPrompt,
  getSnapshotData,
  updateJobPrompt,
} from "../../services/jobService";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchJobsThunk, setSelectedJobId } from "../../store"; // adjust the path as needed

export default function UploadPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Steps: 1=Upload, 2=Preview, 3=Prompt, 4=Loading, 5=Snapshot
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [jobId, setJobId] = useState(null);
  const [snapshotData, setSnapshotData] = useState(null);
  const [snapshotLoading, setSnapshotLoading] = useState(false);
  const [sandboxErrorOpen, setSandboxErrorOpen] = useState(false);
  const [sandboxErrorMessage, setSandboxErrorMessage] = useState("");

  const { parallelization } = useFlags();

  const goToStep = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  // STEP 1: File has been chosen
  const handleFileChosen = (file) => {
    posthog.capture("upload:file_uploaded", {
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
      is_internal_user: false,
    });
    setSelectedFile(file);
    goToStep(2);
  };

  // STEP 2: File preview accepted
  // The FilePreview component returns an object with { gcsUrl, jobId }
  const handleFileAccepted = async ({ gcsUrl, jobId }) => {
    console.log("Set uploaded file url: ", gcsUrl);
    const job = await createJobNoPrompt(gcsUrl, jobId);
    if (!job.error) {
      setJobId(job.id);
      // Set the newly created job as selected so it’s highlighted in the sidebar
      dispatch(setSelectedJobId(job.id));
      // Refresh the jobs list in Redux so Sidebar updates
      dispatch(fetchJobsThunk());
      console.log("Created job with job_id: ", job.id);
      goToStep(3);
    } else {
      console.error("Error creating job: ", job.error);
    }
  };

  // STEP 3: Prompt input submitted
  const handlePromptSubmit = async (userPrompt) => {
    setPrompt(userPrompt);
    goToStep(4); // move to loading page immediately
    try {
      console.log("Updating job prompt:", jobId, userPrompt);
      const updatedJob = await updateJobPrompt(jobId, userPrompt);
      if (!updatedJob.error) {
        console.log("Job prompt updated successfully");
        posthog.capture("job:prompt_updated", {
          job_id: jobId,
          prompt_length: userPrompt.length,
          word_count: userPrompt.split(/\s+/).length,
          is_internal_user: false,
        });
        // Refresh jobs list in Redux so Sidebar updates
        dispatch(fetchJobsThunk());
      } else {
        console.error("Error updating job prompt:", updatedJob.error);
        setSandboxErrorMessage(
          "Something unexpected happened, we are working on it.",
        );
        setSandboxErrorOpen(true);
      }
    } catch (err) {
      console.error("Failed to update job prompt:", err);
      posthog.capture("job:prompt_update_failed", {
        job_id: jobId,
        error: err.message,
      });
      setSandboxErrorMessage(
        "Something unexpected happened, we are working on it.",
      );
      setSandboxErrorOpen(true);
    }
  };

  // STEP 4: Loading – poll for snapshot data every second
  useEffect(() => {
    let isActive = true;
    const pollInterval = 1000;
    let timeoutId;

    const pollStatus = async () => {
      if (!isActive || !jobId || currentStep !== 4) return;

      try {
        const [snapshotRes] = await Promise.all([getSnapshotData(jobId)]);

        if (isActive) {
          const snapshotReady =
            !snapshotRes.error && Object.keys(snapshotRes).length > 0;

          if (snapshotReady) {
            setSnapshotData(snapshotRes);
            setSnapshotLoading(false);
            goToStep(5);
          } else {
            if (snapshotRes.error)
              console.warn("Snapshot status:", snapshotRes.error);
            timeoutId = setTimeout(pollStatus, pollInterval);
          }
        }
      } catch (error) {
        if (isActive) {
          console.error("Polling error:", error);
          setSandboxErrorMessage("Status check failed - retrying...");
          setSandboxErrorOpen(true);
          timeoutId = setTimeout(pollStatus, pollInterval);
        }
      }
    };

    if (currentStep === 4 && jobId) {
      setSnapshotLoading(true);
      pollStatus();
    }

    return () => {
      isActive = false;
      clearTimeout(timeoutId);
    };
  }, [currentStep, jobId]);

  // STEP 5: Snapshot – user accepts snapshot and moves to next page
  const handleSnapshotAccept = () => {
    if (!jobId) return;
    posthog.capture("snapshot:snapshot_accepted", {
      job_id: jobId,
      snapshot_data_points: Object.keys(snapshotData || {}).length,
      is_internal_user: false,
    });
    // Ensure the job is selected so it's highlighted in the sidebar
    dispatch(setSelectedJobId(jobId));
    navigate(`/validate/${jobId}`);
  };

  // Determine top progress step (for your TopProgress component)
  const getTopProgressStep = () => {
    if (currentStep === 5) return 2;
    return currentStep;
  };

  return (
    <>
      <Sidebar />
      <Box
        sx={{
          marginLeft: "15.6rem",
          display: "flex",
          justifyContent: "center",
          width: "calc(100% - 15.6rem)",
        }}
      >
        <TopProgress currentStep={getTopProgressStep()} />
      </Box>

      {/* STEP 1: Upload */}
      <Slide
        direction="up"
        in={currentStep === 1}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <Box
          sx={{
            p: 4,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
            marginLeft: "15.6rem",
          }}
        >
          <FileUpload onFileChosen={handleFileChosen} />
        </Box>
      </Slide>

      {/* STEP 2: File Preview */}
      <Slide
        direction="up"
        in={currentStep === 2}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <Box
          sx={{
            marginTop: "7rem",
            marginLeft: "15.6rem",
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FilePreview
            file={selectedFile}
            onAccept={handleFileAccepted}
            onBack={() => goToStep(1)}
          />
        </Box>
      </Slide>

      {/* STEP 3: Prompt Input */}
      <Slide
        direction="up"
        in={currentStep === 3}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <Box
          sx={{
            marginTop: "12rem",
            marginLeft: "15.6rem",
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PromptInput onPromptSubmit={handlePromptSubmit} />
        </Box>
      </Slide>

      {/* STEP 4: Loading */}
      <Slide
        direction="up"
        in={currentStep === 4}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <Box
          sx={{
            marginTop: "17rem",
            marginLeft: "15.6rem",
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingPage />
        </Box>
      </Slide>

      {/* STEP 5: Snapshot */}
      <Slide
        direction="up"
        in={currentStep === 5}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 300 }}
      >
        <Box sx={{ marginTop: "7rem", marginLeft: "15.6rem" }}>
          <SnapshotDrawer
            snapshotData={snapshotData}
            loading={snapshotLoading}
            onAccept={handleSnapshotAccept}
          />
        </Box>
      </Slide>

      <Snackbar
        open={sandboxErrorOpen}
        autoHideDuration={4000}
        onClose={() => setSandboxErrorOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={() => setSandboxErrorOpen(false)}>
          {sandboxErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
