// editors/LicenseEditor.js
import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";

const LicenseEditor = ({ onSave, data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(formData);
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Edit/Save Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      {/* License Information */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          License Information
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {isEditing ? (
            <>
              <TextField
                label="License Number"
                value={formData.licenseNumber}
                onChange={(e) =>
                  handleInputChange("licenseNumber", e.target.value)
                }
                size="small"
                fullWidth
              />
              <TextField
                label="Valid Until"
                // If you want to store it as a date, you can change type="text" to type="date"
                // and store/format the data accordingly.
                value={formData.validUntil}
                onChange={(e) =>
                  handleInputChange("validUntil", e.target.value)
                }
                size="small"
                fullWidth
              />
              <TextField
                label="Classifications"
                value={formData.classifications}
                onChange={(e) =>
                  handleInputChange("classifications", e.target.value)
                }
                size="small"
                fullWidth
              />
            </>
          ) : (
            <>
              <Typography>
                <strong>License Number:</strong> {formData.licenseNumber}
              </Typography>
              <Typography>
                <strong>Valid Until:</strong> {formData.validUntil}
              </Typography>
              <Typography>
                <strong>Classifications:</strong> {formData.classifications}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      {/* Insurance / Bond Info */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Insurance &amp; Bond
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {isEditing ? (
            <>
              <TextField
                label="Liability"
                value={formData.liability}
                onChange={(e) => handleInputChange("liability", e.target.value)}
                size="small"
                fullWidth
              />
              <TextField
                label="Workers' Compensation"
                value={formData.workersComp}
                onChange={(e) =>
                  handleInputChange("workersComp", e.target.value)
                }
                size="small"
                fullWidth
              />
              <TextField
                label="Bond Capacity"
                value={formData.bondCapacity}
                onChange={(e) =>
                  handleInputChange("bondCapacity", e.target.value)
                }
                size="small"
                fullWidth
              />
            </>
          ) : (
            <>
              <Typography>
                <strong>Liability:</strong> {formData.liability}
              </Typography>
              <Typography>
                <strong>Workers Comp:</strong> {formData.workersComp}
              </Typography>
              <Typography>
                <strong>Bond Capacity:</strong> {formData.bondCapacity}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LicenseEditor;
