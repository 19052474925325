// companyPreferencesService.js

import apiAxios from "./axiosConfig";

/**
 * Helper: Transform the nested divisions/subdivisions JSON
 * into an array of objects with the following shape:
 * { division, divisionName, subdivision, subdivisionPath, item, displayName, input, defaultValue }
 * Only inputs that are present in the items object are returned.
 *
 * In this transformation, the original key (e.g. "Concrete_General")
 * is preserved in the "item" field while the "displayName" captures the
 * original "name" property (e.g. "Concrete (General)").
 */
function transformNestedToArray(nestedData) {
  const results = [];

  /**
   * Recursively traverse the nested JSON.
   *
   * @param {string} divisionKey - the division key (e.g. "Division_03")
   * @param {object} node - the current node in the nested JSON
   * @param {Array} pathSoFar - the subdivision keys from the root to the current node
   * @param {string} divisionName - the display name of the division
   * @param {string} currentSubdivisionName - the display name of the current subdivision level
   */
  function traverse(divisionKey, node, pathSoFar, divisionName, currentSubdivisionName) {
    // If this node has items, flatten them
    if (node.items) {
      // Use the current subdivision name (if available) or fall back to the division name
      const subdivision = currentSubdivisionName || divisionName;
      Object.keys(node.items).forEach((itemKey) => {
        const itemObj = node.items[itemKey];
        if (!itemObj) return;
        // Get the display name from the item object, or fallback to a formatted key
        const itemDisplayName = itemObj.name || itemKey.replace(/_/g, " ");
        
        Object.keys(itemObj).forEach((property) => {
          if (property === "name") return;
          results.push({
            division: divisionKey,
            divisionName: divisionName,
            subdivision: subdivision, // NEW: provide the current subdivision name
            subdivisionPath: [...pathSoFar],
            itemKey: itemKey, // Preserve the original key (e.g. "Concrete_General")
            item: itemDisplayName, // Use the name property as item display value
            displayName: itemObj.name, // Keep displayName for backward compatibility
            input: property,
            defaultValue: itemObj[property],
          });
        });
      });
    }

    // If this node has further subdivisions, recurse deeper
    if (node.subdivisions) {
      Object.keys(node.subdivisions).forEach((subKey) => {
        const subNode = node.subdivisions[subKey];
        if (!subNode) return;
        // Compute the display name for this subdivision (fallback to formatted key)
        const subDivisionName = subNode.name || subKey.replace(/_/g, " ");
        traverse(divisionKey, subNode, [...pathSoFar, subKey], divisionName, subDivisionName);
      });
    }
  }

  Object.keys(nestedData).forEach((divisionKey) => {
    const divisionObj = nestedData[divisionKey];
    if (!divisionObj) return;
    // Use the division's "name" if available; otherwise, fallback to the key
    const divisionName = divisionObj.name || divisionKey;
    traverse(divisionKey, divisionObj, [], divisionName, divisionName);
  });

  return results;
}

/**
 * Helper: Transform an array of settings objects
 * back into the nested JSON structure that /company/preferences expects.
 *
 * When reconstructing, the original key remains intact while the "name"
 * property for each item is set to the captured display name (if available).
 */
function transformArrayToNested(settingsArray) {
  const nestedData = {};

  settingsArray.forEach((setting) => {
    const {
      division,
      divisionName,
      subdivisionPath,
      itemKey, // Original key to use for the item in nested structure
      item, // Display name (not used for the key)
      displayName, // Backward compatibility
      input,
      defaultValue,
    } = setting;

    // Create the division object if it doesn't exist
    if (!nestedData[division]) {
      nestedData[division] = {
        name: divisionName || division,
        images: [],
        subdivisions: {},
      };
    }

    let currentNode = nestedData[division];
    // Walk through the subdivision path to get to the correct node
    if (subdivisionPath && subdivisionPath.length) {
      subdivisionPath.forEach((subKey) => {
        if (!currentNode.subdivisions[subKey]) {
          currentNode.subdivisions[subKey] = {
            name: subKey,
            images: [],
            subdivisions: {},
            items: {},
          };
        }
        currentNode = currentNode.subdivisions[subKey];
      });
    }

    // Now at the deepest subdivision node. Add the item using the original key.
    if (!currentNode.items) {
      currentNode.items = {};
    }
    
    // Use the itemKey as the key in the nested structure
    const key = itemKey || item.replace(/ /g, '_');
    
    if (!currentNode.items[key]) {
      // Set the "name" property to the item display name
      currentNode.items[key] = { 
        name: displayName || item 
      };
    }
    currentNode.items[key][input] = defaultValue;
  });

  return nestedData;
}

// OPTIONAL: Local default structure if your server returns nothing
const defaultPreferencesNested = {
  // -- Put your local default JSON here if you want a fallback -- //
};

/**
 * GET: Fetch the company preferences (nested) and transform them to array form.
 * If the server returns an empty object or fails, we use our local fallback.
 */
export const getDefaultValues = async () => {
  try {
    // Call the endpoint: GET /company/preferences
    const response = await apiAxios.get("/company/preferences");
    const nestedData = response.data; // The divisions/subdivisions JSON

    // Transform to array
    console.log("Fetched nested values:", nestedData);
    let settingsArray = transformNestedToArray(nestedData);
    console.log("Fetched default values:", settingsArray);

    // Return in the shape { settings: [...] } so the UI remains unchanged
    return { settings: settingsArray };
  } catch (error) {
    console.error("Error fetching default values:", error.response?.data || error);
    // Fallback to local default structure if the server call fails
    const fallbackArray = transformNestedToArray(defaultPreferencesNested);
    return { settings: fallbackArray };
  }
};

/**
 * POST: Update the company preferences.
 *  1) Transform the UI's array into nested JSON.
 *  2) POST to /company/preferences.
 *  3) Transform the returned nested JSON back into array form and return to the UI.
 */
export const updateDefaultValues = async (payload) => {
  try {
    const settingsArray = payload.settings || [];
    const nestedData = transformArrayToNested(settingsArray);

    // POST the nested JSON to /company/preferences
    const response = await apiAxios.post("/company/preferences", nestedData);
    const updatedNested = response.data;
    const updatedArray = transformNestedToArray(updatedNested);

    return { settings: updatedArray };
  } catch (error) {
    console.error("Error updating default values:", error.response?.data || error);
    throw error;
  }
};
