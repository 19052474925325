// src/components/TopProgress/TopProgress.js

import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { ReactComponent as DownloadIcon } from "../../static/Icons/download.svg";
import { ReactComponent as TickCircleIcon } from "../../static/Icons/tickcircle.svg";
import { ReactComponent as DocumentTextIcon } from "../../static/Icons/documenttext.svg";

const TopProgressFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "3.5625rem",
  padding: "0.625rem 0rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  flexShrink: 0,
  borderBottom: "0.65px solid var(--Nuetrals-Border---2, #DCDACB)",
  background: "var(--Nuetrals-Background---Primary, #F3F3F1)",
  backdropFilter: "blur(7px)",
  position: "fixed",
  top: 0,
  // left: '7.8rem',
  zIndex: 10,
}));

const ProgressBarFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "center",
}));

const UploadFrame = styled(Box)(() => ({
  display: "flex",
  width: "7.71394rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
}));

const UploadInnerFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

const UploadProgressFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
}));

const DownloadIconStyled = styled(DownloadIcon)(() => ({
  width: "1rem",
  height: "1rem",
}));

const TickCircleIconStyled = styled(TickCircleIcon)(() => ({
  width: "1rem",
  height: "1rem",
}));

const DocumentTextIconStyled = styled(DocumentTextIcon)(() => ({
  width: "1rem",
  height: "1rem",
}));

const UploadProgressText = styled(Typography)(() => ({
  // color: 'var(--Typography-Primary, #1D1D1B)',
  // textAlign: 'center',
  // fontFamily: 'Roboto',
  // fontSize: '0.875rem',
  // fontStyle: 'normal',
  // fontWeight: 500,
  // lineHeight: '120%',
  // justifyContent: 'center',
  // alignContent: 'center',
  // marginLeft: '2.25rem'
}));

const Rectangle = styled(Box)(() => ({
  position: "relative",
  width: "6.5rem",
  height: "0.5rem",
  alignSelf: "stretch",
  borderRadius: "2rem",
  background: "var(--Nuetrals-Border---2, #DCDACB)",
  marginTop: "2rem",
  marginLeft: "-5.6rem",
  alignContent: "center",
}));

/**
 * Interprets steps:
 *   Steps 1..4 => highlight "Upload"
 *   Step 5 => highlight "Validate"
 *   Steps 6..7 => highlight "Proposal"
 */
const TopProgress = ({ currentStep = 1 }) => {
  const getHighlightStep = () => {
    if (currentStep >= 1 && currentStep <= 4) return 1;
    if (currentStep === 5) return 2;
    return 3; // for steps 6..7
  };

  const isActive = (stepIndex) => {
    return getHighlightStep() === stepIndex;
  };

  const getColor = (stepIndex) => (isActive(stepIndex) ? "#47BCFF" : "#DCDACB");
  // const getTextColor = (stepIndex) => (isActive(stepIndex) ? '#1D1D1B' : '#ABA898');

  return (
    <TopProgressFrame>
      <ProgressBarFrame>
        {/* Step 1: Upload */}
        <UploadFrame>
          <UploadInnerFrame>
            <UploadProgressFrame>
              {/* <Box sx={{ marginLeft: '2rem' }} /> */}
              <DownloadIconStyled />
              <UploadProgressText
                variant="body3Medium14"
                sx={{ color: "#1D1D1B" }}
              >
                Upload
              </UploadProgressText>
            </UploadProgressFrame>
            <Rectangle sx={{ background: getColor(1) }} />
          </UploadInnerFrame>
        </UploadFrame>

        {/* Step 2: Validate */}
        <UploadFrame>
          <UploadInnerFrame>
            <UploadProgressFrame>
              <TickCircleIconStyled />
              <UploadProgressText
                variant="body3Medium14"
                sx={{ color: "#1D1D1B" }}
              >
                Validate
              </UploadProgressText>
            </UploadProgressFrame>
            <Rectangle sx={{ background: getColor(2) }} />
          </UploadInnerFrame>
        </UploadFrame>

        {/* Step 3: Proposal */}
        <UploadFrame>
          <UploadInnerFrame>
            <UploadProgressFrame>
              <DocumentTextIconStyled />
              <UploadProgressText
                variant="body3Medium14"
                sx={{ color: "#1D1D1B" }}
              >
                Propose
              </UploadProgressText>
            </UploadProgressFrame>
            <Rectangle sx={{ background: getColor(3) }} />
          </UploadInnerFrame>
        </UploadFrame>
      </ProgressBarFrame>
    </TopProgressFrame>
  );
};

export default TopProgress;
