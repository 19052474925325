import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const FoundationsDetailsEditor = ({ onSave, data }) => {
  // Local state to manage edit mode and form data
  const [isEditing, setIsEditing] = useState(false);
  const [foundationDetails, setFoundationDetails] = useState(data);

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(foundationDetails); // callback to parent with updated data
  };

  // Handle label or value changes in an item
  const handleItemChange = (index, field, value) => {
    setFoundationDetails((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  // Add a new item
  const handleAddItem = () => {
    setFoundationDetails((prev) => [
      ...prev,
      { label: "New Label", value: "New Value" },
    ]);
  };

  // Remove an item
  const handleRemoveItem = (index) => {
    setFoundationDetails((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* Sticky header for Edit/Save button */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      {/* Scrollable content section */}
      <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Foundation Details
        </Typography>

        <List>
          {foundationDetails.map((detail, index) => (
            <ListItem
              key={index}
              sx={{ py: 1, flexDirection: "column", alignItems: "start" }}
              secondaryAction={
                isEditing && (
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                )
              }
            >
              {isEditing ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Label"
                      value={detail.label}
                      onChange={(e) =>
                        handleItemChange(index, "label", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Value"
                      value={detail.value}
                      onChange={(e) =>
                        handleItemChange(index, "value", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    {detail.label}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {detail.value}
                  </Typography>
                </>
              )}
            </ListItem>
          ))}
        </List>

        {isEditing && (
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddItem}
            sx={{ mt: 1 }}
          >
            Add Detail
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FoundationsDetailsEditor;
