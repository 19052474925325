import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Divider,
  TextField,
  Checkbox,
  IconButton,
  CircularProgress,
  InputAdornment,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FolderIcon from "@mui/icons-material/Folder";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import Sidebar from "../Sidebar/Sidebar";
import {
  saveMappedData,
  insertDataIntoCostCatalog,
} from "../../services/pricingService";

const LineItemMappingInterface = ({
  lineItems: initialLineItems = [], // All line items, combined from all files
  divisionsData = [],
  onSaveMapping,
  onBack,
}) => {
  const navigate = useNavigate();
  // console.log("Initial Line Items", initialLineItems);
  // console.log("Divisions Data", divisionsData);
  // State for line items
  const [lineItems, setLineItems] = useState(initialLineItems);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredLineItems, setFilteredLineItems] = useState(initialLineItems);
  const [lineItemSearchQuery, setLineItemSearchQuery] = useState("");

  // State for the Assembli structure
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedSubdivision, setSelectedSubdivision] = useState(null);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [selectedMaterialId, setSelectedMaterialId] = useState(null);
  const [divisionsSearchQuery, setDivisionsSearchQuery] = useState("");
  const [filteredDivisionData, setFilteredDivisionData] = useState([]);

  // UI state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0); // 0 = All, 1 = Mapped, 2 = Unmapped

  // Process divisions data for display
  const [processedDivisionsData, setProcessedDivisionsData] = useState({
    divisions: [],
    subdivisionsByDivision: {},
    itemsBySubdivision: {},
  });

  // Process divisions data on component mount
  useEffect(() => {
    console.log("Divisions Data", divisionsData);
    if (divisionsData && divisionsData.length > 0) {
      const divisions = [
        ...new Set(divisionsData.map((item) => item.division_name)),
      ].sort();

      const subdivisionsByDivision = {};
      const itemsBySubdivision = {};

      divisions.forEach((division) => {
        // Get all subdivisions for this division
        const subdivisions = [
          ...new Set(
            divisionsData
              .filter((item) => item.division_name === division)
              .map((item) => item.subdivision_name)
          ),
        ].sort();

        subdivisionsByDivision[division] = subdivisions;

        // For each subdivision, get all line item descriptions
        subdivisions.forEach((subdivision) => {
          const items = divisionsData
            .filter(
              (item) =>
                item.division_name === division &&
                item.subdivision_name === subdivision
            )
            .map((item) => ({
              description: item.item_description,
              material_id: item.material_id,
            }));
          // Use a unique key combining division and subdivision
          const key = `${division}|${subdivision}`;
          itemsBySubdivision[key] = items.sort((a, b) =>
            a.description.localeCompare(b.description)
          );
        });
      });

      setProcessedDivisionsData({
        divisions,
        subdivisionsByDivision,
        itemsBySubdivision,
      });

      setFilteredDivisionData(divisions);
    }
  }, [divisionsData]);

  // Filter line items based on search query and tab selection
  useEffect(() => {
    let filtered = [...lineItems];

    // Filter by tab
    // Tab logic: 0 => Mapped, 1 => Unmapped
    if (tabValue === 0) {
      // Show Mapped items
      filtered = filtered.filter((item) => item.material_id !== null);
    } else {
      // Show Unmapped items
      filtered = filtered.filter((item) => item.material_id === null);
    }

    // Apply search query if provided
    if (lineItemSearchQuery) {
      const query = lineItemSearchQuery.toLowerCase();
      filtered = filtered.filter((item) => {
        // Search through all fields
        return (
          (item.description &&
            item.description.toLowerCase().includes(query)) ||
          (item.file_path && item.file_path.toLowerCase().includes(query)) ||
          (item.unit_of_measure &&
            item.unit_of_measure.toLowerCase().includes(query)) //||
          //   (item.price && item.price.toString().includes(query)) ||
          //   (item.quantity && item.quantity.toString().includes(query))
        );
      });
    }

    setFilteredLineItems(filtered);
  }, [lineItemSearchQuery, initialLineItems, tabValue]);

  // Auto-select single division
  useEffect(() => {
    if (filteredDivisionData.length === 1) {
      setSelectedDivision(filteredDivisionData[0]);
    } else if (!filteredDivisionData.includes(selectedDivision)) {
      setSelectedDivision(null);
    }
  }, [filteredDivisionData, selectedDivision]);

  // Auto-select single subdivision
  useEffect(() => {
    if (selectedDivision) {
      const subs =
        processedDivisionsData.subdivisionsByDivision[selectedDivision] || [];
      if (subs.length === 1) {
        setSelectedSubdivision(subs[0]);
      } else if (!subs.includes(selectedSubdivision)) {
        setSelectedSubdivision(null);
      }
    } else {
      setSelectedSubdivision(null);
    }
  }, [selectedDivision, processedDivisionsData, selectedSubdivision]);

  // Auto-select single line item
  useEffect(() => {
    if (selectedDivision && selectedSubdivision) {
      const key = `${selectedDivision}|${selectedSubdivision}`;
      const items = processedDivisionsData.itemsBySubdivision[key] || [];
      const currentItemValid = items.some(
        (it) => it.description === selectedLineItem
      );

      if (items.length === 1) {
        setSelectedLineItem(items[0].description);
        setSelectedMaterialId(items[0].material_id);
      } else if (!currentItemValid) {
        setSelectedLineItem(null);
        setSelectedMaterialId(null);
      }
    } else {
      setSelectedLineItem(null);
      setSelectedMaterialId(null);
    }
  }, [
    selectedDivision,
    selectedSubdivision,
    processedDivisionsData,
    selectedLineItem,
  ]);

  // Filter division data based on search query
  useEffect(() => {
    if (!divisionsSearchQuery) {
      setFilteredDivisionData(processedDivisionsData.divisions);
      return;
    }

    const query = divisionsSearchQuery.toLowerCase();

    // Search for matching divisions
    const matchingDivisions = processedDivisionsData.divisions.filter(
      (division) => division.toLowerCase().includes(query)
    );

    // Search for matching subdivisions
    const matchingSubdivisions = [];
    processedDivisionsData.divisions.forEach((division) => {
      const subdivisions =
        processedDivisionsData.subdivisionsByDivision[division] || [];
      if (subdivisions.some((sub) => sub.toLowerCase().includes(query))) {
        if (!matchingDivisions.includes(division)) {
          matchingDivisions.push(division);
        }
      }
    });

    // Search for matching line item descriptions
    processedDivisionsData.divisions.forEach((division) => {
      const subdivisions =
        processedDivisionsData.subdivisionsByDivision[division] || [];
      subdivisions.forEach((subdivision) => {
        const key = `${division}|${subdivision}`;
        const items = processedDivisionsData.itemsBySubdivision[key] || [];
        if (items.some((item) => item.toLowerCase().includes(query))) {
          if (!matchingDivisions.includes(division)) {
            matchingDivisions.push(division);
          }
        }
      });
    });

    setFilteredDivisionData(matchingDivisions.sort());
  }, [divisionsSearchQuery, processedDivisionsData]);

  // Handle division selection
  const handleDivisionSelect = (division) => {
    setSelectedDivision(division);
    setSelectedSubdivision(null);
    setSelectedLineItem(null);
  };

  // Handle subdivision selection
  const handleSubdivisionSelect = (subdivision) => {
    setSelectedSubdivision(subdivision);
    setSelectedLineItem(null);
  };

  // Handle line item selection
  const handleLineItemDescriptionSelect = (lineItemDescription) => {
    // console.log("Selected line item description", lineItemDescription);
    // console.log("Material ID", lineItemDescription.material_id);
    setSelectedLineItem(lineItemDescription.description);
    setSelectedMaterialId(lineItemDescription.material_id);
  };

  // Apply mapping to selected items
  const applyMapping = async () => {
    if (
      !selectedDivision ||
      !selectedSubdivision ||
      !selectedLineItem ||
      selectedItems.length === 0
    ) {
      setSnackbarMessage(
        "Please select both line items and an Assembli category"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    console.log("Applying mapping to selected items", selectedItems);
    console.log(
      `Division: ${selectedDivision}, Subdivision: ${selectedSubdivision}, Line Item: ${selectedLineItem}, Material ID: ${selectedMaterialId}`
    );

    // Get the complete row data for selected items
    const selectedRows = filteredLineItems.filter((item) =>
      selectedItems.includes(item.line_id)
    );

    try {
      // Save Mapped Data to Database and await the response
      const response = await saveMappedData(
        selectedItems,
        selectedMaterialId,
        selectedRows
      );
      console.log("saveMappedDataResponse", response);

      // Create a lookup map for quick access to the updated data
      const updatedDataMap = {};
      if (response.mapped_data && Array.isArray(response.mapped_data)) {
        response.mapped_data.forEach((item) => {
          updatedDataMap[item.line_id] = item;
        });
      }

      // Update line items with the new mapping and the returned data
      const updatedLineItems = lineItems.map((item) => {
        if (selectedItems.includes(item.line_id)) {
          const updatedData = updatedDataMap[item.line_id] || {};
          return {
            ...item,
            material_id: selectedMaterialId,
            material_name: selectedLineItem,
            // Include the updated pricing data from the backend
            unit_material_price:
              updatedData.unit_material_price !== undefined
                ? updatedData.unit_material_price
                : item.unit_material_price,
            unit_labor_price:
              updatedData.unit_labor_price !== undefined
                ? updatedData.unit_labor_price
                : item.unit_labor_price,
            unit: updatedData.unit !== undefined ? updatedData.unit : item.unit,
          };
        }
        return item;
      });

      setLineItems(updatedLineItems);

      // Update filtered line items as well
      setFilteredLineItems((prev) =>
        prev.map((item) => {
          if (selectedItems.includes(item.line_id)) {
            const updatedData = updatedDataMap[item.line_id] || {};
            return {
              ...item,
              material_id: selectedMaterialId,
              material_name: selectedLineItem,
              // Include the updated pricing data from the backend
              unit_material_price:
                updatedData.unit_material_price !== undefined
                  ? updatedData.unit_material_price
                  : item.unit_material_price,
              unit_labor_price:
                updatedData.unit_labor_price !== undefined
                  ? updatedData.unit_labor_price
                  : item.unit_labor_price,
              unit:
                updatedData.unit !== undefined ? updatedData.unit : item.unit,
            };
          }
          return item;
        })
      );

      console.log("Updated line items", updatedLineItems);

      // Show success message
      setSnackbarMessage(`${selectedItems.length} items mapped successfully`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Clear selection after mapping
      setSelectedItems([]);
    } catch (error) {
      console.error("Error applying mapping:", error);
      setSnackbarMessage(
        "Error applying mapping: " + (error.message || "Unknown error")
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const insertData = async () => {
    setIsLoading(true);
    try {
      const response = await insertDataIntoCostCatalog();
      console.log("Response", response.message);
      if (response.message) {
        navigate("/user-pricing");
      }
      setSnackbarMessage("Data inserted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error inserting data:", error);
      setSnackbarMessage(
        "Error inserting data: " + (error.message || "Unknown error")
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Save all mappings and stay on the screen
  const handleSaveAllMappings = async () => {
    setIsLoading(true);

    try {
      if (onSaveMapping) {
        await onSaveMapping(lineItems);
      }

      setSnackbarMessage("Mappings saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving mappings:", error);
      setSnackbarMessage(
        "Error saving mappings: " + (error.message || "Unknown error")
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // DataGrid handles pagination internally

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Helper to determine if a line item has a mapping
  // Consider line items with material_id as null to be unmapped
  const hasMappingData = (item) => {
    return item.material_id !== null;
  };

  // Currency formatter
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Calculate statistics
  const totalItems = lineItems.length;
  const mappedItems = lineItems.filter(hasMappingData).length;
  const unmappedItems = totalItems - mappedItems;

  return (
    <Box
      sx={{
        p: 3,
        marginTop: "1rem",
        overflow: "inherit",
        height: "calc(100vh - 2.5rem)",
      }}
    >
      <Box
        sx={{
          marginLeft: "20.1rem",
          marginTop: "1.86rem",
          marginRight: "6rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.5rem",
          }}
        >
          <Box>
            <Typography variant="h2semibold31">Line Item Mapping</Typography>

            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Chip
                icon={<CheckCircleIcon />}
                label={`${mappedItems} Mapped`}
                color="success"
                variant="outlined"
                sx={{ mr: 1 }}
              />
              <Chip
                icon={<InfoIcon />}
                label={`${unmappedItems} Unmapped`}
                color="warning"
                variant="outlined"
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={insertData}
              disabled={isLoading}
              sx={{ ml: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Insert Into Database"
              )}
            </Button>
            <Button
              variant="contained"
              // onClick={handleSaveAllMappings}
              onClick={applyMapping}
              disabled={
                !selectedDivision ||
                !selectedSubdivision ||
                !selectedLineItem ||
                selectedItems.length === 0
              }
              // disabled={isLoading}
              sx={{ ml: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Save Mappings"}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 100px)",
            gap: 3,
            overflow: "hidden",
          }}
        >
          {/* LEFT PANEL: Line Items */}
          <Paper
            sx={{
              width: "50%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Line Items
              </Typography>

              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                sx={{ mb: 2 }}
              >
                <Tab label="Mapped" />
                <Tab label="Unmapped" />
              </Tabs>

              {/* <TextField
                                fullWidth
                                placeholder="Search line items..."
                                value={lineItemSearchQuery}
                                onChange={(e) => setLineItemSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                variant="outlined"
                            /> */}
            </Box>

            <Box sx={{ flex: 1, overflow: "auto", height: "100%" }}>
              <DataGrid
                rows={filteredLineItems}
                getRowId={(row) => row.line_id}
                checkboxSelection
                disableRowSelectionOnClick
                pagination
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectedItems(newSelectionModel);
                }}
                rowSelectionModel={selectedItems}
                getRowClassName={(params) => {
                  return hasMappingData(params.row)
                    ? "mapped-row"
                    : "unmapped-row";
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                  },
                }}
                columns={[
                  {
                    field: "description",
                    headerName: "Description",
                    flex: 1,
                    minWidth: 250,
                    renderCell: (params) => {
                      // Split description by newline if it exists
                      const description = params.row.description || "";
                      const lines = description.split("\n");

                      return (
                        <Box>
                          {lines.map((line, index) => (
                            <Typography
                              key={index}
                              variant="body2"
                              sx={{ lineHeight: 1.4 }}
                            >
                              {line}
                            </Typography>
                          ))}
                        </Box>
                      );
                    },
                  },
                  // {
                  //     field: 'file_path',
                  //     headerName: 'File',
                  //     width: 200,
                  //     renderCell: (params) => {
                  //         // Extract just the filename from the path
                  //         const filePath = params.row.file_path || "";
                  //         const fileName = filePath.split('/').pop();

                  //         return (
                  //             <Tooltip title={filePath}>
                  //                 <Typography variant="body2" noWrap>
                  //                     {fileName}
                  //                 </Typography>
                  //             </Tooltip>
                  //         );
                  //     }
                  // },
                  {
                    field: "mapped_to",
                    headerName: "Mapped To",
                    width: 200,
                    renderCell: (params) => {
                      return params.row.material_name || "";
                    },
                  },
                  {
                    field: "quantity",
                    headerName: "Qty",
                    width: 80,
                    type: "number",
                  },
                  {
                    field: "unit_of_measure",
                    headerName: "UOM",
                    width: 80,
                  },
                  {
                    field: "price",
                    headerName: "Extracted Price",
                    width: 100,
                    type: "number",
                    valueFormatter: (value) => {
                      return currencyFormatter.format(value);
                    },
                  },
                  {
                    field: "unit_material_price",
                    headerName: "Unit Material Price",
                    width: 100,
                  },
                  {
                    field: "unit_labor_price",
                    headerName: "Unit Labor Price",
                    width: 100,
                  },
                  {
                    field: "assembli_unit",
                    headerName: "Assembli Unit",
                    width: 100,
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "rgba(25, 118, 210, 0.08)",
                  },
                  "& .MuiDataGrid-row.Mui-selected:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.12)",
                  },
                  "& .mapped-row": {
                    backgroundColor: "rgba(46, 125, 50, 0.08)",
                  },
                  "& .mapped-row:hover": {
                    backgroundColor: "rgba(46, 125, 50, 0.12)",
                  },
                  "& .unmapped-row": {
                    backgroundColor: "rgba(255, 160, 0, 0.08)",
                  },
                  "& .unmapped-row:hover": {
                    backgroundColor: "rgba(255, 160, 0, 0.12)",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                p: 2,
                borderTop: "1px solid #e0e0e0",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box sx={{ 
                width: 16, 
                height: 16, 
                backgroundColor: 'rgba(46, 125, 50, 0.3)', 
                mr: 1, 
                borderRadius: '50%' 
              }}></Box>
              <Typography variant="body2" sx={{ mr: 3 }}>Mapped Items</Typography>
              
              <Box sx={{ 
                width: 16, 
                height: 16, 
                backgroundColor: 'rgba(255, 160, 0, 0.3)', 
                mr: 1, 
                borderRadius: '50%' 
              }}></Box>
              <Typography variant="body2">Unmapped Items</Typography>
            </Box> */}
                <b>{selectedItems.length}</b> items selected
              </Box>
            </Box>
          </Paper>

          {/* CENTER CONTROL PANEL */}
          {/* <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "50px"
                    }}>
                        <IconButton
                            color="primary"
                            size="large"
                            onClick={applyMapping}
                            disabled={!selectedDivision || !selectedSubdivision || !selectedLineItem || selectedItems.length === 0}
                        >
                            <ArrowForwardIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                            Apply Mapping
                        </Typography>
                    </Box> */}

          {/* RIGHT PANEL: Assembli Categories */}
          <Paper
            sx={{
              width: "50%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Assembli Structure
              </Typography>
              {/* <TextField
                fullWidth
                placeholder="Search divisions, subdivisions, or items..."
                value={divisionsSearchQuery}
                onChange={(e) => setDivisionsSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                variant="outlined"
              /> */}
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                overflow: "hidden",
              }}
            >
              {/* Divisions List */}
              <Box
                sx={{
                  width: "33.33%",
                  borderRight: "1px solid #e0e0e0",
                  overflow: "auto",
                  bgcolor: selectedDivision ? "#f5f5f5" : "white",
                }}
              >
                <List>
                  {filteredDivisionData.map((division) => (
                    <ListItem
                      key={division}
                      button
                      selected={selectedDivision === division}
                      onClick={() => handleDivisionSelect(division)}
                    >
                      {/* <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon> */}
                      <ListItemText primary={division} />
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* Subdivisions List */}
              <Box
                sx={{
                  width: "33.33%",
                  borderRight: "1px solid #e0e0e0",
                  overflow: "auto",
                  bgcolor: selectedSubdivision ? "#f5f5f5" : "white",
                }}
              >
                {selectedDivision ? (
                  <List>
                    {processedDivisionsData.subdivisionsByDivision[
                      selectedDivision
                    ]?.map((subdivision) => (
                      <ListItem
                        key={subdivision}
                        button
                        selected={selectedSubdivision === subdivision}
                        onClick={() => handleSubdivisionSelect(subdivision)}
                      >
                        {/* <ListItemIcon>
                          <SubdirectoryArrowRightIcon />
                        </ListItemIcon> */}
                        <ListItemText primary={subdivision} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Select a division first
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Line Items List */}
              <Box
                sx={{
                  width: "33.33%",
                  overflow: "auto",
                  bgcolor: selectedLineItem ? "#f5f5f5" : "white",
                }}
              >
                {selectedDivision && selectedSubdivision ? (
                  <List>
                    {processedDivisionsData.itemsBySubdivision[
                      `${selectedDivision}|${selectedSubdivision}`
                    ]?.map((item) => (
                      <ListItem
                        key={item.material_id}
                        button
                        selected={selectedLineItem === item.description}
                        onClick={() => handleLineItemDescriptionSelect(item)}
                      >
                        {/* <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon> */}
                        <ListItemText primary={item.description} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Select a subdivision first
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                p: 2,
                borderTop: "1px solid #e0e0e0",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography variant="body2" gutterBottom>
                Selected Category:
              </Typography>
              {selectedDivision && selectedSubdivision && selectedLineItem ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                  <Typography>
                    <b>{selectedDivision}</b> &gt; <b>{selectedSubdivision}</b>{" "}
                    &gt; <b>{selectedLineItem}</b>
                  </Typography>
                </Box>
              ) : (
                <Typography color="text.secondary">
                  No category selected
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LineItemMappingInterface;
