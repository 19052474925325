import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";
import { VerticalBar } from "./Icons";

const FoundationsDetailsPdf = ({ foundationDetails }) => {
  // Convert object to array if it isn't already an array
  const foundationArray = Array.isArray(foundationDetails)
    ? foundationDetails
    : Object.values(foundationDetails);

  return (
    <Document>
      <View style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Foundation Details</Text>
        </View>

        <View style={styles.section}>
          <View style={{ display: "flex", width: "100%" }}>
            {foundationArray.map((detail, index) => (
              <View
                key={index}
                style={{
                  borderBottom: "1 solid #D1D5DB",
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 5,
                  width: "100%",
                }}
              >
                <Text style={styles.label}>{detail.label}</Text>
                <Text style={styles.text}>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Document>
  );
};

export default FoundationsDetailsPdf;
