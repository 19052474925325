//src/services/companyService.js

import apiAxios from "./axiosConfig";

export const getCompanyProfile = async () => {
  try {
    const response = await apiAxios.get("/company/me");
    return response.data;
  } catch (error) {
    console.error("Error fetching company details:", error.response?.data);
    throw error;
  }
};

export const updateCompanyProfile = async (payload) => {
  try {
    const response = await apiAxios.put("/company/me", payload);
    return response.data;
  } catch (error) {
    console.error("Error updating company details:", error.response?.data);
    throw error;
  }
};

export const deleteCompanyImage = async () => {
  try {
    const response = await apiAxios.post("/delete-company-image");
    return response.data;
  } catch (error) {
    console.error("Error deleting company image:", error);
    throw error;
  }
};

export const fetchCompanyImage = async () => {
  try {
    const response = await apiAxios.get("/company-image");
    return response.data;
  } catch (error) {
    console.error("Error fetching company image:", error);
    throw error;
  }
};
