// src/theme/ThemeProvider.jsx
import React, { useMemo, useState, createContext, useContext } from "react";
import {
	CssBaseline,
	ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";

// Optional: export a context so you can read/toggle theme anywhere
export const ColorModeContext = createContext({
	mode: "light",
	toggleColorMode: () => { },
});

export const AppThemeProvider = ({ children }) => {
	// Manage your “light” vs “dark” in state
	const [mode, setMode] = useState("light");

	// Build a memoized color‐mode context so that you can toggle theme
	const colorMode = useMemo(
		() => ({
			mode,
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
			},
		}),
		[mode]
	);

	// Decide which theme object to use
	const theme = useMemo(() => {
		return mode === "light" ? lightTheme : darkTheme;
	}, [mode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MuiThemeProvider>
		</ColorModeContext.Provider>
	);
};

// (Optional) Helper hook for easy theme toggling in your components
export const useColorMode = () => useContext(ColorModeContext);
