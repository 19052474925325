// store.js
import {
  createSlice,
  createAsyncThunk,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { getJobs, getChatMessages } from "./services/jobService";

// ------------------------------
// takeoffSlice
// ------------------------------

const TAKEOFF_CACHE_EXPIRATION = 24 * 60 * 60 * 1000;

const takeoffSlice = createSlice({
  name: "takeoffData",
  initialState: {
    data: null,
    loading: false,
    error: null,
    proposalId: null,
    proposalName: null,
    unsavedChanges: false,
    jobId: null,
  },
  reducers: {
    setTakeoffData: (state, action) => {
      state.data = action.payload;
    },
    updateTakeoffData: (state, action) => {
      const { id, updates } = action.payload;
      const item = Array.isArray(state.data)
        ? state.data.find((i) => i.id === id)
        : null;
      if (item) {
        Object.assign(item, updates);
      }
    },
    setproposalId: (state, action) => {
      state.proposalId = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    setproposalName: (state, action) => {
      state.proposalName = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
  },
});
export const {
  setTakeoffData,
  updateTakeoffData,
  setproposalId,
  setproposalName,
  setUnsavedChanges,
  setJobId,
} = takeoffSlice.actions;

// ------------------------------
// proposalSlice
// ------------------------------
const proposalSlice = createSlice({
  name: "proposalData",
  initialState: {
    data: {},
  },
  reducers: {
    updateProposalData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetProposalData: (state) => {
      state.data = {};
    },
  },
});
export const { updateProposalData, resetProposalData } = proposalSlice.actions;

// ------------------------------
// chatMessagesSlice
// ------------------------------
export const fetchChatMessagesForJob = createAsyncThunk(
  "chatMessagesData/fetchChatMessagesForJob",
  async (jobId, { rejectWithValue }) => {
    try {
      const data = await getChatMessages(jobId, 20, 0);
      if (data && Array.isArray(data.messages)) {
        return { jobId, messages: data.messages };
      }
      return { jobId, messages: [] };
    } catch (err) {
      return rejectWithValue(err.message || "Error fetching chat");
    }
  },
);

const chatMessagesSlice = createSlice({
  name: "chatMessagesData",
  initialState: {
    messages: {},
    loading: {},
    error: {},
    inputValues: {},
  },
  reducers: {
    setChatMessagesForJob: (state, action) => {
      const { jobId, messages } = action.payload;
      state.messages[jobId] = messages;
      state.loading[jobId] = false;
      state.error[jobId] = null;
    },
    appendChatMessagesForJob: (state, action) => {
      const { jobId, newMessages } = action.payload;
      if (!state.messages[jobId]) {
        state.messages[jobId] = [];
      }
      state.messages[jobId] = [...state.messages[jobId], ...newMessages];
      state.loading[jobId] = false;
      state.error[jobId] = null;
    },
    setChatLoading: (state, action) => {
      const { jobId, isLoading } = action.payload;
      state.loading[jobId] = isLoading;
    },
    setChatError: (state, action) => {
      const { jobId, error } = action.payload;
      state.error[jobId] = error;
      state.loading[jobId] = false;
    },
    resetChatMessages: (state) => {
      state.messages = {};
      state.loading = {};
      state.error = {};
      state.inputValues = {};
    },
    setChatInputValue: (state, action) => {
      const { jobId, value } = action.payload;
      state.inputValues[jobId] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatMessagesForJob.pending, (state, action) => {
        const jobId = action.meta.arg;
        state.loading[jobId] = true;
        state.error[jobId] = null;
      })
      .addCase(fetchChatMessagesForJob.fulfilled, (state, action) => {
        const { jobId, messages } = action.payload;
        state.messages[jobId] = messages;
        state.loading[jobId] = false;
        state.error[jobId] = null;
      })
      .addCase(fetchChatMessagesForJob.rejected, (state, action) => {
        const jobId = action.meta.arg;
        state.loading[jobId] = false;
        state.error[jobId] = action.payload;
      });
  },
});
export const {
  setChatMessagesForJob,
  appendChatMessagesForJob,
  setChatLoading,
  setChatError,
  resetChatMessages,
  setChatInputValue,
} = chatMessagesSlice.actions;

// ------------------------------
// jobsSlice
// ------------------------------
export const fetchJobsThunk = createAsyncThunk(
  "jobsData/fetchJobsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getJobs();
      if (res && !res.error && Array.isArray(res)) {
        return res;
      } else {
        throw new Error(res?.error || "Unknown error fetching jobs");
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const jobsSlice = createSlice({
  name: "jobsData",
  initialState: {
    jobs: [],
    loading: false,
    error: null,
    selectedJobId: null,
  },
  reducers: {
    setSelectedJobId: (state, action) => {
      state.selectedJobId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.jobs = action.payload || [];
        state.error = null;
      })
      .addCase(fetchJobsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error fetching jobs";
      });
  },
});
export const { setSelectedJobId } = jobsSlice.actions;

// ------------------------------
// authSlice
// ------------------------------
const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggedInUsername: null,
    userRole: null,
    userEmail: null,
    companyId: null,
  },
  reducers: {
    setLoggedInUsername: (state, action) => {
      state.loggedInUsername = action.payload;
    },
    clearLoggedInUsername: (state) => {
      state.loggedInUsername = null;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    clearUserRole: (state) => {
      state.userRole = null;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
  },
});
export const {
  setLoggedInUsername,
  clearLoggedInUsername,
  setUserRole,
  clearUserRole,
  setUserEmail,
  setCompanyId,
} = authSlice.actions;

// ------------------------------
// snapshotSlice
// ------------------------------
const snapshotSlice = createSlice({
  name: "snapshotData",
  initialState: {
    data: {},
  },
  reducers: {
    updateSnapshotData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetSnapshotData: (state) => {
      state.data = {};
    },
  },
});
export const { updateSnapshotData, resetSnapshotData } = snapshotSlice.actions;

// ------------------------------
// recentTakeoffsSlice
// ------------------------------
const recentTakeoffsSlice = createSlice({
  name: "recentTakeoffs",
  initialState: {
    list: [],
  },
  reducers: {
    addRecentTakeoff: (state, action) => {
      const newTakeoff = {
        ...action.payload,
        timestamp: Date.now(), // Add timestamp when caching
      };
      // Remove any existing takeoff with the same jobId.
      state.list = state.list.filter((t) => t.jobId !== newTakeoff.jobId);
      // Add the new takeoff to the beginning.
      state.list.unshift(newTakeoff);
      // Limit the list to the 5 most recent.
      if (state.list.length > 5) {
        state.list = state.list.slice(0, 5);
      }
    },
    removeRecentTakeoff: (state, action) => {
      const jobIdToRemove = action.payload;
      state.list = state.list.filter((t) => t.jobId !== jobIdToRemove);
    },
    // This action will remove any cached takeoffs older than 24 hours.
    validateRecentTakeoffs: (state) => {
      state.list = state.list.filter(
        (takeoff) => Date.now() - takeoff.timestamp < TAKEOFF_CACHE_EXPIRATION
      );
    },
  },
});
export const { addRecentTakeoff, removeRecentTakeoff, validateRecentTakeoffs } =
  recentTakeoffsSlice.actions;

// ------------------------------
// Persist Configurations
// ------------------------------
const takeoffPersistConfig = {
  key: "takeoffData",
  storage,
  whitelist: ["data", "proposalId", "unsavedChanges", "proposalName", "jobId"],
};

const proposalPersistConfig = {
  key: "proposalData",
  storage,
  whitelist: ["data"],
};

const chatMessagesPersistConfig = {
  key: "chatMessagesData",
  storage,
  whitelist: ["messages", "inputValues"],
};

const jobsPersistConfig = {
  key: "jobsData",
  storage,
  whitelist: ["jobs", "selectedJobId"],
};

const snapshotPersistConfig = {
  key: "snapshotData",
  storage,
  whitelist: ["data"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["loggedInUsername", "userRole", "userEmail", "companyId"],
};

const recentTakeoffsPersistConfig = {
  key: "recentTakeoffs",
  storage,
  whitelist: ["list"],
};

// ------------------------------
// Root Reducer & Store
// ------------------------------
const rootReducer = combineReducers({
  takeoffData: persistReducer(takeoffPersistConfig, takeoffSlice.reducer),
  proposalData: persistReducer(proposalPersistConfig, proposalSlice.reducer),
  chatMessagesData: persistReducer(
    chatMessagesPersistConfig,
    chatMessagesSlice.reducer,
  ),
  jobsData: persistReducer(jobsPersistConfig, jobsSlice.reducer),
  snapshotData: persistReducer(snapshotPersistConfig, snapshotSlice.reducer),
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  recentTakeoffs: persistReducer(
    recentTakeoffsPersistConfig,
    recentTakeoffsSlice.reducer,
  ),
});

export const store = configureStore({
  reducer: rootReducer,
});

export const persistor = persistStore(store);
