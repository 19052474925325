// src/utils/axiosInterceptor.js

import axios from 'axios';
import { toast } from 'react-toastify';

export const setupAxiosInterceptors = (navigate) => {
  // Request interceptor to add the token to headers
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // let isInterceptorSet = false;

  // Response interceptor to handle 401 errors
  // if (!isInterceptorSet){
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Show a toast notification
        toast.error('Session expired. Please log in again.');

        // Remove the token from localStorage
        localStorage.removeItem('access_token');

        // Redirect to login page
        navigate('/');
      }
      return Promise.reject(error);
    }
  );
  // isInterceptorSet = true;
//  }
};
