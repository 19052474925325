import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import CustomTypography from "../CustomComponents/CustomTypography";

import { ReactComponent as SettingsIconSVG } from "../../static/Icons/setting.svg";
import { ReactComponent as DocumentSVG } from "../../static/Icons/document.svg";
import { ReactComponent as LogoutIconSVG } from "../../static/Icons/logout.svg";
import { ReactComponent as userProfileIcon } from '../../static/Icons/userProfile.svg';

import { AuthContext } from "../../context/authContext";

import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedJobId } from "../../store";

const TabBarAdditionalsContainer = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 0.75rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5625rem",
  alignSelf: "stretch",
}));

const AccountSettingsTab = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.5625rem",
  alignItems: "center",
  alignContent: "center",
  gap: "0.625rem",
  alignSelf: "stretch",
  flexWrap: "wrap",
}));

const AccountSettingsFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  flex: "1 0 0",
  cursor: "pointer",
}));

const AccountSettingsIconFrame = styled(Box)(() => ({
  display: "flex",
  width: "1.125rem",
  height: "1.125rem",
  justifyContent: "center",
  alignItems: "center",
}));

const SettingsIconStyled = styled(SettingsIconSVG)(() => ({
  width: "1.125rem",
  height: "1.125rem",
  flexShrink: 0,
}));

const CostCatalogIconStyled = styled(DocumentSVG)(() => ({
  width: "1.125rem",
  height: "1.125rem",
  flexShrink: 0,
}));

const UserProfileIconStyled = styled(userProfileIcon)(() => ({
  width: "1.125rem",
  height: "1.125rem",
  flexShrink: 0,
}));

const LineDivider = styled(Box)(() => ({
  width: "12.75rem",
  height: "0.04063rem",
  background: "var(--Nuetrals-Border---1, #ABA898)",
}));

const LogoutTab = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 0.75rem",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
}));

const LogoutTabFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0rem",
  alignItems: "center",
  alignContent: "center",
  gap: "0.625rem",
  alignSelf: "stretch",
  flexWrap: "wrap",
}));

const LogoutTabInternalFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  flex: "1 0 0",
  cursor: "pointer",
}));

const LogoutIconFrame = styled(Box)(() => ({
  display: "flex",
  width: "1.125rem",
  height: "1.125rem",
  justifyContent: "center",
  alignItems: "center",
}));

const LogoutIconStyled = styled(LogoutIconSVG)(() => ({
  width: "1.125rem",
  height: "1.125rem",
  flexShrink: 0,
}));

const LogoutText = styled(CustomTypography)(() => ({
  flexShrink: 0,
}));

const TabBarAdditionals = ({ proposalId }) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasUnsavedChanges = useSelector((state) => state.takeoffData.unsavedChanges);
  const userRole = useSelector((state) => state.auth.userRole);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);


  const handleAccountSettingsClick = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
      return;
    }    
    dispatch(setSelectedJobId(null));
    navigate("/account-settings");
  };

  const handleCostCatalog = () => {
    if (hasUnsavedChanges) {
      setUnsavedModalOpen(true);
      return;
    }
    dispatch(setSelectedJobId(null));
    navigate("/choose-cost");
  };

  const handleUserProfile = () => {
    dispatch(setSelectedJobId(null));
    navigate("/account-settings"); // For non-admin users, account settings shows only user profile content
  };

  const handleLogout = () => {
    if (hasUnsavedChanges) {
      setShowConfirmDialog(true);
    } else {
      logout(proposalId);
    }
  };

  return (
    <TabBarAdditionalsContainer>
      {userRole === "Administrator" ? (
        <>
          <AccountSettingsTab onClick={handleAccountSettingsClick}>
            <AccountSettingsFrame>
              <AccountSettingsIconFrame>
                <SettingsIconStyled />
              </AccountSettingsIconFrame>
              <CustomTypography variant="body3Medium14">
                Account Settings
              </CustomTypography>
            </AccountSettingsFrame>
          </AccountSettingsTab>

          <AccountSettingsTab onClick={handleCostCatalog}>
            <AccountSettingsFrame>
              <AccountSettingsIconFrame>
                <CostCatalogIconStyled />
              </AccountSettingsIconFrame>
              <CustomTypography variant="body3Medium14">
                Cost Catalog
              </CustomTypography>
            </AccountSettingsFrame>
          </AccountSettingsTab>
        </>
      ) : (
        // For non-admin users, show only User Profile tab that redirects to /account-settings
        <AccountSettingsTab onClick={handleUserProfile}>
          <AccountSettingsFrame>
            <AccountSettingsIconFrame>
              <UserProfileIconStyled />
            </AccountSettingsIconFrame>
            <CustomTypography variant="body3Medium14">
              User Profile
            </CustomTypography>
          </AccountSettingsFrame>
        </AccountSettingsTab>
      )}

      <LineDivider />

      <LogoutTab>
        <LogoutTabFrame>
          <LogoutTabInternalFrame onClick={handleLogout}>
            <LogoutIconFrame>
              <LogoutIconStyled />
            </LogoutIconFrame>
            <LogoutText variant="body3Medium14">
              <Box sx={{ color: "error.main" }}>Logout</Box>
            </LogoutText>
          </LogoutTabInternalFrame>
        </LogoutTabFrame>
      </LogoutTab>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="info" sx={{ fontSize: "1rem" }}>
          We’re still working on Account Settings. Stay tuned!
        </Alert>
      </Snackbar>


      {/* "Unsaved Changes" single-button dialog */}
      <Dialog
        open={unsavedModalOpen}
        onClose={() => setUnsavedModalOpen(false)}
        aria-labelledby="unsaved-changes-dialog"
      >
        <DialogTitle id="unsaved-changes-dialog">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You might have unsaved changes. Please save them before navigating away.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnsavedModalOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          You might have unsaved changes. Are you sure you want to log out without saving?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setShowConfirmDialog(false);
              logout();
            }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </TabBarAdditionalsContainer>
  );
};

export default TabBarAdditionals;
