// src/components/Profile/CostCatalogue.js
import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MainLayout from '../MainLayout';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

function CostCatalogue() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return (
      <MainLayout>
        <Box sx={{ textAlign: 'center', mt: 10, ml:30 }}>
          <Typography variant="h6">You must be logged in to view this page.</Typography>
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ mt: 4, ml:35 }}>
        <Typography variant="h5">Cost Page</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          This is the cost catalogue page.
        </Typography>
      </Box>
    </MainLayout>
  );
}

export default CostCatalogue;
