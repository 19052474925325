// src/components/Snapshot/SnapshotOverview.jsx
import React from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../static/Icons/close.svg";

// ========== Styled components (unchanged) ==========
const SnapshotOverviewFrame = styled(Box)(({ isDrawer }) => ({
  width: isDrawer ? "35.375rem" : "16.125rem",
  height: isDrawer ? "100vh" : "25rem",
  flexShrink: 0,
  borderRadius: "1.25rem",
  background: "#FFF",
  boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 1200px)": {
    marginTop: isDrawer ? "0" : "3.5rem",
  },
  justifyContent: "flex-start",
  overflowY: isDrawer ? "auto" : "hidden",
}));

const SnapshotHeadingFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "1.8125rem",
  paddingLeft: "0.4375rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "0.875rem",
  flexShrink: 0,
}));

const SnapshotHeadingText = styled(Typography)(() => ({
  marginTop: "1rem",
  color: "#000",
}));

const SnapshotContentFrame = styled(Box)(({ isDrawer }) => ({
  display: "flex",
  width: isDrawer ? "100%" : "14.625rem",
  height: isDrawer ? "auto" : "21.1875rem",
  padding: "0.4375rem 0.77863rem 0.83419rem 0.77863rem",
  marginTop: "1rem",
  marginLeft: isDrawer ? "auto" : "0.75rem",
  marginRight: isDrawer ? "auto" : "0",
  overflowY: "auto",
  overflowX: "hidden",
  gap: "0.55613rem",
  flexShrink: 0,
  borderRadius: "1.05669rem",
  background: "#F3F3F1",
}));

const SnapshotContentInnerFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  flex: 1,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1rem",
  flexShrink: 0,
}));

const SnapshotContentHeadingFrame = styled(Box)(() => ({
  display: "flex",
  height: "2.22456rem",
  padding: "0.55613rem 0rem",
  alignItems: "center",
  gap: "0.55613rem",
  alignSelf: "stretch",
  borderBottom: "0.578px solid #DCDACB",
}));

const SnapshotContentHeadingText = styled(Typography)(() => ({
  color: "#1D1D1B",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

const SnapshotContentTextFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.723rem",
  alignSelf: "stretch",
}));

const SnapshotContentTextInnerFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.44494rem",
  alignSelf: "stretch",
}));

const SnapshotContentTextHeadingFrame = styled(Box)(() => ({
  display: "flex",
  height: "1.83525rem",
  alignItems: "center",
  gap: "0.55613rem",
  alignSelf: "stretch",
}));

const SnapshotContentTextHeadingText = styled(Typography)(() => ({
  color: "#1D1D1B",
  fontFamily: "Roboto",
  fontSize: "0.83419rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
  textTransform: "uppercase",
}));

const SnapshotContentTextContentFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.723rem",
  alignSelf: "stretch",
}));

const SnapshotContentTextContentTextFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.22244rem",
}));

const SnapshotContentTextContentTextHeadingText = styled(Typography)(() => ({
  alignSelf: "stretch",
  color: "#666660",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "136%",
}));

const SnapshotContentTextContentTextContentText = styled(Typography)(() => ({
  alignSelf: "stretch",
  color: "#1D1D1B",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "136%",
}));

const Line = styled(Box)(() => ({
  width: "100%",
  height: "0.03613rem",
  background: "#DCDACB",
}));

const CloseButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  padding: "1rem",
}));

const CloseIconButton = styled(IconButton)(() => ({
  background: "#F3F3F1",
  borderRadius: "30px",
  width: "2rem",
  height: "2rem",
  "&:hover": {
    background: "#ddd",
  },
}));

const renderScheduleTable = (scheduleData) => {
  if (!scheduleData?.headers || !scheduleData?.rows) return null;

  return (
    <Box sx={{ overflowX: "auto", width: "100%" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "1rem",
        }}
      >
        <thead>
          <tr>
            {scheduleData.headers.map((header, idx) => (
              <th
                key={idx}
                style={{
                  padding: "0.75rem",
                  borderBottom: "1px solid #DCDACB",
                  textAlign: "left",
                  backgroundColor: "#FFF",
                }}
              >
                <SnapshotContentTextContentTextHeadingText>
                  {header}
                </SnapshotContentTextContentTextHeadingText>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {scheduleData.rows.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {scheduleData.headers.map((header, colIdx) => {
                const headerKey = header === "Type Mark" ? "Type" : header;
                const cellValue = row[headerKey];

                return (
                  <td
                    key={colIdx}
                    style={{
                      padding: "0.75rem",
                      borderBottom: "1px solid #DCDACB",
                    }}
                  >
                    <SnapshotContentTextContentTextContentText>
                      {cellValue !== undefined && cellValue !== null
                        ? cellValue.toString()
                        : "N/A"}
                    </SnapshotContentTextContentTextContentText>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const isScheduleData = (data) =>
  data && typeof data === "object" && "headers" in data && "rows" in data;

const SnapshotOverview = ({
  snapshotData,
  loading,
  isDrawer = false,
  onClose,
}) => {
  // If snapshotData is a string, parse it
  let parsedData = snapshotData;
  if (typeof snapshotData === "string") {
    try {
      parsedData = JSON.parse(snapshotData);
    } catch (e) {
      parsedData = {}; // fallback if parse fails
    }
  }

  return (
    <SnapshotOverviewFrame isDrawer={isDrawer}>
      {/* Drawer Close Button */}
      {isDrawer && onClose && (
        <CloseButtonContainer>
          <CloseIconButton onClick={onClose}>
            <CloseIcon width={16} height={16} />
          </CloseIconButton>
        </CloseButtonContainer>
      )}

      {/* Title */}
      <SnapshotHeadingFrame>
        <SnapshotHeadingText variant="body1SemiBold17">
          Snapshot Overview
        </SnapshotHeadingText>
      </SnapshotHeadingFrame>

      {/* Content */}
      <SnapshotContentFrame isDrawer={isDrawer}>
        <SnapshotContentInnerFrame>
          <SnapshotContentHeadingFrame>
            <SnapshotContentHeadingText>
              Home Summary
            </SnapshotContentHeadingText>
          </SnapshotContentHeadingFrame>

          {/* If loading, show placeholder; else map tabs */}
          {loading ? (
            <Typography sx={{ margin: "1rem" }}>Loading...</Typography>
          ) : (
            parsedData?.tabs?.map((tab, tabIdx) => (
              <SnapshotContentTextFrame key={tabIdx}>
                <SnapshotContentTextInnerFrame>
                  <SnapshotContentTextHeadingFrame>
                    <SnapshotContentTextHeadingText>
                      {tab.title}
                    </SnapshotContentTextHeadingText>
                  </SnapshotContentTextHeadingFrame>

                  {/* For each section in this tab */}
                  {tab.sections?.map((section, sIdx) => {
                    const sectionData = parsedData?.data?.[section] || {};
                    const isSchedule = isScheduleData(sectionData);
                    const entries = Object.entries(sectionData);

                    return (
                      <React.Fragment key={sIdx}>
                        <SnapshotContentTextContentFrame>
                          {!isSchedule ? (
                            <SnapshotContentTextHeadingFrame>
                              <SnapshotContentTextHeadingText>
                                {section.replace(/([A-Z])/g, " $1").trim()}
                              </SnapshotContentTextHeadingText>
                            </SnapshotContentTextHeadingFrame>
                          ) : null}

                          {isSchedule ? (
                            renderScheduleTable(sectionData)
                          ) : entries.length === 0 ? (
                            <SnapshotContentTextContentTextFrame>
                              <SnapshotContentTextContentTextHeadingText variant="body3">
                                (No data in {section})
                              </SnapshotContentTextContentTextHeadingText>
                            </SnapshotContentTextContentTextFrame>
                          ) : (
                            entries.map(([key, val], idx) => (
                              <SnapshotContentTextContentTextFrame key={idx}>
                                <SnapshotContentTextContentTextHeadingText variant="body3">
                                  {key}
                                </SnapshotContentTextContentTextHeadingText>
                                <SnapshotContentTextContentTextContentText variant="body3">
                                  {Array.isArray(val)
                                    ? val.join(", ")
                                    : val || "N/A"}
                                </SnapshotContentTextContentTextContentText>
                              </SnapshotContentTextContentTextFrame>
                            ))
                          )}
                        </SnapshotContentTextContentFrame>

                        {sIdx < tab.sections.length - 1 && <Line />}
                      </React.Fragment>
                    );
                  })}
                </SnapshotContentTextInnerFrame>
              </SnapshotContentTextFrame>
            ))
          )}
        </SnapshotContentInnerFrame>
      </SnapshotContentFrame>
    </SnapshotOverviewFrame>
  );
};

export default SnapshotOverview;
