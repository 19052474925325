// src/App.js

import React, { useState, useEffect, useContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Outlet,
} from "react-router-dom";

import { AppThemeProvider } from "./theme/ThemeProvider";

import Login from "./components/Authentication/Login";
import Signup from "./components/Authentication/Signup";
import NotFound from "./components/NotFound";
import ThankYouPage from "./components/ChatDashboard/ThankYouPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Dashboard from "./components/ProposalDashboard/Dashboard";
import JobCreationPage from "./components/Jobs/JobCreationPage";
import ProposalBuilderInterface from "./components/ProposalEditor/Editor";
import ChooseCost from "./components/CostCatalog/ChooseCost";
import AssembliPricing from "./components/CostCatalog/AssembliPricingTable";
import CustomPricing from "./components/CostCatalog/CustomPricingTable";
import SignaturePage from "./components/SignaturePage/SignaturePage";

// import Home from './components/Home';

import SignupInvitation from "./components/Authentication/SignupInvitation";
import ValidationOutput from "./components/Validation/ValidationOutput";
// import Profile from "./components/Profile/Profile";
import PrivateRoute from "./utils/PrivateRoute";
import { setupAxiosInterceptors } from "./utils/axiosInterceptor";
import { AuthProvider, AuthContext } from "./context/authContext"; // Import AuthContext

import ProfileSettings from "./components/Profile/ProfileSettings";
import CostCatalogue from "./components/Profile/CostCatalogue";
import CreateUser from "./components/Profile/CreateUser";
import ManageUsers from "./components/Profile/ManageUsers";

import ForgotPassword from "./components/Authentication/ForgotPassword";
import ResetPassword from "./components/Authentication/ResetPassword";

import ForgotUsername from "./components/Authentication/ForgotUsername";
import ResetUsername from "./components/Authentication/ResetUsername";

import UploadPage from "./components/pages/UploadPage";
import ValidationPage from "./components/pages/ValidationPage";
import Authentication from "./components/pages/AuthenticationPage";
import AccountSettings from "./components/pages/AccountSettingsPage";
import HomePage from "./components/pages/Homepage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCSRF } from "./hooks/useCSRF";
import UserPricing from "./components/CostCatalog/UserPricing";
import LineItemMappingPage from "./components/CostCatalog/LineItemMappingPage";

function ProtectedTableLayout() {
  return <Outlet />;
}
const AppContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  return (
    <div style={{ display: "flex" }}>
      {/* {isAuthenticated && !isMobile && <Sidebar />} */}
      <div
        style={{
          width: "100%",
        }}
      >
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/" element={<Signup />} /> */}

          {/* <Route path="/" element={<Login />} />
          <Route path="/" element={<Signup />} /> */}

          <Route path="/signup-invitation" element={<SignupInvitation />} />
          {/* Profile-related routes */}
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfileSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/cost-catalogue"
            element={
              <PrivateRoute>
                <CostCatalogue />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/create-user"
            element={
              <PrivateRoute>
                <CreateUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/manage-users"
            element={
              <PrivateRoute>
                <ManageUsers />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Authentication />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                {" "}
                <UploadPage />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/account-settings"
            element={
              <PrivateRoute>
                {" "}
                <AccountSettings />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/thank-you"
            element={
              <PrivateRoute>
                {" "}
                <ThankYouPage />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/create-job"
            element={
              <PrivateRoute>
                {" "}
                <JobCreationPage />{" "}
              </PrivateRoute>
            }
          />

          <Route path="/choose-cost" element={<ChooseCost />} />
          <Route path="/assembli-pricing" element={<AssembliPricing />} />
          <Route path="/custom-pricing" element={<CustomPricing />} />
          {/* <Route path="/user-pricing" element={<UserPricing />} /> */}
          {/* Adding new Route to move custom custom-pricing to user-pricing*/}

          <Route path="/mapping" element={<LineItemMappingPage />} />
          <Route path="/user-pricing" element={<UserPricing />} />

          <Route element={<ProtectedTableLayout />}>
            <Route
              path="/proposal_editor"
              element={<ProposalBuilderInterface />}
            />
            <Route
              path="/validate/:jobId"
              element={
                <PrivateRoute>
                  <ValidationPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="/proposal" element={<Dashboard />} />

          {/* <Route path="/validate/:projectId/:fileId" element={<PrivateRoute><ValidateFile /></PrivateRoute>} />
          <Route path="/jobs/:jobId/details" element={<PrivateRoute><JobDetailsForm /></PrivateRoute>} />
          <Route path="/jobs/:jobId/takeoff" element={<PrivateRoute><TakeoffPage /></PrivateRoute>} /> */}

          <Route
            path="/validate/:jobId"
            element={
              <PrivateRoute>
                {" "}
                <ValidationOutput />{" "}
              </PrivateRoute>
            }
          />

          {/* Forgot/Reset Password */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          {/* Forgot/Reset Username */}
          <Route path="/forgot-username" element={<ForgotUsername />} />
          <Route path="/reset-username/:token" element={<ResetUsername />} />

          <Route path="/sign-proposal/:token" element={<SignaturePage />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

const App = () => {
  useCSRF(); // Initialize CSRF token

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AuthProvider>
          <AppThemeProvider>
            <AppContent />
          </AppThemeProvider>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
