//EditorRegistry.js
import CompanyOverviewEditor from "./FormComponents/CompanyOverviewEditor";
import LicenseEditor from "./FormComponents/LicenseEditor";
import CompanyOverviewPDF from "./PdfComponents/CompanyOverviewPdf";
import LicenseInsurancePDF from "./PdfComponents/LicensePdf";
import ConstructionDataViewer from "./FormComponents/CostBreakDownEditor";
import CostBreakDownPDF from "./PdfComponents/CostBreakDownPdf";
import HeaderEditor from "./FormComponents/HeaderEditor";
import HeaderPdf from "./PdfComponents/HeaderPdf";
import ProjectOverviewEditor from "./FormComponents/ProjectOverviewEditor";
import ProjectOverviewPdf from "./PdfComponents/ProjectOverviewPdf";
import MaterialSpecificationsEditor from "./FormComponents/MaterialSpecificationsEditor";
import MaterialSpecificationsPDF from "./PdfComponents/MaterialSpecificationsPdf";
import FoundationsDetailsEditor from "./FormComponents/FoundationDetailsEditor";
import FoundationsDetailsPdf from "./PdfComponents/FoundationDetailsPdf";
import AllowancesBudgetEditor from "./FormComponents/AllowancesBudgetEditor";
import AllowancesBudgetPdf from "./PdfComponents/AllowancesBudgetPdf";
import LegalCompliancePDF from "./PdfComponents/LegalCompliancePdf";
import LegalComploanceEditor from "./FormComponents/LegalComplianceEditor";
import SystemsEditor from "./FormComponents/SystemsEditor";
import SystemsPdf from "./PdfComponents/SystemsPdf";
import {
  getHeaderData,
  getLicenseInsuranceData,
  getCompanyData,
  getProjectOverviewData,
  getMaterialSpecificaitonsData,
  getFoundationDetailsData,
  getAllowancesData,
  getLegalComplianceData,
  getSystemsData,
} from "./defaultDataProvider";

// Create a function that returns the registry with current data
const createRegistry = (proposalData = {}, snapshot_data = {}) => {
  // console.log("proposalData", proposalData);
  console.log("snapshot_data", snapshot_data);
  return {
    coverpage: {
      header: {
        data: proposalData["Header_Editor"] || getHeaderData(snapshot_data),
        component: HeaderEditor,
        title: "Header Editor",
        pdfContent: function PDFPreviewWrapper(coverPageData) {
          return <HeaderPdf {...coverPageData} />;
        },
      },
    },
    company: {
      overview: {
        data:
          proposalData["Company_Overview_Editor"] ||
          getCompanyData(snapshot_data),
        component: CompanyOverviewEditor,
        title: "Company Overview Editor",
        pdfContent: function PDFPreviewWrapper(companyData) {
          return <CompanyOverviewPDF {...companyData} />;
        },
      },
      license: {
        data:
          proposalData["License_&_Insurance_Editor"] ||
          getLicenseInsuranceData(snapshot_data),
        component: LicenseEditor,
        title: "License & Insurance Editor",
        pdfContent: function PDFPreviewWrapper(licenseInsuranceData) {
          return <LicenseInsurancePDF {...licenseInsuranceData} />;
        },
      },
    },
    scope: {
      overview: {
        data:
          proposalData["Project_Overview_Editor"] ||
          getProjectOverviewData(snapshot_data),
        component: ProjectOverviewEditor,
        title: "Project Overview Editor",
        pdfContent: function PDFPreviewWrapper(projectOverviewData) {
          return <ProjectOverviewPdf {...projectOverviewData} />;
        },
      },
      materials: {
        data:
          proposalData["Material_Specifications_Editor"] ||
          getMaterialSpecificaitonsData(snapshot_data),
        component: MaterialSpecificationsEditor,
        title: "Material Specifications Editor",
        pdfContent: function PDFPreviewWrapper(materialSpecificationsData) {
          return <MaterialSpecificationsPDF {...materialSpecificationsData} />;
        },
      },
    },

    technical: {
      foundation: {
        data:
          proposalData["Foundation_Details_Editor"] ||
          getFoundationDetailsData(snapshot_data),
        component: FoundationsDetailsEditor,
        title: "Foundation Details Editor",
        pdfContent: function PDFPreviewWrapper(foundationDetailsData) {
          return (
            <FoundationsDetailsPdf foundationDetails={foundationDetailsData} />
          );
        },
      },
      systems: {
        data: proposalData["Systems_Editor"] || getSystemsData(snapshot_data),
        component: SystemsEditor,
        title: "Systems Editor",
        pdfContent: function PDFPreviewWrapper(systemsData) {
          return <SystemsPdf Systems={systemsData} />;
        },
      },
    },

    financial: {
      costs: {
        data: proposalData, // Now this will use the current proposal data
        component: ConstructionDataViewer,
        title: "Cost Break Down",
        pdfContent: function PDFPreviewWrapper(costData) {
          return <CostBreakDownPDF data={costData} />;
        },
      },
      allowances: {
        data:
          proposalData["Allowances_Budget_Editor"] ||
          getAllowancesData(snapshot_data),
        component: AllowancesBudgetEditor,
        title: "Allowances Budget Editor",
        pdfContent: function PDFPreviewWrapper(allowancesData) {
          return <AllowancesBudgetPdf AllowancesBudget={allowancesData} />;
        },
      },
    },
    legal: {
      contract: {
        data:
          proposalData["Legal_Compliance_Editor"] ||
          getLegalComplianceData(snapshot_data),
        component: LegalComploanceEditor,
        title: "Legal Compliance Editor",
        pdfContent: function PDFPreviewWrapper(contractData) {
          return <LegalCompliancePDF LegalCompliance={contractData} />;
        },
      },
    },
  };
};

// Helper function to get editor component
export const getEditor = (sectionId, itemId, proposalData, snapshot_data) => {
  // Create a new registry with the current proposal data
  const currentRegistry = createRegistry(proposalData, snapshot_data);
  return currentRegistry[sectionId]?.[itemId] || null;
};

// Export a default registry for initial state
export const EDITOR_REGISTRY = createRegistry();
