import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";
import { ProjectIcon, ExecutiveSummaryIcon, PreparedByIcon } from "./Icons";
import { getSignedUrl } from "../../../services/uploadService";
import { useState, useEffect } from "react";
import { fetchCompanyImage } from "../../../services/companyService";

/**
 * Renders a PDF layout for the proposal, based on the JSON from ProposalEditor.
 */

const HeaderPdf = ({
  companyInfo,
  proposal,
  preparedFor,
  project,
  executiveSummary,
}) => {
  return (
    <Document>
      <View style={styles.page}>
        {/* -- Top Header Section -- */}
        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          {/* Left side: Company Info */}
          <View style={{ flex: 1 }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <Image
                src={
                  companyInfo.logo ||
                  "https://www.shutterstock.com/image-vector/image-icon-trendy-flat-style-600nw-643080895.jpg"
                }
                alt="Company Logo"
                style={{
                  marginBottom: 10,
                  width: 50,
                  height: 50,
                  objectFit: "contain",
                }}
              />
              <View>
                <Text
                  style={[
                    styles.headerTitle,
                    { fontSize: 16, marginBottom: 2 },
                  ]}
                >
                  {companyInfo.name}
                </Text>

                <Text style={styles.text}>{companyInfo.address}</Text>
                <Text style={styles.text}>
                  Tel: {companyInfo.phone} | License #: {companyInfo.license}
                </Text>
              </View>
            </View>
          </View>

          {/* Right side: Proposal Info */}
          <View style={{ flex: 1, alignItems: "flex-end" }}>
            {/* <Text
              style={[styles.text, { fontWeight: "bold", marginBottom: 2 }]}
            >
              Proposal #: {proposal.number}
            </Text> */}
            <Text style={styles.text}>Date: {proposal.date}</Text>
            <Text style={styles.text}>Valid Until: {proposal.validUntil}</Text>
          </View>
        </View>

        {/* -- "Prepared For" and "Project" Side-by-Side -- */}
        <View style={[styles.grid, { marginTop: 10 }]}>
          {/* Prepared For */}
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <PreparedByIcon />
                  <Text style={styles.title}>Prepared For</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                <Text style={styles.label}>Name</Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {preparedFor.name}
                </Text>

                <Text style={styles.label}>Address</Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {preparedFor.address}
                </Text>

                <Text style={styles.label}>Contact</Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {preparedFor.contact}
                </Text>
              </View>
            </View>
          </View>

          {/* Project */}
          <View style={styles.column}>
            <View style={styles.section}>
              <View style={styles.sectionHeaderBox}>
                <View style={styles.sectionHeader}>
                  <ProjectIcon />
                  <Text style={styles.title}>Project</Text>
                </View>
              </View>
              <View style={styles.contentBox}>
                <Text style={styles.label}>Name</Text>
                <Text style={[styles.text, { marginBottom: 2 }]}>
                  {project.name}
                </Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {project.address}
                </Text>

                <Text style={styles.label}>Project Duration</Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {project.duration}
                </Text>

                <Text style={styles.label}>Estimated Completion</Text>
                <Text style={[styles.text, { marginBottom: 5 }]}>
                  {project.completion}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* -- Executive Summary Section -- */}
        <View style={styles.section}>
          <View style={styles.sectionHeaderBox}>
            <View style={styles.sectionHeader}>
              <ExecutiveSummaryIcon />
              <Text style={styles.title}>Executive Summary</Text>
            </View>
          </View>
          <View style={styles.contentBox}>
            <Text style={styles.text}>{executiveSummary}</Text>
          </View>
        </View>
      </View>
    </Document>
  );
};

export default HeaderPdf;
