// src/contexts/authContext.jsx

import React, { createContext, useState, useEffect } from "react";
import {
  login as loginService,
  logout as logoutService,
  signup as signupService,
  usernamePasswordLogin,
  signupGoogle as signupGoogleService,
} from "../services/authService";
import { proposal_inuse_toggle } from "../services/proposalService";
import { useNavigate } from "react-router-dom";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

// NEW import from store
import { store, setLoggedInUsername } from "../store";
import { posthog } from "posthog-js";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("access_token"),
  );
  const [companyName, setCompanyName] = useState("Assembli AI");
  const navigate = useNavigate();

  function AnalyticsIdentityMonitor() {
    const ldClient = useLDClient();
    const { loggedInUsername, userRole, userEmail, companyId } = useSelector(
      (state) => state.auth,
    );

    useEffect(() => {
      if (!loggedInUsername || loggedInUsername === "anonymous") return;

      // LaunchDarkly identification
      if (ldClient) {
        ldClient.identify({
          kind: "user",
          key: loggedInUsername,
          name: loggedInUsername,
          email: userEmail,
          company_id: companyId,
          role: userRole,
        });
      }

      // Posthog identification
      if (posthog && posthog.__loaded) {
        posthog.identify(loggedInUsername, {
          email: userEmail,
          username: loggedInUsername,
          role: userRole,
          $current_url: window.location.href,
        });
        console.log("Posthog group", companyId);
        posthog.group("company", companyId);
      }
      console.log("Updating posthog info for user", loggedInUsername);
    }, [ldClient, loggedInUsername, userEmail, companyId, userRole]);

    return null;
  }

  const login = async (accessToken) => {
    try {
      const response = await loginService(accessToken);
      if (response.access_token) {
        setIsAuthenticated(true);
        localStorage.setItem("access_token", response.access_token);
        return response.user;
      }
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
    return null;
  };

  const signupGoogle = async (accessToken, companyName) => {
    try {
      const response = await signupGoogleService(accessToken, companyName);
      if (response.access_token) {
        setIsAuthenticated(true);
        localStorage.setItem("access_token", response.access_token);
        return response.user;
      }
    } catch (error) {
      console.error("Signup failed:", error);
      throw error;
    }
    return null;
  };

  const usernamePassLogin = async (username, password) => {
    try {
      const response = await usernamePasswordLogin(username, password);
      if (response.access_token) {
        setIsAuthenticated(true);
        localStorage.setItem("access_token", response.access_token);
        // store.dispatch(setLoggedInUsername(username));
        return response;
      }
    } catch (error) {
      console.error("Username/Password login failed:", error);
      throw error;
    }
    return null;
  };

  const logout = async (proposalId) => {
    // NEW: check for unsaved changes in store
    const state = store.getState();
    const hasUnsavedChanges = state.takeoffData.unsavedChanges;
    if (hasUnsavedChanges) {
      // Prompt user to confirm
      const confirmLogout = window.confirm(
        "You have unsaved changes. Please save them first or cancel your edits before logging out.",
      );
      if (!confirmLogout) {
        // If user does NOT confirm, do not proceed
        return;
      }
    }

    try {
      if (proposalId) {
        await proposal_inuse_toggle({ id: proposalId, untoggle: true });
      }
      await logoutService();
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsAuthenticated(false);
      localStorage.removeItem("access_token");
      navigate("/");
    }
  };

  const signup = async (username, email, password, companyName) => {
    try {
      const response = await signupService(
        username,
        email,
        password,
        companyName,
      );
      if (response.access_token) {
        setIsAuthenticated(true);
        localStorage.setItem("access_token", response.access_token);
        return response;
      }
    } catch (error) {
      console.error("Signup failed:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        usernamePassLogin,
        logout,
        signup,
        signupGoogle,
      }}
    >
      <AnalyticsIdentityMonitor />
      {children}
    </AuthContext.Provider>
  );
};
