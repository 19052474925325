import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  IconButton,
} from "@mui/material";
import { DataGridPro, GridRowModes } from "@mui/x-data-grid-pro";
import ArchiveIcon from "@mui/icons-material/Archive";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import {
  getJobsList,
  updateJobName,
  archiveJob,
} from "../../services/jobService";
import { useDispatch } from "react-redux";
import { fetchJobsThunk, setSelectedJobId } from "../../store"; // adjust the path as needed

// Simple tab panel component
function TabPanel({ children, value, index }) {
  return <div hidden={value !== index}>{value === index && children}</div>;
}

export default function HomePage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All"); // default "all"
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState([
    { field: "created_at", sort: "desc" },
  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [rowModesModel, setRowModesModel] = useState({});
  const dispatch = useDispatch();

  // Helper functions to normalize/format dates
  const normalizeISOString = (isoString) => {
    if (typeof isoString !== "string") return isoString;
    return isoString.replace(/(\.\d{3})\d+/, "$1");
  };

  const formatDateTime = (isoString) => {
    if (!isoString) return "";
    const dt = new Date(normalizeISOString(isoString));
    if (isNaN(dt.getTime())) return "";
    return dt.toLocaleString();
  };

  // Fetch jobs on mount
  useEffect(() => {
    async function fetchJobs() {
      try {
        const data = await getJobsList();
        const normalized = data.map((job) => ({
          ...job,
          status: job.status || "active",
        }));
        setJobs(normalized);
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to load jobs",
          severity: "error",
        });
      }
    }
    fetchJobs();
  }, []);

  // Client-side filter, search, sort
  const displayedJobs = useMemo(() => {
    let result = [...jobs];
    if (statusFilter !== "All") {
      result = result.filter((j) => j.status === statusFilter);
    }
    if (searchQuery.trim()) {
      const lower = searchQuery.toLowerCase();
      result = result.filter((j) => j.job_name.toLowerCase().includes(lower));
    }
    if (sortModel[0]) {
      const { field, sort } = sortModel[0];
      result.sort((a, b) => {
        let valA = a[field];
        let valB = b[field];
        if (field === "created_at") {
          valA = valA ? new Date(normalizeISOString(valA)).getTime() : 0;
          valB = valB ? new Date(normalizeISOString(valB)).getTime() : 0;
        }
        if (valA < valB) return sort === "asc" ? -1 : 1;
        if (valA > valB) return sort === "asc" ? 1 : -1;
        return 0;
      });
    }
    return result;
  }, [jobs, statusFilter, searchQuery, sortModel]);

  // Row editing: update job name
  const processRowUpdate = async (newRow, oldRow) => {
    if (newRow.job_name !== oldRow.job_name) {
      try {
        await updateJobName(newRow.id, newRow.job_name);
        setSnackbar({
          open: true,
          message: "Job name updated",
          severity: "success",
        });
        // Refresh jobs list in the Redux store (and sidebar)
        dispatch(fetchJobsThunk());
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to update job name",
          severity: "error",
        });
        throw error;
      }
    }
    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    console.error("Row update error:", error);
  };

  // Archive a job
  const handleArchiveJob = async (jobId) => {
    try {
      await archiveJob(jobId);
      setJobs((prev) =>
        prev.map((j) => (j.id === jobId ? { ...j, status: "archived" } : j))
      );
      setSnackbar({
        open: true,
        message: "Job archived",
        severity: "success",
      });
      dispatch(fetchJobsThunk());
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to archive job",
        severity: "error",
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Handlers for row editing actions
  const handleEditClick = (id) => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id) => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.View } }));
  };

  const handleCancelClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };

  // Update selected job when clicking on a cell (if not in edit mode)
  const handleCellClick = (params, event) => {
    if (params.field === "job_name") {
      const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
      if (!isInEditMode) {
        dispatch(setSelectedJobId(params.id));
        navigate(`/validate/${params.id}`);
      }
    }
  };

  const columns = [
    {
      field: "job_name",
      headerName: "Job Name",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        if (!isInEditMode) {
          return <span style={{ cursor: "pointer" }}>{params.value}</span>;
        }
        return params.value;
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
      renderCell: (params) => {
        const dt = new Date(params.value);
        return <span>{isNaN(dt.getTime()) ? "" : dt.toLocaleString()}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        return (
          <>
            {isInEditMode ? (
              <>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSaveClick(params.row.id);
                  }}
                  title="Save"
                >
                  <SaveIcon color="primary" />
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCancelClick(params.row.id);
                  }}
                  title="Cancel"
                >
                  <ClearIcon color="error" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditClick(params.row.id);
                  }}
                  title="Edit Job"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleArchiveJob(params.row.id);
                  }}
                  disabled={params.row.status === "archived"}
                  title="Archive Job"
                >
                  <ArchiveIcon
                    color={
                      params.row.status === "archived" ? "disabled" : "primary"
                    }
                  />
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        sx={{
          flex: 1,
          marginLeft: "16rem",
          marginTop: "2rem",
          p: 3,
          overflowY: "auto",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>

        <TabPanel value={selectedTab} index={0}>
          {/* Controls */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              mb: 2,
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search jobs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                  endAdornment: searchQuery && (
                    <IconButton
                      onClick={() => setSearchQuery("")}
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="received_plans">Received Plans</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Sort By</InputLabel>
              <Select
                label="Sort By"
                value={sortModel[0]?.field || ""}
                onChange={(e) =>
                  setSortModel([{ field: e.target.value, sort: "asc" }])
                }
              >
                <MenuItem value="created_at">Created At</MenuItem>
                <MenuItem value="job_name">Job Name</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/chat")}
            >
              + Create Job
            </Button>
          </Box>

          {/* Data Grid */}
          <Box sx={{ height: "70vh", width: "100%", backgroundColor: "#FFF" }}>
            <DataGridPro
              rows={displayedJobs}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onCellClick={handleCellClick}
              pageSizeOptions={[5, 10, 20]}
              disableRowSelectionOnClick
            />
          </Box>
        </TabPanel>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
}
