import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { styles } from "./StyleSheets"; // your existing styles
import SectionHeader from "./SectionHeaderPdf";
import { StyleSheet } from "@react-pdf/renderer";
import { VerticalBar } from "./Icons";
const style = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "#FFFFFF",
  },
  section: {
    marginBottom: 20,
  },
  signatureSection: {
    marginTop: 20,
    padding: 20,
    borderRadius: 5,
    backgroundColor: "#f7f7f7",
    borderTop: "1px solid #ccc",
  },
  agreementText: {
    fontSize: 10,
    marginBottom: 20,
    fontFamily: "Helvetica",
    color: "#333",
    lineHeight: 1.4,
  },
  signatureField: {
    marginTop: 30,
    marginBottom: 30,
  },
  signatureFieldFirst: {
    marginTop: 60,
    marginBottom: 30,
  },
  signatureLine: {
    borderBottom: "1px solid #000",
    width: "60%",
    marginBottom: 5,
  },
  signatureLabel: {
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
  },
  sectionHeader: {
    backgroundColor: "#4472C4",
    color: "white",
    padding: "10px 15px",
    fontSize: 16,
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
    borderRadius: 5,
  },
  signature: {
    border: "1 solid #D1D5DB",
    borderRadius: 10,
    padding: 10,
    paddingBottom: 3,
  },
});
const SignaturePage = () => {
  return (
    <Page style={[styles.page, { padding: 30 }]}>
      <SectionHeader title="E-Signature" />
      <View style={style.signature}>
        <View style={styles.header}>
          <VerticalBar />
          <Text style={styles.headerTitle}>Agreement</Text>
        </View>
        <View style={styles.section}>
          <View style={style.signatureSection}>
            <Text style={styles.text}>
              By signing below, I acknowledge that I have read, understood, and
              agree to the terms and conditions outlined in this proposal. I
              understand that any changes to the scope of work may result in
              additional written approval.
            </Text>

            <Text style={styles.text}>
              Upon signing, this document becomes a binding agreement between
              both parties.
            </Text>

            <View style={style.signatureFieldFirst}>
              <View style={style.signatureLine} />
              <Text style={styles.title}>Client Signature</Text>
            </View>

            <View style={style.signatureField}>
              <View style={style.signatureLine} />
              <Text style={styles.title}>Date</Text>
            </View>

            <View style={style.signatureField}>
              <View style={style.signatureLine} />
              <Text style={styles.title}>Print Name</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default SignaturePage;
