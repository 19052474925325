import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import AssembliPricingSection from "./AssembliPricingSection";
import CustomPricingSection from "./CustomPricingSection";
import Sidebar from "../Sidebar/Sidebar";

import {
  generateSignedURLsForFiles,
  uploadFiles,
  processCostFilesToExtractData,
} from "../../services/pricingService";

function ChooseCost() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();

  // Constants for file validations
  const MAX_FILES = 15;
  const MAX_FILE_SIZE_MB = 20;
  const ALLOWED_TYPES = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/tab-separated-values",
  ];

  const handleFileSelection = (event) => {
    const selectedFiles = Array.from(event.target.files);
    let newFiles = [];
    let errorMessage = "";

    selectedFiles.forEach((file) => {
      if (!ALLOWED_TYPES.includes(file.type)) {
        errorMessage = "Unsupported file type detected.";
      } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        errorMessage = "One or more files exceed the 20 MB size limit.";
      } else {
        newFiles.push(file);
      }
    });

    if (files.length + newFiles.length > MAX_FILES) {
      errorMessage = `You can only upload up to ${MAX_FILES} files.`;
    }

    if (errorMessage) {
      setError(errorMessage);
      setSnackbarOpen(true);
    } else {
      setError("");
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Updated handleUploadAll without alert and with loading state
  const handleUploadAll = async () => {
    try {
      if (files.length === 0) {
        console.log("No files to upload");
        return;
      }
      setIsProcessing(true);
      const signedUrls = await generateSignedURLsForFiles(files);
      if (signedUrls.error) throw new Error(signedUrls.error);

      const paths = await uploadFiles(signedUrls);
      if (paths.error) throw new Error(paths.error);

      await processCostFilesToExtractData(paths);
      // No alert here—files processed successfully!
      setFiles([]); // Clear files after successful processing
    } catch (error) {
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  };

  const handleNext = async () => {
    console.log("Selected Option:", selectedOption);
    if (selectedOption === "assembli") {
      navigate(`/assembli-pricing`);
    } else {
      // For the custom pricing option:
      if (files.length === 0) {
        setOpenConfirmDialog(true);
      } else {
        await handleUploadAll();
        // Automatically navigate after processing
        // Changed the exisiting custom-pricing route to user-pricing to test PricingWorkflow component
        // navigate(`/user-pricing`);

        //Establishing the complete workflow
        navigate('/custom-pricing');
      }
    }
  };

  // Called when user confirms navigating even without new file uploads.
  const handleConfirmProceed = () => {
    setOpenConfirmDialog(false);
        // Changed the exisiting custom-pricing route to user-pricing to test PricingWorkflow component
        // navigate(`/user-pricing`);

        //Establishing the complete workflow
        navigate('/custom-pricing');
  };

  // Called when user decides to cancel proceeding.
  const handleCancelProceed = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        position: "relative", // for overlaying loading spinner
      }}
    >
      <Sidebar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "47.875rem",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "1.625rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "1.8125rem",
              alignSelf: "stretch",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.875rem",
                alignSelf: "stretch",
              }}
            >
              <Typography variant="h2" alignSelf={"stretch"}>
                Cost Catalog Setup
              </Typography>
              <Typography variant="body2" alignSelf={"stretch"}>
                Select if you want to use the Assembli Cost Database or create your own custom pricing model.
              </Typography>
            </Box>

            {/* Sections */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                gap: "1rem",
              }}
            >
              <AssembliPricingSection
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
              />
              <CustomPricingSection
                // Passing down required props
                selectedOption={selectedOption}
                handleOptionChange={handleOptionChange}
                files={files}
                error={error}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                handleFileSelection={handleFileSelection}
                handleRemoveFile={handleRemoveFile}
                isProcessing={isProcessing}
              />
            </Box>
          </Box>
          {/* Action Buttons */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!selectedOption || isProcessing} // disable button while processing
          >
            Next
          </Button>
        </Box>
      </Box>

      {/* Loading Spinner Overlay */}
      {isProcessing && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCancelProceed}>
        <DialogTitle>{"No Files Selected"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have not selected any files to upload. If there were any files previously uploaded, you will see them. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmProceed} color="primary">
            Yes
          </Button>
          <Button onClick={handleCancelProceed} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ChooseCost;
