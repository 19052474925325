import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Popover,
  Typography,
  InputAdornment,
  Chip,
  Menu,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  ContentCopy as ContentCopyIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";

// 1. Add this import at the top:
import apiAxios from "../../services/axiosConfig";

// 2. Modify import from proposalService to include fetchESignature
import {
  fetchProposals,
  duplicateProposal,
  proposal_inuse_toggle,
  fetchESignature, // Add this
  addProposalLog, // Add this
} from "../../services/proposalService";

import { getSnapshotData } from "../../services/jobService";
import {
  setproposalId,
  setproposalName,
  updateProposalData,
  resetProposalData,
  setJobId,
  updateSnapshotData,
  resetSnapshotData,
} from "../../store";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const INITIAL_FILTERS = {
  status: [],
  dateRange: { from: "", to: "" },
};

const STATUS_OPTIONS = ["draft", "Sent", "approved", "declined"];

const ProposalsTable = () => {
  // Redux and navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Main data states
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [totalRows, setTotalRows] = useState(0);

  // Row selection
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // Search state
  const [searchParams, setSearchParams] = useState({
    text: "",
    criteria: "proposal_name",
  });
  const [appliedSearchParams, setAppliedSearchParams] = useState({
    text: "",
    criteria: "proposal_name",
  });

  // Filter states
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [appliedFilters, setAppliedFilters] = useState(INITIAL_FILTERS);

  // UI for filter popover
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeFilterTab, setActiveFilterTab] = useState("status");

  // Menu and duplication states
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    selectedProposal: null,
  });

  // Dialog states for duplicating
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState({
    id: null,
    name: "",
  });

  // **NEW**: Dialog for locked or status-based messages
  const [lockedDialogOpen, setLockedDialogOpen] = useState(false);
  const [lockedDialogMessage, setLockedDialogMessage] = useState("");

  // 3. Add these new state variables after your existing ones
  const [signedPdfPath, setSignedPdfPath] = useState(null);
  const [sentPdfPath, setSentPdfPath] = useState(null);
  const [showViewPdfButton, setShowViewPdfButton] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);

  // Define columns
  const columns = [
    { field: "user_name", headerName: "User", flex: 1, minWidth: 130 },
    {
      field: "proposal_name",
      headerName: "Proposal Name",
      flex: 1.5,
      minWidth: 180,
    },
    { field: "job_name", headerName: "Job Name", flex: 1.5, minWidth: 180 },
    {
      field: "created_at",
      headerName: "Created On",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params) return "Not set";
        return new Date(params).toLocaleDateString("en-US");
      },
    },
    {
      field: "due_date",
      headerName: "Due",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params) return "Not set";
        return new Date(params).toLocaleDateString();
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      // 4. Update the status chip rendering logic to handle all statuses differently
      renderCell: (params) => {
        const status = params?.value || "unknown";
        let color;
        switch (status) {
          case "approved":
            color = "success";
            break;
          case "Sent":
            color = "primary";
            break;
          case "declined":
            color = "error";
            break;
          default:
            color = "default";
        }

        return (
          <Chip
            label={status.charAt(0).toUpperCase() + status.slice(1)}
            color={color}
            size="small"
            sx={{ borderRadius: 2 }}
          />
        );
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      flex: 1,
      minWidth: 130,
      valueGetter: (params) => {
        if (params == null) return "$0.00";
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params);
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setMenuState({
              anchorEl: e.currentTarget,
              selectedProposal: params.row,
            });
          }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  // Handle the menu actions
  const handleMenuAction = async (action) => {
    const { selectedProposal } = menuState;
    if (!selectedProposal) return;

    if (action === "duplicate") {
      setDuplicateData({
        id: selectedProposal.id,
        name: selectedProposal.proposal_name || "",
      });
      setDuplicateDialogOpen(true);
    }

    setMenuState({ anchorEl: null, selectedProposal: null });
  };

  const handleConfirmDuplicate = async () => {
    try {
      setLoading(true);
      await duplicateProposal({
        id: duplicateData.id,
        name: duplicateData.name,
      });
      await loadProposals();
      await addProposalLog({
        proposal_id: duplicateData.id,
        action: "duplicated",
        message: "Proposal duplicated named " + duplicateData.name,
      });
      setError(null);
    } catch (err) {
      setError("Failed to duplicate proposal");
      console.error("Error:", err);
    } finally {
      setLoading(false);
      setDuplicateDialogOpen(false);
    }
  };

  // Build query parameters
  const queryParams = useMemo(
    () => ({
      search: {
        text: appliedSearchParams.text,
        criteria: appliedSearchParams.criteria,
      },
      filters: {
        status: appliedFilters.status,
        date_range: appliedFilters.dateRange,
      },
      pagination: {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      },
    }),
    [appliedSearchParams, appliedFilters, paginationModel]
  );

  // Fetch proposals
  const loadProposals = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchProposals(queryParams);
      console.log("Response:", response);
      setProposals(response.proposals || []);
      setTotalRows(response.total || 0);
      setError(null);
    } catch (err) {
      setError("Failed to load proposals");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  }, [queryParams]);

  useEffect(() => {
    loadProposals();
  }, [loadProposals]);

  // Search logic
  const handleSearch = () => {
    if (!searchParams.text.trim()) {
      setAppliedSearchParams((prev) => ({ ...prev, text: "" }));
    } else {
      setAppliedSearchParams(searchParams);
    }
  };

  const handleSearchChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
    if (field === "text" && !value) {
      setAppliedSearchParams((prev) => ({ ...prev, text: "" }));
    }
  };

  const isSearchActive = Boolean(appliedSearchParams.text);

  // Filter logic
  const handleApplyFilters = () => {
    setAppliedFilters(filters);
    setFilterAnchorEl(null);
  };

  const handleClearFilters = () => {
    setFilters(INITIAL_FILTERS);
    setAppliedFilters(INITIAL_FILTERS);
  };

  // 5. Replace your existing handleRowClick with the updated version
  const handleRowClick = async (params) => {
    const proposalId = params.row.id;
    const proposalStatus = params.row.status;

    try {
      // If the proposal is already approved or sent, show appropriate dialog
      if (proposalStatus === "approved") {
        // Get client details from the proposal
        const clientDetails = params.row.client_details;
        if (clientDetails && clientDetails.length > 0) {
          const clientName = clientDetails[0].client_name || "the client";

          // Show approval dialog
          setLockedDialogMessage(
            <>
              <Typography variant="body1" gutterBottom>
                This proposal has been approved by {clientName}.
              </Typography>
              <Typography variant="body1">
                You can view the signed proposal by clicking the button below.
              </Typography>
            </>
          );

          // Fetch the signed PDF path from ESignatures table
          const esignature = await fetchESignature(proposalId);
          if (esignature && esignature.pdf_path) {
            setSignedPdfPath(esignature.pdf_path);
            setShowViewPdfButton(true);
          }

          setLockedDialogOpen(true);
        } else {
          // Fallback if client details are missing
          setLockedDialogMessage(
            "This proposal has been approved. You can no longer edit it."
          );
          setLockedDialogOpen(true);
        }

        return; // Stop here - don't proceed to editing
      } else if (proposalStatus === "Sent") {
        // Get client details from the proposal
        const clientDetails = params.row.client_details;
        console.log("Client details:", params.row);
        if (clientDetails && clientDetails.length > 0) {
          const clientName = clientDetails[0].client_name || "the client";
          const date = clientDetails[0].sent_date || "the date";
          const pdfPath = clientDetails[0].pdf_path;

          // Show sent dialog
          setLockedDialogMessage(
            <>
              <Typography variant="body1" gutterBottom>
                This proposal has been sent to {clientName} for signature.
              </Typography>
              <Typography variant="body1">
                You can view the proposal by clicking the button below.
              </Typography>
            </>
          );

          if (pdfPath) {
            setSentPdfPath(pdfPath);
            setShowViewPdfButton(true);
          }

          setLockedDialogOpen(true);
        } else {
          // Fallback if client details are missing
          setLockedDialogMessage(
            "This proposal has been sent for signature. You can no longer edit it."
          );
          setLockedDialogOpen(true);
        }

        return; // Stop here - don't proceed to editing
      }

      // For other statuses (draft), proceed with the normal edit flow
      const res = await proposal_inuse_toggle({ id: proposalId });

      if (res.flag) {
        // The proposal is free to edit
        dispatch(setproposalId(proposalId));
        dispatch(setproposalName(params.row.proposal_name));
        dispatch(setJobId(params.row.job_id));
        dispatch(resetProposalData());
        console.log("Proposal data:", params.row.proposal_data);
        dispatch(updateProposalData(params.row.proposal_data));
        dispatch(resetSnapshotData());

        // Now that we know the proposal is free, fetch the snapshot data
        const snapshot_data = await getSnapshotData(params.row.job_id);
        console.log("Snapshot before parse:", snapshot_data);

        // Parse the snapshot data safely
        let parsedSnapshot;
        try {
          parsedSnapshot = JSON.parse(snapshot_data || "{}");
        } catch (parseError) {
          console.error("Error parsing snapshot data:", parseError);
          parsedSnapshot = {};
        }
        console.log("Parsed snapshot data:", parsedSnapshot);

        // Update your store/state with the parsed snapshot data
        dispatch(updateSnapshotData(parsedSnapshot));

        // Navigate to the proposal editor page
        navigate(`/proposal_editor`);
      } else {
        // The proposal is locked by another user
        setLockedDialogMessage(
          `Proposal is currently being edited by ${res.username}. Try again later.`
        );
        setLockedDialogOpen(true);
      }
    } catch (err) {
      console.error("Error checking proposal status:", err);
      setError("There was an error processing your request. Please try again.");
    }
  };

  // 6. Add these new functions for PDF handling
  const handleViewPdf = async () => {
    try {
      let pdfPath = null;

      // Determine which PDF to show based on current dialog context
      if (signedPdfPath) {
        pdfPath = signedPdfPath;
      } else if (sentPdfPath) {
        pdfPath = sentPdfPath;
      }

      if (!pdfPath) {
        setError("PDF path not found");
        return;
      }

      // Generate signed URL for the PDF
      const response = await apiAxios.post("/proposal/generate_signed_url", {
        pdf_path: pdfPath,
      });

      if (response.data && response.data.url) {
        setCurrentPdfUrl(response.data.url);
        setPdfViewerOpen(true);
      } else {
        setError("Failed to generate PDF URL");
      }
    } catch (err) {
      console.error("Error generating signed URL:", err);
      setError("Failed to load PDF");
    }
  };

  const handleClosePdfViewer = () => {
    setPdfViewerOpen(false);
    setCurrentPdfUrl(null);
  };

  // Status filter
  const handleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status.includes(status)
        ? prev.status.filter((s) => s !== status)
        : [...prev.status, status],
    }));
  };

  // Date range filter
  const handleDateRangeChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      dateRange: { ...prev.dateRange, [field]: value },
    }));
  };

  // Active filter count for UI
  const getActiveFilterCount = () => {
    return (
      appliedFilters.status.length +
      (appliedFilters.dateRange.from ? 1 : 0) +
      (appliedFilters.dateRange.to ? 1 : 0)
    );
  };

  useEffect(() => {
    console.log("Proposals after setState:", proposals);
  }, [proposals]);

  return (
    <Box sx={{ width: "100%" }}>
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 2, borderRadius: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Paper
        sx={{
          mb: 3,
          p: 2,
          borderRadius: 2,
          borderLeft: isSearchActive ? 4 : 0,
          borderColor: "primary.main",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Search By</InputLabel>
              <Select
                value={searchParams.criteria}
                onChange={(e) => handleSearchChange("criteria", e.target.value)}
                label="Search By"
                size="small"
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="proposal_name">Proposal Name</MenuItem>
                <MenuItem value="job_name">Job Name</MenuItem>
                <MenuItem value="user_name">User</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", flex: 1, gap: 1 }}>
              <TextField
                size="small"
                placeholder={`Search by ${searchParams.criteria.replace(
                  "_",
                  " "
                )}`}
                value={searchParams.text}
                onChange={(e) => handleSearchChange("text", e.target.value)}
                sx={{
                  flex: 1,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchParams.text && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearchChange("text", "");
                          setAppliedSearchParams((prev) => ({
                            ...prev,
                            text: "",
                          }));
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={!searchParams.text}
                sx={{ borderRadius: 2 }}
              >
                Search
              </Button>
            </Box>
          </Box>

          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              color={getActiveFilterCount() > 0 ? "primary" : "inherit"}
              sx={{
                borderRadius: 2,
                display: "flex",
                height: "100%",
              }}
            >
              Filters
            </Button>
            {getActiveFilterCount() > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  display: "flex",
                  backgroundColor: "#2961A7",
                  padding: "0.4rem",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ display: "flex", color: "#FFFFFF" }}>
                  {getActiveFilterCount()}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>

      <Box sx={{ height: 600, width: "100%" }}>
        {console.log("Proposals:", proposals)}
        <DataGrid
          rows={proposals}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={totalRows}
          paginationMode="server"
          disableColumnFilter
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          onRowClick={handleRowClick}
          loading={loading}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: 2,
            "& .MuiDataGrid-cell": {
              borderColor: "divider",
            },
            ".MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
            },
            ".MuiDataGrid-sortIcon": {
              opacity: "inherit !important",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Box>

      {/* MENU FOR ACTIONS */}
      <Menu
        anchorEl={menuState.anchorEl}
        open={Boolean(menuState.anchorEl)}
        onClose={() => setMenuState({ anchorEl: null, selectedProposal: null })}
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <MenuItem
          onClick={() => handleMenuAction("duplicate")}
          sx={{
            borderRadius: 1,
            mx: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <ContentCopyIcon sx={{ mr: 1, fontSize: 20 }} />
          Duplicate
        </MenuItem>
      </Menu>

      {/* DIALOG FOR DUPLICATING */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Duplicate Proposal</DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label="Proposal Name"
            value={duplicateData.name}
            onChange={(e) =>
              setDuplicateData((prev) => ({ ...prev, name: e.target.value }))
            }
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDuplicateDialogOpen(false)}
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDuplicate}
            sx={{ borderRadius: 2 }}
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>

      {/* FILTER POPOVER */}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: 500,
            borderRadius: 2,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          {/* Header area with Clear All */}
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Filters</Typography>
            <Button
              variant="ghost"
              size="small"
              startIcon={<ClearIcon />}
              onClick={handleClearFilters}
              sx={{ borderRadius: 2 }}
              disabled={
                !(
                  filters.status.length > 0 ||
                  filters.dateRange.from ||
                  filters.dateRange.to
                )
              }
            >
              Clear All
            </Button>
          </Box>

          {/* Tabs for "Status" and "Created At" */}
          <Tabs
            value={activeFilterTab}
            onChange={(_, newValue) => setActiveFilterTab(newValue)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              sx={{
                padding: 1,
                gap: 0,
                backgroundColor: "transparent",
                minWidth: "auto",
                margin: 0,
                borderRadius: "inherit",
                fontSize: "0.7rem",
                "&.Mui-selected": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
              }}
              label="Status"
              value="status"
            />
            <Tab
              sx={{
                padding: 1,
                gap: 0,
                backgroundColor: "transparent",
                minWidth: "auto",
                margin: 0,
                fontSize: "0.7rem",
                borderRadius: "inherit",
                "&.Mui-selected": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
              }}
              label="Created At"
              value="date"
            />
          </Tabs>

          {/* Tab Content */}
          <Box sx={{ mt: 2 }}>
            {activeFilterTab === "status" && (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {STATUS_OPTIONS.map((status) => {
                  const isActive = filters.status.includes(status);
                  return (
                    <Chip
                      key={status}
                      label={status}
                      onClick={() => handleStatusFilter(status)}
                      clickable
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 2,
                      }}
                      color={isActive ? "primary" : "default"}
                    />
                  );
                })}
              </Box>
            )}

            {activeFilterTab === "date" && (
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                <TextField
                  label="From"
                  type="date"
                  value={filters.dateRange.from}
                  onChange={(e) =>
                    handleDateRangeChange("from", e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  label="To"
                  type="date"
                  value={filters.dateRange.to}
                  onChange={(e) => handleDateRangeChange("to", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
              </Box>
            )}
          </Box>

          {/* Actions at bottom */}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <Button
              onClick={() => setFilterAnchorEl(null)}
              sx={{ borderRadius: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              sx={{ borderRadius: 2 }}
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Popover>

      {/* 7. Replace your existing LOCKED/STATUS dialog with this */}
      <Dialog
        open={lockedDialogOpen}
        onClose={() => {
          setLockedDialogOpen(false);
          setShowViewPdfButton(false);
          setSignedPdfPath(null);
          setSentPdfPath(null);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {signedPdfPath
            ? "Proposal Approved"
            : sentPdfPath
            ? "Proposal Sent"
            : "Proposal Status"}
        </DialogTitle>
        <DialogContent>{lockedDialogMessage}</DialogContent>
        <DialogActions>
          {showViewPdfButton && (
            <Button
              onClick={handleViewPdf}
              color="primary"
              variant="contained"
              sx={{ borderRadius: 2, mr: 1 }}
            >
              View Proposal
            </Button>
          )}
          <Button
            onClick={() => {
              setLockedDialogOpen(false);
              setShowViewPdfButton(false);
              setSignedPdfPath(null);
              setSentPdfPath(null);
            }}
            color="primary"
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* 8. Add this PDF VIEWER DIALOG after the other dialogs */}
      <Dialog
        open={pdfViewerOpen}
        onClose={handleClosePdfViewer}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            height: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {signedPdfPath ? "Signed Proposal" : "Proposal"}
            </Typography>
            <IconButton onClick={handleClosePdfViewer} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ flexGrow: 1, p: 0, overflow: "hidden" }}>
          {currentPdfUrl && (
            <iframe
              src={currentPdfUrl}
              width="100%"
              height="100%"
              title="Proposal PDF"
              style={{ border: "none" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ProposalsTable;
