// src/components/TableStructure.jsx
import React, { useState, useEffect, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import CustomTypography from "../CustomComponents/CustomTypography";

// Icon imports
import { ReactComponent as ArrowDownIcon } from "../../static/Icons/arrowDown.svg";
import { ReactComponent as AddSquareIcon } from "../../static/Icons/addSquare.svg";
import { ReactComponent as TrashIcon } from "../../static/Icons/trash.svg";

// Utility and Redux imports
import { rec_calculate_takeoff } from "../../utils/takeoffUtils";
import { useDispatch } from "react-redux";
import { setUnsavedChanges } from "../../store";

// --- Column Width Constants (in rem) ---
// const ITEM_NUMBER_WIDTH = 12.5;      // For "Item #"
const DESCRIPTION_WIDTH = 20;   // For "Description"
const UNIT_WIDTH = 6.25;          // For "Unit"
const QUANTITY_WIDTH = 6.25;
const WASTE_FACTOR_WIDTH = 6.25;      // For "Quantity"
const UNIT_PRICE_WIDTH = 9.25;   // For "Unit Price"
const EXTENDED_PRICE_WIDTH = 9.25; // For "Extended Price"
const MARKUP_PERCENT_WIDTH = 6.25;  // For "Markup %"
const MARKUP_AMOUNT_WIDTH = 8.25;   // For "Markup Amount"
const TAX_PERCENT_WIDTH = 6.25;     // For "Tax %"
const TAX_AMOUNT_WIDTH = 9.25;      // For "Tax Amount"
const TOTAL_WIDTH = 9.25;           // For "Total"

// Total minimum width for header row:
const MIN_HEADER_WIDTH =
  // ITEM_NUMBER_WIDTH +
  DESCRIPTION_WIDTH +
  UNIT_WIDTH +
  QUANTITY_WIDTH +
  WASTE_FACTOR_WIDTH +
  UNIT_PRICE_WIDTH +
  EXTENDED_PRICE_WIDTH +
  MARKUP_PERCENT_WIDTH +
  MARKUP_AMOUNT_WIDTH +
  TAX_PERCENT_WIDTH +
  TAX_AMOUNT_WIDTH +
  TOTAL_WIDTH;

// --- Styled Components ---
export const TableStructureFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.125rem",
  alignSelf: "stretch",
  maxHeight: "calc(100vh - 22vh)",
  overflowY: "auto",
  overflowX: "hidden",
  width: "100%",
}));

export const TableStructureHeadingFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.98388rem 0rem",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "0.70281rem",
  alignSelf: "stretch",
}));

export const TableStructureHeadingInnerFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1.265rem",
  alignSelf: "stretch",
}));

export const TableStructureHeadingIconFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.3125rem",
  alignItems: "center",
  gap: "0.375rem",
}));

export const TableStructureHeadingIcon = styled(ArrowDownIcon)(() => ({
  width: "0.90931rem",
  height: "0.90931rem",
}));

export const TableStructureHeadingRotateIcon = styled(ArrowDownIcon)(() => ({
  width: "0.90931rem",
  height: "0.90931rem",
  transform: "rotate(180deg)",
}));

export const TableStructureHeadingText = styled(Typography)(() => ({
  color: "#000",
  fontFamily: "Roboto",
  fontSize: "1.0625rem",
  fontWeight: 600,
  lineHeight: "120%",
}));

export const TableStructureContentFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  padding: "0.6875rem 0rem",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "1.625rem",
  marginBottom: "1rem",
  borderBottom: "2px solid rgba(49, 43, 43, 0.07)",
}));

export const TableStructureContentColumnInputText = styled(CustomTypography)(() => ({
  color: "#1D1D1B",
}));

export const EditableInputInnerFrame = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "0.125rem",
  borderRadius: "0.5rem",
  background: "#E7E7DF",
  marginRight: "0.5rem",
  "& input": {
    border: "none",
    outline: "none",
    background: "transparent",
  },
});

const InputField = styled("input")({
  flex: 1,
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  padding: "0.5rem",
});

const AddSubdivisionFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.375rem 0.9375rem 0.375rem 0.4375rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

const AddSubdivisionInnerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.8125rem",
  alignItems: "center",
  gap: "0.4375rem",
  borderRadius: "0.75rem",
  border: "1px solid #DCDACB",
  cursor: "pointer",
}));

const AddSquareFrame = styled(Box)(() => ({
  display: "flex",
  width: "1.25rem",
  height: "1.25rem",
  justifyContent: "center",
  alignItems: "center",
}));

const AddSquareIconFrame = styled(AddSquareIcon)(() => ({
  width: "1.25rem",
  height: "1.25rem",
}));

const TrashIconFrame = styled(TrashIcon)(() => ({
  width: "1.25rem",
  height: "1.25rem",
  cursor: "pointer",
}));

// --- TableStructure Component ---
const TableStructure = forwardRef(
  (
    { data = [], setData, isEditing = false, onSubdivisionClick, selectedSubDivision, collapseAll },
    ref
  ) => {
    const [openStates, setOpenStates] = useState([]);
    const dispatch = useDispatch();

    // useEffect(() => {
    //   setOpenStates(data.map(() => true));
    // }, [data]);

    useEffect(() => {
      // If collapseAll is true, then set each division's open state to false (collapsed).
      // Otherwise, set them to true (expanded).
      setOpenStates(data.map(() => (!collapseAll ? true : false)));
    }, [collapseAll, data]);

    const toggleDivision = (idx) => {
      setOpenStates((prev) => {
        const copy = [...prev];
        copy[idx] = !copy[idx];
        return copy;
      });
    };

    const handleDivisionNameChange = (divIdx, newName) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData[divIdx].divisionName = newName;
      // reCalculateAndSetData(newData);
      rec_calculate_takeoff(newData);
      setData(newData);
      dispatch(setUnsavedChanges(true));
    };

    const handleFieldChange = (divIdx, subIdx, field, value) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData[divIdx].subdivisions[subIdx][field] = value;
      // reCalculateAndSetData(newData);
      rec_calculate_takeoff(newData);
      setData(newData);
      dispatch(setUnsavedChanges(true));
    };

    const handleAddSubdivision = (divIdx) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData[divIdx].subdivisions.push({
        subdivisionKey: "", // Will be generated if empty
        name: "",
        unit: "",
        quantity: "0",
        waste_factor: "0",
        unit_price_labor: "0",
        unit_price_material: "0",
        extended_price: "0",
        markup_percent: "0",
        markup_amount: "0",
        tax_percent: "0",
        tax_amount: "0",
        total: "0",
        images: [],
        item_or_material_tags: [],
      });
      // reCalculateAndSetData(newData);
      setData(newData);
      dispatch(setUnsavedChanges(true));
    };

    const handleDeleteSubdivision = (divIdx, subIdx) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData[divIdx].subdivisions.splice(subIdx, 1);
      // reCalculateAndSetData(newData);
      rec_calculate_takeoff(newData);
      setData(newData);
      dispatch(setUnsavedChanges(true));
    };

    const handleDeleteDivision = (divIdx) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData.splice(divIdx, 1);
      // reCalculateAndSetData(newData);
      rec_calculate_takeoff(newData);
      setData(newData);
      dispatch(setUnsavedChanges(true));
    };

    return (
      <TableStructureFrame ref={ref}>
        {data.map((division, dIndex) => {
          // Calculate the total cost for the division by summing all subdivision total prices.
          const divisionTotal = division.subdivisions.reduce(
            (acc, sub) => acc + (parseFloat(sub.total_price) || 0),
            0
          );
          return (
            <Box key={dIndex} sx={{ width: "100%" }}>
              {/* Division Heading */}
              <TableStructureContentFrame
                onClick={() => !isEditing ? toggleDivision(dIndex) : undefined}
                sx={{ cursor: "pointer", padding: "0.5rem 1rem" }}
              >
                {openStates[dIndex] ? (
                  <TableStructureHeadingFrame>
                    <TableStructureHeadingIconFrame>
                      <TableStructureHeadingIcon />
                    </TableStructureHeadingIconFrame>
                  </TableStructureHeadingFrame>
                ) : (
                  <TableStructureHeadingFrame>
                    <TableStructureHeadingIconFrame>
                      <TableStructureHeadingRotateIcon />
                    </TableStructureHeadingIconFrame>
                  </TableStructureHeadingFrame>
                )}
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: "0.75rem",
                    width: "100%",
                  }}
                >
                  <TableStructureHeadingFrame>
                    <TableStructureHeadingInnerFrame>
                        {isEditing ? (
                          <EditableInputInnerFrame>
                            <InputField
                              type="text"
                              value={division.divisionName || ""}
                              onChange={(e) => handleDivisionNameChange(dIndex, e.target.value)}
                              readOnly={!isEditing}
                            />
                          </EditableInputInnerFrame>
                        ) : (
                          <TableStructureHeadingText variant="body1SemiBold17" sx={{ fontWeight: 600 }}>
                          {division.divisionName}
                        </TableStructureHeadingText>
                      )}
                    </TableStructureHeadingInnerFrame>
                  </TableStructureHeadingFrame>
                  {isEditing && (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteDivision(dIndex);
                      }}
                    >
                      <TrashIconFrame />
                    </Box>
                  )}
                  <Box>
                    <TableStructureHeadingText variant="body1SemiBold17" sx={{ fontWeight: 600 }}>
                      Total: {divisionTotal.toFixed(2)}
                    </TableStructureHeadingText>
                  </Box>
                </Box>
              </TableStructureContentFrame>

              {/* Expanded subdivisions container with horizontal scrollbar */}
              {openStates[dIndex] && (
                <Box
                  sx={{
                    borderRadius: "1.6875rem",
                    background: "#fff",
                    boxShadow: "0px 8px 34.8px 0px rgba(185, 185, 162, 0.30)",
                    p: "1rem",
                    maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  <Box sx={{ minWidth: `${MIN_HEADER_WIDTH}rem` }}>
                    {/* Header Row */}
                    <Box
                      sx={{
                        display: "flex",
                        fontWeight: 600,
                        borderBottom: "1.5px solid #DCDACB",
                        py: 1,
                        px: 1,
                      }}
                    >
                      {/* Sticky Description Header Column */}
                      <Box
                        sx={{
                          width: `${DESCRIPTION_WIDTH}rem`,
                          // position: "sticky",
                          left: 0,
                          // backgroundColor: "#E7E7DF",
                          zIndex: 6,
                        }}
                      >
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Description
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Item Name */}
                      <Box sx={{ width: `${DESCRIPTION_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Item Name
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Unit */}
                      <Box sx={{ width: `${UNIT_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Unit
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Quantity */}
                      <Box sx={{ width: `${QUANTITY_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Quantity
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Waste Factor */}
                      <Box sx={{ width: `${WASTE_FACTOR_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Waste Factor
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Material Price */}
                      <Box sx={{ width: `${UNIT_PRICE_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Material Price
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Labor Price */}
                      <Box sx={{ width: `${UNIT_PRICE_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Labor Price
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Extended Price */}
                      <Box sx={{ width: `${EXTENDED_PRICE_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Extended Price
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Markup % */}
                      <Box sx={{ width: `${MARKUP_PERCENT_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Markup %
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Markup Amount */}
                      <Box sx={{ width: `${MARKUP_AMOUNT_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Markup Amount
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Tax % */}
                      <Box sx={{ width: `${TAX_PERCENT_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Tax %
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Tax Amount */}
                      <Box sx={{ width: `${TAX_AMOUNT_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Tax Amount
                        </TableStructureContentColumnInputText>
                      </Box>
                      {/* Total */}
                      <Box sx={{ width: `${TOTAL_WIDTH}rem` }}>
                        <TableStructureContentColumnInputText variant="body2SemiBold16">
                          Total
                        </TableStructureContentColumnInputText>
                      </Box>
                    </Box>
                    {/* Data Rows */}
                    {division.subdivisions.map((sub, sIndex) => (
                      <Box key={sIndex}>
                        <Box
                          sx={{
                            display: "flex",
                            py: 1,
                            px: 1,
                            alignItems: "center",
                            borderBottom:
                              sIndex === division.subdivisions.length - 1
                                ? "none"
                                : "1.5px solid #DCDACB",
                            // cursor: !isEditing ? "pointer" : "default",
                          }}
                          // onClick={(e) => {
                          //   if (!isEditing && onSubdivisionClick) {
                          //     e.stopPropagation();
                          //     onSubdivisionClick(dIndex, sIndex);
                          //   }
                          // }}
                        >
                          {/* Sticky Description Column */}
                          <Box
                            sx={{
                              width: `${DESCRIPTION_WIDTH}rem`,
                              // position: "sticky",
                              left: 0,
                              // backgroundColor: "#E7E7DF",
                              zIndex: 9,
                            }} 
                          >
                            <EditableInputInnerFrame onClick={() => !isEditing ? onSubdivisionClick(dIndex, sIndex) : undefined}>
                              <InputField
                                type="text"
                                value={sub.subdivisionName || ""}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "subdivisionName",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                sx={{cursor: !isEditing && sub.images && sub.images.length > 0 ? "pointer" : "inherit",}}
                                readOnly={!isEditing}
                                style={{ color: sub.images && sub.images.length > 0 ? "#47BCFF" : "inherit", fontWeight: sub.images && sub.images.length > 0 ? "bold" : "normal" }}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Item Name */}
                          <Box sx={{ width: `${DESCRIPTION_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.itemName || ""}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "itemName",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Unit */}
                          <Box sx={{ width: `${UNIT_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.unit || ""}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(dIndex, sIndex, "unit", e.target.value)
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Quantity */}
                          <Box sx={{ width: `${QUANTITY_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.quantity}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(dIndex, sIndex, "quantity", e.target.value)
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Waste Factor */}
                          <Box sx={{ width: `${WASTE_FACTOR_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.waste_factor}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(dIndex, sIndex, "waste_factor", e.target.value)
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Unit Price Material */}
                          <Box sx={{ width: `${UNIT_PRICE_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.unit_price_material}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "unit_price_material",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Unit Price Labor */}
                          <Box sx={{ width: `${UNIT_PRICE_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.unit_price_labor}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "unit_price_labor",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Extended Price */}
                          <Box sx={{ width: `${EXTENDED_PRICE_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField type="text" value={sub.extended_price} readOnly />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Markup % */}
                          <Box sx={{ width: `${MARKUP_PERCENT_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.markup_percent}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "markup_percent",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Markup Amount */}
                          <Box sx={{ width: `${MARKUP_AMOUNT_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.markup_amount}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "markup_amount",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Tax % */}
                          <Box sx={{ width: `${TAX_PERCENT_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.tax_percent}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "tax_percent",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Tax Amount */}
                          <Box sx={{ width: `${TAX_AMOUNT_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField
                                type="text"
                                value={sub.tax_amount}
                                onChange={
                                  isEditing
                                    ? (e) =>
                                        handleFieldChange(
                                          dIndex,
                                          sIndex,
                                          "tax_amount",
                                          e.target.value
                                        )
                                    : undefined
                                }
                                readOnly={!isEditing}
                              />
                            </EditableInputInnerFrame>
                          </Box>
                          {/* Total */}
                          <Box sx={{ width: `${TOTAL_WIDTH}rem` }}>
                            <EditableInputInnerFrame>
                              <InputField type="text" value={sub.total_price} readOnly />
                            </EditableInputInnerFrame>
                          </Box>
                          {isEditing && (
                            <Box
                              sx={{ width: "2rem", textAlign: "center" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteSubdivision(dIndex, sIndex);
                              }}
                            >
                              <TrashIconFrame />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {/* Add Subdivision Button */}
                  {isEditing && (
                    <AddSubdivisionFrame onClick={() => handleAddSubdivision(dIndex)}>
                      <AddSubdivisionInnerFrame>
                        <AddSquareFrame>
                          <AddSquareIconFrame />
                        </AddSquareFrame>
                        <CustomTypography variant="body2Medium16">
                          Add Subdivision
                        </CustomTypography>
                      </AddSubdivisionInnerFrame>
                    </AddSubdivisionFrame>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </TableStructureFrame>
    );
  }
);

TableStructure.displayName = "TableStructure";
export default TableStructure;
