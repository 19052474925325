import apiAxios from './axiosConfig';

/**
 * Gets current subscription information for the authenticated user's company
 */
export const getSubscriptionInfo = async () => {
  try {
    const response = await apiAxios.get('/subscription/info');
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription info:', error.response?.data);
    throw error;
  }
};

/**
 * Changes the subscription plan for the company
 */
export const changeSubscriptionPlan = async (planId) => {
  try {
    console.log('changing plan to:', planId);
    const response = await apiAxios.post('/subscription/change-plan', {
      plan_id: planId,
    });
    return response.data;
  } catch (error) {
    console.error('Error changing subscription plan:', error.response?.data);
    throw error;
  }
};

/**
 * Cancels the company's subscription
 */
export const cancelSubscription = async () => {
  try {
    const response = await apiAxios.post('/subscription/cancel');
    return response.data;
  } catch (error) {
    console.error('Error canceling subscription:', error.response?.data);
    throw error;
  }
};

/**
 * Updates the payment method for the subscription
 */
export const updatePaymentMethod = async (paymentMethodId) => {
  try {
    const response = await apiAxios.post('/subscription/update-payment', {
      payment_method_id: paymentMethodId,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating payment method:', error.response?.data);
    throw error;
  }
};

/**
 * Gets the invoice history for the company
 */
export const getInvoiceHistory = async () => {
  try {
    const response = await apiAxios.get('/subscription/invoice-history');
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice history:', error.response?.data);
    throw error;
  }
};

/**
 * Gets the upcoming invoice for the company
 */
export const getUpcomingInvoice = async () => {
  try {
    const response = await apiAxios.get('/subscription/upcoming-invoice');
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming invoice:', error.response?.data);
    throw error;
  }
};

/**
 * Buys additional jobs for this billing period.
 */
export const addExtraJobs = async (additionalJobs) => {
    try {
      const response = await apiAxios.post('/subscription/add-jobs', {
        additional_jobs: additionalJobs,
      });
      return response.data;
    } catch (error) {
      console.error('Error adding extra jobs:', error.response?.data);
      throw error;
    }
  };