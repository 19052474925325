// src/components/FileUpload/PromptInput.js

import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import CustomTypography from "../CustomComponents/CustomTypography";
import CustomButton from "../CustomComponents/CustomButton";
import { ReactComponent as AiIcon } from "../../static/Icons/Icon.svg";

const PromptInputFrame = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem 1rem",
    width: "48rem",
}));

const UploadPromptText = styled(CustomTypography)(() => ({
    alignSelf: "center",
    color: "var(--Typography-Primary, #1D1D1B)",
    textAlign: "center",
    fontFamily: '"Atyp Display TRIAL", sans-serif',
    fontSize: "1.9375rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%",
}));

const PromptInputInnerFrame = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1.125rem",
    width: "100%",
}));

const Rectangle = styled(Box)(() => ({
    width: "100%",
    minHeight: "13.3125rem",
    flexShrink: 0,
    borderRadius: "1.8125rem",
    background: "var(--Nuetrals-Background---Tertiary, #FFF)",
    boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "1.25rem 1.5rem",
    cursor: "text",
}));

const StyledTextArea = styled("textarea")(() => ({
    width: "100%",
    minHeight: "1.5rem",
    resize: "none",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontFamily: "Roboto, sans-serif",
    fontSize: "1rem",
    lineHeight: "135%",
    color: "#1D1D1B",
    overflow: "hidden",
}));

const GenerateButton = styled(Button)(() => ({
    height: "2.5rem",
    paddingRight: "0.5rem",
    borderRadius: "6.25rem",
    backgroundColor: "var(--Disabled-Background, #E7E7DF)",
    textTransform: "none",
    display: "inline-flex",
    alignItems: "center",
    gap: "0.5rem",
    marginTop: "auto",
    marginLeft: "auto",
    alignSelf: "flex-end",
    "&:hover": {
        backgroundColor: "var(--Disabled-Background-Hover, #DDDDCC)",
    },
}));

const AiIconFrame = styled(AiIcon)(() => ({
    width: "1.17138rem",
    height: "1.11869rem",
}));

const PromptSuggestionFrame = styled(Box)(() => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "0.875rem",
    width: "100%",
}));

const PromptSuggestionTextFrame = styled(CustomTypography)(() => ({
    display: "inline-flex",
    alignItems: "center",
    gap: "0.625rem",
    padding: "0.625rem 1.75rem 0.625rem 1.25rem",
    borderRadius: "6.25rem",
    border: "1px solid var(--Nuetrals-Border---1, #ABA898)",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(0,0,0,0.05)",
    },
}));

const PromptSuggestionAiIcon = styled(AiIcon)(() => ({
    width: "1.17138rem",
    height: "1.11869rem",
    color: "#2961A7",
}));

const PromptSuggestionText = styled(CustomTypography)(() => ({
    color: "var(--Typography-secondary, #666660)",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
}));

const PromptInput = ({ onPromptSubmit }) => {
    const [promptValue, setPromptValue] = useState("");
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (!textAreaRef.current) return;
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }, [promptValue]);

    const handleRectangleClick = (e) => {
        if (
            e.target.closest(".generate-button") ||
            e.target.closest(".suggestion-chip")
        ) {
            return;
        }
        textAreaRef.current?.focus();
    };

    const handleGenerateClick = () => {
        if (onPromptSubmit) {
            onPromptSubmit(promptValue.trim());
        }
    };

    const handleSuggestionClick = (text) => {
        setPromptValue(text);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleGenerateClick();
        }
    };

    return (
        <PromptInputFrame>
            <UploadPromptText>Thank you for uploading the file</UploadPromptText>
            <PromptInputInnerFrame>
                <Rectangle onClick={handleRectangleClick}>
                    {promptValue.length === 0 && (
                        <Typography variant="body3Regular14" 
                        sx={{
                            position: "absolute",
                            top: "1.25rem",
                            left: "1.5rem",
                            width: "90%",
                            color: "var(--Typography-Placeholder, #747367)",
                            pointerEvents: "none",
                        }}
                        >
                            We have started processing each page of your upload and creating a preliminary division by division estimate. What else are you looking for, prompt here….
                        </Typography>
                    )}

                    <StyledTextArea
                        ref={textAreaRef}
                        value={promptValue}
                        onChange={(e) => setPromptValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />

                    <GenerateButton
                        className="generate-button"
                        onClick={handleGenerateClick}
                    >
                        <AiIconFrame />
                        <CustomTypography>Generate</CustomTypography>
                    </GenerateButton>
                </Rectangle>

                {/* <PromptSuggestionFrame>
                    <PromptSuggestionTextFrame
                        className="suggestion-chip"
                        onClick={() =>
                            handleSuggestionClick("Understand my plans and create a validation")
                        }
                    >
                        <PromptSuggestionAiIcon />
                        <PromptSuggestionText>
                            Understand my plans and create a validation
                        </PromptSuggestionText>
                    </PromptSuggestionTextFrame>

                    <PromptSuggestionTextFrame
                        className="suggestion-chip"
                        onClick={() => handleSuggestionClick("Make my plans more effective")}
                    >
                        <PromptSuggestionAiIcon />
                        <PromptSuggestionText>
                            Make my plans more effective
                        </PromptSuggestionText>
                    </PromptSuggestionTextFrame>

                    <PromptSuggestionTextFrame
                        className="suggestion-chip"
                        onClick={() =>
                            handleSuggestionClick(
                                "Take my plans & make them easy to understand"
                            )
                        }
                    >
                        <PromptSuggestionAiIcon />
                        <PromptSuggestionText>
                            Take my plans &amp; make them easy to understand
                        </PromptSuggestionText>
                    </PromptSuggestionTextFrame>
                </PromptSuggestionFrame> */}
            </PromptInputInnerFrame>
        </PromptInputFrame>
    );
};

export default PromptInput;
