import axios from "axios";
import apiAxios from "./axiosConfig";
import { store } from "../store";
import {
  setSelectedJobId,
  setUnsavedChanges,
  setLoggedInUsername,
  setUserRole,
  setUserEmail,
  setCompanyId,
} from "../store";

const API_URL = process.env.REACT_APP_API_URL;

// Username/Password Login
export const usernamePasswordLogin = async (username, password) => {
  try {
    console.log("API_URL", API_URL);
    const response = await apiAxios.post(
      `${API_URL}/login`,
      {
        username,
        password,
      },
      // {
      //    headers: {
      //      'Content-Type': 'application/json',
      //    },
    );
    store.dispatch(setLoggedInUsername(response.data.user.username));
    store.dispatch(setUserRole(response.data.user.role));
    store.dispatch(setUserEmail(response.data.user.email));
    store.dispatch(setCompanyId(response.data.user.company));
    console.log("COMPANY ID", response.data.user.company);
    store.dispatch(setSelectedJobId(null));
    store.dispatch(setUnsavedChanges(false));
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error("Error during login:", error.response?.data);
    throw error;
  }
};

// Google Login
export const login = async (accessToken) => {
  try {
    const response = await apiAxios.post(`${API_URL}/login/callback`, {
      token: accessToken,
    });
    if (response.data.user) {
      store.dispatch(setLoggedInUsername(response.data.user.username));
      store.dispatch(setUserRole(response.data.user.role));
      store.dispatch(setUserEmail(response.data.user.email));
      store.dispatch(setCompanyId(response.data.user.company));
    }
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error("Error during login:", error.response?.data);
    throw error;
  }
};

// Google Signup
export const signupGoogle = async (accessToken, companyName) => {
  try {
    const response = await apiAxios.post(`${API_URL}/signup/callback`, {
      token: accessToken,
      company_name: companyName,
    });
    if (response.data.user) {
      store.dispatch(setLoggedInUsername(response.data.user.username));
      store.dispatch(setUserRole(response.data.user.role));
      store.dispatch(setUserEmail(response.data.user.email));
      store.dispatch(setCompanyId(response.data.user.company_id));
    }
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error("Error during signup:", error.response?.data);
    throw error;
  }
};

// Logout
export const logout = async () => {
  try {
    const token = localStorage.getItem("access_token");
    await apiAxios.post(`${API_URL}/logout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error during logout:", error.response?.data);
  } finally {
    localStorage.removeItem("access_token");
    store.dispatch(setSelectedJobId(null));
    store.dispatch(setUnsavedChanges(false));
  }
};

// Normal Signup
export const signup = async (username, email, password, companyName) => {
  try {
    const response = await apiAxios.post(
      `${API_URL}/signup`,
      {
        username,
        email,
        password,
        company_name: companyName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.data.user) {
      store.dispatch(setLoggedInUsername(response.data.user.username));
      store.dispatch(setUserRole(response.data.user.role));
      store.dispatch(setUserEmail(response.data.user.email));
      store.dispatch(setCompanyId(response.data.user.company_id));
    }
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error("Error during signup:", error.response?.data);
    throw error;
  }
};
