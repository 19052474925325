import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUnsavedChanges } from "../../store";

const TableStructureDataGrid = lazy(() => import("./TableStructureDataGrid"));

const TakeoffDisplayFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const TakeoffDisplayHeadingFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "1rem",
  marginBottom: "1rem",
}));

const EditButton = styled(Button)(() => ({
  display: "flex",
  height: "2.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6.25rem",
  border: "1px solid #ABA898",
  textTransform: "none",
}));

/**
 * Renders the DataGrid with Edit/Cancel/Save and optional "Add Division".
 * All changes remain local until Save is clicked.
 */
const TakeoffDisplay = ({
  loading,
  tableData = [],
  onSave,
  onSubdivisionClick,
  jobName = "Takeoff Data",
  jobId,  
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localData, setLocalData] = useState([]);
  const [allExpanded, setAllExpanded] = useState(true);
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  // On mount or when tableData changes
  useEffect(() => {
    if (Array.isArray(tableData)) {
      setLocalData(tableData);
    }
  }, [tableData]);

  // Scroll to top when jobId changes
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollToTop();
    }
  }, [jobId]);

  // If we exit edit mode, revert localData to tableData
  useEffect(() => {
    if (!isEditing) {
      setLocalData(tableData);
    }
  }, [tableData, isEditing]);

  // Edit / Cancel / Save / Add Division
  const handleEditClick = () => {
    setIsEditing(true);
    // Make a deep copy of the parent data
    setLocalData(JSON.parse(JSON.stringify(tableData)));
    dispatch(setUnsavedChanges(true));
  };

  const handleCancelClick = () => {
    setLocalData(tableData);
    setIsEditing(false);
    dispatch(setUnsavedChanges(false));
  };

  const handleAddDivision = () => {
    const newData = JSON.parse(JSON.stringify(localData));
    // Compute the maximum division number from existing divisions.
    let maxDivisionNum = 0;
    newData.forEach((division) => {
      if (division.divisionKey && division.divisionKey.startsWith("Division_")) {
        const num = parseInt(division.divisionKey.split("Division_")[1], 10);
        if (!isNaN(num)) {
          maxDivisionNum = Math.max(maxDivisionNum, num);
        }
      }
    });
    const newDivisionNum = maxDivisionNum + 1;
    const newDivisionKey = `Division_${newDivisionNum}`;
    
    newData.push({
      divisionKey: newDivisionKey, // Auto-generated key
      divisionName: `New Division ${newDivisionNum}`, // Visible name
      subdivisions: [
        {
          subdivisionKey: "", // You can update this similarly if needed
          subdivisionName: "New Subdivision 1",
          itemKey: `Item_${Date.now()}_${Math.random()}`,
          itemName: "",
          quantity: 0,
          unit: "",
          waste_factor_percent: "0",
          unit_price_material: "0",
          unit_price_labor: "0",
          extended_price: "0",
          markup_percent: "0",
          markup_amount: "0",
          tax_percent: "0",
          tax_amount: "0",
          total_price: "0",
          images: [],
        },
      ],
    });
    setLocalData(newData);
    dispatch(setUnsavedChanges(true));
  };
  

  const handleSave = async () => {
    if (onSave) {
      await onSave(localData); // updates the backend
    }
    setIsEditing(false);
    dispatch(setUnsavedChanges(false));
  };

  // Expand/Collapse All
  const handleToggleAll = () => {
    if (!tableRef.current) return;
    if (allExpanded) {
      tableRef.current.collapseAll();
    } else {
      tableRef.current.expandAll();
    }
    setAllExpanded(!allExpanded);
  };

  // Export selected rows
  const handleExport = () => {
    if (tableRef.current) {
      tableRef.current.exportSelectedRowsToExcel();
    }
  };

  return (
    <TakeoffDisplayFrame>
      <TakeoffDisplayHeadingFrame>
        {isEditing ? (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <EditButton variant="ghost" onClick={handleCancelClick}>
              <Typography variant="body3Medium14">Cancel</Typography>
            </EditButton>
            <EditButton variant="indigo" onClick={handleAddDivision}>
              <Typography variant="body3Medium14">Add Division</Typography>
            </EditButton>
            <EditButton variant="ghost" onClick={handleSave}>
              <Typography variant="body3Medium14">Save</Typography>
            </EditButton>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <EditButton variant="ghost" onClick={handleEditClick}>
              <Typography variant="body3Medium14">Edit</Typography>
            </EditButton>
            <EditButton variant="indigo" onClick={handleExport}>
              <Typography variant="body3Medium14">Export</Typography>
            </EditButton>
          </Box>
        )}
      </TakeoffDisplayHeadingFrame>

      {loading ? (
        <Box sx={{ mt: 2 }}>Loading takeoff data...</Box>
      ) : (
        <Suspense fallback={<div>Loading table...</div>}>
          <TableStructureDataGrid
            ref={tableRef}
            data={localData}
            setData={setLocalData}
            isEditing={isEditing}
            onSubdivisionClick={onSubdivisionClick}
            jobName={jobName}            
            onRowsSelected={(selectedRows) => {
              console.log("Selected rows:", selectedRows);
            }}
            onRowDoubleClick={handleEditClick}
          />
        </Suspense>
      )}
    </TakeoffDisplayFrame>
  );
};

export default TakeoffDisplay;
