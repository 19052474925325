import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import {
  getSignatureRequest,
  submitSignature,
} from "../../services/proposalService";
import { PDFDocument } from "pdf-lib";
import * as pdfjsLib from "pdfjs-dist";
import { addProposalLog } from "../../services/proposalService";

// PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const SignaturePage = () => {
  const { token } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [proposalData, setProposalData] = useState(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  // This will store the final PDF URL (with or without the signature)
  const [pdfWithSignature, setPdfWithSignature] = useState(null);

  // eSign compliance data
  const [userAgentInfo, setUserAgentInfo] = useState("");
  const [documentViewedAt, setDocumentViewedAt] = useState(null);
  const [signatureSubmittedAt, setSignatureSubmittedAt] = useState(null);

  // Editable fields for client name and date
  const [clientName, setClientName] = useState("");
  const [signingDate, setSigningDate] = useState("");

  const sigCanvas = useRef(null);
  const pdfIframeRef = useRef(null);

  useEffect(() => {
    // Capture userAgent & doc-viewed timestamp
    setUserAgentInfo(navigator.userAgent);
    setDocumentViewedAt(new Date().toISOString());

    const fetchSignatureRequestData = async () => {
      try {
        setLoading(true);
        const data = await getSignatureRequest(token);
        if (!data) {
          setError(
            "Failed to load the signature request. It may have expired or been completed."
          );
          return;
        }

        setProposalData(data);

        // If already signed on the server side, show the signed PDF immediately
        if (data.already_signed) {
          setPdfWithSignature(data.pdf_url);
        }

        // Pre-fill client name & date
        setClientName(data.client_name ?? "");
        setSigningDate(
          new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        );
      } catch (err) {
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.includes("expired")
        ) {
          setError(
            "The signing period for this proposal has expired. Please contact the sender for a new signature request."
          );
        } else {
          setError(
            `Failed to load the signature request: ${
              err.message || "Unknown error"
            }`
          );
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchSignatureRequestData();
    } else {
      setError("No signature token provided");
      setLoading(false);
    }
  }, [token]);

  const clearSignature = () => {
    if (sigCanvas.current?.clear) {
      sigCanvas.current.clear();
    }
  };

  /**
   * Example logic to automatically locate "Client Signature" text in the PDF (if desired).
   * Otherwise, you can skip this and just place the signature on the last page or
   * at known coordinates from your server.
   */
  const findSignaturePosition = async (pdfBytes) => {
    try {
      const loadingTask = pdfjsLib.getDocument({ data: pdfBytes });
      const pdfJsDoc = await loadingTask.promise;
      for (let pageNum = pdfJsDoc.numPages; pageNum >= 1; pageNum--) {
        const page = await pdfJsDoc.getPage(pageNum);
        const textContent = await page.getTextContent();
        const viewport = page.getViewport({ scale: 1.0 });
        const pageHeight = viewport.height;

        const signatureTexts = ["Client Signature"];
        for (let item of textContent.items) {
          for (let searchText of signatureTexts) {
            if (item.str.includes(searchText)) {
              // PDF.js coords -> PDF-lib coords
              const signatureX = item.transform[4];
              // Adjust Y as needed — note we add 270 for vertical offset
              const signatureY = pageHeight - item.transform[5] + 270;
              return {
                found: true,
                pageIndex: pageNum - 1,
                x: signatureX,
                y: signatureY,
                width: 100,
                height: 25,
              };
            }
          }
        }
      }
      return { found: false };
    } catch (err) {
      console.error("Error finding signature position:", err);
      return { found: false };
    }
  };

  const overlaySignatureOnPdf = async (signatureDataUrl) => {
    try {
      // Fetch original PDF
      const pdfResponse = await fetch(proposalData.pdf_url);
      const pdfBytes = await pdfResponse.arrayBuffer();

      // Attempt to find "Client Signature"
      const signaturePosition = await findSignaturePosition(pdfBytes);

      // Load with PDF-lib
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pages = pdfDoc.getPages();

      let targetPage, signatureX, signatureY, signatureWidth, signatureHeight;

      if (signaturePosition.found) {
        targetPage = pages[signaturePosition.pageIndex];
        signatureX = signaturePosition.x;
        signatureY = signaturePosition.y;
        signatureWidth = signaturePosition.width;
        signatureHeight = signaturePosition.height;
      } else {
        // Fallback to last page or explicit coords
        targetPage = pages[pages.length - 1];
        const { width, height } = targetPage.getSize();

        if (proposalData.signature_coordinates) {
          const coords = proposalData.signature_coordinates;
          signatureX = width * coords.x;
          signatureY = height * coords.y;
          signatureWidth = width * coords.width;
          signatureHeight = height * coords.height;
        } else {
          signatureWidth = 150;
          signatureHeight = 75;
          signatureX = width * 0.2;
          signatureY = height * 0.65;
        }
      }

      // Embed signature
      const signatureImage = await pdfDoc.embedPng(signatureDataUrl);
      targetPage.drawImage(signatureImage, {
        x: signatureX,
        y: signatureY,
        width: signatureWidth,
        height: signatureHeight,
      });

      // Add date and name
      targetPage.drawText(signingDate, {
        x: signatureX,
        y: signatureY - 80,
        size: 12,
      });
      targetPage.drawText(clientName, {
        x: signatureX,
        y: signatureY - 160,
        size: 12,
      });

      // Save + convert to data URL
      const modifiedPdfBytes = await pdfDoc.save();
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const dataUrl = URL.createObjectURL(blob);

      return dataUrl;
    } catch (err) {
      console.error("Error overlaying signature on PDF:", err);
      throw new Error("Failed to add signature to PDF");
    }
  };

  const handleSubmit = () => {
    if (!sigCanvas.current || sigCanvas.current.isEmpty()) {
      setToastMessage("Please provide a signature before submitting");
      setToastSeverity("error");
      setShowToast(true);
      return;
    }
    setConfirmDialogOpen(true);
  };

  const confirmSubmit = async () => {
    try {
      setConfirmDialogOpen(false);
      setLoading(true);

      // Extract signature as base64
      const signatureData = sigCanvas.current?.toDataURL("image/png") || null;
      if (!signatureData) {
        throw new Error("Could not get signature data from canvas");
      }

      // Capture final signature timestamp
      const signatureTime = new Date().toISOString();
      setSignatureSubmittedAt(signatureTime);

      // Overlays signature on the PDF
      const signedPdfUrl = await overlaySignatureOnPdf(signatureData);

      // We add "#page=9999" to ensure the PDF viewer scrolls to the last page
      setPdfWithSignature(signedPdfUrl + "#page=9999");

      // Convert data URL -> Blob -> File
      const response = await fetch(signedPdfUrl);
      const signedPdfBlob = await response.blob();
      const signedPdfFile = new File([signedPdfBlob], "signed_proposal.pdf", {
        type: "application/pdf",
      });

      // Submit to backend
      const result = await submitSignature({
        token,
        clientName,
        clientEmail: proposalData?.client_email,
        proposalId: proposalData?.proposal_id,
        signatureData,
        userAgentInfo,
        documentViewedAt,
        signatureSubmittedAt,
        signedPdfFile,
      });

      if (result) {
        // Show success dialog
        setSuccessDialogOpen(true);
        await addProposalLog({
          proposal_id: proposalData?.proposal_id,
          action: "approved",
          message: "Proposal approved by " + proposalData?.client_email,
        });
      } else {
        throw new Error("Failed to submit signature");
      }
    } catch (err) {
      setToastMessage(err.message || "Failed to submit signature");
      setToastSeverity("error");
      setShowToast(true);
      console.error("Error submitting signature:", err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" color="error" gutterBottom>
            Error
          </Typography>
          <Typography>{error}</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        {/* Header */}
        <Box
          sx={{ p: 3, bgcolor: "#f8f9fa", borderBottom: "1px solid #e9ecef" }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "#2c3e50", fontWeight: 600 }}
          >
            Sign Proposal
          </Typography>
          <Typography variant="body1">
            Hello {proposalData?.client_name}, please review and sign the
            proposal.
          </Typography>
        </Box>

        {/* Body */}
        <Grid container sx={{ minHeight: { xs: "auto", md: "85vh" } }}>
          {/* Left - PDF Viewer */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderRight: { md: "1px solid #e9ecef" },
              borderBottom: { xs: "1px solid #e9ecef", md: "none" },
              // Constrain height so it scrolls
              height: { xs: "50vh", md: "85vh" },
              overflow: "auto",
            }}
          >
            {/* If the PDF is signed, show the version with #page=9999, else show original */}
            <iframe
              ref={pdfIframeRef}
              src={pdfWithSignature || proposalData?.pdf_url}
              width="100%"
              height="100%"
              title="Proposal PDF"
              style={{ border: "none" }}
            />
          </Grid>

          {/* Right - Signature Area */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                height: { xs: "auto", md: "85vh" },
                boxSizing: "border-box",
              }}
            >
              {/* Show signature pad if pdfWithSignature is NOT set */}
              {!pdfWithSignature ? (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#2c3e50", fontWeight: 600 }}
                  >
                    Your Signature
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2, color: "#666" }}>
                    Please fill in your name and date, then sign in the box. By
                    signing, you agree to the terms and conditions.
                  </Typography>

                  {/* Client Name */}
                  <TextField
                    label="Client Name"
                    variant="outlined"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                  />

                  {/* Signing Date */}
                  <TextField
                    label="Date"
                    variant="outlined"
                    value={signingDate}
                    onChange={(e) => setSigningDate(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                  />

                  {/* Signature canvas */}
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      width: 350,
                      height: 200,
                      className: "signature-canvas",
                    }}
                    backgroundColor="#f8f9fa"
                  />

                  <Box sx={{ display: "flex", gap: 2, mb: 3, mt: 3 }}>
                    <Button
                      variant="outlined"
                      onClick={clearSignature}
                      sx={{
                        borderRadius: 1.5,
                        borderColor: "#6c757d",
                        color: "#6c757d",
                        "&:hover": {
                          borderColor: "#5a6268",
                          bgcolor: "rgba(108,117,125,0.04)",
                        },
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        borderRadius: 1.5,
                        bgcolor: "#4472C4",
                        flex: 1,
                        "&:hover": {
                          bgcolor: "#3a5ea3",
                        },
                      }}
                    >
                      Submit Signature
                    </Button>
                  </Box>

                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2" sx={{ color: "#6c757d" }}>
                    Please confirm your name and date before signing.
                  </Typography>
                </>
              ) : (
                // If pdfWithSignature is set, show "Thank you" + download
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    p: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#2c3e50", textAlign: "center", mb: 3 }}
                  >
                    Thank you for signing the proposal!
                  </Typography>

                  <Box
                    sx={{
                      bgcolor: "#e9f7ef",
                      p: 3,
                      borderRadius: 2,
                      mb: 4,
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: "#27ae60", textAlign: "center", mb: 2 }}
                    >
                      Your signature has been successfully applied to the
                      document.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#666", textAlign: "center" }}
                    >
                      A confirmation has been sent to your email for your
                      records.
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    component="a"
                    href={pdfWithSignature}
                    download="signed_proposal.pdf"
                    sx={{
                      borderRadius: 1.5,
                      bgcolor: "#4472C4",
                      px: 4,
                      py: 1.5,
                      "&:hover": {
                        bgcolor: "#3a5ea3",
                      },
                    }}
                    fullWidth
                  >
                    Download Signed Document
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Confirm Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <DialogTitle>Confirm Signature</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By submitting your signature, you agree to the terms and conditions
            outlined in the proposal. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setConfirmDialogOpen(false)}
            sx={{ color: "#6c757d" }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmSubmit}
            variant="contained"
            sx={{
              bgcolor: "#4472C4",
              "&:hover": {
                bgcolor: "#3a5ea3",
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <DialogTitle>Thank You!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your signature has been successfully submitted. The signed proposal
            is now displayed and available for download. A confirmation has been
            sent to your email for your records.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setSuccessDialogOpen(false)}
            variant="contained"
            sx={{
              bgcolor: "#4472C4",
              "&:hover": {
                bgcolor: "#3a5ea3",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Toast */}
      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastSeverity}
          sx={{ width: "100%", borderRadius: 1.5 }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignaturePage;
