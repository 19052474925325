import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Link,
  styled,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { forgotUsername } from '../../services/userService';
import AlertMessage from '../AlertMessage';
import { ReactComponent as IllustrationIcon } from '../../static/Icons/illustration.svg';
import { ReactComponent as LogoHolder } from '../../static/Icons/logo.svg';

/** -------------
 * Styled Components
 * -------------
 */
const AuthenticationPageFrame = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
}));

const IllustrationFrame = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 2,
  background: theme.palette.neutrals.backgroundPrimary,
  flexDirection: 'column',
}));

const LogoFrame = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-start',
  alignItems: 'center',
  flex: 1,
  marginLeft: '2rem',
}));

const LogoTextFrame = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.3rem',
}));

const IllustrationIconFrame = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 12,
}));

const WelcomeFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '2rem 2rem',
}));

const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // flex: 1,
  width: '100%',
  padding: '2rem 2rem',

}));

const InputFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TextInputFrame = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '3.02144rem',
    background: theme.palette.neutrals.background.input2,
    borderRadius: '0.94419rem',
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '0.69244rem 1.00713rem',
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.neutrals.background.input2} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
}));

const SubmitButtonFrame = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
}));

/** -------------
 * ForgotUsername Component
 * -------------
 */
function ForgotUsername() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotUsername = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await forgotUsername(email);
      setMessage(
        response.message ||
          'If an account with that email exists, a reset link has been sent.'
      );
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to send link. Please try again.');
    }
  };

  return (
    <AuthenticationPageFrame>
      {/* Left Illustration Section */}
      <IllustrationFrame>
        <LogoFrame>
          <LogoHolder />
          <LogoTextFrame>
            <Typography variant="logo">assembli</Typography>
          </LogoTextFrame>
        </LogoFrame>
        <IllustrationIconFrame>
          <IllustrationIcon />
        </IllustrationIconFrame>
      </IllustrationFrame>

      {/* Right Form Section */}
      <WelcomeFrame>
        <Typography
          variant="welcometext"
          align="center"
          sx={{ color: 'typographyPrimary.main' }}
        >
          Forgot Username
        </Typography>
        <Typography
          variant="logintext"
          align="center"
          sx={{ color: 'typographySecondary.main' }}
          my={1}
        >
          Please enter your email to receive a username reset link.
        </Typography>

        <FormFrame>
          <form onSubmit={handleForgotUsername}>
            <InputFrame>
              <Typography variant="body2medium16" sx={{ mb: 1 }}>
                Email
              </Typography>
              <TextInputFrame
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputFrame>

            <SubmitButtonFrame type="submit" variant="contained" color="primary">
              Send Reset Link
            </SubmitButtonFrame>
          </form>
        </FormFrame>

        {/* Alert Messages */}
        {error && (
          <AlertMessage
            message={error}
            type="error"
            onClose={() => setError('')}
          />
        )}
        {message && (
          <AlertMessage
            message={message}
            type="success"
            onClose={() => setMessage('')}
          />
        )}

        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          <Link
            href="/"
            underline="hover"
            style={{ color: '#1565c0', textDecorationColor: '#1565c0' }}
          >
            Back to Login
          </Link>
        </Typography>
      </WelcomeFrame>
    </AuthenticationPageFrame>
  );
}

export default ForgotUsername;
