import { LicenseInfo } from "@mui/x-data-grid-premium";
// Set the MUI Premium license key from the environment variable
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import posthog from "posthog-js";
import { Provider } from "react-redux";
import { PostHogProvider } from "posthog-js/react";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

// 1) Roboto (all the weights you need)
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// 2) Montserrat (for headings) with the weights you use
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

// 3) Any local fonts & custom faces
// import "./App.css";

(async () => {
  const state = store.getState();
  const username = state.auth?.loggedInUsername || "anonymous";

  // 2) Initialize LaunchDarkly
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    context: {
      kind: "user",
      key: username,
    },
  });

  const posthogClient = posthog.init(process.env.REACT_APP_POSTHOG_CLIENT_ID, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Posthog debugging mode");
        posthog.debug();
      }
    },
  });

  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LDProvider>
              <PostHogProvider client={posthogClient}>
                <App />
              </PostHogProvider>
            </LDProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>,
  );
})();
