import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const ProjectOverviewEditor = ({ onSave, data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);

  // Triggered when user clicks "Save"
  const handleSubmit = () => {
    setIsEditing(false);
    if (onSave) {
      onSave(formData);
    }
  };

  // Update top-level fields like visionStatement
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Update a specific item within an array field (e.g., keyDeliverables)
  const handleListItemChange = (field, index, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].map((item, i) => (i === index ? value : item)),
    }));
  };

  // Add a new item to an array field
  const handleAddListItem = (field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: [...prev[field], "New Item"],
    }));
  };

  // Remove an item from an array field
  const handleRemoveListItem = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* Sticky Header for Edit/Save */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      {/* Scrollable Content */}
      <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
        {/* Vision Statement */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Vision Statement
          </Typography>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={3}
              value={formData.visionStatement}
              onChange={(e) =>
                handleInputChange("visionStatement", e.target.value)
              }
            />
          ) : (
            <Typography>{formData.visionStatement}</Typography>
          )}
        </Box>

        {/* Key Deliverables */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Key Deliverables
          </Typography>
          <List>
            {formData.keyDeliverables.map((deliverable, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleRemoveListItem("keyDeliverables", index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={deliverable}
                    onChange={(e) =>
                      handleListItemChange(
                        "keyDeliverables",
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Typography>• {deliverable}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("keyDeliverables")}
              sx={{ mt: 1 }}
            >
              Add Deliverable
            </Button>
          )}
        </Box>

        {/* Two-Column Layout: Design Style & Special Requirements */}
        <Box sx={{ display: "flex", gap: 4 }}>
          {/* Design Style */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom color="primary">
              Design Style
            </Typography>
            <List>
              {formData.designStyle.map((style, index) => (
                <ListItem
                  key={index}
                  sx={{ py: 0 }}
                  secondaryAction={
                    isEditing && (
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() =>
                          handleRemoveListItem("designStyle", index)
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    )
                  }
                >
                  {isEditing ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={style}
                      onChange={(e) =>
                        handleListItemChange(
                          "designStyle",
                          index,
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    <Typography>• {style}</Typography>
                  )}
                </ListItem>
              ))}
            </List>
            {isEditing && (
              <Button
                startIcon={<AddIcon />}
                onClick={() => handleAddListItem("designStyle")}
                sx={{ mt: 1 }}
              >
                Add Style
              </Button>
            )}
          </Box>

          {/* Special Requirements */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom color="primary">
              Special Requirements
            </Typography>
            <List>
              {formData.specialRequirements.map((req, index) => (
                <ListItem
                  key={index}
                  sx={{ py: 0 }}
                  secondaryAction={
                    isEditing && (
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() =>
                          handleRemoveListItem("specialRequirements", index)
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    )
                  }
                >
                  {isEditing ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={req}
                      onChange={(e) =>
                        handleListItemChange(
                          "specialRequirements",
                          index,
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    <Typography>• {req}</Typography>
                  )}
                </ListItem>
              ))}
            </List>
            {isEditing && (
              <Button
                startIcon={<AddIcon />}
                onClick={() => handleAddListItem("specialRequirements")}
                sx={{ mt: 1 }}
              >
                Add Requirement
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectOverviewEditor;
