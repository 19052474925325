import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { useNavigate } from "react-router-dom"; // If using react-router v6
import { PDFViewer } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import { Document, Page, View, StyleSheet, Text } from "@react-pdf/renderer";
import { useSelector, useDispatch } from "react-redux";
import { addProposalLog } from "../../services/proposalService";
import {
  updateProposalData,
  setproposalName,
  resetProposalData,
} from "../../store";
import SectionHeader from "./PdfComponents/SectionHeaderPdf";
import SignaturePage from "./PdfComponents/SignaturePdf";
import {
  updateProposal,
  proposal_inuse_toggle,
  sendProposalEmail,
  sendSignatureRequest,
} from "../../services/proposalService";
import { getEditor } from "./EditorRegistry";
import { getTakeoffData } from "../../services/jobService";
import { usePrompt } from "./usePrompt";
import Sidebar from "../Sidebar/Sidebar";
import TopProgress from "../TopProgress/TopProgress";
import cloneDeep from "lodash/cloneDeep";
import structuredClone from "@ungap/structured-clone";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  DialogContentText,
  Alert,
} from "@mui/material";
import { PDFDocument } from "pdf-lib";

import {
  CheckCircle,
  Add as AddIcon,
  Description as FileTextIcon,
  AttachMoney as DollarSignIcon,
  Build as HammerIcon,
  Balance as ScaleIcon,
  People as UsersIcon,
  Event as CalendarIcon,
  Message as MessageSquareIcon,
  DragIndicator as GripVerticalIcon,
  Visibility as EyeIcon,
  VisibilityOff as EyeOffIcon,
  Error as AlertCircleIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { fetchCompanyImage } from "../../services/companyService";
import { getSignedUrl } from "../../services/uploadService";

import { ReactComponent as DragLargeIcon } from "../../static/Icons/dragLarge.svg";
import { ReactComponent as DragSmallIcon } from "../../static/Icons/dragSmall.svg";
import { ReactComponent as ArrowDownIcon } from "../../static/Icons/arrowDown.svg";
import { ReactComponent as SettingsIcon } from "../../static/Icons/settings.svg";

import { set } from "lodash";
import { posthog } from "posthog-js";

//styled componnets
const CustomStyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    display: "flex",
    width: "65.1875rem",
    padding: "1.5625rem",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    // gap: "2.5rem",
    borderRadius: "2.1875rem",
    background: "var(--Nuetrals-Background---Tertiary, #FFF)",
    boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
  },
}));

//Frame 2898 for drag and drop component
const ProposalBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.0625rem",
  alignSelf: "stretch",
}));

const ProposalDivisionBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "0rem 0rem 0.8125rem 0.8125rem",
}));

const ProposalDivisionTitleBox = styled(Box)(() => ({
  display: "flex",
  height: "4rem",
  padding: "0.875rem 0.9375rem",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  // borderRadius: "0.8125rem 0.8125rem 0rem 0rem",
  borderRadius: "50rem",
  background: "var(--Nuetrals-Background---Tertiary, #FFF)",
}));

const ProposalDivisonIconAndTitle = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.625rem",
  alignSelf: "stretch",
}));

const BoxForArrow = styled(Box)(() => ({
  borderRadius: "1.8125rem",
  background: "var(--Nuetrals-Background---Secondary, #E7E7DF)",
  display: "flex",
  padding: "0.3125rem",
  alignItems: "center",
  alignContent: "center",
  gap: "0.375rem",
  flexWrap: "wrap",
}));

const ArrowDownIconStyled = styled(ArrowDownIcon)(() => ({
  display: "flex",
  width: "0.90931rem",
  height: "0.90931rem",
  justifyContent: "center",
  alignItems: "center",
}));

const ArrowUpIconStyled = styled(ArrowDownIcon)(() => ({
  display: "flex",
  width: "0.90931rem",
  height: "0.90931rem",
  justifyContent: "center",
  alignItems: "center",
  transform: "rotate(180deg)",
}));

const ProposalDivisionContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.375rem",
  alignSelf: "stretch",
  background: "var(--Nuetrals-Background---Primary, #F3F3F1)",
  borderRadius: "0rem 0rem 0.8125rem 0.8125rem",
  borderRight: "2px solid var(--Nuetrals-Background---Tertiary, #FFF)",
  borderBottom: "2px solid var(--Nuetrals-Background---Tertiary, #FFF)",
  borderLeft: "2px solid var(--Nuetrals-Background---Tertiary, #FFF)",
}));

const ProposalDivisionContentSubdivisionBox = styled(Box)(() => ({
  display: "flex",
  padding: "1.125rem 0.9375rem 0.5rem 0.9375rem",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderBottom: "1px solid #E7E7DF",
}));

//Fram 2787
const ProposalDivisionContentSubdivisionFrame = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  gap: "1.625rem",
}));

const buildProposalJson = ({
  sectionsState,
  sectionOrder,
  itemOrder,
  selectedItems,
}) => {
  // Create a new sectionsState with string-based icons
  const serializedSectionsState = Object.entries(sectionsState).reduce(
    (acc, [key, section]) => ({
      ...acc,
      [key]: {
        ...section,
        icon: SECTION_ICON_MAP[key], // Store the icon identifier string
      },
    }),
    {}
  );

  return {
    sectionsState: serializedSectionsState,
    sectionOrder,
    itemOrder,
    selectedItems,
  };
};

// Icon name to actual React component
const ICON_MAP = {
  users: UsersIcon,
  settings: SettingsIcon,
  fileText: FileTextIcon,
  dollar: DollarSignIcon,
  hammer: HammerIcon,
  scale: ScaleIcon,
  message: MessageSquareIcon,
};

// Map each section key to a string icon name
const SECTION_ICON_MAP = {
  company: "users",
  scope: "hammer",
  technical: "settings",
  financial: "dollar",
  legal: "scale",
  service: "message",
};

// Inactivity time set to 5 minutes (in milliseconds)
const IDLE_TIMEOUT = 5 * 60 * 1000; // 300,000 ms

// ---- PDF Page Styles ----
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "#FFFFFF",
  },
  section: {
    marginBottom: 10,
  },
  signatureSection: {
    marginTop: 30,
    marginBottom: 20,
    padding: 10,
    borderTop: "1px solid #000",
  },
  signatureText: {
    fontSize: 12,
    marginBottom: 20,
    fontFamily: "Helvetica",
  },
});

function ProposalPages({
  itemOrder,
  selectedItems,
  sectionsState,
  sectionOrder,
  getEditor,
  proposalData,
  snapshotData,
  logoUrl,
}) {
  // 1. Group items by their section
  const itemsBySection = itemOrder.reduce((acc, item) => {
    if (!acc[item.sectionId]) {
      acc[item.sectionId] = [];
    }
    acc[item.sectionId].push(item);
    return acc;
  }, {});

  // 2. Return a single <Page> as root, just like PDF_Document (but without <Document>)
  return (
    <Page size="A4" style={styles.page}>
      {sectionOrder.map((sectionId) => {
        const sectionItems = itemsBySection[sectionId] || [];
        const section = sectionsState[sectionId];

        // If the section is missing, skip it
        if (!section) return null;

        // Filter to only the items that the user has chosen
        const selectedSectionItems = sectionItems.filter(
          ({ itemId }) => selectedItems[`${sectionId}-${itemId}`]
        );

        // If no items are selected in this section, skip it
        if (selectedSectionItems.length === 0) return null;

        return (
          <View key={sectionId} style={styles.sectionContainer}>
            {/* Optional: Render a section header if it's not a "coverpage" */}
            {sectionId !== "coverpage" && (
              <SectionHeader title={section.title || "Section Title"} />
            )}

            {/* Render each selected item for this section */}
            {selectedSectionItems.map(({ itemId }) => {
              const item = section.items.find((i) => i.id === itemId);
              const editorData = getEditor(
                sectionId,
                itemId,
                proposalData,
                snapshotData
              );

              if (!editorData || !item) return null;

              const PDFContent = editorData.pdfContent;
              let data = editorData.data;
              if (sectionId === "coverpage" && itemId === "header") {
                data = {
                  ...data,
                  companyInfo: {
                    ...data.companyInfo,
                    logo: logoUrl, // Safely "updating" the logo field
                  },
                };
              }

              // If the editor provides a PDF component, render it
              return (
                PDFContent && (
                  <View key={`${sectionId}-${itemId}`} style={styles.section}>
                    <PDFContent {...(data || {})} />
                  </View>
                )
              );
            })}
          </View>
        );
      })}
    </Page>
  );
}

const ProposalBuilderInterface = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // for programmatic routing

  // ---- Retrieve from Redux ----
  const proposalId = useSelector((state) => state.takeoffData.proposalId);
  const proposalName = useSelector((state) => state.takeoffData.proposalName);
  const proposalDataFromRedux = useSelector((state) => state.proposalData.data);
  const [proposal_data, setProposalData] = useState(proposalDataFromRedux);
  // const proposal_data = proposalDataFromRedux;
  const snapshot_data = useSelector((state) => state.snapshotData.data);
  // console.log("Snapshot data", snapshot_data);
  const job_id = useSelector((state) => state.takeoffData.jobId);

  useEffect(() => {
    if (!job_id) return;
    // Clear any previous takeoff data and table data immediately when the job changes

    getTakeoffData(job_id).then((res) => {
      if (!res?.error && res !== null) {
        // console.log("Proposal data", proposal_data);
        // console.log("Fetched takeoff:", res);

        // Create a new object instead of mutating proposal_data directly
        const updatedProposalData = {
          ...proposal_data,
          takeOff: res,
        };

        // setProposalData(updatedProposalData);

        // Dispatch your actions with the new object
        dispatch(resetProposalData());
        dispatch(updateProposalData(updatedProposalData));
        // console.log("Proposal data", updatedProposalData);
      } else {
        console.log("Error fetching takeoff:", res);
      }
    });
  }, []); // Don't forget to include job_id as a dependency!

  // ---- Extract any previously saved ordering from proposal_data ----
  const {
    sectionsState: savedSectionsState,
    sectionOrder: savedSectionOrder,
    itemOrder: savedItemOrder,
    selectedItems: savedSelectedItems,
  } = proposal_data.ordering || {};

  // Helper to get icon component from section key
  const getIconComponent = (sectionKey) => {
    const iconName = SECTION_ICON_MAP[sectionKey];
    return ICON_MAP[iconName] || SettingsIcon;
  };

  // ---- Default sectionsState if none was saved yet ----
  const defaultSectionsState = {
    coverpage: {
      icon: "users",
      title: "Cover Page",
      items: [
        {
          id: "header",
          title: "Header",
          description: "Heading information",
        },
      ],
    },
    company: {
      icon: "users",
      title: "Company Information",
      items: [
        {
          id: "overview",
          title: "Company Overview",
          description: "Background, history, and achievements",
        },
        {
          id: "license",
          title: "License & Insurance",
          description: "Certifications and coverage details",
        },
        // {
        //   id: "portfolio",
        //   title: "Portfolio",
        //   description: "Past projects and testimonials",
        // },
      ],
    },
    scope: {
      icon: "hammer",
      title: "Project Scope",
      items: [
        {
          id: "overview",
          title: "Project Overview",
          description: "Description and objectives",
        },
        // {
        //   id: "timeline",
        //   title: "Construction Timeline",
        //   description: "Schedule and milestones",
        // },
        {
          id: "materials",
          title: "Materials & Finishes",
          description: "Specifications and selections",
        },
      ],
    },
    technical: {
      icon: "settings",
      title: "Technical Specifications",
      items: [
        {
          id: "foundation",
          title: "Foundation Details",
          description: "Foundation details",
        },
        // {
        //   id: "structural",
        //   title: "",
        //   description: "Foundation and framing specs",
        // },
        {
          id: "systems",
          title: "Building Systems",
          description: "HVAC, electrical, fire protection, and plumbing",
        },
        // {
        //   id: "sustainability",
        //   title: "Energy Efficiency",
        //   description: "Green building features",
        // },
      ],
    },
    financial: {
      icon: "dollar",
      title: "Financial Details",
      items: [
        {
          id: "costs",
          title: "Cost Breakdown",
          description: "Detailed pricing structure",
        },
        // {
        //   id: "payment",
        //   title: "Payment Schedule",
        //   description: "Timeline and terms",
        // },
        {
          id: "allowances",
          title: "Allowances",
          description: "Budget allocations",
        },
      ],
    },
    legal: {
      icon: "scale",
      title: "Legal & Compliance",
      items: [
        {
          id: "contract",
          title: "Legal and Compliance Overview",
          description: "Agreement details",
        },
        // {
        //   id: "warranty",
        //   title: "Warranty Information",
        //   description: "Coverage and terms",
        // },
        // {
        //   id: "permits",
        //   title: "Permits & Approvals",
        //   description: "Required documentation",
        // },
      ],
    },
    // service: {
    //   icon: "message",
    //   title: "Customer Service",
    //   items: [
    //     {
    //       id: "communication",
    //       title: "Communication Plan",
    //       description: "Contact protocols",
    //     },
    //     {
    //       id: "support",
    //       title: "Support Services",
    //       description: "During and after construction",
    //     },
    //     {
    //       id: "quality",
    //       title: "Quality Assurance",
    //       description: "Standards and inspections",
    //     },
    //   ],
    // },
  };

  // ---- Default order of sections if none was saved ----
  const defaultSectionOrder = Object.keys(defaultSectionsState);

  // ---- Default item order for all sections if none was saved ----
  const buildDefaultItemOrder = () => {
    const arr = [];
    defaultSectionOrder.forEach((sectionKey) => {
      defaultSectionsState[sectionKey].items.forEach((item) => {
        arr.push({ sectionId: sectionKey, itemId: item.id });
      });
    });
    return arr;
  };

  // ---- Local state initialization ----
  const [sectionsState, setSectionsState] = useState(() => {
    if (savedSectionsState) {
      // Convert saved state to proper format
      return Object.entries(savedSectionsState).reduce(
        (acc, [key, section]) => ({
          ...acc,
          [key]: {
            ...section,
            icon: SECTION_ICON_MAP[key], // map to the icon we use
          },
        }),
        {}
      );
    }
    return defaultSectionsState;
  });
  //for Proposal Name
  const [name, setName] = useState(proposalName);

  const [showInstructions, setShowInstructions] = useState(true);

  const [sectionOrder, setSectionOrder] = useState(
    savedSectionOrder || defaultSectionOrder
  );

  const [itemOrder, setItemOrder] = useState(
    savedItemOrder || buildDefaultItemOrder()
  );

  // 5) Decide which items are checked
  // Only if there's NO previously saved items do we default-check cost breakdown:
  const isFirstTime =
    !savedSelectedItems || Object.keys(savedSelectedItems).length === 0;

  // Start with whatever was saved, or an empty object if none
  const defaultCheckedItems = savedSelectedItems
    ? { ...savedSelectedItems }
    : {};

  // If first time and no prior selection, default "financial-costs" to true
  if (isFirstTime) {
    defaultCheckedItems["financial-costs"] = true;
  }

  const [selectedItems, setSelectedItems] = useState(defaultCheckedItems);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [showEmailToast, setShowEmailToast] = useState(false);
  const [emailToastMessage, setEmailToastMessage] = useState("");
  const [emailToastSeverity, setEmailToastSeverity] = useState("success");

  // Signature request state
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [showSignatureToast, setShowSignatureToast] = useState(false);
  const [signatureToastMessage, setSignatureToastMessage] = useState("");
  const [signatureToastSeverity, setSignatureToastSeverity] =
    useState("success");

  const [logoUrl, setLogoUrl] = useState(null);
  useEffect(() => {
    const fetchLogoUrl = async () => {
      const data = await fetchCompanyImage();
      if (proposal_data["Header_Editor"]?.companyInfo?.logo) {
        try {
          const url = await getSignedUrl(
            proposal_data["Header_Editor"].companyInfo.logo
          );

          setLogoUrl(url.url);
        } catch (err) {
          setLogoUrl(null);
        }
      } else if (data.logo) {
        setLogoUrl(data.logo);
      } else {
        setLogoUrl(
          "https://www.shutterstock.com/image-vector/image-icon-trendy-flat-style-600nw-643080895.jpg"
        );
      }
    };

    fetchLogoUrl();
    //
  }, [proposal_data]);

  // ---- Expand/Collapse Section logic ----
  const [expandedSections, setExpandedSections] = useState({
    coverpage: true,
    company: true,
    scope: true,
    technical: true,
    financial: true,
    legal: true,
    service: true,
  });

  // --- Track if there are unsaved changes ---
  const [isDirty, setIsDirty] = useState(false);
  const { showDialog, confirmNavigation, cancelNavigation, message } =
    usePrompt(
      "You have unsaved changes. Are you sure you want to leave?",
      isDirty
    );

  // --- Warn on tab close/refresh if isDirty is true ---
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  // --- Snackbar (toast) for successful save ---
  const [showToast, setShowToast] = useState(false);

  // ----------------------------------------------------------------
  // 1) Setup a timer for inactivity detection (5 minutes)
  // ----------------------------------------------------------------
  const lastActivityRef = useRef(Date.now());
  const idleTimerRef = useRef(null);

  const resetInactivityTimer = useCallback(() => {
    lastActivityRef.current = Date.now(); // <-- no re-render
  }, []);

  // Whenever user does anything, reset the timer
  useEffect(() => {
    const events = ["mousemove", "mousedown", "keydown", "touchstart"];
    const handleActivity = () => resetInactivityTimer();

    events.forEach((evt) => window.addEventListener(evt, handleActivity));
    return () => {
      events.forEach((evt) => window.removeEventListener(evt, handleActivity));
    };
  }, [resetInactivityTimer]);

  // Periodically check if user has been idle for >= IDLE_TIMEOUT
  useEffect(() => {
    idleTimerRef.current = setInterval(() => {
      if (Date.now() - lastActivityRef.current >= IDLE_TIMEOUT) {
        handleUserInactivity();
      }
    }, 10000);

    return () => clearInterval(idleTimerRef.current);
  }, []);

  // This function runs after 5 minutes of inactivity
  const handleUserInactivity = async () => {
    try {
      // 1. Save the proposal if isDirty or you want guaranteed state
      if (isDirty) {
        await handleSaveDraft(); // reuse existing save logic
      }
      // 2. Mark the proposal as inactive so other users can edit
      await proposal_inuse_toggle({ id: proposalId, untoggle: true });

      // 3. Redirect user to "proposal_dashboard"
      navigate("/proposal");
    } catch (error) {
      console.error("Error auto-saving and marking inactive:", error);
      // Optionally still redirect
      navigate("/proposal");
    }
  };

  // ----------------------------------------------------------------
  // 2) On unmount / tab close / route change, mark proposal inactive
  // ----------------------------------------------------------------
  useEffect(() => {
    const handleBeforeUnload = async (e) => {
      // Mark proposal as inactive
      try {
        await proposal_inuse_toggle({ id: proposalId, untoggle: true });
      } catch (err) {
        console.error("Failed to mark proposal inactive on unload:", err);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Called when user navigates away from this route or closes the component
      (async () => {
        try {
          await proposal_inuse_toggle({ id: proposalId, untoggle: true });
        } catch (err) {
          if (err.response && err.response.status === 401) {
            // Ignore the 401 Unauthorized error
            console.warn("Ignored 401 Unauthorized error on unmount.");
          } else {
            console.error("Failed to mark proposal inactive on unmount:", err);
          }
        }
      })();

      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [proposalId]);

  // On reload or component Mount, mark proposal as active
  useEffect(() => {
    // On mount, mark it active
    const markActive = async () => {
      try {
        await proposal_inuse_toggle({ id: proposalId, untoggle: false });
      } catch (err) {
        console.error("Failed to mark proposal active on mount:", err);
      }
    };

    markActive();
  }, [proposalId]);

  // ------------------------------------------------
  // *** Automatically open Cost Breakdown on mount ***
  // ------------------------------------------------
  useEffect(() => {
    // This will pop up the "Cost Breakdown" editor as soon as the component loads
    console.log("Opening Cost Breakdown dialog on mount");
    setSelectedSection({ sectionId: "financial", itemId: "costs" });
    setOpenDialog(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -----------------------------------------------
  // handleSaveDraft - existing logic to save changes
  // -----------------------------------------------
  const handleSaveDraft = async () => {
    try {
      // Build the current state of the proposal
      const currentProposalData = structuredClone({
        ...proposal_data,
        ordering: buildProposalJson({
          sectionsState,
          sectionOrder,
          itemOrder,
          selectedItems,
        }),
      });

      // Update Redux
      dispatch(updateProposalData(currentProposalData));

      // Persist to backend
      await updateProposal({
        id: proposalId,
        proposal_data: currentProposalData,
      });

      // Show success toast
      setShowToast(true);
      // Reset dirty state
      setIsDirty(false);
    } catch (error) {
      console.error("Error saving draft:", error);
    }
  };

  const [editMode, setEditMode] = useState(false);

  const handleCancel = () => {
    setEditMode(false);
  };

  // ---- Dragging states (SECTION vs ITEM) ----
  const [draggedItem, setDraggedItem] = useState(null); // For a SECTION
  const [draggedSubItem, setDraggedSubItem] = useState(null); // For an ITEM

  // ---- PDF Preview toggle, dialog states, etc. ----
  const [showPreview, setShowPreview] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  // ---- PDF Document for Preview ----
  const PDF_Document = ({
    itemOrder,
    selectedItems,
    sectionsState,
    getEditor,
    sectionOrder,
    logoUrl,
  }) => {
    const itemsBySection = itemOrder.reduce((acc, item) => {
      if (!acc[item.sectionId]) {
        acc[item.sectionId] = [];
      }
      acc[item.sectionId].push(item);
      return acc;
    }, {});
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {sectionOrder.map((sectionId) => {
            const sectionItems = itemsBySection[sectionId] || [];
            const section = sectionsState[sectionId];

            if (!section) return null;

            // Filter items that are actually selected
            const selectedSectionItems = sectionItems.filter(
              ({ itemId }) => selectedItems[`${sectionId}-${itemId}`]
            );

            // Only render section if it has selected items
            if (selectedSectionItems.length === 0) return null;

            return (
              <View key={sectionId}>
                {/* Render section header */}
                {sectionId !== "coverpage" && (
                  <SectionHeader title={section.title || "Section Title"} />
                )}

                {/* Render section items */}
                {selectedSectionItems.map(({ itemId }) => {
                  const item = section.items.find((i) => i.id === itemId);
                  // console.log("in calling get editor", snapshot_data);
                  const editorData = getEditor(
                    sectionId,
                    itemId,
                    proposal_data,
                    snapshot_data
                  );

                  if (!editorData || !item) return null;

                  const PDFContent = editorData.pdfContent;
                  let data = editorData.data;
                  if (sectionId === "coverpage" && itemId === "header") {
                    // console.log("Header data", data);
                    // console.log("logoUrl", logoUrl);
                    data = {
                      ...data,
                      companyInfo: {
                        ...data.companyInfo,
                        logo: logoUrl, // Safely "updating" the logo field
                      },
                    };
                  }
                  // console.log(sectionId, itemId, data);

                  return (
                    PDFContent && (
                      <View
                        key={`${sectionId}-${itemId}`}
                        style={styles.section}
                      >
                        <PDFContent {...(data || {})} />
                      </View>
                    )
                  );
                })}
              </View>
            );
          })}
        </Page>
      </Document>
    );
  };

  const memoizedPDFDocument = useMemo(() => {
    return (
      <PDF_Document
        itemOrder={itemOrder}
        selectedItems={selectedItems}
        sectionsState={sectionsState}
        sectionOrder={sectionOrder}
        getEditor={getEditor}
        logoUrl={logoUrl}
      />
    );
    // Put only the props that affect the PDF output in the dependency array
  }, [
    itemOrder,
    selectedItems,
    sectionsState,
    sectionOrder,
    proposal_data,
    logoUrl,
  ]);

  // ---- Dialog Logic ----
  const handleOpenDialog = (sectionId, itemId) => {
    setSelectedSection({ sectionId, itemId });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSection(null);
  };

  // ---- Expand/Collapse a section ----
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // ---- DRAG & DROP HANDLERS ----
  const handleDragStart = (e, sectionId, itemId = null) => {
    // Mark data as dirty because user is reordering
    setIsDirty(true);

    if (itemId) {
      setDraggedSubItem({ sectionId, itemId });
      e.stopPropagation(); // so parent section drag won't trigger
    } else {
      setDraggedItem(sectionId);
    }
  };

  const handleDragOver = (e, sectionId, itemId = null) => {
    e.preventDefault();

    // ITEM Drag
    if (draggedSubItem && itemId) {
      if (draggedSubItem.itemId === itemId) return;

      const newSectionsState = { ...sectionsState };
      const draggedSection = draggedSubItem.sectionId;
      const draggedItemObj = newSectionsState[draggedSection].items.find(
        (it) => it.id === draggedSubItem.itemId
      );

      // Reorder the global itemOrder array
      const newItemOrder = [...itemOrder];
      const fromIndex = newItemOrder.findIndex(
        (io) =>
          io.sectionId === draggedSection && io.itemId === draggedSubItem.itemId
      );
      const toIndex = newItemOrder.findIndex(
        (io) => io.sectionId === sectionId && io.itemId === itemId
      );
      const [removed] = newItemOrder.splice(fromIndex, 1);
      newItemOrder.splice(toIndex, 0, removed);
      setItemOrder(newItemOrder);

      // If dragging within the same section
      if (draggedSection === sectionId) {
        const items = [...newSectionsState[sectionId].items];
        const drgIndex = items.findIndex(
          (it) => it.id === draggedSubItem.itemId
        );
        const dropIndex = items.findIndex((it) => it.id === itemId);

        items.splice(drgIndex, 1);
        items.splice(dropIndex, 0, draggedItemObj);

        newSectionsState[sectionId].items = items;
        setSectionsState(newSectionsState);
      } else {
        // Dragging between different sections
        const sourceItems = [...newSectionsState[draggedSection].items];
        const targetItems = [...newSectionsState[sectionId].items];

        const drgIndex = sourceItems.findIndex(
          (it) => it.id === draggedSubItem.itemId
        );
        const dropIndex = targetItems.findIndex((it) => it.id === itemId);

        sourceItems.splice(drgIndex, 1);
        targetItems.splice(dropIndex, 0, draggedItemObj);

        newSectionsState[draggedSection].items = sourceItems;
        newSectionsState[sectionId].items = targetItems;
        setSectionsState(newSectionsState);
      }
    }
    // SECTION Drag
    else if (draggedItem && !itemId) {
      if (draggedItem === sectionId) return;

      const newOrder = [...sectionOrder];
      const fromIndex = newOrder.indexOf(draggedItem);
      const toIndex = newOrder.indexOf(sectionId);

      newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, draggedItem);
      setSectionOrder(newOrder);

      // Also reorder itemOrder for that entire section
      const newItemOrder = [...itemOrder];
      const draggedSectionItems = newItemOrder.filter(
        (io) => io.sectionId === draggedItem
      );
      const remaining = newItemOrder.filter(
        (io) => io.sectionId !== draggedItem
      );

      // Insert them before the first item of the target section
      const insertionIndex = remaining.findIndex(
        (io) => io.sectionId === sectionId
      );

      if (insertionIndex >= 0) {
        remaining.splice(insertionIndex, 0, ...draggedSectionItems);
      } else {
        remaining.push(...draggedSectionItems);
      }

      setItemOrder(remaining);
    }
  };

  /**
   * handleDragEnd
   * We finalize the new order and persist to Redux + backend.
   */
  const handleDragEnd = async () => {
    setDraggedItem(null);
    setDraggedSubItem(null);

    // Build new JSON
    const newJsonData = buildProposalJson({
      sectionsState,
      sectionOrder,
      itemOrder,
      selectedItems,
    });

    const newProposalData = cloneDeep(proposal_data);
    newProposalData.ordering = newJsonData;

    dispatch(updateProposalData(newProposalData));

    try {
      await updateProposal({
        id: proposalId,
        proposal_data: {
          ...proposal_data,
          ordering: newJsonData,
        },
      });
    } catch (error) {
      console.error("Error updating proposal:", error);
    }
  };

  //handle click of update proposal name
  const handleUpdate = async () => {
    const res = await updateProposal({ id: proposalId, proposal_name: name });
    if (res) {
      dispatch(setproposalName(name));
    }

    setEditMode(false);
  };

  //Share Button click
  const handleSendEmail = async () => {
    try {
      // 1) Generate PDF Blob from your memoized PDF document
      const blob = await pdf(memoizedPDFDocument).toBlob();

      // 2) Use your sendProposalEmail service
      const result = await sendProposalEmail(shareEmail, blob);
      if (!result) {
        throw new Error("Failed to send email");
      }

      posthog.capture("proposal:proposal_sent", {
        job_id: job_id,
        proposal_data: blob,
        is_internal_user: false,
      });

      // 3) Show success toast
      setEmailToastSeverity("success");
      setEmailToastMessage("Email sent successfully!");
      setShowEmailToast(true);

      // Clear dialog & email field
      setShareDialogOpen(false);
      setShareEmail("");
    } catch (error) {
      console.error("Error sending email:", error);
      setShareDialogOpen(false);

      // 4) Show failure toast
      setEmailToastSeverity("error");
      setEmailToastMessage(error?.message || "Something went wrong.");
      setShowEmailToast(true);
    }
  };

  const handleSendSignatureRequest = async () => {
    try {
      const pdfDocWithSignature = (
        <Document>
          {/* Include all pages from the memoized document */}
          <ProposalPages
            itemOrder={itemOrder}
            selectedItems={selectedItems}
            sectionsState={sectionsState}
            sectionOrder={sectionOrder}
            getEditor={getEditor}
            proposalData={proposal_data}
            snapshotData={snapshot_data}
            logoUrl={logoUrl}
          />

          <SignaturePage />
        </Document>
      );

      const blob = await pdf(pdfDocWithSignature).toBlob();

      // Define signature coordinates (positioned at the client signature line)
      const signatureCoordinates = {
        x: 0.2, // 20% from left edge of page
        y: 0.7, // 70% from top of page
        width: 0.3, // 30% of page width
        height: 0.08, // 8% of page height
      };

      // Check if proposalId is a valid UUID
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (!uuidRegex.test(proposalId)) {
        throw new Error(
          "Invalid proposal ID. Please save the proposal first before sending a signature request."
        );
      }

      // Send the signature request with coordinates
      const result = await sendSignatureRequest(
        clientEmail,
        clientName,
        proposalId,
        blob,
        signatureCoordinates
      );

      await addProposalLog({
        proposal_id: proposalId,
        action: "sent",
        message: "Proposal sent for signature to " + clientEmail,
      });

      if (!result) {
        throw new Error("Failed to send signature request");
      }

      // Show success toast
      setSignatureToastSeverity("success");
      setSignatureToastMessage("Signature request sent successfully!");
      setShowSignatureToast(true);

      // Clear dialog & fields
      setSignatureDialogOpen(false);
      setClientEmail("");
      setClientName("");
    } catch (error) {
      console.error("Error sending signature request:", error);

      // Provide more descriptive error messages for common issues
      if (
        error?.message?.includes("Cannot read properties of undefined") &&
        error?.message?.includes("width")
      ) {
        // A typical cause might be an image or PDF asset that can’t be rendered
        setSignatureToastSeverity("error");
        setSignatureToastMessage(
          "A rendering error occurred: it looks like a PDF element (possibly an image or other resource) is undefined. Check that all images or data within the proposal exist and are properly referenced."
        );
      } else if (error?.message) {
        // For other known error messages
        setSignatureToastSeverity("error");
        setSignatureToastMessage(error.message);
      } else {
        // Fallback message if error object is incomplete
        setSignatureToastSeverity("error");
        setSignatureToastMessage(
          "An unknown error occurred while sending the signature request."
        );
      }

      setShowSignatureToast(true);
      setSignatureDialogOpen(false);
    }
  };

  const handleCheckboxChange = async (sectionId, itemId, checked) => {
    setIsDirty(true);

    const updatedSelectedItems = {
      ...selectedItems,
      [`${sectionId}-${itemId}`]: checked,
    };
    setSelectedItems(updatedSelectedItems);

    // Build new JSON
    const newJsonData = buildProposalJson({
      sectionsState,
      sectionOrder,
      itemOrder,
      selectedItems: updatedSelectedItems,
    });

    const newProposalData = cloneDeep(proposal_data);
    newProposalData.ordering = newJsonData;

    dispatch(updateProposalData(newProposalData));
    try {
      await updateProposal({
        id: proposalId,
        proposal_data: {
          ...proposal_data,
          ordering: newJsonData,
        },
      });
      posthog.capture("proposal:proposal_updated", {
        job_id: job_id,
        proposal_data: newProposalData,
        is_internal_user: false,
      });
    } catch (error) {
      console.error("Error updating proposal:", error);
      posthog.capture("proposal:proposal_update_failed", {
        job_id: job_id,
        error: error,
        is_internal_user: false,
      });
    }
  };

  // ---- Render ----
  return (
    <>
      <Sidebar proposalId={proposalId} />
      <TopProgress currentStep={7} />
      <Box
        sx={{
          display: "flex",
          // Occupies the full vertical space
          height: "100vh",
          marginLeft: "15.6rem", // to account for sidebar
          // backgroundColor: "red",
          // marginTop: "3.5625rem",
        }}
      >
        {/* Main content area: we do NOT set overflow here. */}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            marginTop: "3.5625rem",
            height: "100%",
          }}
        >
          {/* LEFT side: scrollable builder */}
          <Box
            sx={{
              // The left side takes available space
              flex: showPreview ? 1 : "1 0 100%",
              // enable scrolling

              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
          >
            <Box
              sx={{
                position: "sticky",
                // justifyContent: "space-between",
                // alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 1,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Proposal Builder
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {editMode ? (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}
                          size="small"
                          autoFocus
                          sx={{ padding: "1rem", paddingLeft: "0" }}
                        />
                        <IconButton color="success" onClick={handleUpdate}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton color="error" onClick={handleCancel}>
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">{name}</Typography>
                        <IconButton onClick={() => setEditMode(true)}>
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={handleSaveDraft}
                    size="small"
                    sx={{
                      bgcolor: isDirty ? "error.main" : "primary.main",
                      "&:hover": {
                        bgcolor: isDirty ? "error.dark" : "primary.dark",
                      },
                      margin: "0.5rem",
                    }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    // variant="outlined"
                    onClick={() => setShowPreview(!showPreview)}
                    startIcon={showPreview ? <EyeIcon /> : <EyeOffIcon />}
                    size="small"
                    sx={{
                      paddingLeft: "0.5rem",
                      paddingRight: "0.5rem",
                    }}
                  >
                    {showPreview ? "Hide Preview" : "Show Preview"}
                  </Button>
                </Box>
              </Box>
              {showInstructions && (
                <Alert
                  severity="info"
                  icon={false}
                  sx={{ mb: 2 }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowInstructions(false)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography variant="body2" component="div">
                    <strong>Instructions:</strong>
                    <ul style={{ margin: 0, paddingLeft: "1.25rem" }}>
                      <li>Drag and drop sections to reorder them.</li>
                      <li>
                        Check each item you want to include in your proposal.
                      </li>
                      <li>Selected items will appear in the Preview panel.</li>
                      <li>Cost Breakdown can be found in Financial Details</li>
                    </ul>
                  </Typography>
                </Alert>
              )}
            </Box>
            {/* Prompt dialog if isDirty */}

            <Dialog open={showDialog} onClose={cancelNavigation}>
              <DialogTitle>Unsaved Changes</DialogTitle>
              <DialogContent>
                <DialogContentText>{message}</DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* "Save Changes" replaces the old "Stay" button */}
                <Button
                  onClick={async () => {
                    // Call your existing handleSaveDraft() function
                    await handleSaveDraft();
                    // Then allow navigation away (confirmNavigation)
                    confirmNavigation();
                  }}
                  color="primary"
                >
                  Save Changes
                </Button>
              </DialogActions>
            </Dialog>
            {/* 

            {/* Left Panel - Builder Interface */}
            <Card
              sx={{
                // width: showPreview ? "50%" : "100%",
                transition: "width 0.3s",
                height: "fit-content",
                // background: "var(--Nuetrals-Background---Primary, #F3F3F1)",
                overflowY: "auto",
                background: "#FFFFFF",
                border: "none",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <ProposalBox>
                  {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}> */}
                  {sectionOrder.map((sectionKey) => {
                    const section = sectionsState[sectionKey];
                    if (!section) return null;
                    const IconComponent = getIconComponent(sectionKey);
                    return (
                      <Paper
                        key={sectionKey}
                        elevation={1}
                        draggable
                        onDragStart={(e) => handleDragStart(e, sectionKey)}
                        onDragOver={(e) => handleDragOver(e, sectionKey)}
                        onDragEnd={handleDragEnd}
                        sx={{ width: "100%" }}
                      >
                        <ProposalDivisionBox>
                          <ProposalDivisionTitleBox
                            onClick={() => toggleSection(sectionKey)}
                          >
                            <ProposalDivisonIconAndTitle>
                              <DragLargeIcon />
                              <IconComponent />
                              <Typography variant="subtit</Box>le1">
                                {section.title}
                              </Typography>
                            </ProposalDivisonIconAndTitle>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {section.items.length} items
                              </Typography>
                              <BoxForArrow>
                                {expandedSections[sectionKey] ? (
                                  <ArrowDownIconStyled />
                                ) : (
                                  <ArrowUpIconStyled />
                                )}
                              </BoxForArrow>
                            </Box>
                          </ProposalDivisionTitleBox>

                          {expandedSections[sectionKey] && (
                            <ProposalDivisionContentBox>
                              {section.items.map((item) => (
                                <ProposalDivisionContentSubdivisionBox
                                  key={item.id}
                                  draggable
                                  onDragStart={(e) =>
                                    handleDragStart(e, sectionKey, item.id)
                                  }
                                  onDragOver={(e) =>
                                    handleDragOver(e, sectionKey, item.id)
                                  }
                                  onDragEnd={handleDragEnd}
                                >
                                  <ProposalDivisionContentSubdivisionFrame>
                                    <DragSmallIcon />
                                    <Checkbox
                                      checked={
                                        selectedItems[
                                          `${sectionKey}-${item.id}`
                                        ] || false
                                      }
                                      onChange={(e) => {
                                        handleCheckboxChange(
                                          sectionKey,
                                          item.id,
                                          e.target.checked
                                        );
                                      }}
                                      size="small"
                                    />
                                    <Box sx={{ flex: 1 }}>
                                      <Typography variant="subtitle2">
                                        {item.title}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        {item.description}
                                      </Typography>
                                    </Box>

                                    <SettingsIcon
                                      onClick={() => {
                                        // console.log(sectionKey, item.id);
                                        handleOpenDialog(sectionKey, item.id);
                                      }}
                                    />
                                    {/* </Paper> */}
                                  </ProposalDivisionContentSubdivisionFrame>
                                </ProposalDivisionContentSubdivisionBox>
                              ))}

                              <Box></Box>
                            </ProposalDivisionContentBox>
                          )}
                        </ProposalDivisionBox>
                      </Paper>
                    );
                  })}
                </ProposalBox>
              </CardContent>
            </Card>
          </Box>
          {/* Right Panel - PDF Preview */}
          {showPreview && (
            <Box
              sx={{
                width: "50%",
                position: "sticky",
                top: 0,
                alignSelf: "flex-start",
                height: "100vh",
                // height: "100%",
                overflowY: "auto",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  background: "var(--Nuetrals-Background---Secondary, #E7E7DF)",
                }}
              >
                <CardHeader
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#E7E7DF",
                  }}
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">Preview</Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          size="small"
                          // sx={{ color: "primary.main" }}
                          onClick={() => setShareDialogOpen(true)}
                        >
                          Share
                        </Button>
                        <Button
                          size="small"
                          // sx={{ color: "primary.main" }}
                          onClick={() => setSignatureDialogOpen(true)}
                        >
                          Request Signature
                        </Button>
                      </Box>
                    </Box>
                  }
                />
                <CardContent
                  sx={{
                    flex: 1,
                    p: "16px !important",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Object.keys(selectedItems).length === 0 ? (
                    <Typography
                      variant="body2"
                      align="center"
                      color="text.secondary"
                    >
                      Select items to preview them here
                    </Typography>
                  ) : (
                    <Box sx={{ flex: 1, minHeight: 0 }}>
                      <PDFViewer
                        width="100%"
                        height="100%"
                        style={{ border: "none", borderRadius: "0.8125rem" }}
                      >
                        {memoizedPDFDocument}
                      </PDFViewer>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>
          )}
          <Dialog
            open={shareDialogOpen}
            onClose={() => setShareDialogOpen(false)}
          >
            <DialogTitle>Share Proposal</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter the recipient email address:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleSendEmail} variant="contained">
                Send
              </Button>
            </DialogActions>
          </Dialog>

          <CustomStyledDialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                // p: 0,
                // m: 0,
              }}
            >
              <Typography variant="h6">
                {selectedSection &&
                  getEditor(
                    selectedSection.sectionId,
                    selectedSection.itemId,
                    proposal_data
                  )?.title}
              </Typography>
              <IconButton onClick={handleCloseDialog} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers sx={{ width: "100%", p: 2, mt: 0, pt: 0 }}>
              {selectedSection &&
                (() => {
                  const editorData = getEditor(
                    selectedSection.sectionId,
                    selectedSection.itemId,
                    proposal_data,
                    snapshot_data
                  );
                  if (!editorData)
                    return (
                      <Typography>
                        No editor available for this section
                      </Typography>
                    );

                  const EditorComponent = editorData.component;

                  // Example of custom logic for certain sections/items
                  if (
                    selectedSection.sectionId === "financial" &&
                    selectedSection.itemId === "costs"
                  ) {
                    return (
                      <EditorComponent
                        takeOffData={editorData.data}
                        onSave={async (data) => {
                          const newProposalData = cloneDeep(proposal_data);
                          newProposalData.settings = data;
                          setProposalData(newProposalData);
                          dispatch(updateProposalData(newProposalData));

                          await updateProposal({
                            id: proposalId,
                            proposal_data: newProposalData,
                          });

                          handleCloseDialog();
                        }}
                      />
                    );
                  }

                  // Otherwise, a generic usage
                  return (
                    <EditorComponent
                      data={editorData.data}
                      onSave={async (data) => {
                        const newProposalData = cloneDeep(proposal_data);
                        const title = editorData.title.replace(/\s+/g, "_");
                        newProposalData[title] = data;
                        setProposalData(newProposalData);
                        dispatch(updateProposalData(newProposalData));

                        await updateProposal({
                          id: proposalId,
                          proposal_data: newProposalData,
                        });
                        handleCloseDialog();
                      }}
                    />
                  );
                })()}
            </DialogContent>
            {/* </Dialog> */}
          </CustomStyledDialog>
          {/* Snackbar / Toast for a successful save */}
          <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => setShowToast(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={() => setShowToast(false)}
              severity="success"
              sx={{ width: "100%" }}
            >
              Draft saved successfully!
            </Alert>
          </Snackbar>
          {/* Snackbar / Toast for *email sending* */}
          <Snackbar
            open={showEmailToast}
            autoHideDuration={3000}
            onClose={() => setShowEmailToast(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={() => setShowEmailToast(false)}
              severity={emailToastSeverity}
              sx={{ width: "100%" }}
            >
              {emailToastMessage}
            </Alert>
          </Snackbar>

          {/* Signature Toast */}
          <Snackbar
            open={showSignatureToast}
            autoHideDuration={6000}
            onClose={() => setShowSignatureToast(false)}
          >
            <Alert
              onClose={() => setShowSignatureToast(false)}
              severity={signatureToastSeverity}
            >
              {signatureToastMessage}
            </Alert>
          </Snackbar>

          {/* Signature Request Dialog */}
          <Dialog
            open={signatureDialogOpen}
            onClose={() => setSignatureDialogOpen(false)}
          >
            <DialogTitle>Request Signature</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter client information to send a signature request:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="clientName"
                label="Client Name"
                type="text"
                fullWidth
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <TextField
                margin="dense"
                id="clientEmail"
                label="Client Email"
                type="email"
                fullWidth
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setSignatureDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSendSignatureRequest}
                color="primary"
                disabled={!clientName || !clientEmail}
              >
                Send for Signature
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default ProposalBuilderInterface;
