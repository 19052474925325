import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Select, MenuItem, Alert, Snackbar, Box } from "@mui/material";

import {
  handleDeleteRow,
  processRowUpdateForFile,
  validateRow,
} from "../../services/pricingService";

// Custom edit cell for the Division column
const DivisionEditCell = (params) => {
  const { id, value, api, divisionsData } = params;
  const handleChange = (event) => {
    const newDivision = event.target.value;
    // Update the division_name field and reset the subdivision & item fields
    api.setEditCellValue({ id, field: "division_name", value: newDivision });
    api.setEditCellValue({ id, field: "subdivision_name", value: "" });
    api.setEditCellValue({ id, field: "subdivision_id", value: null });
    api.setEditCellValue({ id, field: "material_name", value: "" });
    api.setEditCellValue({ id, field: "material_id", value: null });
  };

  return (
    <Select value={value || ""} onChange={handleChange} fullWidth autoFocus>
      {divisionsData.map((division) => (
        <MenuItem key={division.id} value={division.name}>
          {division.name}
        </MenuItem>
      ))}
    </Select>
  );
};

// Custom edit cell for the Subdivision column
const SubdivisionEditCell = (params) => {
  const { id, value, api, row, divisionsData } = params;
  const selectedDivisionName = row.division_name;
  const matchingDivision = divisionsData.find(
    (division) => division.name === selectedDivisionName
  );
  const subdivisionsOptions = matchingDivision ? matchingDivision.subdivisions : [];

  const handleChange = (event) => {
    const newSubdivisionName = event.target.value;
    const selectedSubdivision = subdivisionsOptions.find(
      (sub) => sub.name === newSubdivisionName
    );
    const newSubdivisionId = selectedSubdivision ? selectedSubdivision.id : null;
    // When subdivision changes, clear any previously selected item
    api.setEditCellValue({
      id,
      field: "subdivision_name",
      value: newSubdivisionName,
    });
    api.setEditCellValue({ id, field: "subdivision_id", value: newSubdivisionId });
    api.setEditCellValue({ id, field: "material_name", value: "" });
    api.setEditCellValue({ id, field: "material_id", value: null });
  };

  return (
    <Select value={value || ""} onChange={handleChange} fullWidth autoFocus>
      {subdivisionsOptions.map((subdiv) => (
        <MenuItem key={subdiv.id} value={subdiv.name}>
          {subdiv.name}
        </MenuItem>
      ))}
    </Select>
  );
};

// Custom edit cell for the Item/Material column
const ItemEditCell = (params) => {
  const { id, value, api, row, divisionsData } = params;
  const selectedDivisionName = row.division_name;
  const selectedSubdivisionName = row.subdivision_name;
  let itemsOptions = [];
  const matchingDivision = divisionsData.find(
    (division) => division.name === selectedDivisionName
  );
  if (matchingDivision) {
    const matchingSubdivision = matchingDivision.subdivisions.find(
      (sub) => sub.name === selectedSubdivisionName
    );
    if (matchingSubdivision) {
      itemsOptions = matchingSubdivision.items || [];
    }
  }

  const handleChange = (event) => {
    const newItemName = event.target.value;
    const selectedItem = itemsOptions.find(item => item.name === newItemName);
    const newMaterialId = selectedItem ? selectedItem.id : null;
    api.setEditCellValue({
      id,
      field: "material_name",
      value: newItemName,
    });
    api.setEditCellValue({ id, field: "material_id", value: newMaterialId });
  };

  return (
    <Select value={value || ""} onChange={handleChange} fullWidth autoFocus>
      {itemsOptions.map((item) => (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

function LineItemsTable({
  lineItems,
  columns, // Dynamic column definitions (from your file data)
  divisionsData, // Divisions and subdivisions (with items) from your API
  rowsPerPage,
  selectedFileId,
  onLineItemsUpdate, // Callback to notify parent of row updates
  selectedRows,
  onRowSelectionChange,
  rowsStatus, // Mapping of row id => "accepted", "rejected", or "pending"
}) {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    if (lineItems && Array.isArray(lineItems)) {
      setRows(
        lineItems.map((item) => ({
          id: item.line_id, // Explicitly set `id` to prevent MUI from adding its own `id`
          ...item,
        }))
      );
    }
  }, [lineItems]);

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Build grid columns including the new item/material dropdown.
  const gridColumns = [
    // COMMENTED THIS CODE TO SHOW JUST THE OUTPUT FROM VERIFY API
    // {
    //   field: "division_name",
    //   headerName: "DIVISION",
    //   flex: 1,
    //   editable: true,
    //   renderEditCell: (params) => (
    //     <DivisionEditCell {...params} divisionsData={divisionsData} />
    //   ),
    // },
    // {
    //   field: "subdivision_name",
    //   headerName: "SUBDIVISION",
    //   flex: 1,
    //   editable: true,
    //   renderEditCell: (params) => (
    //     <SubdivisionEditCell {...params} divisionsData={divisionsData} />
    //   ),
    // },
    // {
    //   field: "subdivision_id", // Hidden column to store the subdivision ID.
    //   headerName: "Subdivision ID",
    //   flex: 1,
    //   editable: true,
    //   hide: true,
    // },
    // {
    //   field: "material_name",
    //   headerName: "Material Name",
    //   flex: 1,
    //   editable: true,
    //   renderEditCell: (params) => (
    //     <ItemEditCell {...params} divisionsData={divisionsData} />
    //   ),
    // },
    // {
    //   field: "material_id", // Hidden column to store the material ID.
    //   headerName: "Material ID",
    //   flex: 1,
    //   editable: true,
    //   hide: true,
    // },
    // Append any dynamic columns from file data (excluding our custom keys)
    ...columns
      .filter((column) =>
        ![
          "division_name",
          "subdivision_name",
          "id",
          "subdivision_id",
          "material_name",
          "material_id",
        ].includes(column.id)
      )
      .map((column) => ({
        field: column.id,
        headerName: column.label,
        flex: 1,
        editable: true,
        hide: column.id === "line_id",
        ...(column.valueFormatter ? { valueFormatter: column.valueFormatter } : {}),
      })),
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleDeleteClick = (id) => async () => {
    console.log(`Clicked on Delete ${id}`);
    const result = await handleDeleteRow(id, selectedFileId);
    if (result.success) {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);

      // Notify parent component of the updated line items
      if (onLineItemsUpdate) {
        onLineItemsUpdate(updatedRows);
      }
  
      setSnackbar({
        open: true,
        message: `Line Item deleted successfully.`,
        severity: "success",
      });
    } else {
      setSnackbar({
        open: true,
        message: `Error deleting row: ${result.error}`,
        severity: "error",
      });
    }
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const isEqual = (obj1, obj2) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
      return true;
    };

    if (isEqual(newRow, oldRow)) {
      console.log("No changes detected. Skipping update.");
      setSnackbar({
        open: true,
        message: `No changes detected for row with ID ${newRow.id}.`,
        severity: "info",
      });
      return null;
    }

    console.log(
      `Changes detected:\nNew Row = ${JSON.stringify(
        newRow
      )}\nOld Row = ${JSON.stringify(oldRow)}`
    );

    const validationErrors = validateRow(newRow);
    console.log("Validation errors:", validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      console.error("Validation errors:", Object.values(validationErrors).join(", \n"));
      setSnackbar({
        open: true,
        message: `Validation errors: ${Object.values(validationErrors).join(", \n")}`,
        severity: "error",
      });
      return null;
    }

    console.log("Validation successful. Proceeding with update.");
    const response = await processRowUpdateForFile(newRow, selectedFileId);
    console.log(response);
    if (Object.keys(response).includes("error")) {
      console.error("Error updating the line item:", response.error);
      setSnackbar({
        open: true,
        message: `Error updating the line item`,
        severity: "error",
      });
      return null;
    }
    const updatedRow = { ...newRow };
    const updatedRows = rows.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );
    setRows(updatedRows);
    setSnackbar({
      open: true,
      message: `Line Item updated successfully.`,
      severity: "success",
    });
    if (onLineItemsUpdate) {
      onLineItemsUpdate(updatedRows);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newModel) => {
    setRowModesModel(newModel);
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={gridColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          // onProcessRowUpdateError={(error) => {
          //   console.error("Error updating row:", error);
          //   setSnackbar({
          //     open: true,
          //     message: `Error updating row: ${error.message}`,
          //     severity: "error",
          //   });
          // }}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          disableRowSelectionOnClick
          checkboxSelection
          keepNonExistentRowsSelected
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                line_id: false,
                text: false,
                line_status: false,
                subdivision_id: false,
                material_id: false,
              },
            },
          }}
          columnVisibilityModel={{
            id: false,
            line_id: false,
            text: false,
            line_status: false,
            subdivision_id: false,
            material_id: false,
          }}
          disableColumnSelector={true}
          disableDensitySelector
          disableColumnFilter
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={onRowSelectionChange}
          getRowClassName={(params) => {
            const status =
              rowsStatus && rowsStatus[params.row.id]
                ? rowsStatus[params.row.id]
                : "pending";
            if (status === "accepted") return "accepted-row";
            if (status === "rejected") return "rejected-row";
            return "";
          }}
          sx={{
            "& .accepted-row": {
              backgroundColor: "rgba(76, 175, 80, 0.1)",
              "&:hover": {
                backgroundColor: "rgba(76, 175, 80, 0.15)",
              },
            },
            "& .rejected-row": {
              backgroundColor: "rgba(244, 67, 54, 0.1)",
              "&:hover": {
                backgroundColor: "rgba(244, 67, 54, 0.15)",
              },
            },
          }}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </Box>
  );
}

export default LineItemsTable;
