import React, { useState } from 'react';
import { TextField, Button, InputAdornment, IconButton, Typography, Link, styled, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertMessage from '../AlertMessage';
import apiAxios from '../../services/axiosConfig';
import { ReactComponent as IllustrationIcon } from '../../static/Icons/illustration.svg';
import { ReactComponent as LogoHolder } from "../../static/Icons/logo.svg";

const AuthenticationPageFrame = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
}));

const IllustrationFrame = styled(Box)(({theme}) => ({
  display: 'flex',
  flex:2,
  background: theme.palette.neutrals.backgroundPrimary,
  flexDirection: 'column',
}));

const LogoFrame = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-start',
  alignItems: 'center',
  flex:1,
  marginLeft: '2rem',
}));

const LogoTextFrame = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.3rem',
}));

const IllustrationIconFrame = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex:12,
}));

const WelcomeFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '2rem 2rem',
}));

const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  width: '100%',
}));

const InputFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TextInputFrame = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '3.02144rem',
    background: theme.palette.neutrals.background.input2,
    borderRadius: '0.94419rem',
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '0.69244rem 1.00713rem',
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.neutrals.background.input2} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
}));

const SignupButtonFrame = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
}));

function SignupInvitation() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  // Extract the invitation token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await apiAxios.post('/signup/invitation', {
        token,
        username,
        password,
        confirm_password: confirmPassword,
      });
      if (response.data) {
        setSuccessMessage('Signup successful!');
        setTimeout(() => {
          navigate('/');
        }, 1500);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Signup failed');
    }
  };

  return (
    <AuthenticationPageFrame>
      <IllustrationFrame>
        <LogoFrame>
          <LogoHolder />
          <LogoTextFrame>
            <Typography variant="logo">assembli</Typography>
          </LogoTextFrame>
        </LogoFrame>
        <IllustrationIconFrame>
          <IllustrationIcon />
        </IllustrationIconFrame>
      </IllustrationFrame>
      <WelcomeFrame>
        <Typography variant="welcometext" align="center" sx={{ color: 'typographyPrimary.main' }}>
          Complete Your Invitation Signup
        </Typography>
        <Typography variant="logintext" align="center" sx={{ color: 'typographySecondary.main' }} my={1}>
          Please complete your details to join the company.
        </Typography>
        <FormFrame>
          <form onSubmit={handleSignup}>
            <InputFrame>
              <Typography variant="body2medium16" sx={{ mb: 1 }}>
                Username
              </Typography>
              <TextInputFrame
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="username"
                required
              />
              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Password
              </Typography>
              <TextInputFrame
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="new-password"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Confirm Password
              </Typography>
              <TextInputFrame
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="new-password"
                required
              />
              <Box sx={{ mt: 1, mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Password must contain:
                </Typography>
                <ul style={{ marginLeft: '1.25rem', marginTop: '0.5rem' }}>
                  <li>At least 12 characters</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one number</li>
                  <li>At least one special character</li>
                </ul>
              </Box>
            </InputFrame>
            <SignupButtonFrame type="submit" variant="contained" color="primary">
              Sign Up
            </SignupButtonFrame>
          </form>
        </FormFrame>
        {error && <AlertMessage message={error} type="error" onClose={() => setError('')} />}
        {successMessage && <AlertMessage message={successMessage} type="success" onClose={() => setSuccessMessage('')} />}
      </WelcomeFrame>
    </AuthenticationPageFrame>
  );
}

export default SignupInvitation;
