import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  IconButton,
  Snackbar,
  Alert,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Paper,
  Tooltip,
} from "@mui/material";
import { ReactComponent as ChooseIcon } from "../../static/Icons/container.svg";
import { ReactComponent as UploadIcon } from "../../static/Icons/upload.svg";
import { ReactComponent as TickIcon } from "../../static/Icons/check_small.svg";
import { ReactComponent as TrashIcon } from "../../static/Icons/trash.svg";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { fetchLineItemCategories } from "../../services/pricingService";

const ChooseIconStyled = styled(ChooseIcon)(() => ({
  width: "0.94413rem",
  height: "0.94413rem",
  strokeWidth: "1.2px",
  stroke: "var(--Accents-Accent, #2961A7)",
}));

const TickMarkIconStyled = styled(TickIcon)(() => ({
  width: "1.25888rem",
  height: "1.25888rem",
  position: "absolute",
  right: "0.05244rem",
  bottom: "0.05244rem",
  zIndex: 10,
}));

const UploadIconStyled = styled(UploadIcon)(() => ({
  width: "1.36769rem",
  height: "1.36769rem",
}));

const TrashIconStyled = styled(TrashIcon)(() => ({
  width: "1.125rem",
  height: "1.125rem",
  justifyContent: "center",
  alignItems: "center",
}));

const InfoIconStyled = styled(InfoIcon)(() => ({
  width: "0.875rem",
  height: "0.875rem",
  marginLeft: "0.25rem",
  cursor: "pointer",
  color: "var(--Accents-Accent, #2961A7)",
}));

const EllipseIconStyled = styled(Box)(() => ({
  width: "0.63144rem",
  height: "0.63144rem",
  fill: "var(--Second-Brick-7, #00407B)",
}));

// This styled component is used to style the whole row in Assembli cost database section
const BoxFeatureStyled = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0.6875rem",
  alignItems: "center",
  gap: "0.6875rem",
  alignSelf: "stretch",
  borderRadius: "0.5rem",
}));

// This styled component is used to style the bullet points
const BoxFrameStyled = styled(Box)(() => ({
  display: "flex",
  padding: "0.25256rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.26288rem",
  borderRadius: "2.52575rem",
  background: "var(--Accents-Accent-Alt, #00407B)",
}));

// For the expand/collapse icons in the dialog
const ExpandIcon = styled("div")(() => ({
  "&::after": {
    content: '"▼"',
    fontSize: "0.75rem",
  },
}));

const CollapseIcon = styled("div")(() => ({
  "&::after": {
    content: '"▶"',
    fontSize: "0.75rem",
  },
}));

// Styled component for the highlighted message container
const HighlightedMessageBox = styled(Box)(() => ({
  background: "var(--Nuetrals-Background---Secondary, #F9F8F4)",
  padding: "1.25rem",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  borderRadius: "0.5rem",
  border: "1px solid var(--Nuetrals-Border---3, #EFEEE7)",
}));

function CustomPricingSection({
  selectedOption,
  handleOptionChange,
  files,
  handleFileSelection,
  handleRemoveFile,
  error,
  snackbarOpen,
  setSnackbarOpen,
  isProcessing,
}) {
  const MAX_FILES = 15;
  const MAX_FILE_SIZE_MB = 20;
  const ALLOWED_TYPES = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/tab-separated-values",
  ];

  const [openGuideDialog, setOpenGuideDialog] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  useEffect(() => {
    const shown = localStorage.getItem("hasShownGuideDialog");
    if (shown === "true") {
      setHasShownDialogOnce(true);
    }
  }, []);
  const [lineItemCategories, setLineItemCategories] = useState({});

  const isSelected = selectedOption === "custom";

  // Initialize all categories as expanded
  useEffect(() => {
    if (Object.keys(lineItemCategories).length > 0) {
      const initialExpandedState = {};
      Object.keys(lineItemCategories).forEach((category) => {
        initialExpandedState[category] = true;
      });
      setExpandedCategories(initialExpandedState);
    }
  }, [lineItemCategories]);

  // Effect to auto-show dialog when "Upload your own" is selected for the first time
  useEffect(() => {
    if (isSelected && !hasShownDialogOnce) {
      setOpenGuideDialog(true);
      setHasShownDialogOnce(true);
      localStorage.setItem("hasShownGuideDialog", "true");
    }
  }, [isSelected, hasShownDialogOnce]);

  useEffect(() => {
    const fetchLineItems = async () => {
      try {
        const response = await fetchLineItemCategories();
        // Convert the array of line items into an object with categories as keys
        // and arrays of line items as values
        // e.g. {"division_name->subdivision_name": ["line_item_1", "line_item_2", ...]}
        let tempLineItemCategories = {};
        response.forEach((item) => {
          const category = `${item.division_name} -> ${item.subdivision_name}`;
          if (!tempLineItemCategories[category]) {
            tempLineItemCategories[category] = [];
          }
          tempLineItemCategories[category].push(item.item_description);
        });
        setLineItemCategories(tempLineItemCategories);
      } catch (error) {
        console.error("Error fetching line item categories: ", error);
      }
    };
    fetchLineItems();
  }, []);

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleCloseDialog = () => {
    setOpenGuideDialog(false);
  };

  // Modified to show dialog when option is selected
  const handleCustomOptionChange = (event) => {
    handleOptionChange(event);

    // If changing to "custom" and hasn't shown dialog yet, open it
    if (event.target.value === "custom" && !hasShownDialogOnce) {
      setOpenGuideDialog(true);
      setHasShownDialogOnce(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.9475rem",
        width: "23.5625rem",
        height: "22.5rem",
        padding: "1.70313rem 1.375rem 1.1875rem 1.375rem",
        borderRadius: "1.25rem",
        background: "var(--Nuetrals-Background---Tertiary, #FFF)",
        boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
        border: isSelected
          ? "1px solid var(--Status-Selected-2, #ABA898)"
          : "1px solid var(--Nuetrals-Border---2, #DCDACB)",
        transition: "border 0.3s ease, background-color 0.3s ease", // Smooth transition for selection
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "20.8125rem",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "0.8125rem",
          cursor: "pointer", // Show a pointer cursor to indicate clickability
        }}
      >
        <Box
          onClick={() =>
            handleCustomOptionChange({ target: { value: "custom" } })
          }
          sx={{
            display: "flex",
            height: "1.5625rem",
            alignItems: "center",
            alignSelf: "stretch",
            gap: "0.5625rem",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0.20981rem",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              borderRadius: "0.3rem",
              backgroundColor: isSelected ? "#2961A7" : "#FFFFFF",
              transition: "background-color 0.3s ease",
            }}
          >
            <ChooseIconStyled />
            {isSelected && <TickMarkIconStyled />}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "11rem",
              height: "0.94413rem",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2medium16">Upload your own</Typography>
          </Box>
          <Tooltip title="View line item examples for better matching">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (!hasShownDialogOnce) {
                  setOpenGuideDialog(true);
                  setHasShownDialogOnce(true);
                  localStorage.setItem("hasShownGuideDialog", "true");
                }
              }}
              sx={{
                ml: 0.5,
                p: 0.5,
                "&:hover": {
                  backgroundColor: "rgba(41, 97, 167, 0.08)",
                },
              }}
            >
              {" "}
              <InfoIconStyled />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              height: "1.75731rem",
              padding: "0.3295rem 0.54919rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.27456rem",
              borderRadius: "2.25156rem",
              backgroundColor: "#00407B",
              border: "0.879px solid var(--Nuetrals-Border---2, #DCDACB)",
            }}
          >
            <Typography variant="body3" sx={{ color: "white" }}>
              Recommended
            </Typography>
          </Box>
        </Box>
        {/* Horizontal Line */}
        <Box
          sx={{
            width: "20.8125rem",
            height: "0.0625rem",
            background: "var(--Nuetrals-Border---2, #DCDACB)",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: isSelected ? "10rem" : "auto",
          overflowY: "auto",
        }}
      >
        {files.length > 0 ? (
          files.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.125rem",
                width: "100%",
                height: "10rem",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Typography-Primary, #1D1D1B)",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                variant="body3semibold14"
              >
                {file.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="captionregular12">
                  {(file.size / (1024 * 1024)).toFixed(2)} MB
                </Typography>
                <IconButton onClick={() => handleRemoveFile(index)}>
                  <TrashIconStyled />
                </IconButton>
              </Box>
              <Box
                sx={{
                  height: "0.0625rem",
                  width: "100%",
                  background: "var(--Nuetrals-Border---2, #DCDACB)",
                }}
              />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <BoxFeatureStyled>
              {/* <BoxFrameStyled>
                <EllipseIconStyled />
              </BoxFrameStyled> */}
              <Typography variant="body3medium14">
                Simply import your cost data via Excel or CSV for more accurate,
                customized estimates.
              </Typography>
            </BoxFeatureStyled>

            <BoxFeatureStyled>
              {/* <BoxFrameStyled>
                <EllipseIconStyled />
              </BoxFrameStyled> */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body3medium14">
                  Upload your historical invoices, receipts, or estimates, and
                  we will handle the sorting and organization for you.
                </Typography>
              </Box>
            </BoxFeatureStyled>

            <UploadIconStyled />

            <Typography variant="regular12">
              Supported file types: PDFs, Images, Excel, CSV/TSV
            </Typography>
            <Typography variant="regular12">
              Max 15 files. Up to 20 MB per file
            </Typography>
          </Box>
        )}
      </Box>

      {isSelected && (
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            width: "20.8125rem",
            height: "2.5rem",
            borderRadius: "6.25rem",
            border: "1px solid var(--Nuetrals-Border---1, #ABA898)",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => document.getElementById("file-upload-input").click()}
          disabled={isProcessing}
        >
          <UploadIconStyled />
          <Typography variant="body3medium14">Upload</Typography>
          <input
            id="file-upload-input"
            type="file"
            hidden
            multiple
            onChange={handleFileSelection}
            accept=".pdf, .jpeg, .jpg, .png, .xlsx, .csv, .tsv"
          />
        </Button>
      )}

      {/* Line Item Guide Dialog */}
      <Dialog
        open={openGuideDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "0.75rem",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "var(--Nuetrals-Background---Secondary, #F9F8F4)",
            padding: "1rem 1.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2medium16">Line Item Examples</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          {/* Highlighted message box with increased spacing */}
          <HighlightedMessageBox>
            <Typography
              variant="body3medium14"
              sx={{ color: "var(--Typography-Secondary, #4D4D49)" }}
            >
              For best results, upload invoices containing line items similar to
              those below. Our system will attempt to map your invoice items to
              these standard categories. We also recommend that your invoices
              include both material and labor prices to enable more accurate
              matching.
            </Typography>
          </HighlightedMessageBox>

          <Paper
            sx={{
              maxHeight: "20rem",
              overflow: "auto",
              boxShadow: "none",
              borderTop: "1px solid var(--Nuetrals-Border---2, #DCDACB)",
              borderBottom: "1px solid var(--Nuetrals-Border---2, #DCDACB)",
              mb: 2,
              mx: 0.5,
            }}
          >
            {Object.keys(lineItemCategories).map((category) => (
              <React.Fragment key={category}>
                <ListItem
                  button
                  onClick={() => toggleCategory(category)}
                  sx={{
                    py: 2,
                    px: 3,
                    borderBottom:
                      "1px solid var(--Nuetrals-Border---3, #EFEEE7)",
                    "&:hover": {
                      backgroundColor:
                        "var(--Nuetrals-Background---Secondary, #F9F8F4)",
                    },
                  }}
                >
                  <ListItemText
                    primary={category}
                    primaryTypographyProps={{
                      variant: "body2medium16",
                      color: "var(--Typography-Primary, #1D1D1B)",
                    }}
                  />
                  {expandedCategories[category] ? (
                    <ExpandIcon />
                  ) : (
                    <CollapseIcon />
                  )}
                </ListItem>
                <Collapse
                  in={expandedCategories[category]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      background:
                        "var(--Nuetrals-Background---Secondary, #F9F8F4)",
                    }}
                  >
                    {lineItemCategories[category].map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          py: 1,
                          pl: 6,
                          pr: 3,
                          borderBottom:
                            index < lineItemCategories[category].length - 1
                              ? "1px solid var(--Nuetrals-Border---3, #EFEEE7)"
                              : "none",
                        }}
                      >
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{
                            variant: "captionregular12",
                            color: "var(--Typography-Secondary, #4D4D49)",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </Paper>

          <Typography
            variant="captionregular12"
            sx={{ color: "var(--Typography-Secondary, #4D4D49)" }}
          >
            Note: Your invoices may use different terminology. Our system will
            try to match the closest equivalent.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            p: 2,
            backgroundColor: "var(--Nuetrals-Background---Secondary, #F9F8F4)",
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "6.25rem",
              width: "7rem",
              border: "1px solid var(--Nuetrals-Border---1, #ABA898)",
            }}
          >
            <Typography variant="body3medium14">Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CustomPricingSection;
