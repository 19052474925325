import React from "react";
import { Svg, Path } from "@react-pdf/renderer";
import { styles } from "./StyleSheets";

export const MilestoneIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 21 21">
    <Path
      d="M3 18.9463H18"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.16665 7.59668H3.83333C3.375 7.59668 3 7.97168 3 8.43001V15.6133C3 16.0717 3.375 16.4467 3.83333 16.4467H5.16665C5.62498 16.4467 5.99998 16.0717 5.99998 15.6133V8.43001C5.99998 7.97168 5.62498 7.59668 5.16665 7.59668Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.1666 4.93848H9.83333C9.375 4.93848 9 5.31348 9 5.77181V15.6135C9 16.0718 9.375 16.4468 9.83333 16.4468H11.1666C11.625 16.4468 12 16.0718 12 15.6135V5.77181C12 5.31348 11.625 4.93848 11.1666 4.93848Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.1666 2.28027H15.8333C15.375 2.28027 15 2.65527 15 3.11361V15.6136C15 16.0719 15.375 16.4469 15.8333 16.4469H17.1666C17.625 16.4469 18 16.0719 18 15.6136V3.11361C18 2.65527 17.625 2.28027 17.1666 2.28027Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const MissionIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.34965 5.0388C2.29132 6.4388 1.66632 8.18047 1.66632 10.0638C1.66632 14.6638 5.39965 18.3971 9.99965 18.3971C14.5997 18.3971 18.333 14.6638 18.333 10.0638C18.333 5.4638 14.5997 1.73047 9.99965 1.73047"
    />
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.16632 10.0638C4.16632 13.2888 6.77465 15.8971 9.99965 15.8971C13.2247 15.8971 15.833 13.2888 15.833 10.0638C15.833 6.8388 13.2247 4.23047 9.99965 4.23047"
    />
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.99963 13.3971C11.8413 13.3971 13.333 11.9055 13.333 10.0638C13.333 8.22214 11.8413 6.73047 9.99963 6.73047"
    />
  </Svg>
);

export const VerticalBar = () => (
  <Svg style={styles.verticalBarIcon} viewBox="0 0 6 21">
    <Path
      fill="#2961A7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-8.74228e-07 20.1543L0 0.154297L6 0.154297L6 20.1543L-8.74228e-07 20.1543Z"
    />
  </Svg>
);

export const ServiceIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M12.9917 10.8303C15.3527 10.8303 17.2667 8.91629 17.2667 6.55528C17.2667 4.19426 15.3527 2.28027 12.9917 2.28027C10.6307 2.28027 8.71667 4.19426 8.71667 6.55528C8.71667 8.91629 10.6307 10.8303 12.9917 10.8303Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      fill="none"
    />
    <Path
      d="M5.29999 16.813C6.71752 16.813 7.86667 15.6639 7.86667 14.2464C7.86667 12.8288 6.71752 11.6797 5.29999 11.6797C3.88246 11.6797 2.73334 12.8288 2.73334 14.2464C2.73334 15.6639 3.88246 16.813 5.29999 16.813Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      fill="none"
    />
    <Path
      d="M13.85 18.9464C15.0282 18.9464 15.9833 17.9912 15.9833 16.813C15.9833 15.6348 15.0282 14.6797 13.85 14.6797C12.6718 14.6797 11.7167 15.6348 11.7167 16.813C11.7167 17.9912 12.6718 18.9464 13.85 18.9464Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      fill="none"
    />
  </Svg>
);

export const CompanyStoryIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.683 6.76719C15.7163 8.80052 15.7163 12.1005 13.683 14.1338C11.6497 16.1672 8.34965 16.1672 6.31632 14.1338C4.28299 12.1005 4.28299 8.80052 6.31632 6.76719C8.34965 4.73385 11.6497 4.73385 13.683 6.76719Z"
    />
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.87463 18.1753C5.20796 17.5087 3.74963 16.3003 2.78296 14.6253C1.83296 12.9837 1.51629 11.1587 1.74129 9.41699"
    />
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.87463 3.87506C6.2913 2.76673 8.0663 2.1084 9.99963 2.1084C11.8913 2.1084 13.633 2.75005 15.033 3.81672"
    />
    <Path
      fill="none"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.1246 18.1753C14.7913 17.5087 16.2496 16.3003 17.2163 14.6253C18.1663 12.9837 18.483 11.1587 18.258 9.41699"
    />
  </Svg>
);

export const LicenseIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 21 21">
    <Path
      d="M8.25836 12.4281L9.50836 13.6781L12.8417 10.3447"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.83335 5.17806H12.1667C13.8334 5.17806 13.8334 4.34473 13.8334 3.51139C13.8334 1.84473 13 1.84473 12.1667 1.84473H8.83335C8.00002 1.84473 7.16669 1.84473 7.16669 3.51139C7.16669 5.17806 8.00002 5.17806 8.83335 5.17806Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.8333 3.52734C16.6083 3.67734 18 4.70234 18 8.51068V13.5107C18 16.844 17.1667 18.5107 13 18.5107H8C3.83333 18.5107 3 16.844 3 13.5107V8.51068C3 4.71068 4.39167 3.67734 7.16667 3.52734"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const InsuranceIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M8.74167 2.03652L4.58334 3.60319C3.62501 3.96152 2.84167 5.09486 2.84167 6.11152V12.3032C2.84167 13.2865 3.49167 14.5782 4.28334 15.1699L7.86667 17.8449C9.04167 18.7282 10.975 18.7282 12.15 17.8449L15.7333 15.1699C16.525 14.5782 17.175 13.2865 17.175 12.3032V6.11152C17.175 5.08652 16.3917 3.95319 15.4333 3.59486L11.275 2.03652C10.5667 1.77819 9.43334 1.77819 8.74167 2.03652Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.54166 10.0698L8.88332 11.4115L12.4667 7.82812"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const CostIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M7.22656 12.5828C7.22656 13.6578 8.05156 14.5245 9.07656 14.5245H11.1682C12.0599 14.5245 12.7849 13.7661 12.7849 12.8328C12.7849 11.8161 12.3432 11.4578 11.6849 11.2245L8.32656 10.0578C7.66823 9.82448 7.22656 9.46615 7.22656 8.44948C7.22656 7.51615 7.95156 6.75781 8.84323 6.75781H10.9349C11.9599 6.75781 12.7849 7.62448 12.7849 8.69948"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 5.6416V15.6416"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.99996 18.9753C14.6023 18.9753 18.3333 15.2443 18.3333 10.6419C18.3333 6.03955 14.6023 2.30859 9.99996 2.30859C5.39759 2.30859 1.66663 6.03955 1.66663 10.6419C1.66663 15.2443 5.39759 18.9753 9.99996 18.9753Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const PreparedByIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 20">
    <Path
      d="M14.1667 17.5H5.83335C2.50002 17.5 1.66669 16.6667 1.66669 13.3333V6.66667C1.66669 3.33333 2.50002 2.5 5.83335 2.5H14.1667C17.5 2.5 18.3334 3.33333 18.3334 6.66667V13.3333C18.3334 16.6667 17.5 17.5 14.1667 17.5Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.6667 6.66699H15.8334"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.5 10H15.8333"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.1667 13.333H15.8334"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.08335 9.40827C7.91637 9.40827 8.59168 8.73296 8.59168 7.89993C8.59168 7.0669 7.91637 6.3916 7.08335 6.3916C6.25032 6.3916 5.57501 7.0669 5.57501 7.89993C5.57501 8.73296 6.25032 9.40827 7.08335 9.40827Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10 13.6085C9.88335 12.4001 8.92502 11.4501 7.71669 11.3418C7.30002 11.3001 6.87502 11.3001 6.45002 11.3418C5.24169 11.4585 4.28335 12.4001 4.16669 13.6085"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const ProjectIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 20">
    <Path
      d="M6.66666 10.167H12.5"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.66666 13.5H10.3167"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.33332 5.00033H11.6667C13.3333 5.00033 13.3333 4.16699 13.3333 3.33366C13.3333 1.66699 12.5 1.66699 11.6667 1.66699H8.33332C7.49999 1.66699 6.66666 1.66699 6.66666 3.33366C6.66666 5.00033 7.49999 5.00033 8.33332 5.00033Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.3333 3.34961C16.1083 3.49961 17.5 4.52461 17.5 8.33294V13.3329C17.5 16.6663 16.6667 18.3329 12.5 18.3329H7.5C3.33333 18.3329 2.5 16.6663 2.5 13.3329V8.33294C2.5 4.53294 3.89167 3.49961 6.66667 3.34961"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const ExecutiveSummaryIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 20">
    <Path
      d="M7.50002 18.3337H12.5C16.6667 18.3337 18.3334 16.667 18.3334 12.5003V7.50032C18.3334 3.33366 16.6667 1.66699 12.5 1.66699H7.50002C3.33335 1.66699 1.66669 3.33366 1.66669 7.50032V12.5003C1.66669 16.667 3.33335 18.3337 7.50002 18.3337Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.125 7.5H6.875"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13.125 12.5H6.875"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const VisionIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M12.9828 10.141C12.9828 11.791 11.6494 13.1244 9.99945 13.1244C8.34945 13.1244 7.01611 11.791 7.01611 10.141C7.01611 8.49105 8.34945 7.15771 9.99945 7.15771C11.6494 7.15771 12.9828 8.49105 12.9828 10.141Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.99938 17.0329C12.941 17.0329 15.6827 15.2995 17.591 12.2995C18.341 11.1245 18.341 9.14954 17.591 7.97454C15.6827 4.97454 12.941 3.24121 9.99938 3.24121C7.05771 3.24121 4.31605 4.97454 2.40771 7.97454C1.65771 9.14954 1.65771 11.1245 2.40771 12.2995C4.31605 15.2995 7.05771 17.0329 9.99938 17.0329Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const KeyDeliverableIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M2.64111 6.80176L9.99945 11.0601L17.3078 6.82676"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.99951 18.6101V11.0518"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.27454 2.66814L3.82454 5.1348C2.81621 5.69314 1.99121 7.09314 1.99121 8.24314V12.9515C1.99121 14.1015 2.81621 15.5015 3.82454 16.0598L8.27454 18.5348C9.22454 19.0598 10.7829 19.0598 11.7329 18.5348L16.1829 16.0598C17.1912 15.5015 18.0162 14.1015 18.0162 12.9515V8.24314C18.0162 7.09314 17.1912 5.69314 16.1829 5.1348L11.7329 2.6598C10.7745 2.1348 9.22454 2.1348 8.27454 2.66814Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const DesignStyleIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 21 21">
    <Path
      d="M18.6751 3.4097C17.3918 6.6097 14.1751 10.9597 11.4834 13.118L9.84176 14.4347C9.63343 14.5847 9.4251 14.718 9.19176 14.8097C9.19176 14.6597 9.18343 14.493 9.15843 14.3347C9.06676 13.6347 8.7501 12.9847 8.19176 12.4264C7.6251 11.8597 6.93343 11.5264 6.2251 11.4347C6.05843 11.4264 5.89176 11.4097 5.7251 11.4264C5.81676 11.168 5.95843 10.9264 6.13343 10.7264L7.43343 9.0847C9.58343 6.39303 13.9501 3.1597 17.1418 1.8847C17.6334 1.70136 18.1084 1.8347 18.4084 2.14303C18.7251 2.45136 18.8751 2.92636 18.6751 3.4097Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9.19173 14.8096C9.19173 15.7263 8.84173 16.6013 8.1834 17.268C7.67507 17.7763 6.9834 18.1263 6.1584 18.2346L4.1084 18.4596C2.99173 18.5846 2.0334 17.6346 2.16673 16.5013L2.39173 14.4513C2.59173 12.6263 4.11673 11.4596 5.7334 11.4263C5.90007 11.418 6.07507 11.4263 6.2334 11.4346C6.94173 11.5263 7.6334 11.8513 8.20006 12.4263C8.7584 12.9846 9.07506 13.6346 9.16673 14.3346C9.17506 14.493 9.19173 14.6513 9.19173 14.8096Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.3665 12.1848C12.3665 10.0098 10.5998 8.24316 8.4248 8.24316"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const SpecialRequirementsIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M2.9167 17.21C3.60837 17.9017 4.72503 17.9017 5.4167 17.21L16.25 6.37666C16.9417 5.68499 16.9417 4.56833 16.25 3.87666C15.5584 3.18499 14.4417 3.18499 13.75 3.87666L2.9167 14.71C2.22503 15.4017 2.22503 16.5183 2.9167 17.21Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.0083 7.61816L12.5083 5.11816"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.0835 2.15964L8.3335 1.79297L7.96683 3.04297L8.3335 4.29297L7.0835 3.9263L5.8335 4.29297L6.20016 3.04297L5.8335 1.79297L7.0835 2.15964Z"
      stroke="#2961A7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M3.75 7.15964L5 6.79297L4.63333 8.04297L5 9.29297L3.75 8.9263L2.5 9.29297L2.86667 8.04297L2.5 6.79297L3.75 7.15964Z"
      stroke="#2961A7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.25 11.3266L17.5 10.96L17.1333 12.21L17.5 13.46L16.25 13.0933L15 13.46L15.3667 12.21L15 10.96L16.25 11.3266Z"
      stroke="#2961A7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const BuildingIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M1.6665 18.8677H18.3332"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.1667 2.20068H5.83333C3.33333 2.20068 2.5 3.69235 2.5 5.53402V18.8673H17.5V5.53402C17.5 3.69235 16.6667 2.20068 14.1667 2.20068Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.8335 14.2842H8.3335"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.6665 14.2842H14.1665"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.8335 10.5342H8.3335"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.6665 10.5342H14.1665"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.8335 6.78418H8.3335"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.6665 6.78418H14.1665"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const DocumentIcon = () => (
  <Svg style={styles.icon} viewBox="0 0 20 21">
    <Path
      d="M18.3327 9.01188V13.1785C18.3327 17.3452 16.666 19.0119 12.4993 19.0119H7.49935C3.33268 19.0119 1.66602 17.3452 1.66602 13.1785V8.17855C1.66602 4.01188 3.33268 2.34521 7.49935 2.34521H11.666"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.3327 9.01188H14.9993C12.4993 9.01188 11.666 8.17855 11.666 5.67855V2.34521L18.3327 9.01188Z"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.83301 11.5122H10.833"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.83301 14.8452H9.16634"
      stroke="#2961A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
