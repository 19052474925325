// src/components/FileUpload/LoadingPage.js

import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import CustomTypography from "../CustomComponents/CustomTypography";
import { ReactComponent as CircularSVG } from "../../static/Icons/circular.svg";
import { Box } from "@mui/material";

// Define a spin animation keyframes
const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingPageFrame = styled(Box)(() => ({
  display: "flex",
  width: "25.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.9375rem",
}));

const LoadingPageInnerFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
  alignSelf: "stretch",
}));

const LoadingPageMainText = styled(CustomTypography)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Primary, #1D1D1B)",
  textAlign: "center",
  fontFamily: '"Atyp Display TRIAL"',
  fontSize: "1.9375rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

const LoadingPageSubText = styled(CustomTypography)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Secondary, #666660)",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "1.0625rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
}));

const LoadingIcon = styled(CircularSVG)(() => ({
  width: "3rem",
  height: "3rem",
  animation: `${spinAnimation} 2s linear infinite`,
}));

// Updated LoadingPage component to accept custom text props
const LoadingPage = ({ mainText, subText }) => {
  return (
    <LoadingPageFrame>
      <LoadingPageInnerFrame>
        <LoadingPageMainText variant="h2">
          {mainText || "Plans & Prompt Received"}
        </LoadingPageMainText>
        <LoadingPageSubText variant="body1">
          {subText || "We are analyzing your plans, this might take a moment"}
        </LoadingPageSubText>
      </LoadingPageInnerFrame>
      <LoadingIcon />
    </LoadingPageFrame>
  );
};

export default LoadingPage;
