export const normalizeTakeOffData = (takeOffData) => {
  // If no data or not an object, return a blank structure
  if (!takeOffData || typeof takeOffData !== "object") {
    return {
      divisionOrder: [],
    };
  }

  // Deep clone
  const clonedData = JSON.parse(JSON.stringify(takeOffData));

  // Gather possible division keys
  const possibleDivisionKeys = Object.keys(clonedData).filter(
    (k) => k !== "divisionOrder"
  );

  // If divisionOrder doesn’t exist or is empty, build it
  if (
    !Array.isArray(clonedData.divisionOrder) ||
    clonedData.divisionOrder.length === 0
  ) {
    clonedData.divisionOrder = possibleDivisionKeys;
  }

  // For each division
  clonedData.divisionOrder.forEach((divKey) => {
    const division = clonedData[divKey];
    if (!division || typeof division !== "object") return;

    // Ensure subdivisions
    if (!division.subdivisions || typeof division.subdivisions !== "object") {
      division.subdivisions = {};
    }

    // If no subdivisionOrder, build it
    if (
      !Array.isArray(division.subdivisionOrder) ||
      division.subdivisionOrder.length === 0
    ) {
      division.subdivisionOrder = Object.keys(division.subdivisions);
    }

    // For each subdivision
    division.subdivisionOrder.forEach((subKey) => {
      const subdivision = division.subdivisions[subKey];
      if (!subdivision || typeof subdivision !== "object") return;

      if (!subdivision.items || typeof subdivision.items !== "object") {
        subdivision.items = {};
      }

      if (
        !Array.isArray(subdivision.itemOrder) ||
        subdivision.itemOrder.length === 0
      ) {
        subdivision.itemOrder = Object.keys(subdivision.items);
      }

      // Normalize numeric fields
      subdivision.itemOrder.forEach((itemKey) => {
        const item = subdivision.items[itemKey];
        if (!item) return;

        item.quantity = toNumber(item.quantity);
        item.unit_price_material = toNumber(item.unit_price_material);
        item.unit_price_labor = toNumber(item.unit_price_labor);
        item.extended_price = toNumber(item.extended_price);
        item.markup_percent = toNumber(item.markup_percent);
        item.markup_amount = toNumber(item.markup_amount);
        item.tax_percent = toNumber(item.tax_percent);
        item.tax_amount = toNumber(item.tax_amount);
        item.total_price = toNumber(item.total_price);
        item.unit = item.unit || "";
      });
    });
  });

  return clonedData;
};

function toNumber(val) {
  const num = parseFloat(val);
  return Number.isFinite(num) ? num : 0;
}

/**
 * Generate default settings for each division:
 *  - All columns visible
 *  - No expansions
 *  - activePreset = 'standard'
 */
export const generateSettings = (divisionData) => {
  const defaultColumns = {
    name: true,
    unit: true,
    quantity: true,
    materialPrice: true,
    laborPrice: true,
    extendedPrice: true,
    markupPercent: true,
    markupAmount: true,
    taxPercent: true,
    taxAmount: true,
    total: true,
    wasteFactor: true,
  };

  if (!divisionData?.divisionOrder?.length) {
    return {
      activePreset: "standard",
      expandedDivisions: {},
      expandedSubdivisions: {},
    };
  }

  const baseSettings = {};
  divisionData.divisionOrder.forEach((divKey) => {
    baseSettings[divKey] = {
      columns: { ...defaultColumns },
    };
  });

  baseSettings.activePreset = "standard";
  baseSettings.expandedDivisions = {};
  baseSettings.expandedSubdivisions = {};
  return baseSettings;
};

/**
 * Build final data for PDF or other usage, respecting column toggles from settings.
 * We'll also compute a 'subdivisionTotal' for each subdivision.
 */
export const createExportData = (divisionData, settings = {}) => {
  // Fallback columns (if no settings for a division)
  const globalColumns = {
    name: true,
    unit: true,
    quantity: true,
    wasteFactor: true,
    materialPrice: true,
    laborPrice: true,
    extendedPrice: true,
    markupPercent: true,
    markupAmount: true,
    taxPercent: true,
    taxAmount: true,
    total: true,
  };

  const getColumnsForDivision = (divKey) => {
    return settings[divKey]?.columns || globalColumns;
  };

  const sumSubdivisionTotal = (subdivision) => {
    if (!subdivision?.itemOrder) return 0;
    return subdivision.itemOrder.reduce((acc, itemKey) => {
      const it = subdivision.items[itemKey];
      return acc + (it?.total_price || 0);
    }, 0);
  };

  const calculateDivisionTotal = (division) => {
    if (!division?.subdivisionOrder) return 0;
    let sum = 0;
    division.subdivisionOrder.forEach((subKey) => {
      const subdivision = division.subdivisions[subKey];
      if (subdivision) {
        sum += sumSubdivisionTotal(subdivision);
      }
    });
    return sum;
  };

  const calculateGrandTotal = () => {
    if (!divisionData?.divisionOrder) return 0;
    let total = 0;
    divisionData.divisionOrder.forEach((divKey) => {
      if (divKey === "General_Info") return;
      const division = divisionData[divKey];
      if (division) {
        total += calculateDivisionTotal(division);
      }
    });
    return total;
  };

  const divisions =
    divisionData.divisionOrder
      ?.filter((divKey) => divKey !== "General_Info")
      .map((divKey) => {
        const division = divisionData[divKey];
        if (!division) return null;

        const col = getColumnsForDivision(divKey);
        const subdivisions =
          division.subdivisionOrder?.map((subKey) => {
            const subdiv = division.subdivisions[subKey];
            if (!subdiv) return null;

            const items =
              subdiv.itemOrder?.map((itemKey) => {
                const it = subdiv.items[itemKey];
                if (!it) return null;

                // Respect toggles by setting column fields to null if false
                return {
                  name: col.name ? it.name : null,
                  unit: col.unit ? it.unit : null,
                  quantity: col.quantity ? it.quantity : null,
                  wasteFactor: col.wasteFactor ? it.waste_factor_percent : null,
                  materialPrice: col.materialPrice
                    ? it.unit_price_material
                    : null,
                  laborPrice: col.laborPrice ? it.unit_price_labor : null,
                  extendedPrice: col.extendedPrice ? it.extended_price : null,
                  markupPercent: col.markupPercent ? it.markup_percent : null,
                  markupAmount: col.markupAmount ? it.markup_amount : null,
                  taxPercent: col.taxPercent ? it.tax_percent : null,
                  taxAmount: col.taxAmount ? it.tax_amount : null,
                  total: col.total ? it.total_price : null,
                };
              }) || [];

            return {
              subdivisionKey: subKey,
              subdivisionName: subdiv.name || subKey,
              subdivisionTotal: sumSubdivisionTotal(subdiv), // <== store the total
              items,
            };
          }) || [];

        return {
          divisionKey: divKey,
          divisionName: division.name || divKey,
          divisionTotal: calculateDivisionTotal(division),
          columns: col,
          subdivisions,
        };
      })
      .filter(Boolean) || [];

  return {
    divisions,
    grandTotal: calculateGrandTotal(),
  };
};
