import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";

const MaterialSpecificationsEditor = ({ onSave, data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);

  const handleSubmit = () => {
    setIsEditing(false);
    onSave?.(formData);
  };

  // Handles changes in array items
  const handleListItemChange = (field, index, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].map((item, i) => (i === index ? value : item)),
    }));
  };

  // Add new item to an array field
  const handleAddListItem = (field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: [...prev[field], "New Item"],
    }));
  };

  // Remove item from an array field
  const handleRemoveListItem = (field, index) => {
    setFormData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  return (
    <Box
      sx={{
        // Define a maximum height so we can scroll
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* Sticky header for Edit/Save button */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          onClick={() => (isEditing ? handleSubmit() : setIsEditing(true))}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      {/* Scrollable content section */}
      <Box sx={{ overflowY: "auto", p: 2, flexGrow: 1 }}>
        {/* Structural Materials */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Structural Materials
          </Typography>
          <List>
            {formData.structuralMaterials.map((material, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleRemoveListItem("structuralMaterials", index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={material}
                    onChange={(e) =>
                      handleListItemChange(
                        "structuralMaterials",
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Typography>• {material}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("structuralMaterials")}
              sx={{ mt: 1 }}
            >
              Add Material
            </Button>
          )}
        </Box>

        {/* Exterior Materials */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Exterior Materials
          </Typography>
          <List>
            {formData.exteriorMaterials.map((material, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleRemoveListItem("exteriorMaterials", index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={material}
                    onChange={(e) =>
                      handleListItemChange(
                        "exteriorMaterials",
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Typography>• {material}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("exteriorMaterials")}
              sx={{ mt: 1 }}
            >
              Add Material
            </Button>
          )}
        </Box>

        {/* Interior Finishes */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Interior Finishes
          </Typography>
          <List>
            {formData.interiorFinishes.map((finish, index) => (
              <ListItem
                key={index}
                sx={{ py: 0 }}
                secondaryAction={
                  isEditing && (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleRemoveListItem("interiorFinishes", index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )
                }
              >
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={finish}
                    onChange={(e) =>
                      handleListItemChange(
                        "interiorFinishes",
                        index,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Typography>• {finish}</Typography>
                )}
              </ListItem>
            ))}
          </List>
          {isEditing && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddListItem("interiorFinishes")}
              sx={{ mt: 1 }}
            >
              Add Finish
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MaterialSpecificationsEditor;
