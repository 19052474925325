// src/services/jobService.js

import apiAxios from "./axiosConfig";
import { store } from "../store";
import { addRecentTakeoff, removeRecentTakeoff } from "../store"; // Redux action to store in recentTakeoffs

/**
 * For any request returning 404 or an explicit "error" message from the server,
 * we skip caching. That way we don't store "Takeoff not generated yet..."
 * as if it were real data, or accidentally store job #582's data under #583.
 */

// Create a new job with prompt
export const createJob = async (jobId, prompt) => {
  try {
    const payload = { job_id: jobId, prompt };
    const response = await apiAxios.post("/jobs", payload);
    return response.data;
  } catch (error) {
    console.error("Error updating job prompt:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "An unexpected error occurred." };
  }
};

// Create a new job with no prompt
export const createJobNoPrompt = async (fileUrl, jobId) => {
  try {
    const payload = { file_url: fileUrl, job_id: jobId };
    const response = await apiAxios.post("/jobs/no_prompt", payload);
    return response.data;
  } catch (error) {
    console.error("Error creating job with no prompt:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "An unexpected error occurred." };
  }
};

export const updateJobPrompt = async (jobId, prompt) => {
  try {
    const payload = { prompt };
    const response = await apiAxios.put(`/jobs/${jobId}/prompt`, payload);
    return response.data;
  } catch (error) {
    console.error("Error updating job prompt:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "An unexpected error occurred." };
  }
};

export const getJobs = async () => {
  try {
    const response = await apiAxios.get("/jobs");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "Failed to fetch jobs. Please try again." };
  }
};

export const getSnapshotData = async (jobId) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/snapshot`);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status >= 500) {
        return { error: error.response.data.error || "Server error" };
      } else {
        return {
          error:
            error.response.data.error ||
            "Snapshot is not generated. Waiting for response",
        };
      }
    }
    return { error: "An unexpected error occurred." };
  }
};

/**
 * Update the takeoff data for a job, then store it in Redux's recentTakeoffs
 * if and only if it's valid.
 */
export const updateTakeoffData = async (jobId, updatedData) => {
  try {
    const response = await apiAxios.put(`/jobs/${jobId}/takeoff`, updatedData);
    const updatedTakeoff = response.data;
    // If there's no error, store it in Redux
    if (!updatedTakeoff?.error) {
      console.log(`[jobService] Updated and caching job ${jobId} takeoff.`);
      store.dispatch(addRecentTakeoff({ jobId, takeoff: updatedTakeoff }));
    }
    return updatedTakeoff;
  } catch (error) {
    console.error("Error updating takeoff data:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "Failed to update takeoff data. Please try again." };
  }
};

export const getTakeoffData = async (inJobId, options = { forceRefresh: false }) => {
  const jobId = inJobId.toString();
  console.log("Fetching takeoff data for job:", jobId);
  console.log("Options:", options);

  if (!options.forceRefresh) {
    const state = store.getState();
    const cachedTakeoffs = state.recentTakeoffs?.list || [];
    console.log("Cached takeoffs:", cachedTakeoffs);
    // If we have a cache entry for this job & no .error => return it
    const cached = cachedTakeoffs.find((t) => t.jobId === jobId);
    if (cached && cached.takeoff && !cached.takeoff.error) {
      console.log(`Returning cached takeoff data for job: ${jobId}`);
      return cached.takeoff;
    }
  }

  console.log(`Fetching takeoff data from backend for job: ${jobId}`);
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/takeoff`);
    const data = response.data;

    if (data && data.error) {
      // e.g. server responded 200 but with {error: "..."}
      console.warn(`[jobService] For job ${jobId}, data.error:`, data.error);
      return { error: data.error };
    }

    // Otherwise, real data
    console.log(`[jobService] Caching fresh takeoff for job ${jobId}.`);
    store.dispatch(addRecentTakeoff({ jobId, takeoff: data }));
    return data;
  } catch (error) {
    // Usually 404 or 500
    if (error.response) {
      const status = error.response.status;
      const msg = error.response.data?.error || "Failed to fetch takeoff.";
      console.log(`Got ${status} for job ${jobId}:`, msg);

      // If we got a 404 => remove any stale cache for that job
      if (status === 404) {
        console.warn(`[jobService] Removing stale cache for job ${jobId} since it's 404.`);
        store.dispatch(removeRecentTakeoff(jobId));
        return { error: msg };
      }

      return { error: msg };
    }
    console.error("Unknown error fetching takeoff data:", error);
    return { error: "Failed to fetch takeoff data. Please try again." };
  }
};




export const uploadFileWithPolicy = async (policyData, file, setProgress) => {
  try {
    const formData = new FormData();
    Object.entries(policyData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    const response = await apiAxios.post(policyData.url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });
    if (response.status !== 204 && response.status !== 201) {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const getJobImages = async (jobId) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/images`);
    return response.data;
  } catch (error) {
    console.error("Error fetching job images:", error.response?.data);
    throw error;
  }
};

export const getChatMessages = async (jobId, limit = 20, offset = 0) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/chat`, {
      params: { limit, offset },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching chat messages:", error.response?.data);
    return { messages: [], total_count: 0 };
  }
};

export const sendChatMessage = async (jobId, message) => {
  try {
    const response = await apiAxios.post(`/jobs/${jobId}/chat`, { message });
    return response.data;
  } catch (error) {
    console.error("Error sending chat message:", error.response?.data);
    return [];
  }
};

export const getJobName = async (jobId) => {
  try {
    const response = await apiAxios.get(`/jobs/${jobId}/name`);
    return response.data;
  } catch (error) {
    console.error("Error fetching job name:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    }
    return { error: "Failed to fetch job name" };
  }
};

export const getJobsList = async () => {
  try {
    const response = await apiAxios.get("/jobs");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.response?.data);
    throw error;
  }
};

export const updateJobName = async (jobId, newName) => {
  try {
    const payload = { job_name: newName };
    const response = await apiAxios.put(`/jobs/${jobId}/name`, payload);
    return response.data;
  } catch (error) {
    console.error("Error updating job name:", error.response?.data);
    throw error;
  }
};

export const archiveJob = async (jobId) => {
  try {
    const response = await apiAxios.put(`/jobs/${jobId}/archive`);
    return response.data;
  } catch (error) {
    console.error("Error archiving job:", error.response?.data);
    throw error;
  }
};
