// src/components/Authentication/Signup.jsx
import React, { useState, useContext } from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Link,
  styled,
  Box
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../AlertMessage';

// --- Styled Components (mirroring the style in Login.jsx) ---

const SignupFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1.5rem',
}));

const WelcomeFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}));

const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  width: '100%',
}));

const InputFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const SignupButtonFrame = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
}));

// This matches the same style overrides used in your Login page.
const TextInputFrame = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '3.02144rem',
    background: theme.palette.neutrals.background.input2,
    borderRadius: '0.94419rem',
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '0.69244rem 1.00713rem',
      // Override autofill styles so that the background and text color remain consistent.
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.neutrals.background.input2} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
}));

function Signup({ onSwitchToLogin }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!companyName.trim()) {
      setError('Company name is required');
      return;
    }

    try {
      const response = await signup(username, email, password, companyName);
      if (response) {
        setSuccessMessage('User created successfully');
        navigate('/chat');
      }
    } catch (error) {
      if (
        error.response?.data?.error ===
        'Password does not meet security requirements'
      ) {
        setError(
          `Password must be at least 12 characters long and contain:
           - At least one uppercase letter
           - At least one lowercase letter
           - At least one digit
           - At least one special character`
        );
      } else {
        setError(error.response?.data?.error || 'Signup failed');
      }
    }
  };

  return (
    <div>
      {/* You can wrap this in a CardComponent if you prefer, 
          but here we’re matching the same layout style as the new Login page. */}
      <SignupFrame>
        {/* Header / Welcome text */}
        <WelcomeFrame>
          <Typography variant="welcometext" align="center" sx={{ color: 'typographyPrimary.main' }}>
            Create An Account
          </Typography>
          <Typography variant="logintext" align="center" sx={{ color: 'typographySecondary.main' }} my={1}>
            Please fill in the details to get started with Assembli
          </Typography>
        </WelcomeFrame>

        {/* Form Section */}
        <FormFrame>
          <form onSubmit={handleSignup}>
            <InputFrame>
              <Typography variant="body2medium16" sx={{ mb: 1 }}>
                Company Name
              </Typography>
              <TextInputFrame
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="organization"
              />

              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Username
              </Typography>
              <TextInputFrame
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="username"
              />

              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Email
              </Typography>
              <TextInputFrame
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="email"
              />

              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Password
              </Typography>
              <TextInputFrame
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Password Requirements (Optional styling) */}
              <Box sx={{ mt: 1, mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Password must contain:
                </Typography>
                <ul style={{ marginLeft: '1.25rem', marginTop: '0.5rem' }}>
                  <li>At least 12 characters</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one number</li>
                  <li>At least one special character</li>
                </ul>
              </Box>
            </InputFrame>

            <SignupButtonFrame type="submit" variant="ghost" color="primary">
              Sign Up
            </SignupButtonFrame>
          </form>
        </FormFrame>
      </SignupFrame>

      {/* Alert Messages */}
      {error && (
        <AlertMessage message={error} type="error" onClose={() => setError('')} />
      )}
      {successMessage && (
        <AlertMessage
          message={successMessage}
          type="success"
          onClose={() => setSuccessMessage('')}
        />
      )}

      {/* Support & Switch to Login */}
      <Box mt={2}>
        <Typography variant="body2" align="center">
          Need help? Contact support at{' '}
          <Link
            href="mailto:support@assembli.ai"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            support@assembli.ai
          </Link>
        </Typography>
      </Box>

      {onSwitchToLogin && (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 2 }}
        >
          Already have an account?{' '}
          <Link
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
            onClick={onSwitchToLogin}
            style={{ cursor: 'pointer' }}
          >
            Login here
          </Link>
        </Typography>
      )}
    </div>
  );
}

export default Signup;
