// src/services/userService.js

import apiAxios from "./axiosConfig";

export const getCurrentUser = async () => {
  try {
    const response = await apiAxios.get("/user/me");
    return response.data;
  } catch (error) {
    console.error("Error fetching current user:", error.response?.data);
    throw error;
  }
};

export const getIsAdmin = async () => {
  try {
    const response = await apiAxios.get("/user/is_admin");
    return response.data;
  } catch (error) {
    console.error("Error checking admin status:", error.response?.data);
    throw error;
  }
};

export const inviteUser = async (email, role) => {
  try {
    const response = await apiAxios.post("/users/invite", { email, role });
    return response.data;
  } catch (error) {
    console.error("Error inviting user:", error.response?.data);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await apiAxios.get("/users");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error.response?.data);
    throw error;
  }
};

export const updateUserRole = async (email, role) => {
  try {
    const response = await apiAxios.put(`/users/${email}/role`, { role });
    return response.data;
  } catch (error) {
    console.error("Error updating user role:", error.response?.data);
    throw error;
  }
};

export const updateCurrentUser = async ({ name, email, phone }) => {
  try {
    // Map "name" to "username" for the backend
    const payload = { username: name, email, phone };
    const response = await apiAxios.put("/user/me", payload);
    return response.data;
  } catch (error) {
    console.error("Error updating current user:", error.response?.data);
    throw error;
  }
};

// Forgot password and reset password endpoints
export const forgotPassword = async (email) => {
  try {
    const response = await apiAxios.post("/user/forgot-password", { email });
    return response.data;
  } catch (error) {
    console.error("Error sending forgot password email:", error.response?.data);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await apiAxios.post(`/user/reset-password/${token}`, {
      password: newPassword,
    });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error.response?.data);
    throw error;
  }
};

export const forgotUsername = async (email) => {
  try {
    const response = await apiAxios.post("/user/forgot-username", { email });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error.response?.data);
    throw error;
  }
};

export const resetUsername = async (token, newUsername) => {
  try {
    const response = await apiAxios.post(`/user/reset-username/${token}`, {
      username: newUsername,
    });
    return response.data;
  } catch (error) {
    console.error("Error resetting username:", error.response?.data);
    throw error;
  }
};

export const deleteUserProfileImage = async () => {
  try {
    const response = await apiAxios.post("/delete-user-profile-image");
    return response.data;
  } catch (error) {
    console.error("Error deleting user profile image:", error);
    throw error;
  }
};

export const fetchUserProfileImage = async () => {
  try {
    const response = await apiAxios.get("/user-profile-image");
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile image:", error);
    throw error;
  }
};
