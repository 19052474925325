import React, { useState, useEffect, useContext } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container 
} from '@mui/material';
import {
  getJobs,
} from '../../services/jobService';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import AlertMessage from '../AlertMessage';
import Navbar from '../Navbar/Navbar';


import { AuthContext } from '../../context/authContext';


const ThankYouPage = ({ onCreateNewJob }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState('');


  const handleCreateNewJob = () => {
    window.open(process.env.REACT_APP_GOOGLE_FORM_URL, '_blank');
  };

  const handleJobClick = async (job) => {
    navigate('/thank-you');
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchJobs();
    } else {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const fetchJobs = async () => {
    try {
      const data = await getJobs();
      setJobs(data);
    } catch (error) {
      console.error('Failed to fetch jobs:', error);
      setError('Failed to load jobs. Please try again later.');
    }
  };

  return (
<>
<Navbar />

      {/* Sidebar */}
      <Sidebar
        open={false}
        onClose={()=>(false)}
        jobs={jobs}
        handleJobClick={handleJobClick}

        // handleNewJob={handleNewJob}
        // handleRenameJob={handleRenameJob}
      />
        {error && (
          <AlertMessage message={error} type="error" />
        )}
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          textAlign: 'center', 
          mt: 10, 
          p: 4, 
          borderRadius: 2, 
          boxShadow: 3 
        }}
      >

        <Typography variant="h4" gutterBottom>
          Thank You!
        </Typography>
        
        <Typography variant="body1" paragraph>
          Your Prompt and Plan has been successfully submitted. 
          
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateNewJob}
          >
            Please Fill out the Google Form
          </Button>
        </Box>
      </Box>
    </Container>

    </>
  );
};

export default ThankYouPage;