import React, { useState } from "react";
import { Button, Box, Tab, Tabs } from "@mui/material";
import JobSelector from "./JobSelector";
import ProposalsTable from "./ProposalsTable";
import ActivityLog from "./ActivityLog";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

const Dashboard = () => {
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  // Add jobs state
  const [jobs, setJobs] = useState([
    { id: 1, job_name: "Office Renovation", created_at: "2024-01-15" },
    { id: 2, job_name: "Retail Construction", created_at: "2024-01-20" },
  ]);

  const [proposals, setProposals] = useState([
    {
      id: "es-10010",
      job_id: 1,
      proposal_name: "Initial Phase Renovation",
      title: "Initial Office Renovation Proposal",
      status: "approved",
      total_amount: 125000.0,
      due_date: "2024-01-31",
      client_name: ["Acme Corp"],
    },
    {
      id: "es-10048",
      job_id: 1,
      proposal_name: "Revised Phase Renovation",
      title: "Revised Office Renovation Proposal",
      status: "approved",
      total_amount: 135000.0,
      due_date: "2024-02-29",
      client_name: ["Acme Corp"],
    },
    {
      id: "es-10023",
      job_id: 2,
      proposal_name: "Initial Retail Space",
      title: "Retail Space Proposal",
      status: "draft",
      total_amount: 350000.0,
      due_date: null,
      client_name: ["Retail Giants LLC"],
    },
  ]);

  const [activityLogs, setActivityLogs] = useState([
    {
      id: 1,
      proposal_id: "es-10010",
      user_name: "John Doe",
      action: "create",
      details: "Created initial proposal",
      timestamp: "2024-01-15T10:00:00",
    },
    {
      id: 2,
      proposal_id: "es-10010",
      user_name: "Jane Smith",
      action: "edit",
      details: "Updated total amount from $120,000 to $125,000",
      timestamp: "2024-01-16T14:30:00",
    },
  ]);

  const handleDuplicate = (proposal) => {
    const newProposal = {
      ...proposal,
      id: `es-${Math.floor(Math.random() * 90000) + 10000}`,
      status: "draft",
      proposal_name: `${proposal.proposal_name} (Copy)`,
      due_date: null,
    };

    const newLog = {
      id: activityLogs.length + 1,
      proposal_id: newProposal.id,
      user_name: "John Doe",
      action: "create",
      details: `Duplicated from proposal ${proposal.id}`,
      timestamp: new Date().toISOString(),
    };

    setProposals([...proposals, newProposal]);
    setActivityLogs([...activityLogs, newLog]);
  };

  const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index}>{value === index && children}</div>
  );

  return (
    <Box
      sx={{
        p: 3,
        marginLeft: "15.6rem", // Adjusted for Sidebar width
        overflowY: "auto", // Make content scrollable
        position: "relative", // Add this
        zIndex: 0, // Add this to ensure content stays below navbar
      }}
    >
      <Sidebar />
      {/* <Navbar /> */}
      {/* <Button
        color="inherit"
        sx={{ mx: 1 }}
        component={Link}
        to="/proposal_editor"
      >
        ...
      </Button>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <JobSelector
          selectedJob={selectedJob}
          jobs={jobs}
          onJobSelect={(e) => setSelectedJob(e.target.value)}
        />
      </Box> */}

      <Box sx={{ mb: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          sx={{ "& .MuiTabs-flexContainer": { justifyContent: "flex-start" } }}
        >
          <Tab
            sx={{
              padding: 1,
              gap: 0,
              backgroundColor: "transparent",
              minWidth: "auto",
              margin: 0,
              borderRadius: "inherit",
              fontSize: "0.7rem",
              "&.Mui-selected": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
              },
            }}
            label="Proposals"
          />
          <Tab
            sx={{
              padding: 1,
              gap: 0,
              backgroundColor: "transparent",
              minWidth: "auto",
              margin: 0,
              fontSize: "0.7rem",
              borderRadius: "inherit",
              "&.Mui-selected": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
              },
            }}
            label="Activity Log"
          />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} index={0}>
        <ProposalsTable onDuplicate={handleDuplicate} />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ActivityLog />
      </TabPanel>
    </Box>
  );
};

export default Dashboard;
