import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  styled,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";

import Cropper from "react-easy-crop";

import {
  getUploadPolicyUserProfile,
  uploadToGCS,
} from "../../services/uploadService";

// MUI Icons
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import {
  getCurrentUser,
  updateCurrentUser,
  fetchUserProfileImage,
  deleteUserProfileImage,
} from "../../services/userService";
import getCroppedImg from "./getCroppedImg"; // The crop utility

// Styled components (as in your existing code) ...
// --------------------------------------------------
const UserProfileFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "3.125rem",
  alignSelf: "stretch",
}));

// ... [rest of your styled components]
const UserProfileInnerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.375rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
}));

const UserProfileHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
}));

const ProfilePictureFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
}));

const ProfilePictureImageFrame = styled(Box)(() => ({
  display: "flex",
  width: "5.17356rem",
  height: "5.17356rem",
  padding: "1.36913rem 1.8255rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.50706rem",
  borderRadius: "2.58506rem",
  background: "#2961A7",
}));

const ProfilePictureText = styled(Typography)(() => ({
  color: "#FFF",
  fontSize: "1.97763rem",
  fontWeight: 600,
  lineHeight: "120%",
}));

const ProfilePictureButtonsFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.875rem",
}));

const PersonalInfoFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: "1.5625rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
}));

const PersonalInfoHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
  justifyContent: "space-between",
}));

const NameInputFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.25rem",
  alignSelf: "stretch",
}));

const NameInputField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    height: "3.02144rem",
    background: theme.palette.neutrals?.background?.input2 || "#F5F5F5",
    borderRadius: "0.94419rem",
    "& fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& input": {
      padding: "0.69244rem 1.00713rem",
      "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${
          theme.palette.neutrals?.background?.input2 || "#F5F5F5"
        } inset`,
        WebkitTextFillColor: theme.palette.text?.primary || "#000",
        transition: "background-color 5000s ease-in-out 0s",
      },
    },
  },
}));

const TeamMemberFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

// --------------------------------------------------
//               MAIN COMPONENT
// --------------------------------------------------
export default function UserProfile() {
  // Local states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // Crop states
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const [profileLogoUrl, setProfileLogoUrl] = useState(null);

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const data = await getCurrentUser();
        setName(data.name || "");
        setEmail(data.email || "");
        setPhone(data.phone || "");
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch user details.",
          severity: "error",
        });
      }
    };
    fetchUserDetails();
  }, []);

  // --------------------------------------------------
  //               HANDLERS
  // --------------------------------------------------
  // File Selection
  const handleSelectLogo = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setIsCropModalOpen(true);
  };

  const handleDeleteLogo = async () => {
    try {
      setProfileLogoUrl(null);
      const res = await deleteUserProfileImage();
    } catch (err) {
      console.error("Error deleting image:", err);
    }
  };
  // Crop Callbacks
  const onCropComplete = useCallback((_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  const handleCropConfirm = async () => {
    if (!selectedFile || !croppedAreaPixels) return;

    try {
      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(selectedFile),
        croppedAreaPixels,
        rotation
      );
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      const policy = await getUploadPolicyUserProfile(
        croppedFile.name,
        croppedFile.type
      );

      // Upload the file to GCS
      const publicUrl = await uploadToGCS(policy, croppedFile);

      const data = await fetchUserProfileImage();
      setProfileLogoUrl(data.logo);

      // Reset states
      setIsCropModalOpen(false);
      setSelectedFile(null);
      setRotation(0);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    } catch (err) {
      console.error("Error cropping image:", err);
      setSnackbar({
        open: true,
        message: `Error cropping: ${err.message}`,
        severity: "error",
      });
    }
  };

  // Rotation Helpers
  const handleRotateLeft = () => {
    setRotation((prev) => (prev - 90) % 360);
  };
  const handleRotateRight = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  // Zoom Helpers
  const handleZoomIn = () => {
    // Increase zoom up to some max (e.g., 3)
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };
  const handleZoomOut = () => {
    // Decrease zoom down to some min (e.g., 1)
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 1));
  };

  // Save user details
  const handleSave = async () => {
    try {
      const response = await updateCurrentUser({ name, email, phone });
      setSnackbar({
        open: true,
        message: response?.message || "User details updated successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to update user details:", error);
      let errorMsg = "Failed to update user details.";
      if (error.response?.data?.error) {
        errorMsg = error.response.data.error;
      }
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    }
  };

  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    const fetchlogo = async () => {
      try {
        const data = await fetchUserProfileImage();
        // console.log(data.logo);
        if (data.logo) {
          setProfileLogoUrl(data.logo);
        }
      } catch (error) {
        console.error("Failed to fetch profile logo:", error);
      }
    };
    fetchlogo();
  }, []);

  // --------------------------------------------------
  //             RENDER
  // --------------------------------------------------
  return (
    <>
      <UserProfileFrame>
        <Typography variant="h3semibold35">User Profile</Typography>

        {/* Profile Picture Section */}
        <UserProfileInnerFrame>
          <UserProfileHeaderFrame>
            <Typography variant="body1SemiBold17">Profile Picture</Typography>
          </UserProfileHeaderFrame>

          <ProfilePictureFrame>
            {profileLogoUrl ? (
              <Box
                sx={{
                  width: 75, // or any desired width
                  height: 75, // or any desired height
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  src={profileLogoUrl}
                  alt="Profile Logo"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ) : (
              <ProfilePictureImageFrame>
                <ProfilePictureText>
                  {name ? name.substring(0, 2).toUpperCase() : "JD"}
                </ProfilePictureText>
              </ProfilePictureImageFrame>
            )}

            <ProfilePictureButtonsFrame>
              <Button variant="ghost" component="label">
                Upload New
                <input
                  type="file"
                  hidden
                  onChange={handleSelectLogo}
                  accept="image/*"
                />
              </Button>
              <Button variant="indigo" onClick={handleDeleteLogo}>
                Delete
              </Button>
            </ProfilePictureButtonsFrame>
          </ProfilePictureFrame>
        </UserProfileInnerFrame>
      </UserProfileFrame>

      {/* Personal Info */}
      <PersonalInfoFrame>
        <PersonalInfoHeaderFrame>
          <Typography variant="body1SemiBold17">
            Personal Information
          </Typography>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </PersonalInfoHeaderFrame>

        <NameInputFrame>
          <Typography variant="body2Medium16">Name</Typography>
          <NameInputField
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </NameInputFrame>

        <TeamMemberFrame>
          <NameInputFrame>
            <Typography variant="body2Medium16">Email</Typography>
            <NameInputField
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </NameInputFrame>
        </TeamMemberFrame>

        <TeamMemberFrame>
          <NameInputFrame>
            <Typography variant="body2Medium16">Phone Number</Typography>
            <NameInputField
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </NameInputFrame>
        </TeamMemberFrame>
      </PersonalInfoFrame>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* CROP MODAL */}
      <Dialog
        open={isCropModalOpen}
        onClose={() => {
          setIsCropModalOpen(false);
          setSelectedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          sx={{
            position: "relative",
            width: "100%",
            height: 400,
            background: "#333",
          }}
        >
          {selectedFile && (
            <>
              {/* Cropper */}
              <Cropper
                image={URL.createObjectURL(selectedFile)}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                cropShape="rect" // or 'round'
                showGrid={true}
                aspect={1} // 1 for square
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />

              {/* ZOOM BUTTONS at TOP RIGHT */}
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={handleZoomIn}
                  color="primary"
                  aria-label="Zoom In"
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  onClick={handleZoomOut}
                  color="primary"
                  aria-label="Zoom Out"
                >
                  <ZoomOutIcon />
                </IconButton>
              </Box>
            </>
          )}
        </DialogContent>

        {/* ROTATION & SAVE/Cancel Controls */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <IconButton onClick={handleRotateLeft} color="primary">
            <RotateLeftIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleRotateRight} color="primary">
            <RotateRightIcon fontSize="large" />
          </IconButton>
        </Box>

        <DialogActions>
          <Button onClick={() => setIsCropModalOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCropConfirm}>
            Save Crop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
