import React, { useEffect, useState } from "react";
import { Box, Button, styled, Typography, TextField, MenuItem, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { ReactComponent as TrashIcon } from "../../static/Icons/trash.svg";
import RolePermissionsDialog from "./RolePermissions";
import { fetchUsers, updateUserRole, inviteUser } from "../../services/userService";

const UserProfileFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "3.125rem",
  alignSelf: "stretch",
  height: "100vh",
}));

const UserProfileInnerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.375rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "var(--Nuetrals-Background---Tertiary, #FFF)",
}));

const UserProfileHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  justifyContent: "space-between",
  borderBottom: "1px solid #D4D4D4",
}));

const ProfilePictureFrame = styled(Box)(() => ({
  display: "flex",
  height: "4.6875rem",
  padding: "0.5rem 0rem",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderBottom: "1px solid #eee",
}));

const ProfilePictureImageFrame = styled(Box)(() => ({
  display: "flex",
  width: "14.125rem",
  height: "3.125rem",
  alignItems: "center",
  gap: "0.75rem",
}));

const ProfilePictureImageInnerFrame = styled(Box)(() => ({
  display: "flex",
  width: "3.125rem",
  height: "3.125rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.30538rem",
  flexShrink: 0,
  borderRadius: "1.55675rem",
  background: "var(--Accents-Accent, #2961A7)",
}));

const ProfilePictureText = styled(Typography)(() => ({
  color: "var(--Typography-Primary-alt, #FFF)",
  fontSize: "1.19094rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

const ProfilePictureNameEmailFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.1875rem",
}));

const ProfilePictureButtonsFrame = styled(Box)(() => ({
  display: "flex",
  gap: "0.875rem",
  alignItems: "flex-end",
  flexDirection: "column",
}));

const RoleandTrashFrame = styled(Box)(() => ({
  display: "flex",
  width: "22.3125rem",
  alignItems: "flex-end",
  gap: "0.0625rem",
  justifyContent: "flex-end",
}));

const RoleFrame = styled(Box)(() => ({
  display: "flex",
  width: "8.3125rem",
  height: "1.8125rem",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "0.625rem",
}));

const TrashIconFrame = styled(Box)(() => ({
  display: "flex",
  height: "1.8125rem",
  padding: "0rem 1.25rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  borderRadius: "1.6875rem",
}));

const StyledTrashIcon = styled(TrashIcon)(() => ({
  display: "flex",
  width: "1.1875rem",
  height: "1.1875rem",
  justifyContent: "center",
  alignItems: "center",
}));

// Styled dropdown for user role
const RoleDropdown = styled(TextField)(() => ({
  width: "8.3125rem",
  "& .MuiOutlinedInput-root": {
    height: "1.8125rem",
    fontSize: "0.875rem",
    padding: "0 8px",
    borderRadius: "0.5rem"
  },
}));

const TEAM_ROLES = [
  { value: "Administrator", label: "Administrator" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Estimator", label: "Estimator" },
];

// ===== Invite User Modal =====
const InviteModalDialog = ({ open, onClose, onInvite }) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("");
  const [error, setError] = useState("");

  const handleInvite = async () => {
    if (!inviteEmail || !inviteRole) {
      setError("Both email and role are required");
      return;
    }
    try {
      await onInvite(inviteEmail, inviteRole);
      onClose();
    } catch (err) {
      setError(err.response?.data?.error || "Invitation failed");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invite a New Team Member</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
        />
        <TextField
          select
          label="Role"
          variant="outlined"
          fullWidth
          margin="normal"
          value={inviteRole}
          onChange={(e) => setInviteRole(e.target.value)}
        >
          {TEAM_ROLES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
        <Button onClick={handleInvite} variant="contained" color="primary">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function TeamManagement() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenInviteModal = () => {
    setInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setInviteModalOpen(false);
  };

  const fetchTeamUsers = async () => {
    try {
      const data = await fetchUsers();
      if (data && data.users) {
        setUsers(data.users);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setSnackbar({ open: true, message: "Failed to fetch team members.", severity: "error" });
    }
  };

  useEffect(() => {
    fetchTeamUsers();
  }, []);

  const handleRoleChange = async (email, newRole) => {
    try {
      await updateUserRole(email, newRole);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.email === email ? { ...user, roles: [newRole] } : user
        )
      );
      setSnackbar({ open: true, message: "User role updated successfully.", severity: "success" });
    } catch (error) {
      console.error("Error updating user role:", error);
      let errorMsg = "Failed to update user role.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      }
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    }
  };

  const handleInvite = async (email, role) => {
    try {
      await inviteUser(email, role);
      setSnackbar({ open: true, message: "Invitation sent successfully.", severity: "success" });
      fetchTeamUsers();
    } catch (error) {
      console.error("Error inviting user:", error);
      let errorMsg = "Failed to send invitation.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      }
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  const getInitials = (name) => {
    if (!name) return "JD";
    const words = name.split(" ");
    if (words.length === 1) return words[0].substring(0, 2).toUpperCase();
    return (words[0][0] + words[1][0]).toUpperCase();
  };

  return (
    <>
      <RolePermissionsDialog open={dialogOpen} onClose={handleCloseDialog} />
      <InviteModalDialog open={inviteModalOpen} onClose={handleCloseInviteModal} onInvite={handleInvite} />
      <UserProfileFrame>
        <Typography variant="h3semibold35">Team Management</Typography>

        <UserProfileInnerFrame>
          <UserProfileHeaderFrame>
            <Typography variant="body1SemiBold17">Team</Typography>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Button variant="indigo" onClick={handleOpenDialog}>
                Role Permissions
              </Button>
              <Button variant="ghost" onClick={handleOpenInviteModal}>
                + Add
              </Button>
            </Box>
          </UserProfileHeaderFrame>

          {users.map((user) => (
            <ProfilePictureFrame key={user.email}>
              <ProfilePictureImageFrame>
                <ProfilePictureImageInnerFrame>
                  <ProfilePictureText>{getInitials(user.username)}</ProfilePictureText>
                </ProfilePictureImageInnerFrame>
                <ProfilePictureNameEmailFrame>
                  <Typography variant="body1Medium17">{user.username}</Typography>
                  <Typography variant="body3Regular14">{user.email}</Typography>
                </ProfilePictureNameEmailFrame>
              </ProfilePictureImageFrame>

              <ProfilePictureButtonsFrame>
                <RoleandTrashFrame>
                  <RoleFrame>
                    <RoleDropdown
                      select
                      value={user.roles && user.roles.length > 0 ? user.roles[0] : ""}
                      onChange={(e) => handleRoleChange(user.email, e.target.value)}
                      variant="outlined"
                    >
                      {TEAM_ROLES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </RoleDropdown>
                  </RoleFrame>
                  <TrashIconFrame>
                    <StyledTrashIcon />
                  </TrashIconFrame>
                </RoleandTrashFrame>
              </ProfilePictureButtonsFrame>
            </ProfilePictureFrame>
          ))}
        </UserProfileInnerFrame>
      </UserProfileFrame>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
