import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Typography,
  Chip,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Example icons (pick whichever icons best match your permissions)
import { ReactComponent as AddIcon } from "../../static/Icons/add.svg";
import { ReactComponent as SendIcon } from "../../static/Icons/sendRole.svg";
import { ReactComponent as PersonAddIcon } from "../../static/Icons/personAdd.svg";
import { ReactComponent as WorkIcon } from "../../static/Icons/workBuilding.svg";
import { ReactComponent as AttachMoneyIcon } from "../../static/Icons/money.svg";
import { ReactComponent as DescriptionIcon } from "../../static/Icons/description.svg";
import { ReactComponent as SettingsIcon } from "../../static/Icons/settings.svg";

// Sample data: each role has an array of permission objects with label + icon
const permissionsData = [
  {
    role: "Admin",
    permissions: [
      { label: "Create Jobs", icon: <AddIcon /> },
      { label: "Send Proposals", icon: <SendIcon /> },
      { label: "Create Users", icon: <PersonAddIcon /> },
      { label: "Manage Jobs", icon: <WorkIcon /> },
      { label: "Manage Cost", icon: <AttachMoneyIcon /> },
      { label: "Manage Proposals", icon: <DescriptionIcon /> },
      { label: "Company Settings", icon: <SettingsIcon /> },
    ],
  },
  {
    role: "Project Manager",
    permissions: [
      { label: "Create Jobs", icon: <AddIcon /> },
      { label: "Send Proposals", icon: <SendIcon /> },
      { label: "Manage Jobs", icon: <WorkIcon /> },
      { label: "Manage Cost", icon: <AttachMoneyIcon /> },
      { label: "Manage Proposals", icon: <DescriptionIcon /> },
    ],
  },
  {
    role: "Estimator",
    permissions: [
      { label: "Manage Jobs", icon: <WorkIcon /> },
      { label: "Manage Cost", icon: <AttachMoneyIcon /> },
      { label: "Manage Proposals", icon: <DescriptionIcon /> },
    ],
  },
];

export default function RolePermissionsDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      {/* Title with Close Button */}
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: 0,
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: 500 }}>
          Role Permissions
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {/* Loop through each Role and its permissions */}
        {permissionsData.map(({ role, permissions }) => (
          <Box
            key={role}
            sx={{
              mb: 3,
              padding: "1.0625rem 0.9375rem",
              borderRadius: "1.0625rem",
              border: "1px solid #DCDACB",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                mb: 2,
                borderBottom: "1px solid #DCDACB",
                pb: 1,
              }}
            >
              {role}
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {permissions.map((perm) => (
                <Chip
                  key={perm.label}
                  label={perm.label}
                  icon={perm.icon}
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    padding: "0.625rem 0.875rem 0.625rem 0.625rem",
                    paddingTop: "1.5rem",
                    paddingBottom: "1.5rem",

                    fontSize: 14,
                    background: "#F3F3F1",
                    border: 0,
                    fontWeight: 500,
                  }}
                />
              ))}
            </Stack>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}
