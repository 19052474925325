export const mockSnapshotData = {
  tabs: [
    { title: 'Project Overview', sections: ['projectIdentification', 'buildingSizeAndLayout'] },
    { title: 'Key Features', sections: ['keyFeaturesAndAmenities', 'siteInformation'] },
    { title: 'Structure & Materials', sections: ['structuralSystemsOverview', 'materialAndFinishOverview'] },
    { title: 'MEP & Compliance', sections: ['mechanicalElectricalAndPlumbingSystems', 'regulatoryAndComplianceInformation'] },
    { title: 'Timeline & Considerations', sections: ['projectTimelineHighlights', 'potentialChallengesOrConsiderations'] },
    { title: 'Team & Documentation', sections: ['projectTeam', 'documentationSummary'] },
    { title: 'Window Schedule', sections: ['windowSchedule'] },
    { title: 'Door Schedule', sections: ['doorSchedule'] },
    { title: 'Finish Schedule', sections: ['finishSchedule'] }
  ],
  data: {
    projectIdentification: {
      'Project Name': 'New Single Family Commercial S.T.R.',
      'Project Location': '506 Jackson Ave, New Orleans LA 70130',
      'Project Type': 'Single Family Commercial',
      'Architectural Style': 'Modern'
    },
    buildingSizeAndLayout: {
      'Total Square Footage': 3148,
      'Number of Stories': 3,
      'Bedrooms': 5,
      'Bathrooms': 5,
      'Garage': 'Yes'
    },
    keyFeaturesAndAmenities: {
      'Exterior Features': ['Front porch', 'Balcony', 'Vertical metal siding'],
      'Interior Features': ['Open floor plan', 'Kitchen island', 'Multiple bathrooms'],
      'Energy Efficiency': ['Spray foam insulation', 'Energy efficient windows'],
      'Technology Integration': ['Security camera system', 'Structured wiring']
    },
    siteInformation: {
      'Site Size': '3,148 SF (0.072 AC)',
      'Topography': 'Urban lot',
      'Access Points': ['Jackson Avenue frontage', 'Rear yard access']
    },
    structuralSystemsOverview: {
      'Foundation Type': 'Concrete foundation with grade beams',
      'Framing Material': 'Wood frame construction',
      'Roof Type': 'Pitched roof with shingles'
    },
    materialAndFinishOverview: {
      'Exterior Materials': ['Metal siding', 'Glass windows', 'Fiber cement trim'],
      'Interior Finishes': ['Tile flooring', 'Paint grade trim', 'Custom cabinetry']
    },
    mechanicalElectricalAndPlumbingSystems: {
      'HVAC System': 'Central air conditioning',
      'Plumbing Features': ['Multiple bathrooms', 'Kitchen fixtures', 'Water heater'],
      'Electrical Systems': ['200A electrical service', 'LED lighting', 'Security system wiring'],
      'Fire Protection': ['Smoke detectors', 'Fire alarm system']
    },
    regulatoryAndComplianceInformation: {
      'Building Codes': 'New Orleans Building Code',
      'Zoning Requirements': 'Fourth District Zoning',
      'Permits & Approvals': 'Department of Safety & Permits approval required'
    },
    projectTimelineHighlights: {
      'Estimated Duration': 'Not specified',
      'Phases': ['Foundation', 'Framing', 'MEP rough-in', 'Finishes']
    },
    potentialChallengesOrConsiderations: {
      'Site Constraints': ['Urban lot limitations', 'Setback requirements'],
      'Technical Complexity': ['Three-story construction', 'Multiple bathroom installations'],
      'Coordination Needs': ['MEP coordination', 'Finish selection coordination']
    },
    documentationSummary: {
      'Plans Provided': ['Floor plans', 'Elevations', 'Sections', 'Details', 'MEP plans', 'Interior elevations'],
      'Specifications': ['Door schedule', 'Window schedule', 'Finish schedule', 'Plumbing schedule']
    },
    projectTeam: {
      'Architect': 'Zach Smith Consulting & Design',
      'Structural Engineer': 'Listed on drawings',
      'MEP Consultant': 'Listed on drawings',
      'Landscape Architect': 'Not specified'
    },
    windowSchedule: {
      headers: ['Type Mark', 'Count', 'Width', 'Height', 'Sill Height', 'Head Height', 'Comments'],
      rows: [{
        Type: 'A',
        Count: '20',
        Width: '3\'-0"',
        Height: '6\'-0"',
        'Sill Height': '2\'-10"',
        'Head Height': '8\'-10"'
      }]
    },
    doorSchedule: {
      headers: ['Mark', 'Location', 'Type', 'Size', 'Material', 'Hardware', 'Comments'],
      rows: [{
        Mark: '101',
        Location: 'Front Door',
        Type: 'Single',
        Size: '3\'-0" x 8\'-0"',
        Material: 'Metal',
        Hardware: 'Lever, deadbolt'
      }]
    },
    finishSchedule: {
      headers: ['Room', 'Floor', 'Wall', 'Ceiling', 'Base', 'Comments'],
      rows: [
        {
          Room: 'Kitchen',
          Floor: 'Tile',
          Wall: 'Paint',
          Ceiling: 'Paint',
          Base: 'Wood'
        },
        {
          Room: 'Bathrooms',
          Floor: 'Tile',
          Wall: 'Tile/Paint',
          Ceiling: 'Paint',
          Base: 'Tile'
        }
      ]
    }
  }
}; 
