import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CustomTypography from "../CustomComponents/CustomTypography";
import CustomButton from "../CustomComponents/CustomButton";
import { Box, Button, CircularProgress, Snackbar } from "@mui/material";
import { ReactComponent as rotateLeft } from "../../static/Icons/rotateLeft.svg";
import { getUploadPolicy, uploadToGCS } from "../../services/uploadService";
import PDFViewer from "../ChatDashboard/PDFViewer";

const FilePreviewFrame = styled(Box)(() => ({
  display: "flex",
  width: "44.33038rem",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "2.1875rem",
}));

const FilePreviewSubFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2.5rem",
  alignSelf: "stretch",
}));

const FilePreviewText = styled(CustomTypography)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Primary, #1D1D1B)",
  textAlign: "center",
  fontFamily: '"Atyp Display TRIAL"',
  fontSize: "1.9375rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

// Remove the black background (#181818):
const PreviewBox = styled(Box)(() => ({
  width: "43.8125rem",
  height: "29.625rem",
  borderRadius: "1.6875rem",
  // background: '#181818', // removed
  boxShadow: "0px 8px 34.8px 0px rgba(185, 185, 162, 0.30)",
  overflow: "auto",
}));

const ActionsFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.4375rem",
}));

const ReuploadButton = styled(Button)(() => ({
  display: "flex",
  width: "8.5625rem",
  height: "2.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  borderRadius: "6.25rem",
  background: "#FFF",
  border: "1px solid var(--Nuetrals-Border---1, #ABA898)",
  "&:hover": {
    background: "#FFF",
  },
}));

const ReuploadStateLayer = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 1.8125rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  flex: "1 0 0",
  alignSelf: "stretch",
}));

const RotateLeftIcon = styled(rotateLeft)(() => ({
  display: "flex",
  width: "1.06725rem",
  height: "1.06725rem",
  justifyContent: "center",
  alignItems: "center",
  color: "var(--Typography-Primary, #1D1D1B)",
  backgroundColor: "#1D1D1B",
}));

const ReuploadText = styled(CustomTypography)(() => ({
  color: "var(--Typography-Primary, #1D1D1B)",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
}));

const AcceptButton = styled(CustomButton)(() => ({
  display: "flex",
  width: "8.5625rem",
  height: "2.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  borderRadius: "6.25rem",
  background: "var(--Accents-Accent, #2961A7)",
}));

const AcceptButtonStateLayer = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 1.8125rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  flex: "1 0 0",
  alignSelf: "stretch",
}));

const AcceptText = styled(CustomTypography)(() => ({
  color: "var(--Typography-Primary-alt, #FFF)",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
}));

/**
 *  file: the raw File user selected
 *  onAccept({ gcsUrl, jobId })
 *  onBack() -> optional: let them go back
 */
const FilePreview = ({ file, onAccept, onBack }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isPdf, setIsPdf] = useState(false);

  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  

  useEffect(() => {
    if (!file) return;
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      setIsImage(true);
    } else if (fileType === "application/pdf") {
      setIsPdf(true);
    }
  }, [file]);

  const handleAccept = async () => {
    if (!file) return;
    setIsUploading(true);
    try {
      console.log("Uploading filepreview");
      const policy = await getUploadPolicy(file.name, file.type);
      const finalUrl = await uploadToGCS(policy, file);
      console.log("Upload worked: ", finalUrl);
      if (onAccept) onAccept({ gcsUrl: finalUrl, jobId: policy.job_id });
    } catch (err) {
      const serverMessage = err.response?.data?.error || err.message;
      setErrorSnackbarMessage(serverMessage || "An error occurred while uploading.");
      setErrorSnackbarOpen(true);      
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <FilePreviewFrame>
      <FilePreviewSubFrame>
        <FilePreviewText variant="h2">Review Your Upload</FilePreviewText>
        <PreviewBox>
          {isImage && (
            <img
              alt="preview"
              src={URL.createObjectURL(file)}
              style={{ width: "100%", height: "auto" }}
            />
          )}
          {isPdf && <PDFViewer file={file} />}
          {!isImage && !isPdf && (
            <Box sx={{ color: "#666", p: 2 }}>
              <p>Preview not available for this file type.</p>
            </Box>
          )}
        </PreviewBox>
      </FilePreviewSubFrame>
      <ActionsFrame>
        {onBack && (
          <ReuploadButton onClick={onBack}>
            <ReuploadStateLayer>
              <RotateLeftIcon />
              <ReuploadText variant="body3">Reupload</ReuploadText>
            </ReuploadStateLayer>
          </ReuploadButton>
        )}
        <AcceptButton onClick={handleAccept} disabled={isUploading}>
          <AcceptButtonStateLayer>
            {isUploading ? (
              <CircularProgress size={22} sx={{ color: "#FFF" }} />
            ) : (
              <AcceptText variant="body3">Accept</AcceptText>
            )}
          </AcceptButtonStateLayer>
        </AcceptButton>
      </ActionsFrame>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        message={errorSnackbarMessage}
      />

    </FilePreviewFrame>
  );
};

export default FilePreview;
