// src/components/Sidebar/Sidebar.jsx

import React, { useEffect } from "react";
import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import TabHeader from "./TabHeader";
import MainTab from "./MainTab";
import TabBarAdditionals from "./TabBarAdditionals";
import { fetchJobsThunk } from "../../store";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "15.6rem",
    height: "100vh",
    paddingBottom: "1.25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.875rem",
    flexShrink: 0,
    overflow: "hidden",

    borderRight: `0.65px solid ${theme.palette.neutrals.border1}`,
    backgroundColor: theme.palette.neutrals.backgroundSecondary,
  },
}));

const Sidebar = ({ proposalId }) => {
  const dispatch = useDispatch();
  const { jobs, loading, error } = useSelector((state) => state.jobsData);

  useEffect(() => {
    // Kick off the async fetch
    dispatch(fetchJobsThunk());
  }, [dispatch]);

  // Check console logs to confirm data
  // console.log(
  //   "[Sidebar] jobs =>",
  //   jobs,
  //   " loading =>",
  //   loading,
  //   " error =>",
  //   error
  // );

  // Just slice the first 5 for "recent"
  const recentJobs = jobs.slice(0, 5);

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <TabHeader />
      <MainTab recentJobs={recentJobs} />
      <TabBarAdditionals proposalId={proposalId} />
    </StyledDrawer>
  );
};

export default Sidebar;
