import axios from "axios";
import apiAxios from "./axiosConfig";

export const fetchAssembliCostData = async () => {
  try {
    const response = await apiAxios.get("/cost_catalog_backend/assembli_pricing");
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Assembli cost data:", error.response?.data);
    if (error.response && error.response.data) {
      return { error: error.response.data.error, details: error.response.data.details };
    } else {
      return { error: "An unexpected error occurred.", details: error.message };
    }
  }
};

export const fetchUserPricingData = async () => {
  try {
    const response = await apiAxios.get("/cost_catalog_backend/files");
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching user pricing data:", error.response?.data || error.message);

    if (error.response) {
      // If backend returns an error message
      return { error: error.response.data?.error || "Error fetching user pricing data." };
    } else if (error.request) {
      return { error: "Network error. Please check your connection." };
    } else {
      // Other unexpected errors
      return { error: "An unexpected error occurred while fetching data." };
    }
  }
};

export const fetchFileDataById = async (file_data) => {
  try {
    const file_id = file_data.file_id;
    console.log(`Fetching file data for file_id = ${JSON.stringify(file_data)}`);
    // EstimateReport (7) - 6234 Pontchartrain Blvd.xls - Sheet1 (1).xlsx
    // get file extension
    const file_name = file_data.file_name;
    const queryParams = {
      params: {
        file_name: file_name
      }
    };
    const response = await apiAxios.get(`/cost_catalog_backend/extracted_data/${file_id}`, queryParams);
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching user pricing data:", error.response?.data || error.message);
    if (error.response) {
      // If backend returns an error message
      return { error: error.response.data?.error || "Error fetching user pricing data." };
    } else if (error.request) {
      return { error: "Network error. Please check your connection." };
    } else {
      // Other unexpected errors
      return { error: "An unexpected error occurred while fetching data." };
    }
  }
}

export const generateSignedURLsForFiles = async (files) => {
  try {
    const signedUrlPromises = files.map(async (file) => {
      const payload = {
        fileName: file.name,
        fileType: file.type,
      };
      console.log(`Generating singed url for file = ${file.name}\nPayload = ${JSON.stringify(payload)}`);
      const response = await apiAxios.post("/cost_catalog_backend/generate_signed_url", payload);
      console.log(`Generated Signed URL for\nfile = ${response.data.data.uploadedPath}`);
      if(response.status === 200){
        return {
          file,
          signedUrl: response.data.data.signedUrl,
          uploadedPath: response.data.data.uploadedPath,
          uploadedFileID: response.data.data.uploadedFileID,
        };
      } else{
        throw new Error(response.data.error || "Failed to generate signed URL.");
      }
    });

    return await Promise.all(signedUrlPromises);
  } catch (error) {
    console.error("Error generating signed URLs:", error.response?.data || error.message);

    if (error.response) {
      throw new Error(error.response.data.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const uploadFiles = async (signedUrls) => {
  try {
    const uploadPromises = signedUrls.map(async ({ file, signedUrl, uploadedPath, uploadedFileID }) => {
      console.log(`Uploading file: ${file.name} to ${uploadedPath}`);
      // Upload file using axios
      // await axios.put(signedUrl, file, {
      //   headers: { "Content-Type": file.type }, // Match Content-Type with file type
      // });
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      }
      );
      if (!response.ok) {
        throw new Error(`Failed to upload file: ${file.name}`);
      }
      console.log(`File uploaded successfully: ${file.name}`);
      // If the upload succeeds, return the uploadedPath
      return { 'uploadedPath': uploadedPath, 'uploadedFileID': uploadedFileID };
    });

    // Resolve all uploads
    return await Promise.all(uploadPromises);
  } catch (error) {
    console.error("Error uploading files:", error.message);
    return {
      error: "Failed to upload files. Please check your network or try again.",
    };
  }
};


export const processCostFilesToExtractData = async (paths) => {
  try {
    const payload = { files: paths };
    console.log("Processing files with payload:", payload);
    const response = await apiAxios.post("/cost_catalog_backend/process_cost_files", payload);
    if (response.status === 200) {
      console.log("Files processed successfully:");
      return response.data; // Success response
    } else {
      console.error("Error processing files:", response.data);
      throw new Error(response.data.error || "Failed to process files.");
    }
  } catch (error) {
    console.error("Unexpected error processing files:", error.response?.data || error.message);
    if (error.response) {
      throw new Error(error.response.data?.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const updateFileStatus = async (file_id, status, selectedRows) => {
  try {
    const payload = { new_status: status, selected_rows: selectedRows };
    const response = await apiAxios.post(
      `/cost_catalog_backend/update_file_status/${file_id}`,
      payload
    );
    if (response.status === 200) {
      console.log("File status updated successfully:");
      return response; // Success
    } else {
      console.error("Error updating file status:", response.data);
      throw new Error(response.data.error || "Failed to update file status.");
    }
  } catch (error) {
    console.error("Unexpected error updating file status:", error.response?.data || error.message);
    if (error.response) {
      throw new Error(error.response.data?.error || "Backend error occurred.");
    } else if (error.request) {
      throw new Error("Network error. Please check your connection.");
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const processRowUpdateForFile = async (newRow, file_id) => {
  try {
    // Make API call to update the database
    const line_id = newRow.line_id;
    const payload = {
      line_id: line_id,
      file_id: file_id, 
      row_data: newRow
    }
    const response = await apiAxios.post(`/cost_catalog_backend/update_line_item`, payload);
    console.log(response);
    if (response.status != 200) {
      return {'error': "Failed to update line item."};
    }
    return response.data;
  } catch (error) {
    // Handle error - you might want to show an error message to the user
    console.error('Error saving row:', error);
    return {'error': 'Failed to update line item.'};// This will cause the grid to revert the changes
  }
};

export const validateRow = (row) => {
  const errors = {};

  // Check if `description` or `full_description` is empty
  // if (!row.description && !row.full_description) {
  //   errors.description = "Either 'description' or 'full_description' is required and cannot be empty.";
  // }
  // Validate labor_price
  // if (row.labor_price !== undefined && row.labor_price !== null) {
  //   let laborPrice = row.labor_price;
  //   if (typeof laborPrice === "string") {
  //     laborPrice = Number(laborPrice);
  //   }
  //   if (isNaN(laborPrice) || laborPrice < 0) {
  //     errors.labor_price = "Labor Price must be a positive number greater than 0.";
  //   }
  // } else {
  //   errors.labor_price = "Labor Price is required.";
  // }

  // Validate material_price
  // if (row.material_price !== undefined && row.material_price !== null) {
  //   let materialPrice = row.material_price;
  //   if (typeof materialPrice === "string") {
  //     materialPrice = Number(materialPrice);
  //   }
  //   if (isNaN(materialPrice) || materialPrice < 0) {
  //     errors.material_price = "Material Price must be a positive number greater than 0.";
  //   }
  // } else {
  //   errors.material_price = "Material Price is required.";
  // }


  // // Validate `quantity` value
  if (row.quantity !== undefined) {
    if (row.quantity === '') {
      errors.quantity = "Quantity cannot be empty.";
    } else if (isNaN(Number(row.quantity)) || Number(row.quantity) <= 0) {
      errors.quantity = "Quantity must be a number and cannot be less than or equal to 0.";
    } else if (typeof row.quantity === 'string') {
      row.quantity = Number(row.quantity);
    }
  }

  // Validate 'Price' value
  if (row.price !== undefined) {
    if (row.price === '') {
      errors.price = "Price cannot be empty.";
    } else if (isNaN(Number(row.price)) || Number(row.price) <= 0) {
      errors.price = "Price must be a number and cannot be less than or equal to 0.";
    } else if (typeof row.price === 'string') {
      row.price = Number(row.price);
    }
  }

  // if (row.division_name === undefined || row.division_name === null || row.division_name === '') {
  //   errors.division_name = "Division is required.";
  // }

  // if (row.subdivision_id === undefined || row.subdivision_id === null || row.subdivision_name === undefined || row.subdivision_name === null || row.subdivision_name === '') {
  //   errors.subdivision_name = "Subdivision is required.";
  // }

  // if(row.material_id === undefined || row.material_id === null || row.material_id === '' || row.material_name === undefined || row.material_name === null || row.material_name === '') {
  //   errors.item_name = "Item Name is required.";
  // }
  return errors;
};


export const handleDeleteRow = async (rowId, selectedFileId) => {
  try {
    const response = await apiAxios.delete(`/cost_catalog_backend/delete_line_item/${selectedFileId}/rows/${rowId}`);
    console.log(`Row with ID ${rowId} deleted successfully.`, response.data);
    return {
      success: true,
      message: response.data.message,
    };
  } catch (error) {
    // Handle HTTP errors
    if (error.response) {
      // Server responded with a status outside the 2xx range
      console.error(`Error deleting row: ${error.response.data.error || error.response.data}`);
      return {
        success: false,
        error: error.response.data.error || error.response.data || "An error occurred.",
      };
    } else if (error.request) {
      // No response was received
      console.error("No response received from server:", error.request);
      return {
        success: false,
        error: "No response from server. Please check your network connection.",
      };
    } else {
      // Other errors (e.g., request setup issues)
      console.error("Error setting up request:", error.message);
      return {
        success: false,
        error: "An unexpected error occurred.",
      };
    }
  }
};

export const getPreviewUrl = async (fileId) => {
  try {
    const response = await apiAxios.get(`/cost_catalog_backend/files/${fileId}/preview`);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch preview URL: ${response.statusText}`);
    }
    console.log(response.data.signed_url);
    return response.data.signed_url; // Contains { signed_url: "..." }
  } catch (error) {
    console.error("Error in getPreviewUrl:", error);
    throw error;
  }
};

export const insertDataIntoCostCatalog = async () => {
  try {
    const response = await apiAxios.post(`/cost_catalog_backend/insert_data_into_cost_catalog`);
    if (response.status !== 200) {
      throw new Error(`Failed to insert data into cost catalog: ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error in insertDataIntoCostCatalog:", error);
    throw error;
  }
};

export const fetchPricingDataByCompany = async () => {
  try {
    const response = await apiAxios.get(`/cost_catalog_backend/user_pricing`);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch cost catalog data: ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error in fetchPricingDataByCompany:", error);
    throw error;
  }
};

export const mapAcceptedLinesToAssembliStructure = async () => {
  try {
    const response = await apiAxios.post(`/cost_catalog_backend/map_accepted_lines_to_assembli_structure`);
    if (response.status !== 200) {
      throw new Error(`Failed to map accepted lines to Assembli structure: ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error in mapAcceptedLinesToAssembliStructure:", error);
    throw error;
  }
};

export const updateCostCatalogRow = async (newRow) => {
  try {
    const response = await apiAxios.post(`/cost_catalog_backend/update_line_item_user_pricing`, newRow);
    if (response.status !== 200) {
      throw new Error(`Failed to update cost catalog row: ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error in updateCostCatalogRow:", error);
    throw error;
  }
}


export const fetchLineItemCategories = async() =>{
  try {
    const response = await apiAxios.get(`/cost_catalog_backend/get_assembli_structure`);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch line item categories: ${response.statusText}`);
    }
    return response.data.divisionsData;
  } catch (error) {
    console.error("Error in fetchLineItemCategories:", error);
    throw error;
  }
}

export const saveMappedData = async (selectedLineItems, selectedMaterialId, selectedRows) => {
  try {
    const payload = {
      selected_line_items: selectedLineItems,
      selected_rows: selectedRows,
      material_id: selectedMaterialId
    };
    
    const response = await apiAxios.post('/cost_catalog_backend/save_mapped_data', payload);
    console.log('Response from save mapped data:', response);
    if (response.status !== 200) {
      throw new Error(response.data.error || 'Failed to save mapped data');
    }
    return response.data;
  } catch (error) {
    console.error('Error saving mapped data:', error);
    return { error: error.message };
  }
};

export const getAllLineItems = async () => {
  try {
    const response = await apiAxios.get('/cost_catalog_backend/get_all_line_items_extracted', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    console.log('Fetched Response for getting all line items:', response);    
    if (!response.status === 200) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to get line items');
    }
    console.log('Fetched Response for getting all line items:', response);
    return await response.data.extracted_data;
  } catch (error) {
    console.error('Error getting line items:', error);
    return { error: error.message };
  }
}