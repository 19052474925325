import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  styled,
  Box,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/userService';
import AlertMessage from '../AlertMessage';
import { ReactComponent as IllustrationIcon } from '../../static/Icons/illustration.svg';
import { ReactComponent as LogoHolder } from '../../static/Icons/logo.svg';

/** -------------
 * Styled Components
 * -------------
 */
const AuthenticationPageFrame = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
}));

const IllustrationFrame = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 2,
  background: theme.palette.neutrals.backgroundPrimary,
  flexDirection: 'column',
}));

const LogoFrame = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-start',
  alignItems: 'center',
  flex: 1,
  marginLeft: '2rem',
}));

const LogoTextFrame = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.3rem',
}));

const IllustrationIconFrame = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 12,
}));

const WelcomeFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '2rem 2rem',
}));

const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // flex: 1,
  width: '100%',
  padding: '2rem 2rem',

}));

const InputFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TextInputFrame = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '3.02144rem',
    background: theme.palette.neutrals.background.input2,
    borderRadius: '0.94419rem',
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '0.69244rem 1.00713rem',
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.neutrals.background.input2} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
  },
}));

const SubmitButtonFrame = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
}));

/** -------------
 * ResetPassword Component
 * -------------
 */
function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await resetPassword(token, password);
      setMessage(response.message || 'Password reset successfully!');
      setTimeout(() => navigate('/'), 2000);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to reset password.');
    }
  };

  return (
    <AuthenticationPageFrame>
      {/* Left Illustration Section */}
      <IllustrationFrame>
        <LogoFrame>
          <LogoHolder />
          <LogoTextFrame>
            <Typography variant="logo">assembli</Typography>
          </LogoTextFrame>
        </LogoFrame>
        <IllustrationIconFrame>
          <IllustrationIcon />
        </IllustrationIconFrame>
      </IllustrationFrame>

      {/* Right Form Section */}
      <WelcomeFrame>
        <Typography
          variant="welcometext"
          align="center"
          sx={{ color: 'typographyPrimary.main' }}
        >
          Reset Password
        </Typography>
        <Typography
          variant="logintext"
          align="center"
          sx={{ color: 'typographySecondary.main' }}
          my={1}
        >
          Please enter a new password below.
        </Typography>

        <FormFrame>
          <form onSubmit={handleResetPassword}>
            <InputFrame>
              <Typography variant="body2medium16" sx={{ mb: 1 }}>
                New Password
              </Typography>
              <TextInputFrame
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <Typography variant="body2medium16" sx={{ mt: 2, mb: 1 }}>
                Confirm Password
              </Typography>
              <TextInputFrame
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <Box sx={{ mt: 1, mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Password must contain:
                </Typography>
                <ul style={{ marginLeft: '1.25rem', marginTop: '0.5rem' }}>
                  <li>At least 12 characters</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one number</li>
                  <li>At least one special character</li>
                </ul>
              </Box>
            </InputFrame>

            <SubmitButtonFrame type="submit" variant="contained" color="primary">
              Reset Password
            </SubmitButtonFrame>
          </form>
        </FormFrame>

        {error && (
          <AlertMessage
            message={error}
            type="error"
            onClose={() => setError('')}
          />
        )}
        {message && (
          <AlertMessage
            message={message}
            type="success"
            onClose={() => setMessage('')}
          />
        )}
      </WelcomeFrame>
    </AuthenticationPageFrame>
  );
}

export default ResetPassword;
