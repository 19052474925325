import React, { useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  Box,
  Paper,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  GridView as GridViewIcon,
  AttachMoney as MoneyIcon,
  Inventory as InventoryIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Tune as TuneIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import {
  normalizeTakeOffData,
  generateSettings,
  createExportData,
} from "../utils/takeOffExport";

// Styled preset button
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "2rem",
  padding: "6px 16px",
  textTransform: "none",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.active": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
}));

// Column definitions. Item Name will be forced "on" and won't show a toggle icon.
const COLUMN_DEFINITIONS = {
  // Item Name is pinned & always visible
  name: { label: "Item Name" },
  unit: { label: "Unit" },
  quantity: { label: "Quantity" },
  wasteFactor: { label: "Waste Factor (%)" },
  materialPrice: { label: "Material Price ($)" },
  laborPrice: { label: "Labor Price ($)" },
  extendedPrice: { label: "Extended Price ($)" },
  markupPercent: { label: "Markup (%)" },
  markupAmount: { label: "Markup ($)" },
  taxPercent: { label: "Tax (%)" },
  taxAmount: { label: "Tax ($)" },
  total: { label: "Total ($)" },
};

let finalJsonData = null;

const CostBreakdownEditor = ({ takeOffData, onSave }) => {
  const divisionData = useMemo(
    () => normalizeTakeOffData(takeOffData.takeOff),
    [takeOffData]
  );

  const defaultSettings = useMemo(
    () => generateSettings(divisionData),
    [divisionData]
  );
  const existingSettings =
    takeOffData.settings && Object.keys(takeOffData.settings).length > 0
      ? takeOffData.settings
      : {};

  const initialSettings = useMemo(() => {
    const merged = cloneDeep(defaultSettings);
    if (!existingSettings || Object.keys(existingSettings).length === 0) {
      merged.activePreset = "overview";
    }
    Object.keys(existingSettings).forEach((k) => {
      if (typeof existingSettings[k] === "object") {
        merged[k] = { ...merged[k], ...existingSettings[k] };
      } else {
        merged[k] = existingSettings[k];
      }
    });
    if (!merged.expandedDivisions) merged.expandedDivisions = {};
    if (!merged.activePreset) merged.activePreset = "overview";
    return merged;
  }, [defaultSettings, existingSettings]);

  const [settings, setSettings] = useState(initialSettings);
  const { activePreset, expandedDivisions } = settings;

  // --- Utility to compute totals ---
  const sumSubdivisionTotal = (subdivision) => {
    if (!subdivision?.itemOrder) return 0;
    return subdivision.itemOrder.reduce(
      (acc, itemKey) => acc + (subdivision.items[itemKey]?.total_price || 0),
      0
    );
  };

  const sumDivisionTotal = (division) => {
    if (!division?.subdivisionOrder) return 0;
    return division.subdivisionOrder.reduce((acc, subKey) => {
      const subdiv = division.subdivisions[subKey];
      return subdiv ? acc + sumSubdivisionTotal(subdiv) : acc;
    }, 0);
  };

  const getGrandTotal = () => {
    if (!divisionData?.divisionOrder) return 0;
    return divisionData.divisionOrder.reduce((acc, divKey) => {
      if (divKey === "General_Info") return acc;
      return acc + sumDivisionTotal(divisionData[divKey]);
    }, 0);
  };

  // --- Expand/Collapse Division logic ---
  const handleToggleDiv = (divKey) => {
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      cloned.expandedDivisions[divKey] = !cloned.expandedDivisions[divKey];
      return cloned;
    });
  };

  const setAllExpansions = (shouldExpand) => {
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      divisionData.divisionOrder.forEach((divKey) => {
        if (divKey === "General_Info") return;
        cloned.expandedDivisions[divKey] = shouldExpand;
      });
      return cloned;
    });
  };

  // Switch to custom preset if user toggles columns manually
  const switchToCustomIfNeeded = () => {
    setSettings((prev) => {
      if (prev.activePreset === "custom") return prev;
      const cloned = cloneDeep(prev);
      cloned.activePreset = "custom";
      return cloned;
    });
  };

  // Hide/Show individual columns (except Item Name)
  const handleColumnToggle = (divKey, colKey) => {
    // If user tries to hide 'name' (Item Name), we ignore it
    if (colKey === "name") {
      return; // forced always visible
    }
    switchToCustomIfNeeded();
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      cloned[divKey].columns[colKey] = !cloned[divKey].columns[colKey];
      return cloned;
    });
  };

  // Preset logic
  const handlePresetChange = (preset) => {
    setSettings((prev) => {
      const cloned = cloneDeep(prev);
      divisionData.divisionOrder.forEach((divKey) => {
        if (divKey === "General_Info") return;
        if (!cloned[divKey]) cloned[divKey] = { columns: {} };
        const columns = cloned[divKey].columns;

        // In any preset, always keep 'name' column forced ON
        columns["name"] = true;

        if (preset === "standard") {
          // Show all columns
          Object.keys(columns).forEach((c) => {
            if (c !== "name") columns[c] = true;
          });
        } else if (preset === "costs") {
          // Hide all, then show cost columns
          Object.keys(columns).forEach((c) => {
            if (c !== "name") columns[c] = false;
          });
          // columns["materialPrice"] = true;
          // columns["laborPrice"] = true;
          // columns["extendedPrice"] = true;
          // columns["markupPercent"] = true;
          // columns["markupAmount"] = true;
          // columns["taxPercent"] = true;
          // columns["taxAmount"] = true;
          // columns["total"] = true;
        } else if (preset === "overview") {
          // Minimal columns: name, quantity, total
          Object.keys(columns).forEach((c) => {
            if (c !== "name") columns[c] = false;
          });
          columns["quantity"] = true;
          columns["total"] = true;
        }
        // 'custom' => do not modify columns
      });
      cloned.activePreset = preset;
      return cloned;
    });
  };

  const handleClickPreset = (preset) => {
    if (preset === "overview") {
      setAllExpansions(false);
    } else if (preset === "standard" || preset === "costs") {
      setAllExpansions(true);
    }
    handlePresetChange(preset);
  };

  // On Save
  const handleSave = () => {
    const finalExport = createExportData(divisionData, settings);
    finalJsonData = finalExport;
    onSave && onSave(settings);
  };

  // If no data:
  if (!divisionData?.divisionOrder?.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>No takeoff data available.</Typography>
      </Box>
    );
  }

  // Filter out non-division data
  const divisionsToShow = divisionData.divisionOrder.filter(
    (d) => d !== "General_Info"
  );

  // Simple preset button
  const PresetButton = ({ icon: Icon, label, preset }) => {
    const isActive = activePreset === preset;
    return (
      <StyledButton
        className={isActive ? "active" : ""}
        startIcon={<Icon />}
        onClick={() => handleClickPreset(preset)}
      >
        {label}
      </StyledButton>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Sticky top bar for Presets & Save */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1200, // keep it above table
          backgroundColor: "#fff",
          p: 2,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Typography sx={{ pb: 1 }} gutterBottom>
          View Presets
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <PresetButton
              icon={GridViewIcon}
              label="Standard"
              preset="standard"
            />
            <PresetButton icon={MoneyIcon} label="Costs Only" preset="costs" />
            <PresetButton
              icon={InventoryIcon}
              label="Overview"
              preset="overview"
            />
            <PresetButton icon={TuneIcon} label="Custom" preset="custom" />
          </Box>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>

      {/* Main Table per Division */}
      <Card>
        <CardContent>
          {divisionsToShow.map((divKey) => {
            const division = divisionData[divKey];
            if (!division) return null;

            const isDivExpanded = !!expandedDivisions[divKey];
            const divisionTotal = sumDivisionTotal(division);
            const columns = settings[divKey]?.columns || {};
            // Force item name to always be true
            // columns.name = true;

            const isOverview = activePreset === "overview";

            return (
              <Paper
                key={divKey}
                elevation={0}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  mb: 2,
                }}
              >
                {/* Division Header */}
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "grey.50",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {!isOverview && (
                      <IconButton
                        size="small"
                        onClick={() => handleToggleDiv(divKey)}
                      >
                        {isDivExpanded ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    )}
                    <Typography>
                      {divKey} - {division?.name ?? ""}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="subtitle1"
                      color="success.main"
                      fontWeight="bold"
                    >
                      Division Total: ${divisionTotal.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>

                {/* Collapse for the division table */}
                {!isOverview && (
                  <Collapse in={isDivExpanded}>
                    <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
                      <Table
                        size="medium"
                        stickyHeader
                        sx={{ tableLayout: "fixed", minWidth: 900 }}
                      >
                        {/* TABLE HEAD */}
                        <TableHead>
                          <TableRow>
                            {/* Subdivision column pinned left in header */}
                            <TableCell
                              sx={{
                                position: "sticky",
                                left: 0,
                                zIndex: 1000,
                                backgroundColor: "#f5f5f5",
                                width: 180,
                              }}
                            >
                              <Typography variant="subtitle2">
                                Subdivision
                              </Typography>
                            </TableCell>

                            {Object.entries(COLUMN_DEFINITIONS).map(
                              ([colKey, colDef]) => {
                                // Always pinned if colKey is 'name'
                                const isNameCol = colKey === "name";
                                // Skip the hide/unhide icon if colKey === 'name'
                                const showToggle = colKey !== "name";

                                const stickyStyles = isNameCol
                                  ? {
                                      position: "sticky",
                                      left: 180,
                                      zIndex: 1000,
                                      backgroundColor: "#f5f5f5",
                                    }
                                  : {};

                                return (
                                  <TableCell
                                    key={colKey}
                                    sx={{ ...stickyStyles, width: 140 }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ mr: 1 }}
                                      >
                                        {colDef.label}
                                      </Typography>

                                      {/* Hide/Show icon except for 'name' */}
                                      {showToggle && (
                                        <Tooltip
                                          title={`${
                                            columns[colKey] ? "Hide" : "Show"
                                          } ${colDef.label}`}
                                        >
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              handleColumnToggle(divKey, colKey)
                                            }
                                          >
                                            {columns[colKey] ? (
                                              <VisibilityIcon
                                                color="primary"
                                                fontSize="small"
                                              />
                                            ) : (
                                              <VisibilityOffIcon
                                                color="action"
                                                fontSize="small"
                                              />
                                            )}
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>

                        {/* TABLE BODY */}
                        <TableBody>
                          {division.subdivisionOrder.map((subKey) => {
                            const subdiv = division.subdivisions[subKey];
                            if (!subdiv) return null;

                            // We'll add a final "subtotal" row after listing items
                            const subdivisionTotal =
                              sumSubdivisionTotal(subdiv);

                            return (
                              <React.Fragment key={subKey}>
                                {/* One row per item */}
                                {subdiv.itemOrder.map((itemKey) => {
                                  const it = subdiv.items[itemKey];
                                  if (!it) return null;

                                  return (
                                    <TableRow
                                      key={`${subKey}_${itemKey}`}
                                      hover
                                    >
                                      {/* Subdivision pinned cell (body) */}
                                      <TableCell
                                        sx={{
                                          position: "sticky",
                                          left: 0,
                                          zIndex: 900,
                                          backgroundColor: "#fff",
                                          width: 180,
                                        }}
                                      >
                                        {subdiv.name ?? subKey}
                                      </TableCell>

                                      {Object.keys(COLUMN_DEFINITIONS).map(
                                        (colKey) => {
                                          // If hidden, collapse
                                          if (!columns[colKey]) {
                                            return (
                                              <TableCell
                                                key={colKey}
                                                sx={{ visibility: "collapse" }}
                                              />
                                            );
                                          }

                                          // Determine cell value
                                          let val = "";
                                          switch (colKey) {
                                            case "name":
                                              val = it.name || itemKey;
                                              break;
                                            case "materialPrice":
                                              val = it.unit_price_material
                                                ? `$${it.unit_price_material.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "laborPrice":
                                              val = it.unit_price_labor
                                                ? `$${it.unit_price_labor.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "extendedPrice":
                                              val = it.extended_price
                                                ? `$${it.extended_price.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "markupPercent":
                                              val = it.markup_percent
                                                ? `${it.markup_percent}%`
                                                : "0%";
                                              break;
                                            case "markupAmount":
                                              val = it.markup_amount
                                                ? `$${it.markup_amount.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "taxPercent":
                                              val = it.tax_percent
                                                ? `${it.tax_percent}%`
                                                : "0%";
                                              break;
                                            case "taxAmount":
                                              val = it.tax_amount
                                                ? `$${it.tax_amount.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "total":
                                              val = it.total_price
                                                ? `$${it.total_price.toLocaleString()}`
                                                : "$0";
                                              break;
                                            case "quantity":
                                              val = it.quantity || 0;
                                              break;
                                            case "unit":
                                              val = it.unit || "";
                                              break;
                                            case "wasteFactor":
                                              // Show as a percentage if you like
                                              val = it.waste_factor_percent
                                                ? `${it.waste_factor_percent}%`
                                                : "0%";
                                              break;
                                            default:
                                              val = "";
                                          }

                                          // Pinned name col
                                          const isNameCol = colKey === "name";
                                          const stickyStyles = isNameCol
                                            ? {
                                                position: "sticky",
                                                left: 180,
                                                zIndex: 900,
                                                backgroundColor: "#fff",
                                              }
                                            : {};

                                          return (
                                            <TableCell
                                              key={colKey}
                                              sx={{
                                                ...stickyStyles,
                                                width: 140,
                                              }}
                                            >
                                              {val}
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>
                                  );
                                })}

                                {/* Subdivision TOTAL ROW */}
                                <TableRow>
                                  {/* Pinned subdiv column with label */}
                                  <TableCell
                                    sx={{
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 900,
                                      backgroundColor: "#f9f9f9",
                                      width: 180,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Sub Total
                                  </TableCell>

                                  {Object.keys(COLUMN_DEFINITIONS).map(
                                    (colKey) => {
                                      // If hidden, collapse
                                      if (!columns[colKey]) {
                                        return (
                                          <TableCell
                                            key={colKey}
                                            sx={{ visibility: "collapse" }}
                                          />
                                        );
                                      }
                                      // If pinned "name" col, just leave blank here
                                      if (colKey === "name") {
                                        return (
                                          <TableCell
                                            key={colKey}
                                            sx={{
                                              position: "sticky",
                                              left: 180,
                                              zIndex: 900,
                                              backgroundColor: "#f9f9f9",
                                              width: 140,
                                            }}
                                          />
                                        );
                                      }
                                      // Only show the sum in the "Total ($)" column
                                      let val = "";
                                      if (colKey === "total") {
                                        val = `$${subdivisionTotal.toLocaleString()}`;
                                      }
                                      return (
                                        <TableCell
                                          key={colKey}
                                          sx={{
                                            backgroundColor: "#f9f9f9",
                                            fontWeight: "bold",
                                            width: 140,
                                          }}
                                        >
                                          {val}
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                )}
              </Paper>
            );
          })}

          {/* GRAND TOTAL */}
          <Paper
            elevation={0}
            sx={{
              mt: 3,
              p: 2,
              bgcolor: "grey.100",
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" fontWeight="bold">
                Grand Total
              </Typography>
              <Typography color="success.main" fontWeight="bold">
                ${getGrandTotal().toLocaleString()}
              </Typography>
            </Box>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CostBreakdownEditor;
export { finalJsonData };
