import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Avatar, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTypography from "../CustomComponents/CustomTypography";
import { ReactComponent as LogoHolder } from "../../static/Icons/logo.svg";

import { fetchUserProfileImage } from "../../services/userService";

// src/components/Sidebar/TabHeader.js

// Styled Components
const Frame = styled(Box)(() => ({
  display: "flex",
  width: "15.375rem",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "0.65px solid var(--Nuetrals-Border---1, #ABA898)",
}));

const TabBarHeader = styled(Box)(() => ({
  display: "flex",
  padding: "0rem 0.625rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

const ProfileLogo = styled(Box)(() => ({
  display: "flex",
  width: "11.5rem",
  height: "3.5625rem",
  padding: "0rem 0.75rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  flexShrink: 0,
}));

const LogoHeader = styled(Box)(() => ({
  display: "flex",
  height: "2.5rem",
  padding: "0rem 0.44906rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.499rem",
  borderRadius: "0.4375rem",
}));

const MockLogo = styled(Box)(() => ({
  display: "flex",
  width: "9.27519rem",
  height: "1.76113rem",
  padding: "0.20406rem 0.09731rem 0.19994rem 0.04756rem",
  justifyContent: "center",
  alignItems: "center",
}));

const MockLogo2 = styled(Box)(() => ({
  display: "flex",
  width: "9.13031rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.36438rem",
  flexShrink: 0,
}));

const Logo = styled(LogoHolder)(() => ({
  width: "1.893rem",
  height: "1.35713rem",
  flexShrink: 0,
}));

const AssembliText = styled(CustomTypography)(() => ({
  width: "6.87294rem",
  flexShrink: 0,
  color: "assembli-secondary",
  fontFamily: "Atyp Display TRIAL",
  fontSize: "1.67938rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-0.05038rem",
}));

const ProfileAvatarBox = styled(Box)(() => ({
  display: "flex",
  height: "3.5625rem",
  padding: "0rem 0.875rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  borderLeft: "0.65px solid var(--Nuetrals-Border---1, #ABA898)",
}));

const ProfilePicture = styled(Box)(() => ({
  display: "flex",
  width: "2.14925rem",
  height: "2.14925rem",
  justifyContent: "center",
  alignItems: "center",
}));

const ProfileAvatar = styled(Avatar)(() => ({
  width: "2.15rem",
  height: "2.15rem",
  flexShrink: 0,
  borderRadius: "2.14925rem",
  background: "url(<path-to-image>) lightgray 50% / cover no-repeat",
}));

const TabHeader = () => {
  // Local state for the profile image URL (fetched from server)
  const [profileImage, setProfileImage] = useState(null);

  // Access the username from Redux store (e.g. state.user.username)
  const username = useSelector((state) => state.auth.loggedInUsername);
  //   console.log("Username:", username);

  // Fetch the profile image on mount
  useEffect(() => {
    const getUserProfileImage = async () => {
      try {
        const data = await fetchUserProfileImage();
        // data should be the image URL or null/undefined
        // console.log("User profile image data:", data.logo);
        setProfileImage(data.logo || null);
      } catch (error) {
        console.error("Error fetching user profile image:", error);
        setProfileImage(null);
      }
    };

    getUserProfileImage();
  }, []);

  // Function to get the initial from username
  const getInitial = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  return (
    <TabBarHeader>
      <Frame>
        <ProfileLogo>
          <LogoHeader>
            <MockLogo>
              <MockLogo2>
                <Logo style={{ width: "2rem", height: "2rem" }} />
                <AssembliText>assembli</AssembliText>
              </MockLogo2>
            </MockLogo>
          </LogoHeader>
        </ProfileLogo>
        <ProfileAvatarBox>
          <ProfilePicture>
            <ProfileAvatar src={profileImage || undefined} alt="User Avatar">
              {
                // If we don't have a valid profileImage, show the initial letter
                !profileImage && username ? getInitial(username) : null
              }
            </ProfileAvatar>
          </ProfilePicture>
        </ProfileAvatarBox>
      </Frame>
    </TabBarHeader>
  );
};

export default TabHeader;
