import React from "react";
import { Paper, Typography, Radio, FormControlLabel, List, ListItem, ListItemText, Box, styled } from "@mui/material";

import { ReactComponent as ChooseIcon } from "../../static/Icons/container.svg";
import { ReactComponent as TickIcon } from "../../static/Icons/check_small.svg";

const ChooseIconStyled = styled(ChooseIcon)(() => ({
  width: "0.94413rem",
  height: "0.94413rem",
  strokeWidth: "1.2px", 
  stroke: "var(--Accents-Accent, #2961A7)",
}));

const TickMarkIconStyled = styled(TickIcon)(() => ({
  width: "1.25888rem",
  height: "1.25888rem",
  position: "absolute",
  right: "0.05244rem",
  bottom: "0.05244rem",
  zIndex: 10,
}));

const EllipseIconStyled = styled(Box)(() => ({
  width: "0.63144rem",
  height: "0.63144rem",
  fill: "var(--Accents-Accent-Alt-Hover, #47BCFF)",
}));

// This styled component is used to style the whole row in Assembli cost database section
const BoxFeatureStyled = styled(Box)(() => ({
        display: "flex",
        padding: "0.625rem 0.6875rem",
        alignItems: "center",
        gap: "0.6875rem",
        alignSelf: "stretch",
        borderRadius: "0.5rem",
}));

// This styled component is used to style the bullet points
const BoxFrameStyled = styled(Box)(() => ({ 
  display: "flex",
  padding: "0.25256rem",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.26288rem",
  borderRadius: "2.52575rem",
  background: "var(--Accents-Accent-Alt, #47BCFF)",
}));

function AssembliPricingSection({ selectedOption, handleOptionChange }) {
  const isSelected = selectedOption === "assembli"; // Check if this option is selected
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "2.23438rem", // Approximately 36px
        width: "23.5625rem", // 377px
        height: "22.5rem", // 360px
        padding: "1.64063rem 1.375rem 2.3125rem 1.375rem", // 26px 22px 37px 22px
        borderRadius: "1.25rem", // 20px
        backgroundColor: "var(--Nuetrals-Background---Tertiary, #FFF)",
        boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)", // Custom elevation
        border: isSelected ? "1px solid var(--Status-Selected-2, #ABA898)" : "1px solid var(--Nuetrals-Border---2, #DCDACB)", // Add a border if selected
        transition: "border 0.3s ease, background-color 0.3s ease", // Smooth transition for selection
      }}
    >
      <Box sx={{
        display: "flex",
        width: "20.8125rem",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.8125rem",
        cursor: "pointer", // Show a pointer cursor to indicate clickability
      }}>
        <Box sx={{
        display: "flex",
        height: "1.5625rem",
        alignItems: "center",
        alignSelf: "stretch",
        gap: "0.5625rem",
        position: "relative",
      }}
      onClick={() => handleOptionChange({ target: { value: "assembli" } })} // Trigger handleOptionChange on click
      >

        <Box sx={{
          display: "flex",
          padding: "0.20981rem",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.3rem",
          position: "relative",
          backgroundColor: isSelected ? "#2961A7" : "#FFFFFF",
          transition: "background-color 0.3s ease", // Smooth background color transition
        }}>
          <ChooseIconStyled />
          {isSelected && (
              <TickMarkIconStyled />
            )}
        </Box>
        <Box sx={{
          display: "flex",
          width: "11rem",
          height: "0.94413rem",
          flexDirection: "column",
          justifyContent: "center",
        }}>
          <Typography variant="body2medium16" >Assembli Cost Database</Typography>
        </Box>

      </Box>
      {/* Horizontal Line */}
      <Box
        sx={{
          width: "20.8125rem",
          height: "0.0625rem",
          background: "var(--Nuetrals-Border---2, #DCDACB)",
        }}
      ></Box>
      </Box>
      
      <Box sx={{
        display: "flex",
        width: "20.8125rem",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.4375rem"
      }}>
      <BoxFeatureStyled>
        {/* <BoxFrameStyled>
          <EllipseIconStyled />
        </BoxFrameStyled> */}
        <Typography variant="body3medium14">Start estimating right away with our comprehensive cost library.</Typography>
      </BoxFeatureStyled>
      <BoxFeatureStyled>
        {/* <BoxFrameStyled>
          <EllipseIconStyled />
        </BoxFrameStyled> */}
        <Typography variant="body3medium14">Compiled from multiple reliable sources across the U.S., this database gives you a solid, directionally accurate baseline for material and labor costs.</Typography>
      </BoxFeatureStyled>
      </Box>
    </Box>
  );
}

export default AssembliPricingSection;
