import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  styled,
  Typography,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  Select,
  IconButton,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { getDefaultValues, updateDefaultValues } from "../../services/companyPreferencesService";

// OPTIONAL: If you want a local default structure to fall back on:
const defaultPreferencesNested = {
  // -- your entire default JSON structure here, e.g.:
  // "Division_01": {
  //   ...
  // },
  // ...
};

// ------------- Styled Components -------------
const UserProfileFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1rem",
  alignSelf: "stretch",
  flex: 0,
  width: "100%",
}));

const UserProfileInnerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.375rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
  width: "100%",
}));

const UserProfileHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
  width: "100%",
}));

const SearchFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  width: "100%",
  padding: "0.5rem 0",
}));

const SearchInputFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  backgroundColor: "#F5F5F5",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  gap: "0.5rem",
}));

const PersonalInfoFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1.5625rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
  flex: 1,
  width: "100%",
  marginTop: "1rem",
  overflow: "auto", // Handles vertical overflow for the whole content area
}));

const PersonalInfoHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
  justifyContent: "space-between",
  width: "100%",
}));

const DivisionListFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flex: 1,
  overflowY: "auto", // Handles vertical scrolling *within* the list
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  height: "calc(100% - 3.5rem)", // Calculated height
  minHeight: "30rem",
  maxHeight: "100%",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#c1c1c1",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#a8a8a8",
  },
}));

const DivisionItemFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "1rem 1.5rem",
  borderBottom: "1px solid #F3F3F1",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F9F9F9",
  },
  fontSize: "1rem",
  fontWeight: 500,
}));

const SubdivisionContentFrame = styled(Box)(() => ({
  padding: "0 0 1rem 2.5rem", // Keep indentation for subdivision content
  width: "100%",
  backgroundColor: "#FFFFFF", // Ensure background covers potential overlap
}));

const StyledInputBase = styled(InputBase)(() => ({
  flex: 1,
  fontSize: "0.875rem",
}));

// We’ll use a bit more spacing for the symbol
const symbolRight = "1rem";
const inputPaddingRight = "2.5rem"; // Default padding when a symbol is present
const commonFieldWidth = "12rem";

// Common style for text fields with trailing symbols
const commonTextFieldStyle = {
  width: commonFieldWidth,
  "& .MuiInputBase-root": {
    height: "2.5rem",
    padding: "0 0.75rem", // Base padding
    backgroundColor: "#F5F5F5",
    borderRadius: "0.5rem",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    textAlign: "left", // Ensure text aligns left
    paddingRight: inputPaddingRight, // Make space for the symbol
  },
};

// ------------- Waste Factor -------------
const PercentageInput = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  backgroundColor: "#F5F5F5", // Match TextField background
  borderRadius: "0.5rem",
  overflow: "hidden", // Ensure symbol stays contained if needed
  width: commonFieldWidth, // Match TextField width
}));

const StyledPercentageTextField = styled(TextField)(() => ({
  ...commonTextFieldStyle, // Inherit common styles
}));

const PercentSymbol = styled(Box)(() => ({
  position: "absolute",
  right: symbolRight, // Position symbol on the right
  top: "50%", // Center vertically
  transform: "translateY(-50%)", // Adjust vertical centering
  fontSize: "0.875rem",
  color: "#666",
  pointerEvents: "none", // Make symbol non-interactive
}));

// ------------- $/SF -------------
const CostPerSfInput = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  backgroundColor: "#F5F5F5",
  borderRadius: "0.5rem",
  overflow: "hidden",
  width: commonFieldWidth,
}));

const StyledCostPerSfTextField = styled(TextField)(() => ({
  ...commonTextFieldStyle,
}));

const CostPerSfSymbol = styled(Box)(() => ({
  position: "absolute",
  right: symbolRight,
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "0.875rem",
  color: "#666",
  pointerEvents: "none",
}));

// ------------- $/Month -------------
const CostPerMonthInput = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  backgroundColor: "#F5F5F5",
  borderRadius: "0.5rem",
  overflow: "hidden",
  width: commonFieldWidth,
}));

const StyledCostPerMonthTextField = styled(TextField)(() => ({
  ...commonTextFieldStyle,
}));

const CostPerMonthSymbol = styled(Box)(() => ({
  position: "absolute",
  right: symbolRight,
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "0.875rem",
  color: "#666",
  pointerEvents: "none",
}));

// ------------- $/Cy -------------
const CostPerCyInput = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  backgroundColor: "#F5F5F5",
  borderRadius: "0.5rem",
  overflow: "hidden",
  width: commonFieldWidth,
}));

const StyledCostPerCyTextField = styled(TextField)(() => ({
  ...commonTextFieldStyle,
}));

const CostPerCySymbol = styled(Box)(() => ({
  position: "absolute",
  right: symbolRight,
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "0.875rem",
  color: "#666",
  pointerEvents: "none",
}));


// ------------- inches -------------
const InchesInput = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  backgroundColor: "#F5F5F5",
  borderRadius: "0.5rem",
  overflow: "hidden",
  width: commonFieldWidth,
}));

const StyledInchesTextField = styled(TextField)(() => ({
  ...commonTextFieldStyle,
}));

const InchesSymbol = styled(Box)(() => ({
  position: "absolute",
  right: symbolRight,
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "0.875rem",
  color: "#666",
  pointerEvents: "none",
}));

// ------------- Item Table Styling -------------
const ItemContainer = styled(Box)(() => ({
  width: "100%",
  borderRadius: "0.5rem",
  overflow: "hidden", // Keep borders neat
  border: "1px solid #F3F3F1",
  backgroundColor: "#FFF",
  marginTop: "0.75rem",
  boxShadow: "none", // Remove default elevation/shadow if any
}));

const ItemHeader = styled(Box)(() => ({
  display: "flex",
  backgroundColor: "#F5F5F5",
  padding: "0.75rem 1.25rem",
  borderBottom: "1px solid #F3F3F1",
  fontWeight: 500,
  color: "#333333",
  textAlign: "left", // Ensure header text is left-aligned
}));

const ItemRow = styled(Box)(() => ({
  display: "flex",
  padding: "0.75rem 1.25rem",
  borderBottom: "1px solid #F3F3F1",
  "&:last-child": {
    borderBottom: "none",
  },
  alignItems: "center", // Vertically align items in the row
  "&:hover": {
    backgroundColor: "#FAFAFA", // Subtle hover effect
  },
  textAlign: "left", // Ensure row text is left-aligned by default
}));

// Define columns with flex properties for layout
// Adjusted flex values: Item: 1.5, Input: 1.5, Default Value: 1
const ItemColumn = styled(Box)(({ flexValue = 1 }) => ({
  flexGrow: flexValue, // Use flexGrow for distribution
  flexShrink: 1, // Allow shrinking
  flexBasis: 0, // Start from 0 basis
  paddingRight: "1rem", // Add some padding between columns
  "&:last-child": {
    paddingRight: 0, // No padding on the last column
  },
  textAlign: "left", // Explicitly left-align content within column
  overflowWrap: "break-word", // Allow long words to break
  wordBreak: "break-word", // Ensure words break to prevent overflow
}));

const SaveButton = styled(Button)(() => ({
  backgroundColor: "#2961A7",
  color: "#FFF",
  borderRadius: "0.5rem",
  padding: "0.5rem 1.5rem",
  "&:hover": {
    backgroundColor: "#1E4C8A",
  },
  "&.Mui-disabled": {
    backgroundColor: "#E0E0E0",
    color: "#A0A0A0",
  },
}));

// Height options
const HEIGHT_OPTIONS = ["8 ft", "9 ft", "10 ft", "12 ft"];

/**
 * Helper function to format snake_case keys into Title Case strings.
 * e.g., "project_management_cost_per_month" -> "Project Management Cost Per Month"
 */
function formatInputKey(key) {
  if (!key) return "";
  // Replace underscores with spaces, then capitalize the first letter of each word
  return key
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

/**
 * Recursively traverse the nested JSON, collecting all items
 * no matter how deep the subdivisions go.
 */
function transformNestedToArray(nestedData) {
  const results = [];

  // Helper to get subdivision name, falling back if needed
  function getSubdivisionName(node, key) {
    // Prefer node.name, fallback to key (formatted nicely if possible), ensure it's never undefined
    return node?.name || key.replace(/_/g, " ") || "Unnamed Subdivision";
  }

  function traverse(divisionKey, node, parentSubdivisionName) {
    // Determine the current subdivision's display name
    const currentLevelName = node?.name || parentSubdivisionName || "Unnamed";

    // Use parentSubdivisionName if traversing deeper, else use the node's own name
    const subdivisionForItems =
      parentSubdivisionName || getSubdivisionName(node, "");

    // If this node has items, process them
    if (node.items) {
      Object.keys(node.items).forEach((itemKey) => {
        const itemObj = node.items[itemKey];
        if (!itemObj) return;
        
        // Get the name property for display, or fallback to nicely formatted key
        const itemDisplayName = itemObj.name || itemKey.replace(/_/g, " ");
        
        Object.keys(itemObj).forEach((property) => {
          // Skip the 'name' property itself
          if (property === "name") return;
          results.push({
            division: divisionKey,
            subdivision: subdivisionForItems,
            itemKey: itemKey, // Store the original key
            item: itemDisplayName, // Use the name property for display
            input: property,
            defaultValue: itemObj[property],
          });
        });
      });
    }

    // If this node has further subdivisions, recurse deeper
    if (node.subdivisions) {
      Object.keys(node.subdivisions).forEach((subKey) => {
        const subNode = node.subdivisions[subKey];
        if (subNode) {
          const subDisplayName = getSubdivisionName(subNode, subKey);
          traverse(divisionKey, subNode, subDisplayName);
        }
      });
    }
  }

  Object.keys(nestedData).forEach((divisionKey) => {
    const divisionObj = nestedData[divisionKey];
    if (divisionObj) {
      traverse(
        divisionKey,
        divisionObj,
        divisionObj.name || divisionKey.replace(/_/g, " ")
      );
    }
  });

  return results;
}

export default function CompanyPreferences() {
  const [expandedDivisions, setExpandedDivisions] = useState({});
  const [expandedDivision, setExpandedDivision] = useState(""); // Keep track of the last explicitly toggled division if needed
  const [settings, setSettings] = useState([]); // Flat array of settings from API or default
  const [divisions, setDivisions] = useState({}); // Organized by division -> subdivision name -> items
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [changedSettings, setChangedSettings] = useState({}); // Tracks user modifications { uniqueKey: newValue }
  const inputRefs = useRef({}); // For focusing inputs if needed

  // Snackbar state for notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // 'success', 'error', 'warning', 'info'
  });

  // Fetch and organize settings on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        let fetchedSettings = [];
        try {
          // 1) Attempt to get data from API service
          const response = await getDefaultValues(); // Expects { settings: [...] }
          fetchedSettings = response.settings || [];
        } catch (apiError) {
          console.warn("Failed to fetch default values from API:", apiError);
        }

        // 2) If API response is empty or failed, use local default structure
        if (fetchedSettings.length === 0) {
          console.log(
            "API returned no settings or failed, using local default structure."
          );
          fetchedSettings = transformNestedToArray(defaultPreferencesNested); // Ensure fallback is also transformed
        }

        setSettings(fetchedSettings);

        // Organize the flat list into a nested structure for UI display:
        // divisions = { DivisionKey: { SubdivisionName: [item1, item2], ... }, ... }
        const divisionGroups = {};
        fetchedSettings.forEach((setting) => {
          const divisionKey = setting.division;
          // Use either the item’s subdivision name OR fallback to the division’s name if no subdivision is set
          const subdivisionName =
            setting.subdivision || "Unnamed Subdivision";

          if (!divisionGroups[divisionKey]) {
            // Store an internal key to hold the actual "division name" if present
            divisionGroups[divisionKey] = {
              _divisionName: setting.divisionName || divisionKey.replace(/_/g, " "),
            };
          }

          if (!divisionGroups[divisionKey][subdivisionName]) {
            divisionGroups[divisionKey][subdivisionName] = [];
          }
          divisionGroups[divisionKey][subdivisionName].push(setting);
        });

        setDivisions(divisionGroups);

        // Automatically expand all divisions by default after fetching
        const divKeys = Object.keys(divisionGroups).sort();
        const initialExpansionState = {};
        divKeys.forEach((key) => {
          initialExpansionState[key] = true;
        });
        setExpandedDivisions(initialExpansionState);

        if (divKeys.length > 0) {
          setExpandedDivision(divKeys[0]);
        }
      } catch (error) {
        console.error("Error processing settings data:", error);
        setSnackbar({
          open: true,
          message: "Failed to load company preferences.",
          severity: "error",
        });
        setDivisions({});
        setSettings([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Toggle expansion state for a specific division
  const toggleDivision = (divisionName) => {
    setExpandedDivisions((prev) => ({
      ...prev,
      [divisionName]: !prev[divisionName],
    }));
    setExpandedDivision(divisionName);
  };

  // Handle changes in input fields and store them temporarily
  const handleSettingChange = (division, subdivision, itemKey, item, inputKey, value) => {
    const changeKey = `${division}|${subdivision}|${itemKey}|${inputKey}`;
    setChangedSettings((prev) => ({
      ...prev,
      [changeKey]: value,
    }));
  };

  // Specific handler for percentage inputs to ensure cursor position and numeric only
  const handlePercentageFocus = (event) => {
    const input = event.target;
    const value = input.value;
    setTimeout(() => {
      if (input) {
        const valueLength = value ? value.length : 0;
        try {
          input.setSelectionRange(valueLength, valueLength);
        } catch (e) {
          console.warn("Could not set selection range on percentage input:", e);
        }
      }
    }, 0);
  };

  const handlePercentageChange = (
    division,
    subdivision,
    itemKey,
    item,
    inputKey,
    event
  ) => {
    // Allow only numeric digits for percentage fields
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    handleSettingChange(division, subdivision,itemKey, item, inputKey, numericValue);
  };

  // Update search term state as user types in the search box
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNumericChange = (
    division,
    subdivision,
    itemKey,
    item,
    inputKey,
    event,
    allowDecimal = false // Flag to control decimal allowance
  ) => {
    const value = event.target.value;
    let sanitizedValue = "";

    if (allowDecimal) {
      // Allow digits and at most one decimal point
      // Remove non-digit/non-dot characters, then remove subsequent dots
      sanitizedValue = value.replace(/[^0-9.]/g, "").replace(/(\..*?)\./g, '$1');
    } else {
      // Allow only digits (integers)
      sanitizedValue = value.replace(/[^0-9]/g, "");
    }

    // Update the state with the sanitized value
    handleSettingChange(division, subdivision, itemKey, item, inputKey, sanitizedValue);
  };

  // Filter the divisions based on the current search term
  const getFilteredDivisions = () => {
    if (!searchTerm.trim()) {
      return divisions; // No search term, return all divisions
    }
    const searchLower = searchTerm.toLowerCase();
    const filtered = {};

    Object.keys(divisions).forEach((divKey) => {
      const divisionData = divisions[divKey];
      const matchingSubdivisions = {};
      let divisionMatches = false;

      // Check if division key or its stored display name matches
      const divisionDisplayName = divisionData._divisionName || divKey;
      if (
        divKey.toLowerCase().includes(searchLower) ||
        divisionDisplayName.toLowerCase().includes(searchLower)
      ) {
        divisionMatches = true;
      }

      // Check each subdivision
      Object.keys(divisionData).forEach((subName) => {
        if (subName === "_divisionName") return; // Skip the internal name key
        const items = divisionData[subName].filter((item) => {
          const formattedInput = formatInputKey(item.input);
          const itemString = `${item.division} ${item.subdivision} ${item.item} ${item.input} ${formattedInput} ${
            item.defaultValue ?? ""
          }`.toLowerCase();
          return (
            itemString.includes(searchLower) ||
            subName.toLowerCase().includes(searchLower)
          );
        });

        if (items.length > 0) {
          matchingSubdivisions[subName] = items;
        } else if (subName.toLowerCase().includes(searchLower)) {
          matchingSubdivisions[subName] = divisionData[subName];
        }
      });

      if (divisionMatches || Object.keys(matchingSubdivisions).length > 0) {
        filtered[divKey] =
          Object.keys(matchingSubdivisions).length > 0
            ? { ...matchingSubdivisions, _divisionName: divisionData._divisionName }
            : divisionData;
      }
    });

    return filtered;
  };

  // Determine the type of input needed based on the setting's input key or value
  function getInputType(setting) {
    const key = setting.input; // e.g., "waste_factor_percent"
    const value =
        setting.defaultValue !== undefined && setting.defaultValue !== null
            ? String(setting.defaultValue)
            : "";

    if (key === "waste_factor_percent" || key.endsWith("_percentage")) {
        return "percentage";
    }
    if (
        key === "on_center_stud" ||
        key === "joist_spacing" ||
        key === "stud_spacing" ||
        key === "truss_spacing"
    ) {
        return "inches";
    }
    if (key.endsWith("_cost_per_sf")) {
        return "cost_per_sf";
    }
    if (
        key.endsWith("_cost_per_month") ||
        key.startsWith("monthly_") ||
        key.startsWith("Monthly") ||
        (key.toLowerCase().includes("monthly") && key.toLowerCase().includes("cost"))
    ) {
        return "cost_per_month";
    }
    if (key.endsWith("_cost_per_cy") || key.toLowerCase().includes("cost_per_cy")) {
        return "cost_per_cy";
    }
    if (value.toLowerCase() === "yes" || value.toLowerCase() === "no") {
        return "yesno";
    }
    if (
        key.toLowerCase().includes("height") &&
        (value.toLowerCase().includes("ft") || value.toLowerCase().includes("feet"))
    ) {
        if (value === "8 ft, 9 ft, or 10 ft") {
            return "height_multi";
        }
        if (HEIGHT_OPTIONS.includes(value)) {
            return "height";
        }
    }
    return "text";
}



  // Render the correct input component based on the setting type
  const renderInput = (setting, index) => {
    const valueToDisplay = getDisplayValue(setting);
    const inputId = `${setting.division}|${setting.subdivision}|${setting.itemKey}|${setting.input}|${index}`;
    const inputType = getInputType(setting);

    switch (inputType) {
      case "percentage":
        return (
          <PercentageInput>
            <StyledPercentageTextField
              value={valueToDisplay}
              onChange={(e) =>
                handleNumericChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e,
                  true
                )
              }
              onFocus={handlePercentageFocus}
              inputProps={{
                type: "number",
                style: { textAlign: "left" },
                ref: (el) => (inputRefs.current[inputId] = el),
                inputMode: "decimal",
              }}
            />
            <PercentSymbol>%</PercentSymbol>
          </PercentageInput>
        );
      case "cost_per_sf":
        return (
          <CostPerSfInput>
            <StyledCostPerSfTextField
              value={valueToDisplay}
              onChange={(e) =>
                handleNumericChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e,
                  true
                )
              }
              inputProps={{
                type: "number",
                step: "0.01",
                style: { textAlign: "left" },
                ref: (el) => (inputRefs.current[inputId] = el),
                inputMode: "decimal",                
              }}
            />
            <CostPerSfSymbol>$/SF</CostPerSfSymbol>
          </CostPerSfInput>
        );
      case "cost_per_month":
        return (
          <CostPerMonthInput>
            <StyledCostPerMonthTextField
              value={valueToDisplay}
              onChange={(e) =>
                handleNumericChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e,
                  true
                )
              }
              inputProps={{
                type: "number",
                step: "0.01",
                style: { textAlign: "left" },
                ref: (el) => (inputRefs.current[inputId] = el),
                inputMode: "decimal",
              }}
            />
            <CostPerMonthSymbol>$/Month</CostPerMonthSymbol>
          </CostPerMonthInput>
        );
      case "cost_per_cy":
        return (
            <CostPerCyInput>
                <StyledCostPerCyTextField
                    value={valueToDisplay}
                    onChange={(e) =>
                        handleNumericChange(
                            setting.division,
                            setting.subdivision,
                            setting.itemKey,
                            setting.item,
                            setting.input,
                            e,
                            true
                        )
                    }
                    inputProps={{
                        type: "number",
                        step: "0.01",
                        style: { textAlign: "left" },
                        ref: (el) => (inputRefs.current[inputId] = el),
                        inputMode: "decimal",
                    }}
                />
                <CostPerCySymbol>$/Cy</CostPerCySymbol>
            </CostPerCyInput>
        );
             
      case "inches":
        return (
          <InchesInput>
            <StyledInchesTextField
              value={valueToDisplay}
              onChange={(e) =>
                handleNumericChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e,
                  true
                )
              }
              inputProps={{
                type: "number",
                step: "1",
                style: { textAlign: "left" },
                ref: (el) => (inputRefs.current[inputId] = el),
                inputMode: "decimal",
              }}
            />
            <InchesSymbol>inches</InchesSymbol>
          </InchesInput>
        );
      case "yesno":
        return (
          <FormControl sx={{ width: commonFieldWidth }}>
            <Select
              value={valueToDisplay || "No"}
              onChange={(e) =>
                handleSettingChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e.target.value
                )
              }
              sx={{
                height: "2.5rem",
                borderRadius: "0.5rem",
                backgroundColor: "#F5F5F5",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                textAlign: "left",
                "& .MuiSelect-select": { paddingRight: "32px !important" },
              }}
              displayEmpty
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        );
      case "height":
      case "height_multi": {
        const currentHeightValue = valueToDisplay || HEIGHT_OPTIONS[0];
        const allHeightOptions = [...HEIGHT_OPTIONS, "8 ft, 9 ft, or 10 ft"];

        return (
          <FormControl sx={{ width: commonFieldWidth }}>
            <Select
              value={currentHeightValue}
              onChange={(e) =>
                handleNumericChange(
                  setting.division,
                  setting.subdivision,
                  setting.itemKey,
                  setting.item,
                  setting.input,
                  e.target.value
                )
              }
              sx={{
                height: "2.5rem",
                borderRadius: "0.5rem",
                backgroundColor: "#F5F5F5",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                textAlign: "left",
                "& .MuiSelect-select": { paddingRight: "32px !important" },
              }}
            >
              {allHeightOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      default:
        return (
          <TextField
            fullWidth
            value={valueToDisplay || ""}
            onChange={(e) =>
              handleNumericChange(
                setting.division,
                setting.subdivision,
                setting.itemKey,
                setting.item,
                setting.input,
                e,
                true
              )
            }
            sx={{
              width: commonFieldWidth,
              "& .MuiOutlinedInput-root": {
                height: "2.5rem",
                borderRadius: "0.5rem",
                backgroundColor: "#F5F5F5",
                "& fieldset": {
                  border: "none",
                },
              },
              "& .MuiInputBase-input": {
                textAlign: "left",
                paddingRight: "0.75rem",
              },
            }}
            inputProps={{
              ref: (el) => (inputRefs.current[inputId] = el),
              inputMode: "decimal",              
            }}
          />
        );
    }
  };

  // Get the value to display in an input: either the changed value or the original default
  const getDisplayValue = (setting) => {
    const key = `${setting.division}|${setting.subdivision}|${setting.itemKey}|${setting.input}`;
    if (key in changedSettings) {
      return changedSettings[key];
    }
    return setting.defaultValue !== undefined && setting.defaultValue !== null
      ? setting.defaultValue
      : "";
  };

  // Save changed settings back to the server
  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedSettings = settings.map((setting) => {
        const key = `${setting.division}|${setting.subdivision}|${setting.itemKey}|${setting.input}`;
        if (key in changedSettings) {
          return {
            ...setting,
            defaultValue: changedSettings[key],
          };
        }
        return setting;
      });

      const payload = { settings: updatedSettings };
      await updateDefaultValues(payload);

      setSettings(updatedSettings);

      // Re-organize data
      const divisionGroups = {};
      updatedSettings.forEach((s) => {
        const divisionKey = s.division;
        const subdivisionName = s.subdivision || "Unnamed Subdivision";
        if (!divisionGroups[divisionKey]) {
          divisionGroups[divisionKey] = {
            _divisionName: s.divisionName || divisionKey.replace(/_/g, " "),
          };
        }
        if (!divisionGroups[divisionKey][subdivisionName]) {
          divisionGroups[divisionKey][subdivisionName] = [];
        }
        divisionGroups[divisionKey][subdivisionName].push(s);
      });
      setDivisions(divisionGroups);

      setChangedSettings({});
      setSnackbar({
        open: true,
        message: "Company preferences updated successfully.",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to save settings:", error);
      setSnackbar({
        open: true,
        message: "Failed to update preferences. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Close the snackbar notification
  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredDivisionsData = getFilteredDivisions();
  const divisionKeys = Object.keys(filteredDivisionsData).sort((a, b) => {
    const numA = parseInt(a.match(/\d+$/)?.[0] || "0");
    const numB = parseInt(b.match(/\d+$/)?.[0] || "0");
    return numA - numB;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Header Section */}
      <UserProfileFrame>
        <Typography variant="h3semibold35" sx={{ mb: 1 }}>
          Company Preferences
        </Typography>

        {/* Search Section */}
        <UserProfileInnerFrame>
          <UserProfileHeaderFrame>
            <Typography variant="body1SemiBold17">Search</Typography>
          </UserProfileHeaderFrame>
          <SearchFrame>
            <SearchInputFrame>
              <SearchIcon color="action" />
              <StyledInputBase
                placeholder="Search Divisions, Subdivisions, Items..."
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
              />
              {searchTerm && (
                <IconButton size="small" onClick={() => setSearchTerm("")}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </SearchInputFrame>
          </SearchFrame>
        </UserProfileInnerFrame>
      </UserProfileFrame>

      {/* Main Content Area (Divisions List) */}
      <PersonalInfoFrame>
        <PersonalInfoHeaderFrame>
          <Typography variant="body1SemiBold17">Divisions</Typography>
          <SaveButton
            variant="contained"
            onClick={handleSave}
            disabled={Object.keys(changedSettings).length === 0 || loading}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : "Save"}
          </SaveButton>
        </PersonalInfoHeaderFrame>

        {loading && Object.keys(divisions).length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              minHeight: "30rem",
            }}
          >
            <CircularProgress />
          </Box>
        ) : !loading && divisionKeys.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              minHeight: "30rem",
              textAlign: "center",
              p: 2,
            }}
          >
            <Typography>
              {searchTerm
                ? "No settings match your search criteria."
                : "No preferences found."}
            </Typography>
          </Box>
        ) : (
          <DivisionListFrame>
            {divisionKeys.map((divisionKey) => {
              // Pull the actual division name from our stored property
              const divisionDisplayName =
                filteredDivisionsData[divisionKey]._divisionName ||
                divisionKey.replace(/_/g, " ");

              return (
                <Box key={divisionKey} sx={{ mb: 0.5 }}>
                  {/* Division Header (Clickable to toggle) */}
                  <DivisionItemFrame
                    onClick={() => toggleDivision(divisionKey)}
                    sx={{
                      backgroundColor: expandedDivisions[divisionKey]
                        ? "#F5F5F5"
                        : "transparent",
                      borderBottomWidth: expandedDivisions[divisionKey] ? 0 : "1px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: expandedDivisions[divisionKey]
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.2s ease-in-out",
                        marginRight: "0.75rem",
                        fontSize: "0.8rem",
                      }}
                    >
                      ▶
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {divisionDisplayName}
                    </Typography>
                  </DivisionItemFrame>

                  {expandedDivisions[divisionKey] && (
                    <Box sx={{ borderBottom: "1px solid #F3F3F1" }}>
                      {Object.keys(filteredDivisionsData[divisionKey] || {})
                        .filter((k) => k !== "_divisionName")
                        .sort()
                        .map((subdivisionNameKey) => {
                          const items =
                            filteredDivisionsData[divisionKey][subdivisionNameKey];
                          if (!items || items.length === 0) {
                            return null;
                          }

                          // If there's no explicit subdivision name, fall back to the division name
                          const subdivisionDisplayName =
                            items[0].subdivision ||
                            items[0].divisionName ||
                            subdivisionNameKey;

                          return (
                            <SubdivisionContentFrame
                              key={`${divisionKey}-${subdivisionNameKey}`}
                            >
                              <Typography
                                variant="body1"
                                sx={{ mt: 1.5, mb: 1, fontWeight: 600, color: "#444" }}
                              >
                                {/* {subdivisionDisplayName} */}
                              </Typography>

                              <ItemContainer>
                                <ItemHeader>
                                  <ItemColumn flexValue={1.5}>Item</ItemColumn>
                                  <ItemColumn flexValue={1.5}>Input</ItemColumn>
                                  <ItemColumn flexValue={1}>Default Value</ItemColumn>
                                </ItemHeader>

                                {items.map((setting, index) => (
                                  <ItemRow
                                    key={`${setting.division}-${subdivisionDisplayName}-${setting.itemKey}-${setting.input}-${index}`}
                                  >
                                    <ItemColumn flexValue={1.5}>
                                      {setting.item}
                                    </ItemColumn>
                                    <ItemColumn flexValue={1.5}>
                                      {formatInputKey(setting.input)}
                                    </ItemColumn>
                                    <ItemColumn flexValue={1}>
                                      {renderInput(setting, index)}
                                    </ItemColumn>
                                  </ItemRow>
                                ))}
                              </ItemContainer>
                            </SubdivisionContentFrame>
                          );
                        })}
                    </Box>
                  )}
                </Box>
              );
            })}
          </DivisionListFrame>
        )}
      </PersonalInfoFrame>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
