import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  styled,
  Typography,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";

// Cropper & Helpers
import Cropper from "react-easy-crop";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import getCroppedImg from "./getCroppedImg"; // <-- Make sure to have this utility

// Services
import {
  getCompanyProfile,
  updateCompanyProfile,
  deleteCompanyImage,
  fetchCompanyImage,
} from "../../services/companyService";
import {
  getUploadPolicyCompanyProfile,
  uploadToGCS,
} from "../../services/uploadService";

// The 50 U.S. states
const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const UserProfileFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "3.125rem",
  alignSelf: "stretch",
  height: "100vh",
  flex: 1,
}));

const UserProfileInnerFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.375rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
}));

const UserProfileHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
}));

const ProfilePictureFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
}));

const ProfilePictureImageFrame = styled(Box)(() => ({
  display: "flex",
  width: "5.17356rem",
  height: "5.17356rem",
  padding: "1.36913rem 1.8255rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.50706rem",
  borderRadius: "2.58506rem",
  background: "#2961A7",
}));

const ProfilePictureText = styled(Typography)(() => ({
  color: "#FFF",
  fontSize: "1.97763rem",
  fontWeight: 600,
  lineHeight: "120%",
}));

const ProfilePictureButtonsFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.875rem",
}));

const PersonalInfoFrame = styled(Box)(() => ({
  display: "flex",
  padding: "1.5625rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1.5625rem",
  alignSelf: "stretch",
  borderRadius: "1.625rem",
  background: "#FFF",
  flex: 1,
}));

const PersonalInfoHeaderFrame = styled(Box)(() => ({
  display: "flex",
  paddingBottom: "1.0625rem",
  alignItems: "center",
  gap: "1.125rem",
  alignSelf: "stretch",
  borderBottom: "1px solid #D4D4D4",
  justifyContent: "space-between",
}));

const CompanyInfoInnerFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2.75rem",
  alignSelf: "stretch",
}));

const CompanyContentInfoFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.25rem",
  alignSelf: "stretch",
}));

const NameInputFrame = styled(Box)(() => ({
  display: "flex",
  width: "12.6875rem",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const NameInputField = styled(TextField)(({ theme }) => ({
  display: "flex",
  height: "3rem",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    height: "3.02144rem",
    background: theme.palette.neutrals?.background?.input2 || "#F5F5F5",
    borderRadius: "0.94419rem",
    "& fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& input": {
      padding: "0.69244rem 1.00713rem",
      "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${
          theme.palette.neutrals?.background?.input2 || "#F5F5F5"
        } inset`,
        WebkitTextFillColor: theme.palette.text?.primary || "#000",
        transition: "background-color 5000s ease-in-out 0s",
      },
    },
  },
}));

const TeamMemberFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

export default function CompanyProfile() {
  // ----------------------------------------------------------------
  //                     State for Address fields
  // ----------------------------------------------------------------
  const [street, setStreet] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [stateValue, setStateValue] = useState("");

  // Company Contact fields
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  // Possibly store your company logo URL here if you have it
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);

  // ----------------------------------------------------------------
  //                      Crop/Upload States
  // ----------------------------------------------------------------
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  // ----------------------------------------------------------------
  //                      Snackbar
  // ----------------------------------------------------------------
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // ----------------------------------------------------------------
  //               Fetch existing data on mount
  // ----------------------------------------------------------------

  useEffect(() => {
    const fetchlogo = async () => {
      try {
        const data = await fetchCompanyImage();
        console.log(data.logo);
        if (data.logo) {
          setCompanyLogoUrl(data.logo);
        }
      } catch (error) {
        console.error("Failed to fetch company logo:", error);
      }
    };
    fetchlogo();
  }, []);
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const data = await getCompanyProfile();

        // If data.address is present, populate fields
        if (data.address) {
          setStreet(data.address.street || "");
          setApt(data.address.apt || "");
          setCity(data.address.city || "");
          setZipcode(data.address.zipcode || "");
          setStateValue(data.address.state || "");
        }

        setCompanyEmail(data.company_email || "");
        setCompanyPhone(data.company_phone_number || "");

        // If you have a stored logo URL, set it here:
        if (data.logo_url) {
          setCompanyLogoUrl(data.logo_url);
        }
      } catch (error) {
        console.error("Failed to fetch company details:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch company details.",
          severity: "error",
        });
      }
    };
    fetchCompanyDetails();
  }, []);

  // ----------------------------------------------------------------
  //                    Handle Save to API
  // ----------------------------------------------------------------
  const handleSave = async () => {
    try {
      const payload = {
        street,
        apt,
        city,
        state: stateValue,
        zipcode,
        company_email: companyEmail,
        company_phone_number: companyPhone,
        // If you want to pass a logo URL to the server:
        logo_url: companyLogoUrl,
      };
      const response = await updateCompanyProfile(payload);
      const successMessage =
        response.message || "Company details updated successfully.";
      setSnackbar({ open: true, message: successMessage, severity: "success" });
    } catch (error) {
      console.error("Failed to update company details:", error);
      let errorMsg = "Failed to update company details.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      }
      setSnackbar({ open: true, message: errorMsg, severity: "error" });
    }
  };

  // ----------------------------------------------------------------
  //               File Select & Crop Handlers
  // ----------------------------------------------------------------
  const handleSelectLogo = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setIsCropModalOpen(true);
  };
  const handleDeleteLogo = async () => {
    try {
      setCompanyLogoUrl("");
      const res = await deleteCompanyImage();
    } catch (error) {
      console.error("Failed to delete company logo:", error);
    }
  };

  const onCropComplete = useCallback((_, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  const handleCropConfirm = async () => {
    if (!selectedFile || !croppedAreaPixels) return;
    try {
      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(selectedFile),
        croppedAreaPixels,
        rotation
      );
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      // Request upload policy from the server
      const policy = await getUploadPolicyCompanyProfile(
        croppedFile.name,
        croppedFile.type
      );

      // Upload the file to GCS (publicUrl is the final GCS URL)
      const publicUrl = await uploadToGCS(policy, croppedFile);

      // Update local state with the new image URL
      const data = await fetchCompanyImage();
      setCompanyLogoUrl(data.logo);

      // Close modal and reset
      setIsCropModalOpen(false);
      setSelectedFile(null);
      setRotation(0);
      setCrop({ x: 0, y: 0 });
      setZoom(1);

      // Snackbar success
      setSnackbar({
        open: true,
        message: "Logo uploaded and cropped successfully.",
        severity: "success",
      });
    } catch (err) {
      console.error("Error cropping image:", err);
      setSnackbar({
        open: true,
        message: `Error cropping/uploading: ${err.message}`,
        severity: "error",
      });
    }
  };

  // ----------------------------------------------------------------
  //                     Rotation & Zoom
  // ----------------------------------------------------------------
  const handleRotateLeft = () => {
    setRotation((prev) => (prev - 90) % 360);
  };
  const handleRotateRight = () => {
    setRotation((prev) => (prev + 90) % 360);
  };
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };
  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 1));
  };

  // ----------------------------------------------------------------
  //                      Snackbar Close
  // ----------------------------------------------------------------
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  // ----------------------------------------------------------------
  //                          RENDER
  // ----------------------------------------------------------------
  return (
    <Box sx={{ display: "flex", flexDirection:"column", gap: "1.875rem" }}>
      <UserProfileFrame>
        <Typography variant="h3semibold35">Company Profile</Typography>
        <UserProfileInnerFrame>
          <UserProfileHeaderFrame>
            <Typography variant="body1SemiBold17">Profile Picture</Typography>
          </UserProfileHeaderFrame>
          <ProfilePictureFrame>
            {companyLogoUrl ? (
              <Box
                sx={{
                  width: 75,
                  height: 75,
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  src={companyLogoUrl}
                  alt="Profile Logo"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ) : (
              <ProfilePictureImageFrame>
                <ProfilePictureText>JD</ProfilePictureText>
              </ProfilePictureImageFrame>
            )}
            <ProfilePictureButtonsFrame>
              <Button variant="ghost" component="label">
                Upload New
                <input
                  type="file"
                  hidden
                  onChange={handleSelectLogo}
                  accept="image/*"
                />
              </Button>
              <Button variant="indigo" onClick={handleDeleteLogo}>
                Delete
              </Button>
            </ProfilePictureButtonsFrame>
          </ProfilePictureFrame>
        </UserProfileInnerFrame>
      </UserProfileFrame>

      <PersonalInfoFrame>
        <PersonalInfoHeaderFrame>
          <Typography variant="body1SemiBold17">Company Info</Typography>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </PersonalInfoHeaderFrame>
        <CompanyInfoInnerFrame>
          <CompanyContentInfoFrame>
            <Typography variant="body2SemiBold16">Location</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
                alignContent: "flex-start",
              }}
            >
              <NameInputFrame>
                <Typography variant="body2Medium16">Street Address</Typography>
                <NameInputField
                  placeholder="Street Address"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </NameInputFrame>
              <NameInputFrame>
                <Typography variant="body2Medium16">Suite/Apt</Typography>
                <NameInputField
                  placeholder="Suite/Apt"
                  value={apt}
                  onChange={(e) => setApt(e.target.value)}
                />
              </NameInputFrame>
              <NameInputFrame>
                <Typography variant="body2Medium16">City</Typography>
                <NameInputField
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </NameInputFrame>
              <NameInputFrame>
                <Typography variant="body2Medium16">Zipcode</Typography>
                <NameInputField
                  type="text"
                  inputProps={{ pattern: "[0-9]*" }}
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </NameInputFrame>
              <NameInputFrame>
                <Typography variant="body2Medium16">State</Typography>
                <NameInputField
                  select
                  placeholder="State"
                  value={stateValue}
                  onChange={(e) => setStateValue(e.target.value)}
                >
                  {US_STATES.map((st) => (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  ))}
                </NameInputField>
              </NameInputFrame>
            </Box>
          </CompanyContentInfoFrame>
          <CompanyContentInfoFrame>
            <Typography variant="body2SemiBold16">Contact</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
                alignContent: "flex-start",
              }}
            >
              <NameInputFrame>
                <Typography variant="body2Medium16">Company Email</Typography>
                <NameInputField
                  placeholder="Company Email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              </NameInputFrame>
              <NameInputFrame>
                <Typography variant="body2Medium16">Phone Number</Typography>
                <NameInputField
                  placeholder="Phone Number"
                  value={companyPhone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                />
              </NameInputFrame>
            </Box>
          </CompanyContentInfoFrame>
        </CompanyInfoInnerFrame>
      </PersonalInfoFrame>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Crop Modal */}
      <Dialog
        open={isCropModalOpen}
        onClose={() => {
          setIsCropModalOpen(false);
          setSelectedFile(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          sx={{
            position: "relative",
            width: "100%",
            height: 400,
            background: "#333",
          }}
        >
          {selectedFile && (
            <>
              <Cropper
                image={URL.createObjectURL(selectedFile)}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                cropShape="rect"
                showGrid={true}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />
              {/* Zoom Buttons (top-right corner) */}
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={handleZoomIn}
                  color="primary"
                  aria-label="Zoom In"
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  onClick={handleZoomOut}
                  color="primary"
                  aria-label="Zoom Out"
                >
                  <ZoomOutIcon />
                </IconButton>
              </Box>
            </>
          )}
        </DialogContent>
        {/* Rotation & Save/Cancel */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <IconButton onClick={handleRotateLeft} color="primary">
            <RotateLeftIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleRotateRight} color="primary">
            <RotateRightIcon fontSize="large" />
          </IconButton>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              setIsCropModalOpen(false);
              setSelectedFile(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCropConfirm}>
            Save Crop
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
