import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, Typography, Snackbar, Alert } from '@mui/material';
import { ReactComponent as building4Icon } from '../../static/Icons/building4.svg';
import { ReactComponent as building5Icon } from '../../static/Icons/building51.svg';
import { ReactComponent as userProfileIcon } from '../../static/Icons/userProfile.svg';
import { ReactComponent as peopleIcon } from '../../static/Icons/people.svg';
import { ReactComponent as pricingIcon } from '../../static/Icons/pricing.svg';
import { ReactComponent as settingsIcon } from '../../static/Icons/settings.svg';

import Sidebar from '../Sidebar/Sidebar';
import UserProfile from '../AccountSettings/UserProfile';
import CompanyProfile from '../AccountSettings/CompanyProfile';
import TeamManagement from '../AccountSettings/TeamManagement';
import CompanyPreferences from '../AccountSettings/CompanyPreferences';
import BillingPage from '../AccountSettings/Billing';


// -------------------- Styled Components --------------------
const AccountSettingsPageWrapper = styled(Box)(() => ({
  display: 'flex',
}));

const AccountSettingsPageFrame = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '15.6rem',
  width: '12.75rem',
  height: '100vh',
  padding: '2.8125rem 0.5625rem 1.1875rem 0.5625rem',
  justifyContent: 'center',
  gap: '0.625rem',
  flexShrink: 0,
  borderRight: '1px solid var(--Nuetrals-Border---2, #DCDACB)',
  background: 'var(--Nuetrals-Background---Primary, #F3F3F1)',
}));

const AccountSettingsFrame = styled(Box)(() => ({
  display: 'flex',
  width: '11.3125rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.875rem',
  flexShrink: 0,
}));

const AccountSettingsHeaderFrame = styled(Box)(() => ({
  display: 'flex',
  padding: '0.75rem 0.625rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.625rem',
  alignSelf: 'stretch',
}));

const HeaderText = styled(Typography)(() => ({
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '0.02238rem',
  alignSelf: 'stretch',
}));

const SeperationLine = styled(Box)(() => ({
  width: '10.0625rem',
  height: '1px',
  borderBottom: '1px solid #D8D8D8',
}));

const AccountSettingsMenuFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.75rem',
  alignSelf: 'stretch',
}));

const AccountSettingsMenuItemFrame = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  display: 'flex',
  padding: '0.625rem',
  alignItems: 'center',
  gap: '0.625rem',
  alignSelf: 'stretch',
  cursor: 'pointer',
  borderRadius: active ? '0.6875rem' : '0',
  background: active ? theme.palette.neutrals.backgroundSecondary : 'inherit',
  '&:hover': {
    borderRadius: '0.6875rem',
    background: theme.palette.neutrals.backgroundSecondary,
  },
}));

const AccountSettingsMenuItemText = styled(Typography)(() => ({
  color: 'var(--Typography-Primary, #1D1D1B)',
  fontFamily: 'Roboto',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.02238rem',
}));

const UserProfileIconFrame = styled(userProfileIcon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Building4IconFrame = styled(building4Icon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Building5IconFrame = styled(building5Icon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
  stroke: '#1D1D1B'
}));

const PeopleIconFrame = styled(peopleIcon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
}));

const PricingIconFrame = styled(pricingIcon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SettingsIconFrame = styled(settingsIcon)(() => ({
  display: 'flex',
  width: '1.125rem',
  height: '1.125rem',
  justifyContent: 'center',
  alignItems: 'center',
}));

const RemainingSpaceFrame = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  width: 'calc(100% - 28.36rem)',
  alignItems: 'flex-start',
  justifyContent: 'space-around',
  background: 'var(--Nuetrals-Background---Primary, #F3F3F1)',
}));

const RemainingSpaceInnerFrame = styled(Box)(() => ({
  display: 'flex',
  gap: '1.875rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100vh',
  width: '70%',
  padding: '1.875rem 0rem',
  overflow: 'auto',
}));

// -------------------- Main Component --------------------
export default function AccountSettingsPage() {
  const userRole = useSelector((state) => state.auth.userRole);
  const [activeTab, setActiveTab] = useState('UserProfile');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // Helper: Is this an Administrator?
  const isAdmin = userRole === 'Administrator';

  // Conditionally define the menu items:
  // - If admin, show all tabs.
  // - Otherwise, only show User Profile.
  const menuItems = [
    {
      tab: 'UserProfile',
      label: 'User Profile',
      icon: <UserProfileIconFrame />,
    },
    {
      tab: 'CompanyProfile',
      label: 'Company Settings',
      icon: <Building4IconFrame />,
      adminOnly: true,
    },
    {
      tab: 'TeamManagement',
      label: 'Team Management',
      icon: <PeopleIconFrame />,
      adminOnly: true,
    },
    {
      tab: 'CompanyPreferences',
      label: 'Company Preferences',
      icon: <SettingsIconFrame />,
      adminOnly: true,
    },
    {
      tab: 'Billing',
      label: 'Plan & Billing',
      icon: <PricingIconFrame />,
      adminOnly: true,
    },
  ];

  // Decide what to render in the right-hand space
  const renderContent = () => {
    if (!isAdmin) {
      // If NOT admin, always show UserProfile
      return <UserProfile />;
    }

    // If admin, switch on activeTab
    switch (activeTab) {
      case 'UserProfile':
        return <UserProfile />;
      case 'CompanyProfile':
        return <CompanyProfile />;
      case 'TeamManagement':
        return <TeamManagement />;
      case 'CompanyPreferences':
        return <CompanyPreferences />;
      // For tabs that are "in progress", we do not update activeTab
      case 'Billing':
        return <BillingPage />;
      default:
        return <UserProfile />;
    }
  };

  return (
    <AccountSettingsPageWrapper>
      {/* Left global sidebar */}
      <Sidebar />

      {/* The "Account Settings" sidebar on the left */}
      <AccountSettingsPageFrame>
        <AccountSettingsFrame>
          <AccountSettingsHeaderFrame>
            <HeaderText>Account Settings</HeaderText>
            <SeperationLine />
          </AccountSettingsHeaderFrame>

          <AccountSettingsMenuFrame>
            {menuItems
              // For non-admin users, filter out admin-only items:
              .filter((item) => (isAdmin ? true : !item.adminOnly))
              .map((item) => (
                <AccountSettingsMenuItemFrame
                  key={item.tab}
                  active={activeTab === item.tab}
                  onClick={() => setActiveTab(item.tab)}
                >
                  {item.icon}
                  <AccountSettingsMenuItemText>
                    {item.label}
                  </AccountSettingsMenuItemText>
                </AccountSettingsMenuItemFrame>
              ))}
          </AccountSettingsMenuFrame>
        </AccountSettingsFrame>
      </AccountSettingsPageFrame>

      {/* Right side content */}
      <RemainingSpaceFrame>
        <RemainingSpaceInnerFrame>{renderContent()}</RemainingSpaceInnerFrame>
      </RemainingSpaceFrame>

      {/* Snackbar for "in progress" messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          severity="info"
          onClose={() => setOpenSnackbar(false)}
          sx={{ fontSize: '1rem' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </AccountSettingsPageWrapper>
  );
}