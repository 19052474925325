import apiAxios from "./axiosConfig";

export const getUploadPolicy = async (fileName, contentType) => {
  try {
    const response = await apiAxios.post("/upload-policy", {
      file_name: fileName,
      content_type: contentType,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting upload policy:", error);
    throw error;
  }
};

export const uploadToGCS = async (policy, file) => {
  try {
    const response = await fetch(policy.url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("GCS Upload Error:", errorText);
      throw new Error(`Upload failed: ${errorText}`);
    }

    // Return the full GCS URL without any timestamp prefixing
    return `https://storage.googleapis.com/${policy.bucket}/${policy.object_name}`;
  } catch (error) {
    console.error("Error uploading to GCS:", error);
    throw error;
  }
};

export const getUploadPolicyForProposal = async (
  fileName,
  contentType,
  proposalId
) => {
  try {
    const response = await apiAxios.post("/upload-policy-proposal", {
      file_name: fileName,
      content_type: contentType,
      proposalId: proposalId,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting upload policy:", error);
    throw error;
  }
};

export const getUploadPolicyUserProfile = async (fileName, contentType) => {
  try {
    console.log(fileName, contentType);
    const response = await apiAxios.post("/upload-policy-profile", {
      file_name: fileName,
      content_type: contentType,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting upload policy:", error);
    throw error;
  }
};

export const getUploadPolicyCompanyProfile = async (fileName, contentType) => {
  try {
    console.log(fileName, contentType);
    const response = await apiAxios.post("/upload-policy-profile-company", {
      file_name: fileName,
      content_type: contentType,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting upload policy:", error);
    throw error;
  }
};

export const getSignedUrl = async (fileName) => {
  try {
    console.log("Getting signed url for:", fileName);
    const response = await apiAxios.post("/get-policy-proposal", {
      file_name: fileName,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting signed url:", error);
    throw error;
  }
};
