/**
 * Recalculate numeric fields (extended_price, markup_amount, tax_amount, total_price)
 * for each item in each subdivision. Called whenever the user changes data locally.
 */
export function rec_calculate_takeoff(data) {
  data.forEach((division) => {
    if (!Array.isArray(division.subdivisions)) return;
    if (division.divisionName === "General_Info") return;

    division.subdivisions.forEach((sub) => {
      const qty = parseFloat(sub.quantity) || 0;
      const labor = parseFloat(sub.unit_price_labor) || 0;
      const material = parseFloat(sub.unit_price_material) || 0;

      // --- Updated Here ---
      let wasteFactorPercent = 0;
      if (typeof sub.waste_factor_percent === "string" && sub.waste_factor_percent.includes("%")) {
        wasteFactorPercent = parseFloat(sub.waste_factor_percent.replace("%", "").trim());

      } else {
        wasteFactorPercent = parseFloat(sub.waste_factor_percent) || 0;
      }
      // --- End Update ---

      if (wasteFactorPercent < 0) {
        // --- Updated Error Message ---
        throw new Error(
          `Waste factor percent cannot be negative in division "${division.divisionName || "unknown"}", ` +

            `subdivision "${sub.subdivisionName || sub.name || "unknown"}".`
        );
        // --- End Update ---
      }

      // --- Calculation using wasteFactorPercent (formula remains the same as it expects a percentage) ---
      const extendedPrice = qty * (labor + material) * (1 + wasteFactorPercent / 100);

      let markupPerc = 0;
      if (
        typeof sub.markup_percent === "string" &&
        sub.markup_percent.includes("%")
      ) {
        markupPerc = parseFloat(sub.markup_percent.replace("%", "").trim());
      } else {
        markupPerc = parseFloat(sub.markup_percent) || 0;
      }
      if (markupPerc < 0) {
        throw new Error(
          `Markup percent cannot be negative in division "${
            division.divisionName || "unknown"
          }", ` +
            `subdivision "${sub.subdivisionName || sub.name || "unknown"}".`
        );
      }
      const markupAmount = extendedPrice * (markupPerc / 100);

      let taxPerc = 0;
      if (
        typeof sub.tax_percent === "string" &&
        sub.tax_percent.includes("%")
      ) {
        taxPerc = parseFloat(sub.tax_percent.replace("%", "").trim());
      } else {
        taxPerc = parseFloat(sub.tax_percent) || 0;
      }
      if (taxPerc < 0) {
        throw new Error(
          `Tax percent cannot be negative in division "${
            division.divisionName || "unknown"
          }", ` +
            `subdivision "${sub.subdivisionName || sub.name || "unknown"}".`
        );
      }
      const taxAmount = (extendedPrice + markupAmount) * (taxPerc / 100);

      const totalPrice = extendedPrice + markupAmount + taxAmount;

      // Format numeric outputs as strings
      sub.extended_price = Number.isInteger(extendedPrice)
        ? extendedPrice.toString()
        : extendedPrice.toFixed(2).replace(/\.?0+$/, "");
      sub.markup_amount = Number.isInteger(markupAmount)
        ? markupAmount.toString()
        : markupAmount.toFixed(2).replace(/\.?0+$/, "");
      sub.tax_amount = Number.isInteger(taxAmount)
        ? taxAmount.toString()
        : taxAmount.toFixed(2).replace(/\.?0+$/, "");
      sub.total_price = Number.isInteger(totalPrice)
        ? totalPrice.toString()
        : totalPrice.toFixed(2).replace(/\.?0+$/, "");
    });
  });

  return data;
}