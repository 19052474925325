export const getColumnNameToTitleCase = (columnName) => {
    if(!columnName) return "";
    const words = columnName.split("_");
    if(words.length === 0) return "";
    let result = "";
    for (let i = 0; i < words.length; i++) {
      result += words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      if (i < words.length - 1) {
        result += " "; // Add space between words if not the last word.
      }
    }
    return result;
  }    
