import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { borderRadius, padding } from "@mui/system";
import { Margin } from "@mui/icons-material";

const SectionHeader = ({ title }) => (
  <View style={sectionHeaderStyles.sectionHeader}>
    <View style={sectionHeaderStyles.sectionHeaderContent}>
      <Text style={sectionHeaderStyles.sectionLabel}>SECTION</Text>
      <Text style={sectionHeaderStyles.sectionTitle}>{title}</Text>
    </View>
  </View>
);

// Add these styles to your StyleSheets.js
export const sectionHeaderStyles = {
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    // position: "relative",
    // overflow: "hidden",
  },
  sectionHeaderContent: {
    display: "flex",
    backgroundColor: "#2961A7",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    // marginBottom: 20,
    width: "95%",
    padding: 8,
    paddingLeft: 16,
  },
  sectionLabel: {
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    opacity: 0.8,
  },
  sectionTitle: {
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 2,
  },
  sectionHeaderSubsection: {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: "#E74C3C",
    padding: 8,
    width: 60,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subsectionNumber: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
};

export default SectionHeader;
