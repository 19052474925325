import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryIcon from "@mui/icons-material/History";
import { fetchActivityLogs } from "../../services/proposalService";

const ActivityLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadLogs = async () => {
      try {
        const data = await fetchActivityLogs({});
        console.log(data);
        setLogs(data.logs || []);
      } catch (err) {
        setError("Failed to load activity logs");
      } finally {
        setLoading(false);
      }
    };
    loadLogs();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Not set";
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getActionIcon = (action) => {
    switch (action) {
      case "create":
        return <AddIcon />;
      case "edit":
        return <EditIcon />;
      case "sent":
        return <SendIcon />;
      case "approved":
        return <CheckCircleIcon />;
      default:
        return <HistoryIcon />;
    }
  };

  const getActionColor = (action) => {
    switch (action) {
      case "create":
        return "primary";
      case "edit":
        return "info";
      case "sent":
        return "warning";
      case "approved":
        return "success";
      default:
        return "default";
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date & Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Proposal Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{formatDate(log.created_at)}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <PersonIcon fontSize="small" />
                  {log.user_name || "Unknown User"}
                </Box>
              </TableCell>
              <TableCell>
                <Tooltip title={log.action}>
                  <IconButton size="small" color={getActionColor(log.action)}>
                    {getActionIcon(log.action)}
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>{log.message || "No details available"}</TableCell>
              <TableCell>{log.proposal_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActivityLog;
