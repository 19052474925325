// src/components/CustomComponents/CustomButton.jsx
import React from "react";
import { styled, Button } from "@mui/material";

const StyledButton = styled(Button)(
  ({ width, height, border, hoverColor, theme }) => ({
    // Base styling:
    backgroundColor: "#2961A7", 
    color: "#FFFFFF",
    textTransform: "none",

    // Let the user override the button size/border:
    width: width || "auto",
    height: height || "auto",
    border: border || "5rem",
    borderRadius: "5rem",

    // MUI will automatically handle `type="submit"` or any other HTML "type" prop
    "&:hover": {
      backgroundColor: hoverColor || "#00407B", 
    },
  })
);

/**
 * A reusable, customizable button.
 * 
 * Props you can pass:
 * - `width`, `height`, `border`
 * - `hoverColor` if you don’t want the default #00407B
 * - `variant` from MUI if needed ("contained", "outlined", etc.)
 * - `type` ("submit", "button", etc.)
 * 
 * Usage:
 *  <CustomButton width="200px" height="50px" hoverColor="#FF0000">
 *  Click me
 * </CustomButton>
 */



const CustomButton = ({
  children,
  width,
  height,
  border,
  hoverColor,
  ...rest
}) => {
  return (
    <StyledButton
      width={width}
      height={height}
      border={border}
      hoverColor={hoverColor}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
