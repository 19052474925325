import React, { useState, useEffect } from 'react';
import {
  Box,
  styled,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField // <-- For extra jobs input
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// Services
import {
  getSubscriptionInfo,
  cancelSubscription,
  getInvoiceHistory,
  changeSubscriptionPlan,
  getUpcomingInvoice,
  addExtraJobs // <-- New import for "Buy Extra Jobs"
} from '../../services/subscriptionService';

import { getJobs } from '../../services/jobService';
import { fetchUsers } from '../../services/userService';

// -------------------- Plan Definitions --------------------
const PLANS = {
  'ON DEMAND': {
    name: 'On Demand',
    price: 99,
    period: 'per job',
    features: [
      'Pay as you go',
      'No monthly commitment',
      'All features included',
      '1 user included',
    ],
    limitations: ['Base fee + $99 per job'],
    max_jobs: 1,
    max_users: 1,
    priceId: 'ON_DEMAND',
  },
  STARTER: {
    name: 'Starter',
    monthly: {
      price: 250,
      period: 'per month',
      priceId: 'STARTER_MONTHLY',
    },
    yearly: {
      price: 2500,
      period: 'per year',
      priceId: 'STARTER_YEARLY',
    },
    features: [
      'Up to 5 jobs per month',
      'Up to 5 users',
      '$50 per overage job',
      'All features included',
    ],
    limitations: [],
    max_jobs: 5,
    max_users: 5,
  },
  GROWTH: {
    name: 'Growth',
    monthly: {
      price: 500,
      period: 'per month',
      priceId: 'GROWTH_MONTHLY',
    },
    yearly: {
      price: 5000,
      period: 'per year',
      priceId: 'GROWTH_YEARLY',
    },
    features: [
      'Up to 15 jobs per month',
      'Up to 10 users',
      '$30 per overage job',
      'All features included',
    ],
    limitations: [],
    max_jobs: 15,
    max_users: 10,
  },
  PRO: {
    name: 'Pro',
    monthly: {
      price: 1000,
      period: 'per month',
      priceId: 'PRO_MONTHLY',
    },
    yearly: {
      price: 10000,
      period: 'per year',
      priceId: 'PRO_YEARLY',
    },
    features: [
      'Up to 50 jobs per month',
      'Up to 20 users',
      '$20 per overage job',
      'All features included',
    ],
    limitations: [],
    max_jobs: 50,
    max_users: 20,
  },
};

// -------------------- Styled Components --------------------
const BillingPageFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.875rem',
  height: '100%',
  overflowY: 'auto',
  paddingBottom: '2rem',
}));

const SectionFrame = styled(Box)(() => ({
  display: 'flex',
  padding: '1.5625rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.375rem',
  alignSelf: 'stretch',
  borderRadius: '1.625rem',
  background: '#FFF',
  width: '100%',
}));

const SectionHeaderFrame = styled(Box)(() => ({
  display: 'flex',
  paddingBottom: '1.0625rem',
  alignItems: 'center',
  gap: '1.125rem',
  alignSelf: 'stretch',
  borderBottom: '1px solid #D4D4D4',
  justifyContent: 'space-between',
}));

const PlanCard = styled(Card)(({ active }) => ({
  borderRadius: '1rem',
  transition: 'all 0.3s ease',
  border: active ? '2px solid #2961A7' : '1px solid #E0E0E0',
  boxShadow: active ? '0 4px 12px rgba(41, 97, 167, 0.2)' : 'none',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const PlanCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const CheckIconStyled = styled(CheckIcon)(() => ({
  width: '1rem',
  height: '1rem',
  color: '#4CAF50',
}));

const CloseIconStyled = styled(CloseIcon)(() => ({
  width: '1rem',
  height: '1rem',
  color: '#F44336',
}));

const PriceText = styled(Typography)(() => ({
  fontSize: '2rem',
  fontWeight: 700,
  color: '#2961A7',
  marginBottom: '0.5rem',
}));

const PeriodText = styled(Typography)(() => ({
  fontSize: '0.875rem',
  color: '#757575',
  marginBottom: '1rem',
}));

const FeatureText = styled(Typography)(() => ({
  fontSize: '0.875rem',
  marginLeft: '0.5rem',
  color: '#333',
}));

const LimitationText = styled(Typography)(() => ({
  fontSize: '0.875rem',
  marginLeft: '0.5rem',
  color: '#F44336',
}));

const StatusChip = styled(Chip)(({ status }) => {
  let color = '#4CAF50';
  if (status === 'past_due') color = '#FFC107';
  if (status === 'canceled' || status === 'canceling') color = '#F44336';

  return {
    backgroundColor: `${color}20`,
    color: color,
    fontWeight: 600,
    borderRadius: '1rem',
  };
});

const UsageProgressBar = styled(Box)(({ value, max }) => {
  const percentage = Math.min((value / max) * 100, 100);
  let color = '#4CAF50';

  if (percentage > 90) color = '#F44336';
  else if (percentage > 75) color = '#FFC107';

  return {
    width: '100%',
    height: '0.5rem',
    backgroundColor: '#E0E0E0',
    borderRadius: '0.25rem',
    marginTop: '0.25rem',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: `${percentage}%`,
      backgroundColor: color,
      borderRadius: '0.25rem',
      transition: 'width 0.5s ease',
    },
  };
});

// -------------------- Main Component --------------------
export default function Billing() {
  // States for subscription data and usage
  const [subscription, setSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [billingCycle, setBillingCycle] = useState('monthly');

  // Loading & dialogs
  const [loading, setLoading] = useState(true);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // NEW STATES FOR "BUY EXTRA JOBS" DIALOG:
  const [extraJobsDialogOpen, setExtraJobsDialogOpen] = useState(false);
  const [extraJobsValue, setExtraJobsValue] = useState('1');

  // -------------------- Effects --------------------
  useEffect(() => {
    const loadAllData = async () => {
      try {
        setLoading(true);

        // 1) Fetch job & user counts from separate endpoints
        const [jobsData, usersData] = await Promise.all([
          getJobs(),
          fetchUsers(),
        ]);

        setJobCount(jobsData.length || 0);
        setUserCount(usersData.users?.length || 0);

        // 2) Fetch subscription (for plan tier/status)
        const subData = await getSubscriptionInfo();
        console.log('subData:', subData);
        setSubscription(subData.subscription);

        // 3) Fetch invoice history
        const invoiceData = await getInvoiceHistory();
        setInvoices(invoiceData.invoices || []);
      } catch (error) {
        console.error('Error loading Billing data:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load billing information',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadAllData();
  }, []);

  useEffect(() => {
    const loadUpcomingInvoice = async () => {
      try {
        const data = await getUpcomingInvoice();
        setUpcomingInvoice(data.upcoming_invoice); // Might be null if none
      } catch (error) {
        console.error("Failed to load upcoming invoice", error);
      }
    };
  
    loadUpcomingInvoice();
  }, []);


  // -------------------- Handlers --------------------
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      setConfirmCancelDialogOpen(false);

      await cancelSubscription();

      if (subscription) {
        setSubscription({
          ...subscription,
          status: 'canceling',
        });
      }

      setSnackbar({
        open: true,
        message: 'Your subscription has been scheduled for cancellation at the end of the current billing period',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to cancel subscription',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Attempt to change the subscription plan.
   * If successful, re-fetch subscription info or just update state.
   */
  const handlePlanChange = async (priceId) => {
    try {
      setLoading(true);
      const resp = await changeSubscriptionPlan(priceId);
      // Re-fetch subscription info:
      const updatedSub = await getSubscriptionInfo();
      setSubscription(updatedSub.subscription);
      setSnackbar({
        open: true,
        message: resp.message || `Successfully changed plan`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error changing subscription plan:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to change plan',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  // NEW: BUY EXTRA JOBS handlers
  const handleOpenExtraJobsDialog = () => {
    setExtraJobsValue('1');
    setExtraJobsDialogOpen(true);
  };

  const handleCloseExtraJobsDialog = () => {
    setExtraJobsDialogOpen(false);
  };

  const handleSubmitExtraJobs = async () => {
    try {
      setLoading(true);
      const additionalJobs = parseInt(extraJobsValue, 10);
      const resp = await addExtraJobs(additionalJobs);

      // Re-fetch subscription info to see updated max_jobs
      const updatedSub = await getSubscriptionInfo();
      setSubscription(updatedSub.subscription);

      setSnackbar({
        open: true,
        message: resp.message,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding extra jobs:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to add extra jobs',
        severity: 'error',
      });
    } finally {
      setLoading(false);
      setExtraJobsDialogOpen(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const getStatusDisplay = (status) => {
    switch (status) {
      case 'active':
        return 'Active';
      case 'past_due':
        return 'Past Due';
      case 'canceled':
        return 'Canceled';
      case 'canceling':
        return 'Canceling';
      default:
        return status;
    }
  };

  if (loading && !subscription) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  
  if (!loading && !subscription) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant="h6" color="error">Sorry, failed to fetch subscription details.</Typography>
      </Box>
    );
  }
  
  const effectiveJobsLimit =
  (subscription.max_jobs || 0) + (subscription.temporary_job_overage || 0);
  console.log('effectiveJobsLimit:', effectiveJobsLimit);
  console.log('subscription:', subscription);

  // -------------------- Render --------------------

  
  let currentPlanDetails = { price: '--', period: 'per month' };
if (subscription && subscription.plan_id && PLANS[subscription.tier] && PLANS[subscription.tier].monthly) {
  const tierPlans = PLANS[subscription.tier];
  if (tierPlans.monthly.priceId === subscription.plan_id) {
    currentPlanDetails = tierPlans.monthly;
  } else if (tierPlans.yearly.priceId === subscription.plan_id) {
    currentPlanDetails = tierPlans.yearly;
  }
} else if (subscription && PLANS[subscription.tier] && !PLANS[subscription.tier].monthly) {
  // For plans that don't have variants (e.g. ON_DEMAND)
  currentPlanDetails = PLANS[subscription.tier];
}


  return (
    <BillingPageFrame>
      <Typography variant="h3semibold35">Plan & Billing</Typography>

      {/* Current Subscription Section */}
      <SectionFrame>
        <SectionHeaderFrame>
          <Typography variant="body1SemiBold17">Current Subscription</Typography>
          {subscription && subscription.status === 'active' && (
            <Button
              variant="indigo"
              color="error"
              onClick={() => setConfirmCancelDialogOpen(true)}
            >
              Cancel Plan
            </Button>
          )}
        </SectionHeaderFrame>

        {subscription && (
          <Box sx={{ width: '100%' }}>
            {/* Current Plan Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Box>
                <Typography variant="h6" fontWeight={600}>
                  {PLANS[subscription.tier]?.name || subscription.tier}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" color="textSecondary" mr={1}>
                    Status:
                  </Typography>
                  <StatusChip
                    label={getStatusDisplay(subscription.status)}
                    status={subscription.status}
                    size="small"
                  />
                </Box>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <PriceText>
                  {/* ${PLANS[subscription.tier]?.price ?? '--'} */}
                  ${subscription?.price}
                </PriceText>
                <PeriodText>
                {subscription?.period}
                </PeriodText>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Usage Section */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight={600} mb={2}>
                Current Usage
              </Typography>

              {/* Jobs */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Jobs Used</Typography>
                  <Typography variant="body2" fontWeight={600}>
                    {jobCount} / {effectiveJobsLimit}
                  </Typography>
                </Box>
                <UsageProgressBar
                  value={jobCount}
                  max={effectiveJobsLimit || 1}
                />
              </Box>

              {/* BUY EXTRA JOBS BUTTON */}
              {subscription.status === 'active' && (
                <Button
                  variant="contained"
                  onClick={handleOpenExtraJobsDialog}
                  disabled={loading}
                >
                  Buy Extra Jobs
                </Button>
              )}

              {/* Users */}
              <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Team Members</Typography>
                  <Typography variant="body2" fontWeight={600}>
                    {userCount} / {subscription.max_users}
                  </Typography>
                </Box>
                <UsageProgressBar
                  value={userCount}
                  max={subscription.max_users || 1}
                />
              </Box>
            </Box>

            {/* On Demand special note */}
            {subscription.tier === 'ON DEMAND' && (
              <Box sx={{ mb: 2, p: 2, backgroundColor: '#F5F5F5', borderRadius: '0.5rem' }}>
                <Typography variant="body2" fontWeight={600}>
                  On Demand Pricing
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  ${PLANS['ON DEMAND'].price} per job
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </SectionFrame>


      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button
          variant={billingCycle === 'monthly' ? "contained" : "ghost"}
          onClick={() => setBillingCycle('monthly')}
        >
          Monthly
        </Button>
        <Button
          variant={billingCycle === 'yearly' ? "contained" : "ghost"}
          onClick={() => setBillingCycle('yearly')}
          sx={{ ml: 1 }}
        >
          Yearly
        </Button>
      </Box>

      {/* Available Plans Section */}
      <SectionFrame>
        <SectionHeaderFrame>
          <Typography variant="body1SemiBold17">Available Plans</Typography>
        </SectionHeaderFrame>

        <Grid container spacing={3}>
        {Object.entries(PLANS).map(([tierKey, plan]) => {
          // For plans with monthly/yearly variants, determine details for the toggle variant
          const planDetails = plan.monthly ? plan[billingCycle] : plan;
          const isCurrent =
            subscription &&
            subscription.plan_id &&
            plan.monthly &&
            planDetails.priceId === subscription.plan_id;
          
          return (
            <Grid item xs={12} sm={6} md={3} key={tierKey}>
              <PlanCard active={isCurrent}>
                <PlanCardContent>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" fontWeight={600}>
                      {plan.name}
                    </Typography>
                  </Box>
                  <PriceText>${planDetails.price}</PriceText>
                  <PeriodText>{planDetails.period}</PeriodText>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ flexGrow: 1 }}>
                    {plan.features.map((feature, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }} key={index}>
                        <CheckIconStyled />
                        <FeatureText>{feature}</FeatureText>
                      </Box>
                    ))}
                    {plan.limitations.map((limitation, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }} key={index}>
                        <CloseIconStyled />
                        <LimitationText>{limitation}</LimitationText>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ mt: 'auto', pt: 2 }}>
                    {isCurrent ? (
                      <Button variant="outlined" color="primary" fullWidth disabled>
                        Current Plan
                      </Button>
                    ) : subscription && subscription.status === 'active' ? (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handlePlanChange(planDetails.priceId)}
                      >
                        Change Plan
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary" fullWidth disabled>
                        Not Available
                      </Button>
                    )}
                  </Box>
                </PlanCardContent>
              </PlanCard>
            </Grid>
          );
        })}
        </Grid>
      </SectionFrame>


    {/* 3) Upcoming Invoice Section (NEW) */}
    <SectionFrame>
      <SectionHeaderFrame>
        <Typography variant="body1SemiBold17">Upcoming Invoice</Typography>
      </SectionHeaderFrame>

      {/* Render your upcoming invoice data here */}
      {upcomingInvoice ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" gutterBottom>
            Next Payment Date:{' '}
            {new Date(upcomingInvoice.next_payment_attempt * 1000).toLocaleString()}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total: ${upcomingInvoice.total.toFixed(2)}{' '}
            {upcomingInvoice.currency.toUpperCase()}
          </Typography>

          {/* Example line-item table */}
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Proration</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upcomingInvoice.lines.map((line, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{line.description}</TableCell>
                    <TableCell>${line.amount.toFixed(2)}</TableCell>
                    <TableCell>{line.proration ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{line.quantity || 1}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No upcoming invoice found.
        </Typography>
      )}
    </SectionFrame>


      {/* Invoice History Section */}
      <SectionFrame>
        <SectionHeaderFrame>
          <Typography variant="body1SemiBold17">Invoice History</Typography>
        </SectionHeaderFrame>

        {invoices.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 'none', border: '1px solid #E0E0E0' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice #</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.number}</TableCell>
                    <TableCell>{formatDate(invoice.created)}</TableCell>
                    <TableCell>
                      {formatDate(invoice.period_start)} - {formatDate(invoice.period_end)}
                    </TableCell>
                    <TableCell>
                      ${invoice.amount_paid.toFixed(2)}{' '}
                      {invoice.currency.toUpperCase()}
                    </TableCell>
                    <TableCell>
                      <StatusChip
                        label={
                          invoice.status.charAt(0).toUpperCase() +
                          invoice.status.slice(1)
                        }
                        status={invoice.status === 'paid' ? 'active' : invoice.status}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        size="small"
                        href={invoice.hosted_invoice_url}
                        target="_blank"
                        rel="noopener"
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <Typography variant="body1" color="textSecondary">
              No invoices found
            </Typography>
          </Box>
        )}
      </SectionFrame>

      {/* Cancel Subscription Dialog */}
      <Dialog
        open={confirmCancelDialogOpen}
        onClose={() => setConfirmCancelDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to cancel your subscription?
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Your subscription will remain active until the end of the current
            billing period. After that, you will lose access unless you reactivate
            your subscription.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmCancelDialogOpen(false)}>
            Keep Subscription
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>

      {/* NEW: Extra Jobs Dialog */}
      <Dialog
        open={extraJobsDialogOpen}
        onClose={handleCloseExtraJobsDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Buy Extra Jobs</DialogTitle>
        <DialogContent>
          <TextField
            // label="Additional Jobs"
            type="number"
            value={extraJobsValue}
            onChange={(e) => setExtraJobsValue('1')} // Force to always be "1"
            fullWidth
            InputProps={{
              readOnly: true, // Make the field read-only
              inputProps: {
                min: 1,
                max: 1,
              }
            }}            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExtraJobsDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmitExtraJobs}
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </BillingPageFrame>
  );
}
