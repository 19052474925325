// src/components/Snapshot/Snapshot.js

import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Tabs, Tab } from "@mui/material";
import CustomTypography from "../CustomComponents/CustomTypography";

//
// ===================== Styled Components =====================
//

const SnapshotFrame = styled(Box)(() => ({
  display: "flex",
  width: "46rem",
  height: "80vh",
  flexDirection: "column",
  alignItems: "center",
  gap: "2.1875rem",
  justifyContent: "center",
  margin: "0 auto", // Centers horizontally
  flex: 1,
  position: "relative",
}));

const SnapshotInnerFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2.5rem",
  alignSelf: "stretch",
}));

const SnapshotHeader = styled(Box)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Primary, #1D1D1B)",
  textAlign: "center",
  fontFamily: '"Atyp Display TRIAL"',
  fontSize: "1.9375rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

/**
 * SnapshotCard now reflects theme overrides:
 * - borderRadius: "1.9375rem"
 * - boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)"
 */
const SnapshotCard = styled(Box)(() => ({
  display: "flex",
  width: "46rem",
  padding: "0rem 2.1875rem 1.8125rem 2.1875rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.1875rem",
  borderRadius: "1.9375rem",
  background: "var(--Nuetrals-Background---Tertiary, #FFF)",
  boxShadow: "0px 8px 18.7px 0px rgba(175, 170, 126, 0.10)",
}));

const HomeTextFrame = styled(Box)(() => ({
  display: "flex",
  paddingTop: "1.1875rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.875rem",
  alignSelf: "stretch",
  borderBottom: "1px solid var(--Nuetrals-Border---2, #DCDACB)",
}));

const HomeTextInnerFrame = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
}));

const HomeTextInnerInnerFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

const HomeText = styled(CustomTypography)(() => ({
  color: "var(--Typography-Primary, #1D1D1B)",
  fontFamily: '"Atyp Display TRIAL"',
  fontSize: "1.5625rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
}));

const Line = styled(Box)(() => ({
  width: "41.625rem",
  height: "0.0625rem",
}));

const DetailsFrame = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  gap: "1.5rem",
  alignSelf: "stretch",
}));

const SnapshotDetailsFrame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.0625rem",
  flex: "1 0 0",
}));

const SnapshotDetailsHeader = styled(Box)(() => ({
  display: "flex",
  padding: "0.625rem 0rem",
  alignItems: "center",
  gap: "0.625rem",
  alignSelf: "stretch",
}));

const SnapshotHeading = styled(CustomTypography)(() => ({
  color: "var(--Typography-Primary, #1D1D1B)",
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
  textTransform: "uppercase",
}));

const SnapshotDetailsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.25rem",
  alignSelf: "stretch",
}));

const SnapshotDetailsContentFrame = styled(Box)(() => ({
  color: "var(--Typography-Primary, #1D1D1B)",
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
  textTransform: "uppercase",
}));

const SnapshotContentHeaderText = styled(CustomTypography)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Secondary, #666660)",
  fontFamily: "Roboto",
  fontSize: "1.07831rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
}));

const SnapshotContentContentText = styled(CustomTypography)(() => ({
  alignSelf: "stretch",
  color: "var(--Typography-Primary, #1D1D1B)",
  fontFamily: "Roboto",
  fontSize: "1.0625rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
}));

// Next button appears only on the last tab and triggers the onAccept callback.
const NextButton = styled(Box)(() => ({
  display: "flex",
  width: "8.5625rem",
  height: "2.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  borderRadius: "6.25rem",
  background: "var(--Accents-Accent, #2961A7)",
  cursor: "pointer",
}));

const NextButtonText = styled(CustomTypography)(() => ({
  color: "var(--Typography-Primary-alt, #FFF)",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
}));

//
// ===================== Snapshot Component (Dynamic Logic) =====================
//

/**
 * @param {object | string} snapshotData - The dynamic snapshot data (can be a parsed object or JSON string).
 * @param {boolean} loading - Whether data is being loaded.
 * @param {Function} onAccept - Callback when the Next button is clicked (acts as Accept).
 */
const Snapshot = ({ snapshotData, loading, onAccept }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  // If snapshotData is a JSON string, parse it:
  if (snapshotData && typeof snapshotData === "string") {
    snapshotData = JSON.parse(snapshotData);
  }

  const totalTabs = snapshotData?.tabs?.length || 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <SnapshotFrame>
      <SnapshotInnerFrame>
        <SnapshotHeader variant="h3">Snapshot Overview</SnapshotHeader>

        {loading ? (
          <CustomTypography variant="body1">
            Loading snapshot data...
          </CustomTypography>
        ) : (
          <>
            {/* Render the current tab as a card */}
            {snapshotData?.tabs?.map((tab, index) =>
              index === value ? (
                <SnapshotCard key={index}>
                  <HomeTextFrame>
                    <HomeTextInnerFrame>
                      <HomeTextInnerInnerFrame>
                        <HomeText variant="h3">{tab.title}</HomeText>
                      </HomeTextInnerInnerFrame>
                    </HomeTextInnerFrame>
                    <Line
                      sx={{
                        background: theme.palette?.neutrals?.border2 || "#DCDACB",
                      }}
                    />
                  </HomeTextFrame>

                  {/* Sections */}
                  <DetailsFrame>
                    {tab.sections?.map((section, sectionIndex) => (
                      <SnapshotDetailsFrame key={sectionIndex}>
                        <SnapshotDetailsHeader>
                          <SnapshotHeading variant="h3">
                            {section.replace(/([A-Z])/g, " $1").trim()}
                          </SnapshotHeading>
                        </SnapshotDetailsHeader>

                        {/* Key/Value pairs from snapshotData.data[section] */}
                        {Object.entries(snapshotData?.data?.[section] || {}).map(
                          ([key, val], idx) => (
                            <SnapshotDetailsContent key={idx}>
                              <SnapshotDetailsContentFrame>
                                <SnapshotContentHeaderText variant="body1">
                                  {key}
                                </SnapshotContentHeaderText>
                                <SnapshotContentContentText variant="body1">
                                  {Array.isArray(val)
                                    ? val.length > 0
                                      ? val.join(", ")
                                      : "N/A"
                                    : val || "N/A"}
                                </SnapshotContentContentText>
                              </SnapshotDetailsContentFrame>
                            </SnapshotDetailsContent>
                          )
                        )}
                      </SnapshotDetailsFrame>
                    ))}
                  </DetailsFrame>
                </SnapshotCard>
              ) : null
            )}
          </>
        )}
      </SnapshotInnerFrame>

      {/* Bottom Navigation: Tabs indicator and Next button (stuck to the bottom) */}
      {!loading && totalTabs > 0 && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": { justifyContent: "center" },
              "& .MuiTab-root": {
                padding: 0,
                gap: "0.3125rem",
                backgroundColor: "#DCDACB",
                minWidth: "3.5738rem",
                margin: "0 4px",
                borderRadius: "1.07756rem",
                height: "0.4rem",
                minHeight: "0.4rem",
                "&.Mui-selected": { backgroundColor: "#47BCFF" },
              },
            }}
          >
            {snapshotData?.tabs?.map((_, i) => (
              <Tab key={i} />
            ))}
          </Tabs>
          {value === totalTabs - 1 && (
            <NextButton onClick={() => { if (onAccept) onAccept(); }}>
              <NextButtonText variant="body3">Next</NextButtonText>
            </NextButton>
          )}
        </Box>
      )}
    </SnapshotFrame>
  );
};

export default Snapshot;
