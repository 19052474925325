// ValidationPage.jsx

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Sidebar from "../Sidebar/Sidebar";
import TopProgress from "../TopProgress/TopProgress";
import { posthog } from "posthog-js";

import {
  getSnapshotData,
  getTakeoffData,
  updateTakeoffData,
  getJobName,
} from "../../services/jobService";
import {
  addProposalLog,
  createProposal,
  proposal_inuse_toggle,
} from "../../services/proposalService";

import { useDispatch, useSelector } from "react-redux";
import {
  setproposalId,
  setproposalName,
  updateProposalData,
  resetProposalData,
  updateSnapshotData,
  resetSnapshotData,
  setJobId,
  addRecentTakeoff,
} from "../../store";

import TakeoffDisplay from "../EstimationStudio/TakeoffDisplay";
import SnapshotOverview from "../Snapshot/SnapshotOverview";
import SnapshotDrawer from "../Snapshot/SnapshotDrawer";
import AiAssistant from "../EstimationStudio/AiAssistant";
import ImageDrawer from "../EstimationStudio/ImageDrawer";

import LoadingPage from "../FileUpload/LoadingPage";
import { mockSnapshotData } from "../Snapshot/__mocks__/mockSnapshotData";

function transformTakeoffData(rawTakeoff) {
  if (!rawTakeoff) return [];
  const divisions = Object.keys(rawTakeoff);
  const dataArray = [];

  divisions.forEach((divKey) => {
    if (divKey === "General_Info") return;
    const divObj = rawTakeoff[divKey];
    const divisionName = divObj.name || divKey;
    const subdivisions = [];
    Object.keys(divObj.subdivisions || {}).forEach((subKey) => {
      const subObj = divObj.subdivisions[subKey];
      const itemKeys = Object.keys(subObj.items || {});
      itemKeys.forEach((ik) => {
        const item = subObj.items[ik];
        subdivisions.push({
          subdivisionKey: subKey,
          subdivisionName: subObj.name || subKey,
          itemKey: ik,
          itemName: item.name || "",
          quantity: item.quantity ?? 0,
          unit: item.unit || "",
          waste_factor_percent: item.waste_factor_percent ?? "0",
          unit_price_material: item.unit_price_material ?? "0",
          unit_price_labor: item.unit_price_labor ?? "0",
          extended_price: item.extended_price ?? "0",
          markup_percent: item.markup_percent ?? "0",
          markup_amount: item.markup_amount ?? "0",
          tax_percent: item.tax_percent ?? "0",
          tax_amount: item.tax_amount ?? "0",
          total_price: item.total_price ?? "0",
          images: subObj.images || [],
        });
      });
    });

    dataArray.push({
      divisionKey: divKey,
      divisionName,
      subdivisions,
    });
  });
  return dataArray;
}

function convertArrayToTakeOffStructure(dataArray, originalTakeoff) {
  const newTakeoff = {};
  dataArray.forEach((div) => {
    const divisionKey = div.divisionKey || div.divisionName;
    if (!newTakeoff[divisionKey]) {
      newTakeoff[divisionKey] = {
        name: div.divisionName || divisionKey,
        subdivisions: {},
      };
    }
    div.subdivisions.forEach((sub, sIndex) => {
      const subKey = sub.subdivisionKey || `Subdivision_${sIndex + 1}`;
      if (!newTakeoff[divisionKey].subdivisions[subKey]) {
        newTakeoff[divisionKey].subdivisions[subKey] = {
          name: sub.subdivisionName || subKey,
          images: sub.images || [],
          items: {},
        };
      }
      const itemKey = sub.itemKey || `Item_${Date.now()}_${Math.random()}`;
      newTakeoff[divisionKey].subdivisions[subKey].items[itemKey] = {
        name: sub.itemName || "",
        quantity: parseFloat(sub.quantity) || 0,
        unit: sub.unit || "",
        waste_factor_percent: parseFloat(sub.waste_factor_percent) || 0,
        unit_price_material: parseFloat(sub.unit_price_material) || 0,
        unit_price_labor: parseFloat(sub.unit_price_labor) || 0,
        extended_price: parseFloat(sub.extended_price) || 0,
        markup_percent: parseFloat(sub.markup_percent) || 0,
        markup_amount: parseFloat(sub.markup_amount) || 0,
        tax_percent: parseFloat(sub.tax_percent) || 0,
        tax_amount: parseFloat(sub.tax_amount) || 0,
        total_price: parseFloat(sub.total_price) || 0,
      };
    });
  });
  if (originalTakeoff?.General_Info) {
    newTakeoff.General_Info = originalTakeoff.General_Info;
  }
  return newTakeoff;
}

const ValidationPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();

  const pollCountRef = useRef(0);

  const [jobName, setJobName] = useState("");
  const [snapshotData, setSnapshotData] = useState(null);
  const [loadingSnapshot, setLoadingSnapshot] = useState(true);

  const [rawTakeoff, setRawTakeoff] = useState(null);
  const [loadingTakeoff, setLoadingTakeoff] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const unsavedChanges = useSelector(
    (state) => state.takeoffData.unsavedChanges
  );

  // 1) Load job name
  useEffect(() => {
    if (!jobId) return;
    (async () => {
      try {
        const res = await getJobName(jobId);
        if (res && res.job_name) {
          setJobName(res.job_name.replace(/\.pdf$/i, ""));
        } else if (res?.error) {
          console.warn(
            `[ValidationPage] Could not load job name for ${jobId}:`,
            res.error
          );
        }
      } catch (err) {
        console.error(
          `[ValidationPage] Error fetching job name for ${jobId}:`,
          err
        );
      }
    })();
  }, [jobId]);

  useEffect(() => {
    // When jobId changes, scroll to top
    if (jobId) {
      // Reset window scroll
      window.scrollTo({ top: 0, behavior: "smooth" });

      // Also reset scroll position of content containers
      const contentContainer = document.querySelector(
        ".MuiBox-root > .MuiBox-root"
      );
      if (contentContainer) {
        contentContainer.scrollTop = 0;
      }
    }
  }, [jobId]);

  // 2) Warn on unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
        return e.returnValue;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [unsavedChanges]);

  useEffect(() => {
    // Push current state to history stack when component mounts
    window.history.pushState(null, "", window.location.pathname);

    const handlePopState = (e) => {
      if (unsavedChanges) {
        // Prevent default navigation by pushing state back
        window.history.pushState(null, "", window.location.pathname);

        // Ask the user if they want to leave
        const userConfirmed = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        );

        if (userConfirmed) {
          // If they confirm, allow navigation by going back twice
          // (once to overcome our pushState above, once for actual navigation)
          window.history.go(-2);
        }
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [unsavedChanges]);

  // 3) Load snapshot + takeoff data
  useEffect(() => {
    if (!jobId) return;

    // ------------- Added lines for cleanup and isMounted control -------------
    let isMounted = true;
    let pollTimeoutId;
    // -------------------------------------------------------------------------

    console.log(`[ValidationPage] resetting states for job ${jobId}`);
    setLoadingSnapshot(true);
    setSnapshotData(null);

    setLoadingTakeoff(true);
    setRawTakeoff(null);
    setTableData([]);

    setErrorSnackbarOpen(false);
    setErrorSnackbarMessage("");

    // Snapshot fetch
    (async () => {
      try {
        if (process.env.NODE_ENV === "development") {
          if (!isMounted) return; // Stop if unmounted before finishing
          setSnapshotData(mockSnapshotData);
          setLoadingSnapshot(false);
          return;
        }
        const snap = await getSnapshotData(jobId);
        if (!isMounted) return; // Stop if unmounted before finishing
        if (snap?.error) {
          setErrorSnackbarMessage("Failed to generate blueprint snapshot.");
          setErrorSnackbarOpen(true);
          posthog.capture("snapshot:snapshot_failed", {
            job_id: jobId,
            error: snap.error,
            is_internal_user: false,
          });
        } else {
          setSnapshotData(snap.response || snap);
        }
      } catch (err) {
        if (isMounted) {
          console.error(`[ValidationPage] Snapshot error:`, err);
          setErrorSnackbarMessage("Error generating blueprint snapshot.");
          setErrorSnackbarOpen(true);
        }
      } finally {
        if (isMounted) {
          setLoadingSnapshot(false);
          posthog.capture("snapshot:snapshot_loaded", {
            job_id: jobId,
            snapshot_data_points: Object.keys(snapshotData || {}).length,
            is_internal_user: false,
          });
        }
      }
    })();

    pollCountRef.current = 0;
    const maxAttempts = 245000; // 7 days
    const pollInterval = 2500;

    const pollTakeoff = async () => {
      if (!isMounted) return; // Stop if unmounted
      pollCountRef.current += 1;
      console.log(
        `[ValidationPage] Poll #${pollCountRef.current} for job ${jobId}`
      );
      const takeoffRes = await getTakeoffData(jobId);

      if (!isMounted) return; // Stop if unmounted after fetch

      if (takeoffRes?.error) {
        // If "Takeoff not generated" or 404, keep loading
        if (
          takeoffRes.error.includes("not generated") ||
          takeoffRes.error.includes("Try again later")
        ) {
          if (pollCountRef.current < maxAttempts) {
            pollTimeoutId = setTimeout(pollTakeoff, pollInterval);
          }
          return;
        } else {
          // Some other error
          console.error(
            `[ValidationPage] Non-404 error for job ${jobId}:`,
            takeoffRes.error
          );
          setErrorSnackbarMessage(takeoffRes.error);
          setLoadingTakeoff(false);
          posthog.capture("takeoff:takeoff_failed", {
            job_id: jobId,
            error: takeoffRes.error,
            is_internal_user: false,
          });
          return;
        }
      } else {
        // We have valid data
        console.log(`Got valid takeoff for job ${jobId}`);
        setRawTakeoff(takeoffRes.takeoff_details || takeoffRes);
        setLoadingTakeoff(false);
        posthog.capture("takeoff:takeoff_loaded", {
          job_id: jobId,
          takeoff_data: rawTakeoff,
          is_internal_user: false,
        });
      }
    };

    pollTakeoff();

    // ------------- Cleanup function to stop polling if jobId changes or unmounts -------------
    return () => {
      isMounted = false;
      if (pollTimeoutId) {
        clearTimeout(pollTimeoutId);
      }
    };
    // ------------------------------------------------------------------------------------------
  }, [jobId]);

  // 4) Transform rawTakeoff => tableData
  useEffect(() => {
    if (rawTakeoff) {
      dispatch(addRecentTakeoff({ jobId, takeoff: rawTakeoff }));
      setTableData(transformTakeoffData(rawTakeoff));
    } else {
      setTableData([]);
    }
  }, [rawTakeoff, jobId, dispatch]);

  // 5) Create Proposal
  const [proposalDialogOpen, setProposalDialogOpen] = useState(false);
  const [proposalName, setProposalName] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");

  const handleCreateProposal = useCallback(() => {
    setProposalName(jobName || "New Proposal");
    setProposalDialogOpen(true);
  }, [jobName]);

  const handleDialogClose = useCallback(() => {
    setProposalDialogOpen(false);
  }, []);

  const handleDialogNext = async () => {
    const newProposal = await createProposal(
      jobId,
      proposalName,
      proposalDescription,
      rawTakeoff
    );
    if (newProposal && newProposal.id) {
      await proposal_inuse_toggle({ id: newProposal.id });
      dispatch(setJobId(jobId));
      dispatch(setproposalId(newProposal.id));
      dispatch(setproposalName(proposalName));
      posthog.capture("proposal:proposal_created", {
        job_id: jobId,
        proposal_id: newProposal.id,
        proposal_name: proposalName,
        is_internal_user: false,
      });

      dispatch(resetSnapshotData());
      const data =
        typeof snapshotData === "string"
          ? JSON.parse(snapshotData)
          : snapshotData || {};
      dispatch(updateSnapshotData(data));

      dispatch(resetProposalData());
      dispatch(
        updateProposalData({
          ...newProposal.proposal_data,
          settings: {},
        })
      );
      await addProposalLog({
        proposal_id: newProposal.id,
        action: "create",
        message: "Proposal created from Job - " + jobName,
      });
      navigate(`/proposal_editor`);
    } else {
      console.error(`[ValidationPage] CreateProposal error for job ${jobId}`);
      setErrorSnackbarMessage(
        "Something unexpected happened. Please try again."
      );
      setErrorSnackbarOpen(true);
      posthog.capture("proposal:proposal_create_fail", {
        job_id: jobId,
        is_internal_user: false,
      });
    }
  };

  // 6) Save updated table data => backend
  const handleSaveTakeoff = async (updatedArray) => {
    const newShape = convertArrayToTakeOffStructure(updatedArray, rawTakeoff);
    const result = await updateTakeoffData(jobId, newShape);
    if (!result?.error && result.takeoff_details) {
      setRawTakeoff(result.takeoff_details);
    } else {
      console.error(`[ValidationPage] SaveTakeoff error for job ${jobId}`);
      setErrorSnackbarMessage(
        "Failed to update takeoff data. Please try again."
      );
      setErrorSnackbarOpen(true);
    }
  };

  // 7) Subdivision => image drawer
  const handleSubdivisionClick = (dIndex, sIndex) => {
    const divItem = tableData[dIndex];
    if (!divItem) return;
    const { subdivisions } = divItem;
    if (!Array.isArray(subdivisions)) return;
    const subItem = subdivisions[sIndex];
    if (!subItem) return;
    const images = Array.isArray(subItem.images) ? subItem.images : [];
    if (images.length > 0) {
      setSelectedSub({ dIndex, sIndex, images });
      setOpenDrawer("image");
    }
  };

  return (
    <Box sx={{ overflowX: "hidden", overflowY: "hidden" }}>
      <Sidebar />
      <TopProgress currentStep={5} />

      {errorSnackbarOpen && (
        <Box sx={{ mt: "4rem", mx: 2, ml: { xs: 0, md: "15.6rem" } }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setErrorSnackbarOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorSnackbarMessage}
          </Alert>
        </Box>
      )}

      <Box
        sx={{
          marginLeft: "15.6rem",
          marginTop: "3rem",
          padding: "2rem",
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: { xs: "1 1 100%", md: "calc(100% - 320px)" },
            display: "flex",
            flexDirection: "column",
            overflowX: "auto",
          }}
        >
          {loadingTakeoff ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <LoadingPage mainText="Your request is in progress. Check back in a few minutes." />
            </Box>
          ) : (
            <>
              <TakeoffDisplay
                loading={loadingTakeoff}
                tableData={tableData}
                onSave={handleSaveTakeoff}
                onSubdivisionClick={handleSubdivisionClick}
                jobName={jobName}
                jobId={jobId}
              />
              {tableData.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    p: "0.625rem 0.875rem",
                    alignItems: "center",
                    borderRadius: "1.1875rem",
                    border: "1px solid #E9E9E9",
                    boxShadow: "0px 8px 34.8px 0px rgba(185,185,162,0.30)",
                    mt: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateProposal}
                  >
                    Generate Proposal
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "300px" },
            flexShrink: 0,
            position: { xs: "static", md: "sticky" },
            top: { md: "2rem" },
            alignSelf: "flex-start",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{ width: "100%", cursor: "pointer" }}
            onClick={() => setOpenDrawer("snapshot")}
          >
            <SnapshotOverview
              snapshotData={snapshotData}
              loading={loadingSnapshot}
            />
          </Box>
          <Box
            sx={{ width: "100%", cursor: "pointer" }}
            onClick={() => setOpenDrawer("assistant")}
          >
            <AiAssistant jobId={jobId} />
          </Box>
        </Box>
      </Box>

      {/* IMAGE DRAWER */}
      {openDrawer === "image" && selectedSub && (
        <Box
          onClick={() => {
            setOpenDrawer(null);
            setSelectedSub(null);
          }}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{ backgroundColor: "#FFF", p: 2, borderRadius: 2 }}
          >
            <ImageDrawer
              onClose={() => {
                setOpenDrawer(null);
                setSelectedSub(null);
              }}
              images={selectedSub.images || []}
            />
          </Box>
        </Box>
      )}

      {/* SNAPSHOT DRAWER */}
      {openDrawer === "snapshot" && (
        <Box
          onClick={() => setOpenDrawer(null)}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{ backgroundColor: "#FFF", p: 2, borderRadius: 2 }}
          >
            <SnapshotOverview
              snapshotData={snapshotData}
              loading={loadingSnapshot}
              isDrawer
              onClose={() => setOpenDrawer(null)}
            />
          </Box>
        </Box>
      )}

      {/* ASSISTANT DRAWER */}
      {openDrawer === "assistant" && (
        <Box
          onClick={() => setOpenDrawer(null)}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()}
            sx={{ backgroundColor: "#FFF", p: 2, borderRadius: 2 }}
          >
            <AiAssistant
              jobId={jobId}
              isDrawer
              onClose={() => setOpenDrawer(null)}
            />
          </Box>
        </Box>
      )}

      {/* CREATE PROPOSAL DIALOG */}
      <Dialog
        open={proposalDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create Proposal</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              margin="normal"
              label="Proposal Name"
              variant="outlined"
              value={proposalName}
              onChange={(e) => setProposalName(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Proposal Description"
              variant="outlined"
              multiline
              rows={4}
              value={proposalDescription}
              onChange={(e) => setProposalDescription(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setProposalDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogNext}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ValidationPage;
