// src/components/pages/AuthenticationPage.jsx
import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import Login from '../Authentication/Login';
import Signup from '../Authentication/Signup';

import { ReactComponent as IllustrationIcon} from '../../static/Icons/illustration.svg';
import { ReactComponent as LogoHolder } from "../../static/Icons/logo.svg";

/**
 * Combined Authentication page that toggles between Login & Signup.
 * By default, it shows Login first.
 */

const AuthenticationPageFrame = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
}));

const IllustrationFrame = styled(Box)(({theme}) => ({
  display: 'flex',
  flex:2,
  background: theme.palette.neutrals.backgroundPrimary,
  flexDirection: 'column',
}));

const LogoFrame = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'flex-start',
  alignItems: 'center',
  flex:1,
  marginLeft: '2rem',
}));

const LogoTextFrame = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.3rem',
}));

const IllustrationIconFrame = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex:12,
}));


const FormFrame = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));


export default function AuthenticationPage() {
  console.log("Authentication Page");

  // Toggle between Login or Signup
  const [isLogin, setIsLogin] = useState(true);

  return (
    <AuthenticationPageFrame>
        {/* Illustration */}
        <IllustrationFrame>

          <LogoFrame>
            <LogoHolder />
            <LogoTextFrame>
            <Typography variant="logo">assembli</Typography>
            </LogoTextFrame>
          </LogoFrame>
          <IllustrationIconFrame>
            <IllustrationIcon />
          </IllustrationIconFrame>

        </IllustrationFrame>
      <FormFrame>

      <Login />

      </FormFrame>

    </AuthenticationPageFrame>

  );
}
