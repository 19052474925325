import React, { useEffect, useState, useMemo } from "react";
import { 
    Box, 
    Snackbar, 
    Alert, 
    Typography, 
    TextField, 
    IconButton, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem 
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "../Sidebar/Sidebar";

import {
    TableStructureFrame,
    TableStructureHeadingFrame,
    TableStructureHeadingIconFrame,
    TableStructureHeadingIcon,
    TableStructureHeadingRotateIcon,
    TableStructureHeadingText,
} from "../EstimationStudio/TableStructure";

import {
    DataGrid,
    GridActionsCellItem,
    GridRowModes,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import {
    fetchPricingDataByCompany,
    updateCostCatalogRow,
} from "../../services/pricingService";
import { getColumnNameToTitleCase } from "../../utils/generalUtils";

/** Helper to format numbers as USD currency. */
const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

/** Main content styled (reduced gap for less blank space). */
const MainContent = styled(Box)(() => ({
    display: "flex",
    width: "calc(100% - 30rem)", // More responsive width calculation
    maxWidth: "70rem", // Set a max width if needed
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1rem", // smaller gap
    marginLeft: "26.1rem",
    padding: "2rem",
    marginTop: "2.86rem",
    marginRight: "4rem",
    flex: 1,
}));

function UserPricingDivisions({ rowsPerPage = 5 }) {
    const [divisionData, setDivisionData] = useState([]);
    const [allItems, setAllItems] = useState([]); // Store all items for filtering
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchField, setSearchField] = useState("");

    // Toggles for each division (open/close)
    const [openDivisions, setOpenDivisions] = useState({});

    // Global Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchPricingDataByCompany();
                if (!response) {
                    throw new Error("No response from server");
                }
                if (response.error) {
                    throw new Error(response.error);
                }

                const fetchedRows = response.user_pricing_data.map((row, index) => ({
                    id: row.id || row.pricing_id || `temp-${index}`,
                    ...row,
                    // Convert to numbers to avoid NaN
                    unit_price_material: Number(row.unit_price_material),
                    unit_price_labor: Number(row.unit_price_labor),
                }));

                // Save all items for filtering
                setAllItems(fetchedRows);

                // Process and group the data
                processAndGroupData(fetchedRows);
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: `Error fetching data: ${error.message}`,
                    severity: "error",
                });
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // When search query changes, filter and regroup the data
    useEffect(() => {
        if (allItems.length > 0) {
            let filteredItems = [...allItems];
            
            if (searchQuery.trim()) {
                const lowerQuery = searchQuery.toLowerCase();
                filteredItems = allItems.filter(item => {
                    // Handle undefined or null values
                    const fieldValue = item[searchField];
                    if (fieldValue === undefined || fieldValue === null) return false;
                    
                    // Convert to string and search
                    return String(fieldValue).toLowerCase().includes(lowerQuery);
                });
            }
            
            // Process and group the filtered data
            processAndGroupData(filteredItems);
            
            // Auto-expand divisions that have matching items
            if (searchQuery.trim()) {
                const matchingDivisions = {};
                filteredItems.forEach(item => {
                    const divName = item.division_name || "Unknown Division";
                    matchingDivisions[divName] = true;
                });
                
                setOpenDivisions(prev => {
                    const newState = { ...prev };
                    Object.keys(matchingDivisions).forEach(div => {
                        newState[div] = true;
                    });
                    return newState;
                });
            }
        }
    }, [searchQuery, searchField, allItems]);

    // Process and group data into divisions
    const processAndGroupData = (items) => {
        // Group rows by division_name
        const groupedByDivision = {};
        items.forEach((item) => {
            const divisionKey = item.division_name || "Unknown Division";
            if (!groupedByDivision[divisionKey]) {
                groupedByDivision[divisionKey] = [];
            }
            groupedByDivision[divisionKey].push(item);
        });

        // Build division-specific data
        const builtDivisionData = Object.entries(groupedByDivision).map(
            ([divisionName, rows]) => {
                // Generate columns from the first row
                let columns = [];
                if (rows.length > 0) {
                    const sample = rows[0];
                    // We'll define a desired order
                    const desiredOrder = [
                        "subdivision_name",
                        "item_name",
                        "unit",
                        "unit_price_material",
                        "unit_price_labor",
                    ];

                    // Create columns (excluding 'id' and 'division_name')
                    columns = Object.keys(sample)
                        .filter((k) => k !== "id" && k !== "division_name")
                        .map((key) => {
                            const colDef = {
                                field: key,
                                headerName: getColumnNameToTitleCase(key.replace(/_/g, " ")),
                            };
                            
                            // Set flex values based on column type
                            if (key === "subdivision_name") {
                                colDef.flex = 1;
                            } else if (key === "item_name") {
                                colDef.flex = 1;
                            } else if (key === "unit") {
                                colDef.flex = 0.3;
                            } else if (key === "unit_price_material") {
                                colDef.headerName = "Material Cost";
                                colDef.type = "number";
                                colDef.editable = true;
                                colDef.flex = 0.5;
                                colDef.valueFormatter = (params) =>
                                    currencyFormatter.format(params ?? 0);
                            } else if (key === "unit_price_labor") {
                                colDef.headerName = "Labor Cost";
                                colDef.type = "number";
                                colDef.editable = true;
                                colDef.flex = 0.5;
                                colDef.valueFormatter = (params) =>
                                    currencyFormatter.format(params ?? 0);
                            } else if (key.toLowerCase().includes("price")) {
                                colDef.type = "number";
                                colDef.flex = 0.5;
                                colDef.valueFormatter = (params) =>
                                    currencyFormatter.format(params ?? 0);
                            } else {
                                // Default flex for any other columns
                                colDef.flex = 0.5;
                            }
                            return colDef;
                        });

                    // Sort them according to desiredOrder
                    columns.sort((a, b) => {
                        const aIndex = desiredOrder.indexOf(a.field);
                        const bIndex = desiredOrder.indexOf(b.field);
                        // if both not found, keep same order
                        if (aIndex === -1 && bIndex === -1) return 0;
                        // if one not found, it goes to end
                        if (aIndex === -1) return 1;
                        if (bIndex === -1) return -1;
                        // else sort by index
                        return aIndex - bIndex;
                    });
                }

                return {
                    division: divisionName,
                    rows,
                    columns,
                    // Each division has its own rowModesModel
                    rowModesModel: {},
                };
            }
        );

        setDivisionData(builtDivisionData);
    };

    // Get available search fields from the first item
    const searchableFields = useMemo(() => {
        if (allItems.length === 0) return [];
        
        const firstItem = allItems[0];
        return Object.keys(firstItem)
            .filter(key => 
                key !== 'id' && 
                key !== 'division_name' && 
                !key.includes('price') &&
                typeof firstItem[key] !== 'object'
            )
            .map(key => ({
                value: key,
                label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
            }));
    }, [allItems]);

    // Close the Snackbar
    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    // Expand/collapse a division
    const toggleDivision = (divisionName) => {
        setOpenDivisions((prev) => ({
            ...prev,
            [divisionName]: !prev[divisionName],
        }));
    };

    // MUI DataGrid row update (for each division)
    const handleProcessRowUpdate = async (divisionName, newRow, oldRow) => {
        try {
            if (
                (newRow.unit_price_material != null && newRow.unit_price_material < 0) ||
                (newRow.unit_price_labor != null && newRow.unit_price_labor < 0)
            ) {
                throw new Error("Prices must be non-negative.");
            }

            const response = await updateCostCatalogRow(newRow);
            if (!response) {
                throw new Error("No response from server during update.");
            }
            if (response.error) {
                throw new Error(response.error);
            }

            // Update the allItems state to reflect changes
            setAllItems(prev => 
                prev.map(item => 
                    item.id === newRow.id ? {...item, ...newRow} : item
                )
            );

            setSnackbar({
                open: true,
                message: "Row updated successfully",
                severity: "success",
            });
            return newRow;
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Update failed: ${error.message}`,
                severity: "error",
            });
            return oldRow;
        }
    };

    // Update rowModesModel for the correct division
    const handleRowModesModelChange = (divisionName, newModel) => {
        setDivisionData((prev) =>
            prev.map((d) => {
                if (d.division !== divisionName) return d;
                return { ...d, rowModesModel: newModel };
            })
        );
    };

    // Edit/Save/Cancel
    const handleEditClick = (divisionName, id) => () => {
        setDivisionData((prev) =>
            prev.map((d) => {
                if (d.division !== divisionName) return d;
                return {
                    ...d,
                    rowModesModel: {
                        ...d.rowModesModel,
                        [id]: { mode: GridRowModes.Edit },
                    },
                };
            })
        );
    };

    const handleSaveClick = (divisionName, id) => () => {
        setDivisionData((prev) =>
            prev.map((d) => {
                if (d.division !== divisionName) return d;
                return {
                    ...d,
                    rowModesModel: {
                        ...d.rowModesModel,
                        [id]: { mode: GridRowModes.View },
                    },
                };
            })
        );
    };

    const handleCancelClick = (divisionName, id) => () => {
        setDivisionData((prev) =>
            prev.map((d) => {
                if (d.division !== divisionName) return d;
                return {
                    ...d,
                    rowModesModel: {
                        ...d.rowModesModel,
                        [id]: { mode: GridRowModes.View, ignoreModifications: true },
                    },
                };
            })
        );
    };

    const handleClearSearch = () => {
        setSearchQuery("");
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            {/* Sidebar */}
            <Sidebar />

            {/* This container can scroll vertically to show all divisions */}
            <Box sx={{ flex: 1, overflowY: "auto" }}>
                <MainContent>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignSelf: "stretch",
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography variant="h2semibold31">
                            Custom Cost Catalog
                        </Typography>
                    </Box>

                    {/* Search Controls - similar to HomePage */}
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            mb: 2,
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexGrow: 1 }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Search items..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                fullWidth
                                InputProps={{
                                    startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                                    endAdornment: searchQuery && (
                                        <IconButton
                                            onClick={handleClearSearch}
                                            size="small"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Box>

                        <FormControl size="small" sx={{ minWidth: 180 }}>
                            <InputLabel>Search Field</InputLabel>
                            <Select
                                label="Search Field"
                                value={searchField}
                                onChange={(e) => setSearchField(e.target.value)}
                            >
                                {searchableFields.map((field) => (
                                    <MenuItem key={field.value} value={field.value}>
                                        {field.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <TableStructureFrame sx={{ width: "100%" }}>
                        {divisionData.map((divisionObj) => {
                            const { division, rows, columns, rowModesModel } = divisionObj;

                            // Append the "Actions" column at the end
                            const finalColumns = [
                                ...columns,
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerName: "Actions",
                                    width: 120,
                                    getActions: ({ id }) => {
                                        const isInEditMode =
                                            rowModesModel[id]?.mode === GridRowModes.Edit;
                                        if (isInEditMode) {
                                            return [
                                                <GridActionsCellItem
                                                    key={`save-${id}`}
                                                    icon={<SaveIcon />}
                                                    label="Save"
                                                    onClick={handleSaveClick(division, id)}
                                                    color="primary"
                                                />,
                                                <GridActionsCellItem
                                                    key={`cancel-${id}`}
                                                    icon={<CancelIcon />}
                                                    label="Cancel"
                                                    onClick={handleCancelClick(division, id)}
                                                    color="inherit"
                                                />,
                                            ];
                                        }
                                        return [
                                            <GridActionsCellItem
                                                key={`edit-${id}`}
                                                icon={<EditIcon />}
                                                label="Edit"
                                                onClick={handleEditClick(division, id)}
                                                color="inherit"
                                            />,
                                        ];
                                    },
                                },
                            ];

                            // Skip empty divisions when filtering
                            if (searchQuery.trim() && rows.length === 0) {
                                return null;
                            }

                            return (
                                <Box key={division} sx={{ width: "100%" }}>
                                    {/* Division heading with count */}
                                    <TableStructureFrame
                                        onClick={() => toggleDivision(division)}
                                        sx={{
                                            cursor: "pointer",
                                            padding: "0.5rem 1rem",
                                            marginBottom: "0.5rem", // reduce vertical spacing
                                            width: "100%", // Ensure full width
                                        }}
                                    >
                                        <TableStructureHeadingFrame>
                                            <TableStructureHeadingIconFrame>
                                                {openDivisions[division] ? (
                                                    <TableStructureHeadingIcon />
                                                ) : (
                                                    <TableStructureHeadingRotateIcon />
                                                )}
                                            </TableStructureHeadingIconFrame>
                                            <TableStructureHeadingText>
                                                {division}
                                            </TableStructureHeadingText>
                                        </TableStructureHeadingFrame>
                                    </TableStructureFrame>

                                    {/* If open, show the DataGrid */}
                                    {openDivisions[division] && (
                                        <TableStructureFrame
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                                overflowX: "auto", // horizontal scroll if needed
                                                '& .MuiDataGrid-root': {
                                                    width: '100%' // Make DataGrid fill the container
                                                }
                                            }}
                                        >
                                            <Box sx={{ 
                                                height: 400, 
                                                width: '100%', // Set Box to 100% width
                                                '& .MuiDataGrid-root': {
                                                    width: '100%' // Ensure DataGrid takes full width
                                                }
                                            }}>
                                                <DataGrid
                                                    loading={loading}
                                                    rows={rows}
                                                    columns={finalColumns}
                                                    editMode="row"
                                                    rowModesModel={rowModesModel}
                                                    onRowModesModelChange={(model) =>
                                                        handleRowModesModelChange(division, model)
                                                    }
                                                    processRowUpdate={(newRow, oldRow) =>
                                                        handleProcessRowUpdate(division, newRow, oldRow)
                                                    }
                                                    // pageSize={rowsPerPage}
                                                    // rowsPerPageOptions={[5, 10, 25]}
                                                    disableRowSelectionOnClick
                                                    // slots={{ toolbar: GridToolbar }}
                                                    // slotProps={{
                                                    //     toolbar: {
                                                    //         showQuickFilter: true,
                                                    //         printOptions: { disableToolbarButton: true },
                                                    //         csvOptions: { disableToolbarButton: true },
                                                    //     },
                                                    // }}
                                                    onProcessRowUpdateError={(error) => {
                                                        setSnackbar({
                                                            open: true,
                                                            message: `Row update error: ${error.message}`,
                                                            severity: "error",
                                                        });
                                                    }}
                                                    initialState={{
                                                        columns: {
                                                            columnVisibilityModel: {
                                                                id: false,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </TableStructureFrame>
                                    )}
                                </Box>
                            );
                        })}
                    </TableStructureFrame>

                    {/* Show message when no results found */}
                    {searchQuery.trim() && divisionData.every(div => div.rows.length === 0) && (
                        <Box sx={{ p: 2, textAlign: "center", width: "100%" }}>
                            <Typography variant="body1">
                                No items found matching the search criteria.
                            </Typography>
                        </Box>
                    )}

                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbar.severity}
                            sx={{ width: "100%" }}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </MainContent>
            </Box>
        </Box>
    );
}

export default UserPricingDivisions;