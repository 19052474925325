// src/components/CustomTypography.jsx
import React from "react";
import { styled, Typography } from "@mui/material";

// Our 2 main colors:
const PRIMARY_COLOR = "#1D1D1B";
const PRIMARY_ALT = "#FFFFFF";
const SECONDARY_COLOR = "#666660";
const PLACEHOLDER_COLOR = "#747367";
const ASSEMBLI_SECONDARY = "#00407B";

const StyledTypography = styled(Typography)(({ colorOption }) => {
  let color;
  switch (colorOption) {
    case "primary-alt":
      color = PRIMARY_ALT;
      break;
    case "secondary":
      color = SECONDARY_COLOR;
      break;
    case "placeholder":
      color = PLACEHOLDER_COLOR;
      break;
    case "assembli-secondary":
      color = ASSEMBLI_SECONDARY;
      break;
    default:
      color = PRIMARY_COLOR;
  }
  return { color };
});

/**
 * Usage:
 *   <CustomTypography variant="h1">Hello</CustomTypography>
 *   <CustomTypography variant="body2" colorOption="primary-alt">
 *     White text
 *   </CustomTypography>
 */
const CustomTypography = ({ children, colorOption, ...rest }) => {
  return (
    <StyledTypography colorOption={colorOption} {...rest}>
      {children}
    </StyledTypography>
  );
};

export default CustomTypography;
